import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

import api from "resources/endpoints"
import {
  MobilePushExport,
  MobilePushExports,
  MobilePushNotification,
} from "../mobilePushNotificationTypes"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { Segment } from "resources/segment/segment/segmentTypes"
import { MOBILE_PUSH_NOTIFICATION } from "../mobilePushNotificationQueries"

const EXPORT = "export" as const

export function useFetchMobilePushExports(
  id: MobilePushNotification["id"],
  config?: UseInfiniteQueryOptions<MobilePushExports, string>,
) {
  const { data, ...rest } = useInfiniteQuery<MobilePushExports, string>(
    [MOBILE_PUSH_NOTIFICATION, id, EXPORT],
    ({ pageParam }) => api.mobilePushNotification.export.list({ id, limit: 20, offset: pageParam }),
    {
      ...config,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.push_notification_exports.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.push_notification_exports) : [] }
}

export function useCreateMobilePushActivation() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, segmentIds }: { id: MobilePushNotification["id"]; segmentIds: Array<Segment["id"]> }) =>
      api.mobilePushNotification.export.create(id, segmentIds),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([MOBILE_PUSH_NOTIFICATION, id, EXPORT])
        dispatch(showToast("Mobile push sent."))
      },
    },
  )
}

export function useFetchMobilePushExport(
  pushNotificationId: MobilePushNotification["id"],
  exportId: MobilePushExport["id"],
) {
  return useQuery(
    [MOBILE_PUSH_NOTIFICATION, pushNotificationId, EXPORT, exportId],
    () => api.mobilePushNotification.export.retrieve(pushNotificationId, exportId),
    { select: ({ push_notification_export }) => push_notification_export },
  )
}
