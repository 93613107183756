import React, { useRef, useState } from "react"
import classnames from "classnames"
import Button from "components/UI/elements/Button/Button"
import Image from "./reporting.svg"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import { useMutationObserver } from "hooks/useMutationObserver"
import { useFetchSystemInfo } from "resources/systemInfo/systemInfoQueries"
import styles from "./Reporting.module.scss"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { useFetchAllEnabledReports } from "resources/report/reportQueries"

// define iframe height if developing on localhost because iframe is cross origin and
// you cannot set height dynamically based on content
const DEFAULT_IFRAME_HEIGHT = process.env.NODE_ENV !== "production" ? 500 : 0

export default function Reporting() {
  const [activeIndex, setActiveIndex] = useState(0)
  const hasAccess = useHasAccess()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeHeight, setIframeHeight] = useState(DEFAULT_IFRAME_HEIGHT)

  const { data: systemInfo, isLoading: isLoadingSystemInfo } = useFetchSystemInfo()
  const reportingEnabled = systemInfo?.reporting_enabled

  const {
    data: reports,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useFetchAllEnabledReports({
    enabled: Boolean(reportingEnabled && hasAccess.reports),
  })

  const updateIframeHeight = () => {
    if (DEFAULT_IFRAME_HEIGHT === 0) {
      if (iframeRef.current) {
        const embedFrames =
          iframeRef.current.contentWindow?.document.getElementsByClassName("EmbedFrame")
        if (embedFrames && embedFrames.length > 0) {
          const embedFrame = embedFrames[0]
          setIframeHeight(embedFrame.scrollHeight + 2)
        }
      }
    }
  }

  useMutationObserver({
    target:
      DEFAULT_IFRAME_HEIGHT === 0 ? iframeRef.current?.contentWindow?.document.body : undefined,
    options: {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    },
    callback: updateIframeHeight,
  })

  // `isLoading` is true if the query is disabled and stays true. We have to check `isFetching` to
  //  exclude that scenario from this if statement.
  const isLoading = (isLoadingReports && isFetchingReports) || isLoadingSystemInfo

  if (!isLoading && !reportingEnabled) return <ReportingNotEnabled />

  if (!hasAccess.reports)
    return (
      <Page title="Reporting">
        <MarketingContent img={{ alt: "Reporting", src: Image }}>
          <h1>Reporting</h1>
          <strong>
            It seems like you don't have access to the Reporting tab. If you want to know more about
            your access settings, please contact your administrator.
          </strong>
          <p>
            The Reporting tab shows analytics for your data set and it's customized by the Meiro
            team. Here, you can check various charts across your data sources, as well as the web
            banners' performance.
          </p>
          <p>
            P.S. Do you still need your CDP data in Tableau, Qlick, Yellowfin, etc? No problem! All
            this flexibility is just for you, your Meiro account manager will help you to set it up.
          </p>
          <p>
            Learn more about{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/chapter/reporting-dashboard"
              target="_blank"
              rel="noreferrer"
            >
              reporting dashboard examples here
            </a>
            .
          </p>
        </MarketingContent>
      </Page>
    )

  if (!isLoading && reports?.length === 0) return <ReportingNotEnabled />

  return (
    <Page title="Reporting">
      {isLoading && <LoadingIndicator />}
      {!isLoading && reports && (
        <>
          <PaperHeader size="small" className={styles.reportingHeader}>
            {reports.map((report, index) => (
              <Button
                key={report.id}
                color="grey"
                variant="outlined"
                className={classnames(styles.sectionButton, {
                  [styles.active]: activeIndex === index,
                })}
                onClick={() => {
                  if (activeIndex !== index) {
                    const previous = reports[activeIndex]
                    const next = reports[index]
                    setActiveIndex(index)
                    if (previous.link !== next.link) {
                      setIframeHeight(DEFAULT_IFRAME_HEIGHT)
                    }
                  }
                }}
              >
                {report.name}
              </Button>
            ))}
          </PaperHeader>
          <Paper hasHeader className={styles.content}>
            <div className={styles.iframeWrapper}>
              <LoadingIndicator className={styles.iframeLoading} />
              <iframe
                key={activeIndex}
                className={styles.embed}
                title="Report"
                src={reports[activeIndex].link}
                ref={iframeRef}
                height={iframeHeight}
                onLoad={updateIframeHeight}
              ></iframe>
            </div>
          </Paper>
        </>
      )}
    </Page>
  )
}

const ReportingNotEnabled = () => (
  <Page title="Reporting">
    <MarketingContent img={{ alt: "Reporting", src: Image }}>
      <h1>Reporting</h1>
      <strong>
        To set this up, please contact{" "}
        <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
          support@meiro.io
        </a>{" "}
        or get in touch with your Meiro account manager!
      </strong>
      <p>
        We are closing the stack from the top end by adding full data visualization and reporting
        layers. Use your integrated data, build or let us help you build bespoke reports.
      </p>
      <p className={styles.postScript}>
        P.S. Do you still need your CDP data in Tableau, Qlick, Yellowfin, etc? No problem! All this
        flexibility is just for you, your Meiro account manager will help you to set it up.
      </p>
    </MarketingContent>
  </Page>
)
