import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import {
  useFetchPushNotificationsChannel,
  useModifyPushNotificationsChannel,
} from "resources/channel/channelQueries"
import PushNotificationsChannelForm, {
  PushNotificationsChannelFormValues,
} from "./PushNotificationsChannelForm"

export default function PushNotificationsChannel() {
  const { data, isLoading } = useFetchPushNotificationsChannel()
  const { mutate, isLoading: isModifying } = useModifyPushNotificationsChannel()

  const submitForm = async ({
    firebaseFileList,
    registrationTokenId,
  }: PushNotificationsChannelFormValues) => {
    if (isModifying) {
      return
    }

    const formData = new FormData()
    if (firebaseFileList) formData.append("firebase_account_data", firebaseFileList[0])
    if (registrationTokenId) formData.append("registration_token_attribute_id", registrationTokenId)

    mutate({ data: formData })
  }

  if (isLoading) {
    return (
      <Page title="Mobile Push channel">
        <LoadingIndicator />
      </Page>
    )
  }

  if (!data) {
    return null
  }

  return (
    <PushNotificationsChannelForm channel={data} onSubmit={submitForm} isSubmitting={isModifying} />
  )
}
