import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Flag } from "types/util"
import {
  CustomerAttributeRetrieveResponse,
  CustomerAttributesListPayload,
  CustomerAttributeListResponse,
  CustomerAttributeRetrievePaginatedPayload,
} from "./customerAttributeTypes"

const customerAttribute = {
  list<T extends Flag = 0>({
    customer_entity_id,
    attribute_values_max_count = 10,
    load_full_structure = 0 as T,
  }: CustomerAttributesListPayload): Promise<CustomerAttributeListResponse<T>> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory(
          "get",
          `/customers/${customer_entity_id}/attributes`,
          {
            offset,
            limit,
            load_full_structure,
            attribute_values_max_count,
          },
          false,
          true,
          "",
          false,
          true,
        ),
      key: "customer_attributes",
      includeAll: true,
    })
  },
  retrieve({
    attribute_id,
    customer_entity_id,
    limit = 10,
    offset = 0,
  }: CustomerAttributeRetrievePaginatedPayload): Promise<CustomerAttributeRetrieveResponse> {
    return requestFactory("get", `/customers/${customer_entity_id}/attributes/${attribute_id}`, {
      offset,
      limit,
    })
  },
}

export default customerAttribute
