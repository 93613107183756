import React, { lazy, PureComponent, Suspense } from "react"
import { connect } from "react-redux"
import { isDirty, submit, reset, formValueSelector } from "redux-form"
import PropTypes from "prop-types"
import { ReactSortable } from "react-sortablejs"
import _isEmpty from "lodash/isEmpty"
import _toInteger from "lodash/toInteger"
import _get from "lodash/get"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"

// ui components
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import InsightForm from "./components/InsightForm"
import ConfirmModal from "components/UI/components/ConfirmModal"

// actions
import { showToast } from "actions/toast.action"

// helpers
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { getFunctionName } from "helpers/insight.helper"
import { api } from "api"
import { SEGMENT_ANALYTICS_FUNCTIONS, TOAST, MODAL } from "sharedConstants"

import "./Insights.scss"
import {
  getCompoundAttributeSubAttribute,
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import InsightsFilterForm from "components/UI/components/InsightsFilterForm"
import classNames from "classnames"
const DummyInsight = lazy(() => import("./components/DummyInsight"))

const CONDITION_HAS_VALUE = [
  SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
  SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
]

const CONDITION_HAS_COUNT = [
  SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
]

const CONDITION_HAS_TILE_DISPLAY = [
  SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
  SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
]

const CONDITION_HAS_TWO_VALUES = [SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value]

const DragHandle = ({ moving, disabled }) => (
  <IconButton
    className={classNames("drag-button", { moving, disabled })}
    color="grey"
    icon="grip-vertical"
    variant="transparent"
    disabled={disabled}
    tooltip={disabled ? "Dragging is disabled when items are filtered." : undefined}
  />
)

const SortableItem = ({
  value,
  compareValue,
  attribute,
  subAttribute,
  toggleModeFunction,
  toggleConfirmModal,
  onMouseOver,
  onMouseOut,
  showTooltip,
  moving,
  id,
  draggingDisabled,
}) => {
  return (
    <div className={`sortable-tile-wrapper`} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <div className="tile-with-buttons">
        <div onClick={toggleModeFunction} className="tile-clickable">
          <Suspense fallback={<LoadingIndicator />}>
            <DummyInsight
              id={id}
              name={value.name}
              compareValue={compareValue}
              funcType={value.function}
              attribute={attribute}
              attributeDeleted={!attribute}
              subAttribute={subAttribute}
              color={_get(value, "frontend_settings.color")}
              displayType={_get(value, "frontend_settings.tile_type", "chart")}
              count={_get(value, "settings.count")}
              showNewBadge={moment().diff(value.created, "days") < 8}
            />
          </Suspense>
        </div>
        <div className={`action-buttons ${showTooltip === true ? "shown" : "hidden"}`}>
          <IconButton
            color="grey"
            className="edit-button"
            onClick={toggleModeFunction}
            iconStyle="far"
            icon="pencil"
            variant="transparent"
          />
          <DragHandle moving={moving} disabled={draggingDisabled} />
          <IconButton
            color="grey"
            onClick={toggleConfirmModal(value.attribute_id, value.id, value.name)}
            className="trash-button"
            icon="trash-alt"
            variant="transparent"
          />
        </div>
      </div>
    </div>
  )
}

class Insights extends PureComponent {
  afterSaveAction = "list"

  constructor(props) {
    super(props)
    this.state = {
      mode: "table",
      editingAggregation: {},
      data: null,
      deleteModal: {
        open: false,
        itemName: "",
        aggregationId: null,
        attributeId: null,
        loading: false,
      },
      tooltip: null,
      moving: false,
      dragging: false,
      unsavedThingsModal: {
        show: false,
        step: null,
      },
    }
  }

  componentDidMount() {
    this.fetchAllAttributesAggregations()
  }

  fetchAllAttributesAggregations = async () => {
    const caller = new AllResourceItemsFetcher()
    const data = await caller
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.attributesAggregations.list(offset, limit, "order_index", "ASC", loadFullStructure, 0),
      )
      .setDataPath("attribute_aggregations")
      .setLoadFullStructure(0)
      .run()
    this.setState({
      data: data,
    })
  }

  toggleMode =
    (aggregation = {}) =>
    () => {
      this.setState(prevState => ({
        mode: prevState.mode === "table" ? "form" : "table",
        editingAggregation: aggregation,
        tooltip: null,
      }))
    }

  saveAnalyticsForm = async data => {
    if (!this.state.saving) {
      this.setState({
        saving: true,
      })
      const { editingAggregation } = this.state
      const color = _get(data, "color", null)
      const tileType = _get(data, "tile_type")
      const frontendSettings = {}
      if (!_isEmpty(editingAggregation)) {
        // update existing
        let settingsValue = null
        if (CONDITION_HAS_VALUE.includes(data.function.value)) {
          settingsValue = {
            value: data.value,
          }
        } else if (CONDITION_HAS_COUNT.includes(data.function.value)) {
          settingsValue = {
            count: _toInteger(data.count),
          }
        } else if (CONDITION_HAS_TWO_VALUES.includes(data.function.value)) {
          settingsValue = {
            value_from: data.value_from,
            value_to: data.value_to,
          }
        }
        if (CONDITION_HAS_TILE_DISPLAY.includes(data.function.value)) {
          frontendSettings.tile_type = tileType
        }
        const reqObjectData = {
          attribute_id: data.attribute_id,
          sub_attribute_id: data.subattribute ? data.subattribute.value : null,
          name: data.name,
          function: data.function.value,
          description: data.description ? data.description : null,
          settings: settingsValue,
          frontend_settings: {
            ...editingAggregation.frontend_settings,
            ...frontendSettings,
            color,
          },
        }
        try {
          const response = await api.attributesAggregations.modify(
            editingAggregation.id,
            reqObjectData,
          )
          this.props.showToast("Insight edited.", TOAST.TYPE.SUCCESS)
          const aggregation = response.attribute_aggregation
          this.setState(prevState => ({
            saving: false,
            data: prevState.data.map(item => {
              if (item.id === aggregation.id) {
                return aggregation
              }
              return item
            }),
            mode: "table",
          }))
        } catch (err) {
          if (_get(err, "response.status") === 404) {
            this.fetchAllAttributesAggregations()
            this.setState({
              saving: false,
              mode: "table",
            })
          } else {
            this.setState({
              saving: false,
            })
          }
        }
      } else {
        // create new
        try {
          if (CONDITION_HAS_TILE_DISPLAY.includes(data.function.value)) {
            frontendSettings.tile_type = tileType
          }
          const response = await this.createNewAttributeAggregation({
            ...data,
            frontend_settings: { ...frontendSettings, color },
          })
          if (this.afterSaveAction === "form") {
            this.props.dispatch(reset("CreateInsightForm"))
          }
          this.props.showToast("Insight created.", TOAST.TYPE.SUCCESS)
          this.setState(prevState => ({
            saving: false,
            data: [...prevState.data, response.attribute_aggregation],
            mode: this.afterSaveAction === "list" ? "table" : "form",
          }))
        } catch (err) {
          this.setState({
            saving: false,
          })
        }
      }
    }
  }

  createNewAttributeAggregation = async formData => {
    const reqObjectData = {
      name: formData.name,
      function: formData.function.value,
      frontend_settings: formData.frontend_settings,
      attribute_id: formData.attribute_id,
      sub_attribute_id: formData.subattribute ? formData.subattribute.value : null,
    }
    if (formData.description) {
      reqObjectData["description"] = formData.description
    }
    if (formData.value && CONDITION_HAS_VALUE.includes(formData.function.value)) {
      reqObjectData["settings"] = { value: formData.value }
    } else if (formData.count && CONDITION_HAS_COUNT.includes(formData.function.value)) {
      reqObjectData["settings"] = { count: _toInteger(formData.count) }
    } else if (
      formData.value_from &&
      formData.value_to &&
      CONDITION_HAS_TWO_VALUES.includes(formData.function.value)
    ) {
      const { value_from, value_to } = formData
      reqObjectData["settings"] = { value_from, value_to }
    }
    return await api.attributesAggregations.create(reqObjectData)
  }

  onSaveClick = type => () => {
    this.afterSaveAction = type
    this.props.dispatch(submit("CreateInsightForm"))
  }

  toggleConfirmModal = (attributeId, aggregationId, itemName) => evt => {
    if (evt) {
      evt.stopPropagation()
    }

    if (attributeId && aggregationId) {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: true,
          attributeId,
          aggregationId,
          itemName,
        },
      }))
    } else {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: false,
        },
      }))
    }
  }

  deleteAttributeAggregation = async () => {
    const { deleteModal } = this.state
    try {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          loading: true,
        },
      }))
      await api.attributesAggregations.delete(deleteModal.aggregationId)
      this.props.showToast("Insight deleted.", TOAST.TYPE.SUCCESS)
      this.setState(prevState => ({
        data: prevState.data.filter(item => item.id !== deleteModal.aggregationId),
        mode: "table",
        deleteModal: {
          ...prevState.deleteModal,
          open: false,
          loading: false,
        },
      }))
    } catch (err) {
      if (_get(err, "response.status") === 404) {
        // attribute aggregation was already deleted
        this.fetchAllAttributesAggregations()
        this.setState(prevState => ({
          mode: "table",
          deleteModal: {
            ...prevState.deleteModal,
            open: false,
            loading: false,
          },
        }))
      } else {
        this.setState(prevState => ({
          deleteModal: {
            ...prevState.deleteModal,
            loading: false,
          },
        }))
      }
    }
  }

  onSortEnd = async evt => {
    const { oldIndex, newIndex } = evt
    if (oldIndex !== newIndex) {
      const { data } = this.state
      const destinationAggregation = data[newIndex]
      const sourceAggregation = data[oldIndex]
      try {
        this.setState({
          moving: true,
        })
        await api.attributesAggregations.modify(sourceAggregation.id, {
          order_index: destinationAggregation.order_index,
        })
        await this.fetchAllAttributesAggregations()
        this.setState({ moving: false })
      } catch (err) {
        await this.fetchAllAttributesAggregations()
        this.setState({ moving: false })
      }
    }
  }

  onMouseOver = aggregationId => () => {
    const { dragging, tooltip } = this.state
    if (tooltip !== aggregationId && !dragging) {
      this.setState({
        tooltip: aggregationId,
      })
    }
  }

  onMouseOut = aggregationId => () => {
    const { dragging, tooltip } = this.state
    if (tooltip === aggregationId && !dragging) {
      this.setState({
        tooltip: null,
      })
    }
  }

  navigateInsights =
    (step, force = false) =>
    () => {
      const { data, editingAggregation } = this.state
      if (this.props.isFormDirty && force === false) {
        this.setState({
          unsavedThingsModal: {
            show: true,
            step,
          },
        })
      } else {
        const findCurrentInsightIndex = id => {
          return data.findIndex(item => item.id === id)
        }

        const currentInsightIndex = findCurrentInsightIndex(editingAggregation.id)
        if (currentInsightIndex !== -1) {
          if (
            (currentInsightIndex > 0 && step === -1) ||
            (currentInsightIndex < data.length - 1 && step === 1)
          ) {
            this.setState({
              editingAggregation: data[currentInsightIndex + step],
            })
          }
        }
      }
    }

  closeUnsavedInsightModal = () => {
    this.setState({
      unsavedThingsModal: {
        show: false,
        step: null,
      },
    })
  }

  confirmInsightNavigation = () => {
    const { unsavedThingsModal } = this.state
    this.navigateInsights(unsavedThingsModal.step, true)()
    this.closeUnsavedInsightModal()
  }

  render() {
    const {
      mode,
      editingAggregation,
      data: insights,
      deleteModal,
      tooltip,
      moving,
      unsavedThingsModal,
    } = this.state
    const { areAttributesFulfilled, attributesMapById, searchTerm, filteredSource } = this.props

    if (!areAttributesFulfilled || !insights) {
      return (
        <Page title="Insights">
          <LoadingIndicator />
        </Page>
      )
    }

    let filteredInsights = insights
    if (searchTerm) {
      filteredInsights = filteredInsights.filter(item =>
        item.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
      )
    }
    if (filteredSource) {
      filteredInsights = filteredInsights.filter(
        item => attributesMapById[item.attribute_id]?.source.id === filteredSource.value,
      )
    }
    const isFiltered = filteredInsights !== insights

    let headerTitle = "Insights"
    if (mode === "form" && _isEmpty(editingAggregation)) {
      headerTitle = "Create insight"
    } else if (mode === "form" && !_isEmpty(editingAggregation)) {
      headerTitle = "Edit insight"
    }

    const initialValues = {
      tile_type: "chart",
      count: 1,
    }
    let isLeftNavButtonDisabled = false,
      isRightNavButtonDisabled = false
    if (!_isEmpty(editingAggregation)) {
      if (insights.length > 1) {
        if (editingAggregation.id === insights[0].id) {
          isLeftNavButtonDisabled = true
        } else if (editingAggregation.id === insights[insights.length - 1].id) {
          isRightNavButtonDisabled = true
        }
      }

      initialValues["name"] = editingAggregation.name
      initialValues["attribute_id"] = editingAggregation.attribute_id
      initialValues["description"] = editingAggregation.description
      initialValues["color"] = _get(editingAggregation, "frontend_settings.color")
      initialValues["tile_type"] = _get(editingAggregation, "frontend_settings.tile_type", "chart")
      const attribute = attributesMapById[editingAggregation.attribute_id]
      if (attribute) {
        let dataType
        if (isAttributeCompound(attribute.data_type)) {
          const subAttribute = getCompoundAttributeSubAttribute(
            editingAggregation.sub_attribute_id,
            attribute.data_type,
          )
          if (subAttribute) {
            initialValues["subattribute"] = {
              ...subAttribute,
              label: subAttribute.name,
              value: subAttribute.id,
            }
            dataType = subAttribute.data_type
          }
        } else {
          dataType = attribute.data_type
        }
        initialValues["function"] = {
          label: getFunctionName(editingAggregation.function, dataType),
          value: editingAggregation.function,
        }
      }
      if (CONDITION_HAS_VALUE.includes(editingAggregation.function)) {
        initialValues["value"] = _get(editingAggregation, "settings.value")
      } else if (CONDITION_HAS_COUNT.includes(editingAggregation.function)) {
        initialValues["count"] = _get(editingAggregation, "settings.count")
      } else if (CONDITION_HAS_TWO_VALUES.includes(editingAggregation.function)) {
        initialValues["value_from"] = _get(editingAggregation, "settings.value_from")
        initialValues["value_to"] = _get(editingAggregation, "settings.value_to")
      }
    }

    return (
      <Page
        className="admin-segment-analytics"
        title={headerTitle}
        headerContent={
          <>
            {mode === "form" && (
              <div className="form-action-buttons">
                <Button
                  className="cancel"
                  color="grey"
                  variant="outlined"
                  onClick={this.toggleMode()}
                >
                  Cancel
                </Button>
                {!_isEmpty(initialValues) && (
                  <Button
                    className="delete-button"
                    color="red"
                    icon="trash-alt"
                    variant="outlined"
                    onClick={this.toggleConfirmModal(
                      editingAggregation.attribute_id,
                      editingAggregation.id,
                      editingAggregation.name,
                    )}
                  >
                    Delete
                  </Button>
                )}
                {_isEmpty(editingAggregation) && (
                  <Button
                    color="grey"
                    type="submit"
                    variant="outlined"
                    onClick={this.onSaveClick("form")}
                    className="save-create"
                  >
                    Save and create new one
                  </Button>
                )}
                <Button type="submit" onClick={this.onSaveClick("list")}>
                  Save
                </Button>
                {mode === "form" && !_isEmpty(editingAggregation) && insights.length > 1 && (
                  <React.Fragment>
                    <div className="vertical-line" />
                    <Button
                      color="grey"
                      disabled={isLeftNavButtonDisabled}
                      variant="outlined"
                      onClick={this.navigateInsights(-1)}
                      className="left-button"
                    >
                      <FontAwesomeIcon icon={["far", "chevron-left"]} />
                    </Button>
                    <Button
                      color="grey"
                      disabled={isRightNavButtonDisabled}
                      variant="outlined"
                      onClick={this.navigateInsights(1)}
                    >
                      <FontAwesomeIcon icon={["far", "chevron-right"]} />
                    </Button>
                  </React.Fragment>
                )}
              </div>
            )}
            {mode === "table" && (
              <>
                <InsightsFilterForm />
                <div className="actions-wrapper">
                  <Button onClick={this.toggleMode()}>+ Create insight</Button>
                </div>
              </>
            )}
          </>
        }
      >
        {mode === "form" && (
          <InsightForm onSubmit={this.saveAnalyticsForm} initialValues={initialValues} />
        )}
        {mode === "table" &&
          (filteredInsights.length === 0 ? (
            <Paper className="admin-segment-analytics-content">
              <p className="info-message">
                {insights.length === 0
                  ? 'Click on "Create Insight" to get started.'
                  : "No insights found."}
              </p>
            </Paper>
          ) : (
            <div className="admin-segment-analytics-content-wrapper">
              <p className="info-message">
                Values are <strong>randomized</strong> and for <strong>preview only</strong>.
              </p>

              <ReactSortable
                list={filteredInsights}
                setList={newData => this.setState({ data: newData })}
                onStart={() => this.setState({ dragging: true })}
                onEnd={() => this.setState({ dragging: false })}
                onUpdate={this.onSortEnd}
                animation={200}
                handle=".drag-button"
                className={`admin-segment-analytics-content tiles-section`}
              >
                {filteredInsights.map((row, index) => {
                  const attribute = attributesMapById[row.attribute_id]
                  let subAttribute
                  if (isAttributeCompound(attribute?.data_type) && row.sub_attribute_id) {
                    const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
                    subAttribute = subAttributes.find(
                      subAttr => subAttr.id === row.sub_attribute_id,
                    )
                  }
                  let dataTooltipPlacement = index % 4 === 3 ? "left" : "right"
                  const compareValue =
                    row.settings?.value_from && row.settings?.value_to
                      ? [row.settings.value_from, row.settings.value_to]
                      : row.settings?.value
                  return (
                    <SortableItem
                      id={row.id}
                      key={`item-${row.id}`}
                      index={index}
                      value={row}
                      compareValue={compareValue}
                      attribute={attribute}
                      subAttribute={subAttribute}
                      toggleModeFunction={this.toggleMode(row)}
                      toggleConfirmModal={this.toggleConfirmModal}
                      onMouseOver={this.onMouseOver(row.id)}
                      onMouseOut={this.onMouseOut(row.id)}
                      showTooltip={tooltip === row.id}
                      moving={moving}
                      disabled={moving === true}
                      dataTooltipPlacement={dataTooltipPlacement}
                      draggingDisabled={isFiltered}
                    />
                  )
                })}
              </ReactSortable>
            </div>
          ))}
        <ConfirmModal
          open={deleteModal.open}
          handleClose={this.toggleConfirmModal()}
          handleConfirm={this.deleteAttributeAggregation}
          title="Are you sure?"
          action="delete"
          what="insight"
          item={deleteModal.itemName}
          isLoading={deleteModal.isLoading}
          type={MODAL.TYPE.DELETE}
        />
        <ConfirmModal
          open={unsavedThingsModal.show}
          handleClose={this.closeUnsavedInsightModal}
          handleConfirm={this.confirmInsightNavigation}
          title="Are you sure?"
          action="cancel"
          text="Do you really want to leave current insight and lose unsaved changes?"
          type={MODAL.TYPE.CANCEL}
        />
      </Page>
    )
  }
}

Insights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
}

const selector = formValueSelector("InsigtsFilter")
const mapStateToProps = state => ({
  isFormDirty: isDirty("CreateInsightForm")(state),
  searchTerm: selector(state, "search"),
  filteredSource: selector(state, "select"),
})

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, type) => dispatch(showToast(message, type)),
    dispatch,
  }
}

Insights = connect(mapStateToProps, mapDispatchToProps)(Insights)

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()

  return (
    <Insights {...props} attributesMapById={attributesMap} areAttributesFulfilled={isSuccess} />
  )
}
