import { isEmpty } from "ramda"

import { AttributesMapById } from "resources/attribute/attributeTypes"

export function replaceAttributePlaceholders(text: string, attributesMapById: AttributesMapById) {
  return text.replaceAll(
    /(?:{{(?<attributeId>[^.]+?)(?:\.(?<dimensionId>.+?))?}})/g,
    (match, attributeId, dimensionId) => {
      const attribute = attributesMapById[attributeId]

      if (
        !attribute ||
        !attribute.examples ||
        isEmpty(attribute.examples) ||
        (!dimensionId && !Array.isArray(attribute.examples)) ||
        (dimensionId &&
          // @ts-ignore
          (!attribute.examples[dimensionId] || isEmpty(attribute.examples[dimensionId])))
      ) {
        return match
      }

      // @ts-ignore
      const examples: any[] = dimensionId ? attribute.examples[dimensionId] : attribute.examples

      // Return longest example to have a better chance to catch problems with message length
      return examples.reduce((a, b) => (a.toString().length >= b.toString().length ? a : b))
    },
  )
}
