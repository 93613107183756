import React from "react"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import EmailsChannelForm from "./EmailsChannelForm"
import { useFetchEmailChannel, useModifyEmailsChannel } from "resources/channel/channelQueries"
import { EmailsChannelPayload } from "resources/channel/channelTypes"
import Page from "components/UI/Page/Page"

export default function EmailsChannel() {
  const { data, isLoading } = useFetchEmailChannel()
  const { mutate, isLoading: isSubmitting } = useModifyEmailsChannel()

  if (isLoading) {
    return (
      <Page title="Email channel">
        <LoadingIndicator />
      </Page>
    )
  }

  if (!data) return null

  const onSubmit = (formValues: EmailsChannelPayload) => mutate({ data: formValues })

  return <EmailsChannelForm isSubmitting={isSubmitting} channel={data} onSubmit={onSubmit} />
}
