import _toString from "lodash/toString"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import {
  COMPOUND_ATTRIBUTE_DATA_TYPES,
  ATTRIBUTE_DATA_TYPES,
  DEFINITION_CALC_TYPE_OPTIONS,
} from "./AttributeForm"
import { isEmpty } from "ramda"
import { clone } from "lodash"

const definitionDefaults = {
  weight: "1",
}

export const getInitialAttributeFormValues = (attribute, sourceOptions = []) => {
  if (!attribute) {
    return {
      is_unique: "1",
      compound_dimensions: [{}],
      definitionType: "predefined",
      definition: definitionDefaults,
      isDefinitionShown: true,
    }
  }

  // In some cases attribute definition can come from the BE as `{}`; in that case migrate to null
  if (isEmpty(attribute.definition)) attribute.definition = null

  const formInitValues = {
    id: attribute.id,
    name: attribute.name,
    source_id: {
      value: attribute.source.id,
      label: attribute.source.name,
      hidden: (attribute.source.is_hidden ?? 0) === 1,
    },
    description: attribute.description,
    is_unique: _toString(attribute.is_unique),
    definition: attribute.definition ? clone(attribute.definition) : definitionDefaults,
    isDefinitionShown: attribute.definition !== null,
    definitionType: "predefined",
  }

  if (isAttributeCompound(attribute.data_type)) {
    formInitValues.data_type = ATTRIBUTE_DATA_TYPES.find(dataType => dataType.value === "compound")
    const dimensions = getCompoundAttributeSubAttributes(attribute.data_type)
    formInitValues.compound_dimensions = dimensions.map(dimension => ({
      ...dimension,
      data_type: COMPOUND_ATTRIBUTE_DATA_TYPES.find(
        dataType => dataType.value === dimension.data_type,
      ),
    }))
  } else {
    formInitValues.data_type = ATTRIBUTE_DATA_TYPES.find(
      dataType => dataType.value === attribute.data_type,
    )
  }
  if (Array.isArray(attribute.tags)) {
    formInitValues.tag_ids = attribute.tags.map(tag => tag.id)
  } else {
    formInitValues.tag_ids = []
  }

  if (attribute.definition) {
    if (attribute.definition.type === "custom") {
      formInitValues.definitionType = "custom"
    } else {
      formInitValues.definition.sources = attribute.definition.sources
        ?.map(sourceId => sourceOptions.find(({ value }) => value === sourceId))
        .filter(Boolean)
      formInitValues.definition.types = attribute.definition.types?.map(eventType => ({
        label: eventType,
        value: eventType,
      }))
      formInitValues.definition.versions = attribute.definition.versions?.map(version => ({
        label: version,
        value: version,
      }))
      formInitValues.definition.type = DEFINITION_CALC_TYPE_OPTIONS.find(
        ({ value }) => value === attribute.definition.type,
      )
    }
  }

  return formInitValues
}
