import React, { Component } from "react"
import { reduxForm, Form, Field, FormSection, FieldArray, formValueSelector } from "redux-form"
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import TextArea from "components/UI/elements/TextArea/ReduxFormTextArea"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"
import { OPTION_GROUP_ICONS, OPTION_GROUP_COLORS, TOAST } from "sharedConstants"
import IconsRadioGroup from "components/UI/components/IconsRadioGroup"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import { ReactSortable } from "react-sortablejs"
import Tag from "components/UI/elements/Tag"
import _get from "lodash/get"
import _set from "lodash/set"
import CheckboxReduxFormField from "components/UI/elements/Checkbox/CheckboxReduxFormField"
import _pickBy from "lodash/pickBy"
import _keys from "lodash/keys"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import { showToast } from "actions/toast.action"
import "./DestinationForm.scss"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { isNil } from "ramda"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"

class DestinationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draggingData: null,
      attributeTooltip: null,
      attributeDragging: false,
      showExportedAttributesPicker: false,
      showMandatoryAttributesPicker: false,
    }
  }

  renderParameters = ({ fields }) => {
    return (
      <div className="parameter-content">
        {fields.map((parameter, index) => (
          <div
            key={index}
            className={`parameter-item-wrapper ${index === fields.length - 1 ? "last" : ""}`}
          >
            <div className="parameter-box" key={index}>
              <div className="row">
                <Field
                  name={`${parameter}.name`}
                  component={TextField}
                  placeholder="MI Key"
                  label="Meiro Integrations Key"
                  validate={required}
                  className="parameter-key"
                />
                <Field
                  name={`${parameter}.title`}
                  component={TextField}
                  placeholder="Name"
                  label="Name"
                  className="parameter-name"
                  validate={required}
                />
              </div>
              <div className="row checkboxes">
                <Field
                  name={`${parameter}.required`}
                  label="Required field"
                  component={CheckboxReduxFormField}
                />
                <Field
                  name={`${parameter}.input_mode.value_from_list`}
                  label="Value from list"
                  component={CheckboxReduxFormField}
                />
                <Field
                  name={`${parameter}.input_mode.user_input`}
                  label="User input"
                  component={CheckboxReduxFormField}
                />
              </div>
            </div>
            <IconButton
              color="red"
              onClick={() => fields.remove(index)}
              icon="trash-alt"
              className="trash"
              tooltip="Delete"
              variant="transparent"
            />
          </div>
        ))}
        <Button
          size="md"
          onClick={() => fields.push({ required: true, values: {} })}
          className={fields.length === 0 ? "add-parameter-button mt" : "add-parameter-button"}
        >
          + Add parameter
        </Button>
      </div>
    )
  }

  submitForm = values => {
    const { disabledFields = [], onSubmit } = this.props
    const data = {
      name: values.name,
      icon: `${values.icon}.png`,
      description: values.description,
      attribute_ids: values.attribute_ids,
      mandatory_attribute_ids: values.mandatory_attribute_ids,
      settings: {},
      frontend_settings: {
        color: values.color,
      },
    }

    if (!disabledFields.includes("id")) {
      data.id = values.id
    }

    if (values.mi_workspace) {
      data.mi_workspace_id = values.mi_workspace.value
    }

    if (values.mandatory_attribute_ids && values.mandatory_attribute_ids.length > 1) {
      data.settings.mandatory_attributes_operator = _get(
        values,
        "settings.mandatory_attributes_operator",
        "or",
      )
    }

    const parameters = _get(values, "settings.mi_workspace_variables")
    if (parameters) {
      const transformedParameters = parameters.map(param => ({
        ...param,
        input_mode: _keys(_pickBy(param.input_mode)),
      }))
      data.settings = {
        ...data.settings,
        mi_workspace_variables: transformedParameters,
      }
    }

    onSubmit(data)
  }

  getAttributeList = attributeIds => {
    if (isNil(attributeIds) || !this.props.areAttributesFulfilled) {
      return []
    }

    const { attributesMap } = this.props
    let attributes = []
    if (Array.isArray(attributeIds)) {
      attributes = attributeIds
    } else {
      attributes = [...attributeIds]
    }

    attributes = attributes.map(id => {
      const [attributeId, dimensionId] = id.split(".")
      const attribute = attributesMap[attributeId]

      if (!attribute) {
        return { id, name: `N/A attribute: ${id}` }
      }

      if (dimensionId) {
        const dimension = getCompoundAttributeSubAttribute(dimensionId, attribute.data_type)

        if (!dimension) {
          return { id, name: `N/A attribute: ${id}` }
        }

        return { ...attribute, id, name: `${attribute.name}: ${dimension.name}` }
      }

      return attribute
    })

    return attributes
  }

  selectExportedAttribute = attributeId => {
    if (!attributeId) return

    const { attributeIds, change, showToast } = this.props
    let newAttributeIds
    if (Array.isArray(attributeIds)) {
      if (!attributeIds.includes(attributeId)) {
        newAttributeIds = [...attributeIds, attributeId]
        this.updateDraggingData(newAttributeIds)
        change("attribute_ids", newAttributeIds)
      } else {
        showToast(`Attribute already added.`, TOAST.TYPE.ERROR)
      }
    } else {
      newAttributeIds = [attributeId]
      this.updateDraggingData(newAttributeIds)
      change("attribute_ids", newAttributeIds)
    }
  }

  selectMandatoryAttribute = attributeId => {
    if (!attributeId) return

    const { mandatoryAttributeIds, change, showToast } = this.props
    let newMandatoryAttributeIds
    if (Array.isArray(mandatoryAttributeIds)) {
      if (!mandatoryAttributeIds.includes(attributeId)) {
        newMandatoryAttributeIds = [...mandatoryAttributeIds, attributeId]
        change("mandatory_attribute_ids", newMandatoryAttributeIds)
      } else {
        showToast(`Attribute already added.`, TOAST.TYPE.ERROR)
      }
    } else {
      newMandatoryAttributeIds = [attributeId]
      change("mandatory_attribute_ids", newMandatoryAttributeIds)
    }
  }

  onExportedAttributesPickerClose = () => {
    this.setState({ showExportedAttributesPicker: false })
  }

  showExportedAttributesPicker = () => {
    this.setState({ showExportedAttributesPicker: true })
  }

  onMandatoryAttributesPickerClose = () => {
    this.setState({ showMandatoryAttributesPicker: false })
  }

  showMandatoryAttributesPicker = () => {
    this.setState({ showMandatoryAttributesPicker: true })
  }

  onExportedAttributesSort = evt => {
    const { attributeIds, change } = this.props
    const { oldIndex, newIndex } = evt
    if (oldIndex !== newIndex) {
      const newAttributeIds = [...attributeIds]
      newAttributeIds.splice(oldIndex, 1)
      newAttributeIds.splice(newIndex, 0, attributeIds[oldIndex])
      change("attribute_ids", newAttributeIds)
    }
  }

  onExportedAttrMouseOver = attributeId => {
    const { attributeDragging, attributeTooltip } = this.state
    if (attributeTooltip !== attributeId && !attributeDragging) {
      this.setState({
        attributeTooltip: attributeId,
      })
    }
  }

  onExportedAttrMouseOut = attributeId => {
    const { attributeDragging, attributeTooltip } = this.state
    if (attributeTooltip === attributeId && !attributeDragging) {
      this.setState({
        attributeTooltip: null,
      })
    }
  }

  deleteExportedAttribute = attributeId => () => {
    const { attributeIds, change } = this.props
    const newAttributeIds = attributeIds.filter(id => id !== attributeId)
    change("attribute_ids", newAttributeIds)
    this.updateDraggingData(newAttributeIds)
  }

  deleteMandatoryAttribute = attributeId => () => {
    const { mandatoryAttributeIds, change } = this.props
    const newMandatoryAttributeIds = mandatoryAttributeIds.filter(id => id !== attributeId)
    change("mandatory_attribute_ids", newMandatoryAttributeIds)
  }

  updateDraggingData = attributeIds => {
    this.setState({
      draggingData: this.getAttributeList(attributeIds),
    })
  }

  renderExportedAttributesList = () => {
    const { attributeIds } = this.props
    const { attributeTooltip, showExportedAttributesPicker, draggingData } = this.state
    const attributes = this.getAttributeList(attributeIds)

    const data = draggingData ? draggingData : attributes
    return (
      <div className="exported-attributes">
        <p className="label">Exported attributes</p>
        <div className="attributes">
          {data.length > 0 && (
            <ReactSortable
              list={data}
              setList={newData => {
                this.setState({
                  draggingData: newData,
                })
              }}
              onStart={() => this.setState({ attributeDragging: true })}
              onEnd={() => this.setState({ attributeDragging: false })}
              onUpdate={this.onExportedAttributesSort}
              animation={200}
              handle=".drag-button"
              className="exported-sortable"
            >
              {data.map(attr => {
                return (
                  <Tag
                    key={attr.id}
                    color={attr.is_hidden ? "white-red" : "white"}
                    clickable={false}
                    className={`attribute exported-attr ${
                      attributeTooltip === attr.id ? "show-tooltip" : ""
                    }`}
                    onMouseOver={() => {
                      this.onExportedAttrMouseOver(attr.id)
                    }}
                    onMouseOut={() => {
                      this.onExportedAttrMouseOut(attr.id)
                    }}
                  >
                    {attr.is_hidden === 1 && (
                      <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
                    )}
                    {attr.source ? `${attr.source.name}: ${attr.name}` : attr.name}
                    <span className="tag-actions">
                      <IconButton
                        className="drag-button"
                        color="grey"
                        icon="grip-vertical"
                        variant="transparent"
                      />
                      <IconButton
                        color="grey"
                        onClick={this.deleteExportedAttribute(attr.id)}
                        icon="times"
                        iconStyle="far"
                        variant="transparent"
                      />
                    </span>
                  </Tag>
                )
              })}
            </ReactSortable>
          )}
          {!showExportedAttributesPicker && (
            <span>
              <Button
                className="exported-attributes-button-add"
                color="grey"
                size="xs"
                variant="outlined"
                onClick={this.showExportedAttributesPicker}
              >
                + Add attribute
              </Button>
            </span>
          )}
          {showExportedAttributesPicker && (
            <AttributePicker
              isEditable={true}
              handleAttributeSelect={this.selectExportedAttribute}
              onClose={this.onExportedAttributesPickerClose}
              fixedSize="tag-size"
              focusOnLoad={true}
              className="exported-attribute-picker"
              includeHidden
            />
          )}
        </div>
      </div>
    )
  }

  renderMandatoryAttributesList = () => {
    const { mandatoryAttributeIds } = this.props
    const { showMandatoryAttributesPicker } = this.state
    const attributes = this.getAttributeList(mandatoryAttributeIds)

    return (
      <div className="mandatory-attributes-wrapper">
        <p className="label">Mandatory attributes</p>
        {attributes.length > 1 && (
          <FormSection name="settings" className="mandatory-operator">
            <ToggleSwitchField
              name="mandatory_attributes_operator"
              leftValue="and"
              rightValue="or"
              width="80px"
              className="and-or-toggle"
              size="tiny"
            />
          </FormSection>
        )}
        <div className={`attributes ${attributes.length > 1 ? "with-operator" : ""}`}>
          {attributes.length > 0 && (
            <div className="mandatory-attributes">
              {attributes.map(attribute => (
                <Tag
                  key={attribute.id}
                  color={attribute.is_hidden ? "white-red" : "white"}
                  clickable={true}
                  className="attribute"
                  onClick={this.deleteMandatoryAttribute(attribute.id)}
                >
                  {attribute.is_hidden === 1 && (
                    <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
                  )}
                  {attribute.source
                    ? `${attribute.source.name}: ${attribute.name}`
                    : attribute.name}
                </Tag>
              ))}
            </div>
          )}
          {!showMandatoryAttributesPicker && (
            <Button
              color="grey"
              size="xs"
              variant="outlined"
              onClick={this.showMandatoryAttributesPicker}
              className="mandatory-attributes-button-add"
            >
              + Add attribute
            </Button>
          )}
          {showMandatoryAttributesPicker && (
            <AttributePicker
              isEditable={true}
              handleAttributeSelect={this.selectMandatoryAttribute}
              onClose={this.onMandatoryAttributesPickerClose}
              fixedSize="tag-size"
              focusOnLoad={true}
              className="mandatory-attribute-picker"
              includeHidden
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      handleSubmit,
      disabledFields = [],
      globalSettings,
      initialValues,
      workspaces,
      areWorkspacesFulfilled,
      destinations,
    } = this.props
    const isMiApiSet = !isNil(globalSettings?.["mi_api"]?.value)
    const hasMiSettingsSection = initialValues.id > 1000

    const alreadyUsedColors = []
    destinations.forEach(destination => {
      const color = destination.frontend_settings?.color
      if (color) {
        alreadyUsedColors.push(color)
      }
    })

    return (
      <section className="destination-form">
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Destination ID"
                name="id"
                label="Destination ID"
                validate={required}
                maxLength={60}
                className="destination-id"
                disabled={disabledFields.includes("id")}
              />
              <Field
                component={TextField}
                placeholder="Name"
                name="name"
                label="Name"
                validate={required}
                className="destination-name"
                disabled={disabledFields.includes("name")}
              />
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part">
              <h2>Description</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextArea}
                placeholder="Description (optional)"
                name="description"
                label="Description (optional)"
                className="destination-description"
                rows={8}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="left-part">
              <h2>Icon</h2>
            </div>
            <div className="right-part">
              <div>
                <Field
                  name="icon"
                  icons={OPTION_GROUP_ICONS}
                  component={IconsRadioGroup}
                  validate={required}
                />
                <label className="color-label">Define destination color</label>
                <Field
                  name="color"
                  colors={OPTION_GROUP_COLORS}
                  alreadyUsedColors={alreadyUsedColors}
                  component={ColorRadioGroup}
                  validate={required}
                />
              </div>
            </div>
          </div>
          <div className={`form-row grey ${!hasMiSettingsSection ? "last" : ""}`}>
            <div className="left-part">
              <h2>Attributes</h2>
            </div>
            <div className="right-part flex-wrap">
              {this.renderExportedAttributesList()}
              {this.renderMandatoryAttributesList()}
            </div>
          </div>
          {hasMiSettingsSection && (
            <React.Fragment>
              <div className="form-row">
                <div className="left-part">
                  <h2>Meiro Integrations</h2>
                </div>
                <div className="right-part">
                  {isMiApiSet && (
                    <Field
                      label="MI Workspace"
                      component={SelectField}
                      placeholder="MI Workspace"
                      name="mi_workspace"
                      options={workspaces}
                      isLoading={!areWorkspacesFulfilled}
                      className="destination-mi-workspace"
                    />
                  )}
                  {!isMiApiSet && <p className="no-mi-api-set">No MI API credentials set</p>}
                </div>
              </div>
              <div className="form-row grey last">
                <div className="left-part">
                  <h2>Parameters</h2>
                </div>
                <div className="right-part">
                  <FormSection name="settings" className="settings">
                    <FieldArray name="mi_workspace_variables" component={this.renderParameters} />
                  </FormSection>
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
      </section>
    )
  }
}

DestinationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  disabledFields: PropTypes.array,
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMap: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
}

const selector = formValueSelector("DestinationForm")
const mapStateToProps = state => ({
  attributeIds: selector(state, "attribute_ids"),
  mandatoryAttributeIds: selector(state, "mandatory_attribute_ids"),
})

DestinationForm = connect(mapStateToProps, { showToast })(DestinationForm)

const validate = values => {
  let errors = {}

  if (_get(values, "settings.mi_workspace_variables")) {
    values.settings.mi_workspace_variables.forEach((parameter, index) => {
      if (
        !_get(parameter, "input_mode.value_from_list") &&
        !_get(parameter, "input_mode.user_input")
      ) {
        _set(
          errors,
          `settings.mi_workspace_variables[${index}].input_mode.value_from_list`,
          "Select one input mode.",
        )
      }
    })
  }

  return errors
}

DestinationForm = reduxForm({
  form: "DestinationForm",
  touchOnBlur: false,
  enableReinitialize: true,
  validate,
})(DestinationForm)

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap({
    includeHidden: true,
  })
  const { data: destinations = [] } = useFetchAllDestinations()
  const { data: globalSettings } = useFetchGlobalSettings()
  const { data: workspaces = [], isSuccess: areWorkspacesFulfilled } = useFetchWorkspaceOptions()

  return (
    <DestinationForm
      {...props}
      attributesMap={attributesMap}
      areAttributesFulfilled={isSuccess}
      destinations={destinations}
      globalSettings={globalSettings}
      workspaces={workspaces}
      areWorkspacesFulfilled={areWorkspacesFulfilled}
    />
  )
}
