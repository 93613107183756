import React, { useState } from "react"
import moment from "moment"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LabelForm from "./components/LabelForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"
import { MOMENT, MODAL } from "sharedConstants"
import "./Labels.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useCreateLabel,
  useDeleteLabel,
  useFetchAllLabels,
  useModifyLabel,
} from "resources/attributeLabel/attributeLabelQueries"
import { Label } from "resources/attributeLabel/attributeLabelTypes"

export default function Labels() {
  const [view, setView] = useState<"list" | "form">("list")
  const [selectedLabel, setSelectedLabel] = useState<Label | null>(null)
  const { data: labels = [], isSuccess, isLoading } = useFetchAllLabels()
  const createMutation = useCreateLabel()
  const modifyMutation = useModifyLabel()
  const deleteMutation = useDeleteLabel()
  const [labelIdToDelete, setLabelIdToDelete] = useState<Label["id"] | null>(null)
  function submit(values: Pick<Label, "name">) {
    if (selectedLabel) {
      modifyMutation.mutate(
        { id: selectedLabel.id, data: values },
        {
          onSuccess() {
            refetchAttributes()
            setView("list")
            setSelectedLabel(null)
          },
        },
      )
    } else {
      createMutation.mutate(
        { data: values },
        {
          onSuccess() {
            setView("list")
          },
        },
      )
    }
  }
  function deleteLabel() {
    deleteMutation.mutate(
      { id: labelIdToDelete! },
      {
        onSuccess() {
          setView("list")
          setLabelIdToDelete(null)
          setSelectedLabel(null)
        },
      },
    )
  }

  return (
    <Page
      title="Attribute labels"
      className="setup-labels"
      headerContent={
        <>
          {view === "list" && <Button onClick={() => setView("form")}>+ Create label</Button>}
          {view === "form" && (
            <div className="buttons">
              {selectedLabel && (
                <Button
                  color="red"
                  icon="trash-alt"
                  variant="outlined"
                  onClick={() => setLabelIdToDelete(selectedLabel.id)}
                  loading={deleteMutation.isLoading}
                >
                  Delete
                </Button>
              )}

              <Button
                color="grey"
                variant="outlined"
                onClick={() => {
                  setView("list")
                  setSelectedLabel(null)
                }}
              >
                Cancel
              </Button>
              <RemoteSubmitButton
                // @ts-ignore
                isLoading={createMutation.isLoading || modifyMutation.isLoading}
                formName="LabelForm"
              />
            </div>
          )}
        </>
      }
    >
      {isLoading && <LoadingIndicator />}

      {view === "list" &&
        isSuccess &&
        (labels.length === 0 ? (
          <Paper>
            <RowMessage>Click on the "Create Label" to get started.</RowMessage>
          </Paper>
        ) : (
          <Paper noPaddingTop>
            <Table className="labels-table">
              <Thead stickyHeader>
                <Th className="label-name">Name</Th>
                <Th className="label-created">Created</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {labels.map(label => (
                  <Tr key={label.id}>
                    <Td className="label-name">
                      <Tag color="primary">{label.name}</Tag>
                    </Td>
                    <Td className="label-created">
                      {moment.utc(label.created).local().format(MOMENT.DATE_FORMAT)}
                    </Td>
                    <Td textAlignRight className="actions">
                      <IconButton
                        color="black"
                        size="xs"
                        onClick={() => {
                          setView("form")
                          setSelectedLabel(label)
                        }}
                        icon="pencil-alt"
                        tooltip="Edit"
                        variant="outlined"
                      />
                      <IconButton
                        color="red"
                        size="xs"
                        onClick={() => setLabelIdToDelete(label.id)}
                        className="left-margin"
                        icon="trash-alt"
                        tooltip="Delete"
                        variant="outlined"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Paper>
        ))}

      {view === "form" && (
        <Paper className="label-form-wrapper">
          <LabelForm
            onFormClose={() => setView("list")}
            onLabelDelete={() => setLabelIdToDelete(selectedLabel?.id ?? null)}
            onSubmit={submit}
            initialValues={selectedLabel ?? {}}
            type={selectedLabel ? "EDIT" : "CREATE"}
          />
        </Paper>
      )}
      <ConfirmModal
        open={!!labelIdToDelete}
        type={MODAL.TYPE.DELETE}
        handleClose={() => setLabelIdToDelete(null)}
        handleConfirm={deleteLabel}
        title="Delete label"
        action="delete"
        what="label"
        item={labels?.find(({ id }) => id === labelIdToDelete)?.name}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}
