import React, { useState } from "react"
import classNames from "classnames"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import Page from "components/UI/Page/Page"
import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import SelectField from "components/UI/elements/SelectField"
import { email, port, required } from "helpers/validators.helper"
import { useFetchAllAttributes } from "resources/attribute/attributeQueries"
import { Attribute } from "resources/attribute/attributeTypes"
import { getCompoundAttributeSubAttributes } from "resources/attribute/compoundAttributeUtils"
import { EmailsChannel, EmailsChannelPayload } from "resources/channel/channelTypes"
import { getRoutePath } from "routes"

import styles from "./EmailsChannelForm.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import IconButton from "components/UI/elements/IconButton/IconButton"
import {
  useCreateEmailSender,
  useDeleteEmailSender,
  useFetchEmailSenders,
  useResendEmailSenderVerification,
  useSetDefaultEmailSender,
} from "resources/channel/emailSenders/emailSendersQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Sender } from "resources/channel/emailSenders/emailSendersTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"

type EmailsChannelFormProps = {
  channel: EmailsChannel
  isSubmitting: boolean
  onSubmit: (formValues: EmailsChannelPayload) => void
}

export default function EmailsChannelForm({
  channel,
  isSubmitting,
  onSubmit,
}: EmailsChannelFormProps) {
  const history = useHistory()

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailsChannelPayload>({
    defaultValues: {
      emails_with_consents_attribute_id: channel?.emails_with_consents_attribute_id,
      from_email: channel?.from_email,
      reply_to_email: channel?.reply_to_email,
      smtp_config: channel?.smtp_config,
    },
  })

  const submitHandler = (formValues: EmailsChannelPayload) =>
    onSubmit({ ...formValues, smtp_config: { ...formValues.smtp_config, use_tls: true } })

  const { data: senders = [], isLoading: isLoadingSenders } = useFetchEmailSenders()

  const { data: attributes = [], isLoading: isLoadingAttributes } = useFetchAllAttributes()

  let attributeOptions: Array<{ label: string; value: Attribute["id"] }> = []
  if (attributes.length > 0)
    attributeOptions = attributes
      .filter(({ data_type }) => {
        const subAttributes = getCompoundAttributeSubAttributes(data_type)
        if (subAttributes.length === 0) return false

        const emailSubAttribute = subAttributes.find(subAttr => subAttr.id === "email")
        const consentSubAttribute = subAttributes.find(subAttr => subAttr.id === "consent")
        return !!emailSubAttribute && !!consentSubAttribute
      })
      .map(({ id, name }) => ({ label: name, value: id }))

  const getAttributeOption = (id: Attribute["id"]) =>
    attributeOptions.find(attr => attr.value === id)

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Page
        title="Email channel"
        headerContent={
          <div className={styles.header}>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => history.push(getRoutePath("administration.channels"))}
            >
              Cancel
            </Button>
            <Button loading={isSubmitting} type="submit">
              Save
            </Button>
          </div>
        }
      >
        <div className={styles.page}>
          <Paper className={styles.paper}>
            <div className={styles.row}>
              <div className={styles.description}>
                <div>
                  <h3>SMTP account</h3>
                  <p>Connection type will be STARTTLS by default.</p>
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.columnFlexBox}>
                  <div className={styles.rowFlexBox}>
                    <TextInput
                      error={errors?.smtp_config?.host?.message}
                      label="Server name"
                      placeholder="Server name"
                      className={classNames(styles.server, {
                        [styles.error]: errors?.smtp_config?.host || errors?.smtp_config?.port,
                      })}
                      {...register("smtp_config.host", { validate: { required } })}
                    />
                    <TextInput
                      error={errors?.smtp_config?.port?.message}
                      label="Port"
                      placeholder="Port"
                      className={classNames(styles.port, {
                        [styles.error]: errors?.smtp_config?.host || errors?.smtp_config?.port,
                      })}
                      {...register("smtp_config.port", {
                        validate: { required, port },
                      })}
                    />
                  </div>
                </div>
                <div className={styles.credentialsFlexBox}>
                  <TextInput
                    error={errors?.smtp_config?.username?.message}
                    label="Username"
                    placeholder="Username"
                    {...register("smtp_config.username", { validate: { required } })}
                  />
                  <TextInput
                    error={errors?.smtp_config?.password?.message}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    {...register("smtp_config.password", { validate: { required } })}
                  />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.description}>
                <div>
                  <h3>Email attribute</h3>
                  <p>
                    The attribute has to be a compound attribute with <code>email</code> and{" "}
                    <code>consent</code> dimensions.
                  </p>
                </div>
              </div>
              <div className={styles.fields}>
                <Controller
                  control={control}
                  name="emails_with_consents_attribute_id"
                  rules={{ validate: { required } }}
                  render={({ field: { value, onChange } }) => (
                    <div className={styles.attributePickerFlexBox}>
                      <SelectField
                        isLoading={isLoadingAttributes}
                        input={{
                          value: getAttributeOption(value),
                          onChange: ({ value }: { value: Attribute["id"] }) => onChange(value),
                        }}
                        label="Email attribute"
                        meta={{
                          touched: true,
                          error: errors?.emails_with_consents_attribute_id?.message,
                        }}
                        noOptionsMessage="No attribute matches the given criteria."
                        options={attributeOptions}
                        placeholder="Type to search..."
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.description}>
                <div>
                  <h3>Verified senders</h3>
                  <p>
                    Set up your verified senders. Email addresses will need to be verified with a
                    confirmation email.
                  </p>
                </div>
              </div>
              <div className={styles.sendersWrapper}>
                {isLoadingSenders && <LoadingIndicator />}

                {senders.length > 0 && (
                  <>
                    <div className={styles.label}>Sender email</div>

                    {senders.map(sender => (
                      <SenderRow
                        sender={sender}
                        key={sender.email}
                        deleteDisabled={senders.length === 1}
                      />
                    ))}
                  </>
                )}

                <AddSender />
              </div>
            </div>
          </Paper>
        </div>
      </Page>
    </form>
  )
}

type SenderRowProps = {
  sender: Sender
  deleteDisabled?: boolean
}

function SenderRow({ sender, deleteDisabled }: SenderRowProps) {
  const setDefaultMutation = useSetDefaultEmailSender()
  const resendVerificationMutation = useResendEmailSenderVerification()
  const deleteMutation = useDeleteEmailSender()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <div className={styles.sender}>
      <ConfirmModal
        title="Are you sure?"
        open={isDeleteModalOpen}
        type="delete"
        action="delete"
        what="sender"
        item={sender.email}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() => deleteMutation.mutate({ email: sender.email })}
        isLoading={deleteMutation.isLoading}
      />

      <div className={styles.senderEmail}>{sender.email}</div>
      {sender.default && (
        <div className={styles.default}>
          <FontAwesomeIcon icon={["fas", "check"]} />
          Default sender
        </div>
      )}
      {sender.verified ? (
        <>
          <Button
            className={styles.defaultButton}
            variant="link"
            color="grey"
            size="xs"
            onClick={() => setDefaultMutation.mutate({ email: sender.email })}
            loading={setDefaultMutation.isLoading}
          >
            Set as default
          </Button>
          <div className={styles.verified}>
            <FontAwesomeIcon icon={["fas", "circle-check"]} />
            Verified address
          </div>
        </>
      ) : (
        <Button
          className={styles.resendButton}
          size="xs"
          variant="link"
          onClick={() => resendVerificationMutation.mutate({ email: sender.email })}
          loading={resendVerificationMutation.isLoading}
        >
          <FontAwesomeIcon icon={["far", "paper-plane"]} />
          Resend verification
        </Button>
      )}
      <IconButton
        color="red"
        size="xs"
        icon="trash-alt"
        tooltip="Delete"
        variant="transparent"
        onClick={() => setIsDeleteModalOpen(true)}
        disabled={deleteDisabled}
      />
    </div>
  )
}

function AddSender() {
  const [isOpen, setIsOpen] = useState(false)
  const createMutation = useCreateEmailSender()
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<{ email: string }>()
  const addSender = handleSubmit(({ email }) =>
    createMutation.mutate(
      { data: { email } },
      {
        onSuccess() {
          reset()
          setIsOpen(false)
        },
      },
    ),
  )

  if (!isOpen) {
    return (
      <Button
        className={styles.addSenderButton}
        size="sm"
        onClick={() => setIsOpen(true)}
        variant="link"
        type="button"
        icon="plus"
        iconStyle="far"
      >
        Add email address
      </Button>
    )
  }

  return (
    <div className={styles.addSender}>
      <div className={styles.addSenderEmail}>
        <TextInput
          error={errors?.email?.message}
          placeholder="Sender email"
          {...register("email", { validate: { required, email } })}
        />
      </div>

      <Button size="sm" onClick={addSender} loading={createMutation.isLoading}>
        Add address
      </Button>

      <IconButton
        icon="times"
        iconStyle="fas"
        variant="transparent"
        color="grey"
        size="xs"
        onClick={() => {
          reset()
          setIsOpen(false)
        }}
      />
    </div>
  )
}
