import requestFactory from "api/request"
import {
  ChannelListResponse,
  EmailsChannelPayload,
  EmailsChannelResponse,
  PushNotificationChannelReponse,
} from "./channelTypes"
import senders from "./emailSenders/emailSendersEndpoints"

const channel = {
  list(): Promise<ChannelListResponse> {
    return requestFactory("get", "/channels")
  },
  pushNotifications: {
    retrieve(): Promise<PushNotificationChannelReponse> {
      return requestFactory("get", "/channels/push_notifications")
    },
    modify(data: FormData): Promise<PushNotificationChannelReponse> {
      return requestFactory(
        "patch",
        "/channels/push_notifications",
        data,
        false,
        true,
        "",
        false,
        false,
        { "Content-Type": "multipart/form-data" },
      )
    },
  },
  emails: {
    retrieve(): Promise<EmailsChannelResponse> {
      return requestFactory("get", "/channels/emails")
    },
    modify(data: EmailsChannelPayload): Promise<EmailsChannelResponse> {
      return requestFactory("patch", "/channels/emails", data)
    },
    senders,
  },
}

export default channel
