import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import FileUploadDnD from "components/UI/components/FileUploadDnD/FileUploadDnD"
import Button from "components/UI/elements/Button/Button"
import FileField from "components/UI/elements/FileField/FileField"
import Paper from "components/UI/elements/Paper"
import Page from "components/UI/Page/Page"
import { jsonFile, maxFileSize } from "helpers/validators.helper"
import { useForm, Controller } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { Attribute } from "resources/attribute/attributeTypes"
import { PushNotificationChannel } from "resources/channel/channelTypes"
import { getRoutePath } from "routes"
import styles from "./PushNotificationsChannel.module.scss"

export type PushNotificationsChannelFormValues = {
  firebaseFileList: FileList | null
  registrationTokenId: Attribute["id"] | null
}

export const MAX_UPLOAD_BYTES = 2 * 1024 * 1024

type PushNotificationsChannelFormProps = {
  channel: PushNotificationChannel
  onSubmit: (values: PushNotificationsChannelFormValues) => void
  isSubmitting: boolean
}

export default function PushNotificationsChannelForm({
  channel,
  onSubmit,
  isSubmitting,
}: PushNotificationsChannelFormProps) {
  const history = useHistory()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    control,
  } = useForm<PushNotificationsChannelFormValues>({
    defaultValues: { registrationTokenId: channel.registration_token_attribute_id },
    mode: "onChange",
  })

  const onFirebaseFileDrop = (files: FileList) => {
    if (files && files[0])
      setValue("firebaseFileList", files, { shouldDirty: true, shouldValidate: true })
  }

  const { firebase_account_data } = channel

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Page
        title="Mobile Push channel"
        headerContent={
          <div className={styles.header}>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.channels"))
              }}
            >
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting} disabled={!isDirty || !isValid}>
              Save
            </Button>
          </div>
        }
      >
        <Paper hasHeader className={styles.config}>
          <div className={styles.configRow}>
            <div className={styles.description}>
              <div>
                <h3>Firebase configuration file</h3>
              </div>
            </div>
            <div className={styles.configDetails}>
              {firebase_account_data && (
                <div className={styles.firebaseDetails}>
                  <span className={styles.configuredMarkup}>Configured</span>
                </div>
              )}
              <FileUploadDnD
                onFileDrop={onFirebaseFileDrop}
                label={`${firebase_account_data ? "re" : ""}configure firebase`}
              >
                <Controller
                  control={control}
                  name="firebaseFileList"
                  rules={{
                    validate: {
                      maxFileSize: maxFileSize(MAX_UPLOAD_BYTES),
                      jsonFile,
                    },
                  }}
                  render={({ field: { value, ref, onBlur, onChange } }) => (
                    <div>
                      <FileField
                        ref={ref}
                        error={errors.firebaseFileList?.message}
                        accept=".json"
                        value={value?.[0]?.name}
                        onBlur={onBlur}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const target = event.target as HTMLInputElement
                          const { files } = target
                          if (files && files[0]) onChange(files)
                        }}
                        className={styles.fileUploadField}
                      />
                      <p className={styles.fileUploadDesc}>
                        Firebase configuration JSON file. Max size: 2MB
                      </p>
                    </div>
                  )}
                />
              </FileUploadDnD>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.description}>
              <div>
                <h3>Registration token attribute</h3>
              </div>
            </div>
            <div className={styles.configDetails}>
              <Controller
                control={control}
                name="registrationTokenId"
                render={({ field }) => (
                  <AttributePicker
                    attributeId={field.value}
                    isEditable={true}
                    handleAttributeSelect={field.onChange}
                    withDimensions
                  />
                )}
              />
            </div>
          </div>
        </Paper>
      </Page>
    </form>
  )
}
