import React from "react"
import classNames from "classnames"
import moment from "moment"
import Linkify from "react-linkify"
import Waypoint from "react-waypoint"

import AttributeOverview from "./AttributeOverview/AttributeOverview"
import AttributeTable from "./AttributeTable/AttributeTable"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page, { PageProps } from "components/UI/Page/Page"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import StatusElement from "components/UI/elements/StatusElement"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
import Username from "components/Username/Username"
import { MOMENT } from "sharedConstants"
import { DBFormatDateTime, ExportStatus } from "types/util"
import { User } from "resources/user/userTypes"
import { ConditionTree } from "types/conditionTree"
import { SegmentCondition } from "resources/segment/segment/segmentConditionsTypes"

import styles from "./ExportLog.module.scss"

type ExportLogProps = {
  conditionsOperation: ConditionTree<SegmentCondition> | null
  goBackRoute: PageProps["backRouteFallback"]
  isLoadingExport: boolean
  name: string
  stats: { statuses_history: { [K in ExportStatus]: DBFormatDateTime } } | null
  userId: User["id"]
  hasMoreLogs?: boolean
  isFetchingLogs?: boolean
  isLoadingLogs?: boolean
  logs?: Array<{
    id: number
    level: "info" | "error"
    text: string
    created: DBFormatDateTime
  }>
  loadMoreLogs?: () => void
}

export default function ExportLog({
  conditionsOperation,
  goBackRoute,
  isLoadingExport,
  name,
  stats,
  userId,
  loadMoreLogs,
  hasMoreLogs = false,
  isFetchingLogs = false,
  isLoadingLogs = false,
  logs,
}: ExportLogProps) {
  if (isLoadingExport) return <LoadingIndicator />

  return (
    <Page title={name} backRouteFallback={goBackRoute}>
      <div className={styles.details}>
        <div className={styles.timeline}>
          <PaperHeader size="small" titleText="Timeline"></PaperHeader>
          {stats != null && (
            <Paper hasHeader={true} className={styles.timelineContent} noPaddingTop>
              <Table className={styles.timelineTable}>
                <Thead stickyHeader="timeline-table-sticky-header">
                  <Th className={styles.timelineStatus}>Status</Th>
                  <Th>Date</Th>
                  <Th textAlignRight>User</Th>
                </Thead>
                <Tbody>
                  {Object.keys(stats.statuses_history).map(status => (
                    <Tr key={status}>
                      <Td>
                        <StatusElement status={status} />
                      </Td>
                      <Td>
                        {moment
                          .utc(stats.statuses_history[status as ExportStatus])
                          .local()
                          .format(MOMENT.DATE_TIME_WITH_SECONDS)}
                      </Td>
                      <Td textAlignRight>
                        <Username userId={userId} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Paper>
          )}
        </div>
        <div className={styles.attributes}>
          <PaperHeader size="small" titleText="Attributes" />
          <Paper hasHeader={true}>
            {conditionsOperation !== null ? (
              <>
                <AttributeOverview conditionTree={conditionsOperation} />
                <AttributeTable conditionTree={conditionsOperation} />
              </>
            ) : (
              <div className={styles.noConditions}>No conditions defined.</div>
            )}
          </Paper>
        </div>
      </div>
      {isLoadingLogs && <LoadingIndicator />}
      {!isLoadingLogs && logs && (
        <div className={styles.exportLogs}>
          <PaperHeader
            size="small"
            titleText="Logs"
            subTitleText="Segment export logs are archived after 30 days."
          />
          {logs.length > 0 && (
            <Paper hasHeader={true} noPaddingTop>
              <Table className={styles.logTable}>
                <Thead stickyHeader="logs-table-sticky-header">
                  <Th className={classNames(styles.logHead, styles.logStatus)}>Level</Th>
                  <Th className={classNames(styles.logHead, styles.time)}>Time</Th>
                  <Th className={classNames(styles.logHead, styles.message)}>Text</Th>
                </Thead>
                <Tbody>
                  {logs.map((log, index) => (
                    <Tr key={index}>
                      <Td
                        className={classNames(styles.logCell, styles.logStatus, {
                          [styles.error]: log.level === "error",
                        })}
                      >
                        {log.level}
                      </Td>
                      <Td
                        className={classNames(styles.logCell, styles.time, {
                          [styles.error]: log.level === "error",
                        })}
                      >
                        {moment.utc(log.created).local().format(MOMENT.DATE_TIME_WITH_SECONDS)}
                      </Td>
                      <Td
                        className={classNames(styles.logCell, styles.message, {
                          [styles.error]: log.level === "error",
                        })}
                      >
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          {log.text}
                        </Linkify>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {hasMoreLogs && !isFetchingLogs && (
                <Waypoint onEnter={loadMoreLogs} bottomOffset={-300} />
              )}
              {hasMoreLogs && isFetchingLogs && <LoadingIndicator />}
            </Paper>
          )}
        </div>
      )}
    </Page>
  )
}
