import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Field, reduxForm } from "redux-form"

import PasswordField from "components/UI/components/PasswordField/PasswordField"
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"

import "./PasswordChangeForm.scss"

class PasswordChangeForm extends PureComponent {
  render() {
    const { handleSubmit, onSubmit } = this.props

    return (
      <form className="password-change-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <PasswordField passwordFieldLabel="New password" placeholder="New password" autoFocus />
        <Field
          name="repeat_password"
          component={TextField}
          className="repeat-pwd-field"
          type="password"
          placeholder="Confirm password"
          label="Confirm password"
        />
        <button type="submit" className="hidden-submit">
          submit
        </button>
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  if (values.password && values.password !== values.repeat_password) {
    errors.repeat_password = "Password doesn't match."
  }
  return errors
}

PasswordChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
  form: "UserPasswordChange",
  touchOnBlur: false,
  validate,
})(PasswordChangeForm)
