import React from "react"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SegmentChip from "components/SegmentChip/SegmentChip"
import { CustomerSegments } from "resources/customer/segment/customerSegmentTypes"
import { useHasSegmentPermission } from "resources/user/currentUserQueries"

import styles from "./SegmentParticipation.module.scss"

type SegmentParticipationProps = {
  data: CustomerSegments
  isFetchingNextPage: boolean
  fetchNextPage: () => void
  hasNextPage?: boolean
}

export default function SegmentParticipation({
  data,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: SegmentParticipationProps) {
  const hasSegmentPermission = useHasSegmentPermission()

  return (
    <div className={styles.root}>
      <span className={styles.title}>
        <b>Profile currently in these segments</b>
      </span>
      <div className={styles.items}>
        {data.map(({ segment_id, segment_name, segment_type }) => {
          const permission = hasSegmentPermission(segment_id, {
            segmentType: segment_type,
          })
          const hasAccess =
            typeof permission === "boolean" ? permission : ["read", "write"].includes(permission)

          return (
            <SegmentChip
              key={segment_id}
              hasAccess={hasAccess}
              segmentId={segment_id}
              segmentName={segment_name}
              segmentType={segment_type}
            />
          )
        })}
        {hasNextPage && isFetchingNextPage && <LoadingIndicator className={styles.loading} />}
        {hasNextPage && !isFetchingNextPage && (
          <Button
            color="black"
            variant="link"
            onClick={_ => fetchNextPage()}
            className={styles.showMore}
          >
            + Show more
          </Button>
        )}
      </div>
    </div>
  )
}
