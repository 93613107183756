import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import { Segment } from "resources/segment/segment/segmentTypes"
import { UserRole } from "resources/userRole/userRoleTypes"
import { EmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { UserFull } from "resources/user/userTypes"
import { TrashItem } from "../trashTypes"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { Email } from "resources/email/emailTypes"

export const segmentToTrashItemTransform = (segment: Segment): TrashItem => {
  const { id, name, created, user_id, featured, prebuilt } = segment
  return {
    id,
    name,
    deleted_at: `${created.replace(" ", "T")}Z`,
    deleted_by: user_id,
    type: featured ? "featured_segments" : prebuilt ? "smart_segments" : "custom_segments",
  }
}

export const embeddedWebBannerToTrashItemTransform = (
  embeddedWebBanner: EmbeddedWebBanner,
): TrashItem => {
  const { id, name, created, user_id } = embeddedWebBanner
  return {
    id,
    name,
    deleted_at: `${created}Z`,
    deleted_by: user_id,
    type: "embedded_web_banners",
  }
}

export const popupWebBannerToTrashItemTransform = (popupWebBanner: PopupWebBanner): TrashItem => {
  const { id, name, created, user_id } = popupWebBanner
  return {
    id,
    name,
    deleted_at: `${created}Z`,
    deleted_by: user_id,
    type: "popup_web_banners",
  }
}

export const segmentExportDestinationToTrashItemTransform = (
  segmentExportDestination: SegmentExportDestination,
): TrashItem => {
  const { id, name, created, user_id } = segmentExportDestination
  return {
    id,
    name,
    deleted_at: `${created.replace(" ", "T")}Z`,
    deleted_by: user_id,
    type: "segment_export_destinations",
  }
}

export const userRoleToTrashItemTransform = (userRole: UserRole): TrashItem => {
  const { id, name, modified, last_modified_by } = userRole
  return {
    id,
    name,
    deleted_at: `${modified}Z`,
    deleted_by: last_modified_by,
    type: "user_roles",
  }
}

export const userToTrashItemTransform = (user: UserFull): TrashItem => {
  const { id, name, modified, last_modified_by } = user
  return {
    id,
    name,
    deleted_at: `${modified}Z`,
    deleted_by: last_modified_by,
    type: "users",
  }
}

export const mobilePushNotificationToTrashItemTransform = (
  mobilePushNotification: MobilePushNotification,
): TrashItem => {
  const { id, name, modified, modified_by } = mobilePushNotification
  return {
    id,
    name,
    deleted_at: `${modified}Z`,
    deleted_by: modified_by,
    type: "push_notifications",
  }
}

export const emailToTrashItemTransform = (email: Email): TrashItem => {
  const { id, name, modified, modified_by } = email
  return {
    id,
    name,
    deleted_at: `${modified}Z`,
    deleted_by: modified_by,
    type: "emails",
  }
}
