import React, { PureComponent } from "react"
import _get from "lodash/get"
import moment from "moment"
import { getFormValues } from "redux-form"
import TimeAgo from "react-timeago"
import Select from "react-select"

// ui components
import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import CreateUserModal from "./CreateUserModal/CreateUserModal"
import ConfirmModal from "components/UI/components/ConfirmModal"
import SearchForm from "components/UI/components/SearchForm"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import Table, { Thead, Th, Tbody, Td, Tr, SortButton } from "components/UI/elements/Table"

// helpers, constants
import { TOAST, MODAL } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { getRoutePath } from "routes"
import { copyStringToClipboard } from "helpers/string.helper"

import "./UsersList.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import create from "zustand"
import { useDeleteUser, useFetchActiveUsers, useModifyUser } from "resources/user/userQueries"
import { useSelector } from "store"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import Page from "components/UI/Page/Page"
import { Link } from "react-router-dom"

class UsersList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      createUserModalOpen: false,
      deleteModal: {
        open: false,
        item: null,
      },
      resendingInvitation: false,
      roles: null,
      copyingLink: false,
      isLoadingRoles: true,
    }
  }

  componentDidMount() {
    this.fetchAllRoles()
  }

  fetchAllRoles = async () => {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.userRole.list(offset, limit))
        .setDataPath("roles")
        .run()
      this.setState({
        roles: data
          .filter(role => role.id !== 2)
          .map(role => ({ label: role.name, value: role.id, features: role.features })),
      })
    } catch {
    } finally {
      this.setState({ isLoadingRoles: false })
    }
  }

  deleteUser = () => {
    const { deleteModal } = this.state
    const { deleteUser } = this.props
    deleteUser(deleteModal.item.id)
    this.closeDeleteUserModal()
  }

  openDeleteUserModal = user => () => {
    this.setState({
      deleteModal: {
        open: true,
        item: user,
      },
    })
  }

  closeDeleteUserModal = () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        open: false,
      },
    })
  }

  toggleCreateUserModal = () => {
    this.setState(prevState => ({
      createUserModalOpen: !prevState.createUserModalOpen,
    }))
  }

  resendInvitation = (name, email) => () => {
    const { resendingInvitation } = this.state
    if (!resendingInvitation) {
      this.setState({ resendingInvitation: true })
      api.user
        .resendInvitation(email)
        .then(() => {
          this.props.dispatch(
            showToast(
              `The user has been successfully re-invited. Invitation will expire in 5 days.`,
              TOAST.TYPE.SUCCESS,
            ),
          )
        })
        .catch()
        .finally(() => {
          this.setState({ resendingInvitation: false })
        })
    }
  }

  goToUser = user => () => {
    this.props.history.push({
      pathname: getRoutePath("administration.users.detail", { id: user.id }),
      state: { goBack: true },
    })
  }

  copyInvitationLink = userId => async () => {
    try {
      this.setState({
        copyingLink: true,
      })
      const response = await api.user.getInvitationLink(userId)
      const link = response.user_invitation_link
      copyStringToClipboard(link)
      this.setState({
        copyingLink: false,
      })
      this.props.dispatch(
        showToast(
          "New invitation link is copied to clipboard! Use it before it expires in 5 days.",
          TOAST.TYPE.SUCCESS,
        ),
      )
    } catch (err) {
      this.setState({
        copyingLink: false,
      })
    }
  }

  render() {
    const { filters, users, areUsersFulfilled, toggleDisabled, setUserRole } = this.props
    const {
      createUserModalOpen,
      deleteModal,
      resendingInvitation,
      roles,
      copyingLink,
      isLoadingRoles,
    } = this.state
    const { orderBy, orderDir } = filters

    const isLoading = isLoadingRoles || !areUsersFulfilled

    return (
      <Page
        title="Users"
        className="users"
        headerContent={
          <>
            <SearchForm
              placeholder="Search for users"
              className="users-search"
              initialValues={{ search: "" }}
              form="UsersSearch"
            />
            <Button onClick={() => this.toggleCreateUserModal("user")}>+ Create user</Button>
          </>
        }
      >
        <>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Paper noPaddingTop>
              <div className="trash-button-wrapper">
                <Link to={getRoutePath("administration.users.trash")}>
                  <Button
                    type="button"
                    color="grey"
                    variant="outlined"
                    icon="trash-alt"
                    iconStyle="far"
                  >
                    Trash
                  </Button>
                </Link>
              </div>
              <Table className="admin-users">
                <Thead stickyHeader>
                  <Th className="gravatar">&nbsp;</Th>
                  <Th className="user-name">
                    <SortButton
                      column="name"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => filters.setSort("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="email-col">
                    <SortButton
                      column="email"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => filters.setSort("email")}
                      label="Email"
                    />
                  </Th>
                  <Th textAlignRight className="last-login">
                    <SortButton
                      column="last_login"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => filters.setSort("last_login")}
                      label="Last login"
                    />
                  </Th>
                  <Th textAlignRight className="role-column">
                    <SortButton
                      column="role"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => filters.setSort("role.name")}
                      label="Role"
                    />
                  </Th>
                  <Th textAlignRight className="toggle-column">
                    <SortButton
                      column="disabled"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => filters.setSort("disabled")}
                      label="Enabled"
                    />
                  </Th>
                  <Th className="action-column two-icon">&nbsp;</Th>
                </Thead>
                <Tbody>
                  {users.map(user => (
                    <Tr key={user.id} className={user.disabled ? "disabled-user" : ""}>
                      <Td className="gravatar user-table-cell">
                        <div className="clickable-avatar" onClick={this.goToUser(user)}>
                          <Avatar
                            name={user.name}
                            email={user.email}
                            disabledLook={user.disabled === true}
                          />
                        </div>
                      </Td>
                      <Td className="user-table-cell" textBlack textBigger textBold>
                        <span
                          className="link-hover"
                          onClick={this.goToUser(user)}
                          data-testid="td-name"
                        >
                          {user.name}
                        </span>
                      </Td>
                      <Td className="email-col user-table-cell">{user.email}</Td>
                      <Td
                        textAlignRight
                        className={`last-login user-table-cell ${!user.last_login ? "never" : ""}`}
                      >
                        {user.last_login && (
                          <TimeAgo
                            date={moment.utc(user.last_login).local().format("YYYY-MM-DD HH:mm:ss")}
                          />
                        )}
                        {!user.last_login && (
                          <React.Fragment>
                            <div>Never,</div>
                            <span
                              className={`resend-inv ${resendingInvitation ? "sending" : ""}`}
                              onClick={this.resendInvitation(user.name, user.email)}
                            >
                              Resend invitation
                            </span>{" "}
                            <span>or</span>{" "}
                            <span
                              className={`copy-inv-link ${copyingLink ? "copying" : ""}`}
                              onClick={this.copyInvitationLink(user.id)}
                            >
                              Create new invitation link
                            </span>
                          </React.Fragment>
                        )}
                      </Td>
                      <Td className="role-column user-table-cell">
                        <Select
                          value={roles ? roles.find(role => role.value === user.role.id) : null}
                          onChange={opt => setUserRole(user.id, opt.value)}
                          options={roles === null ? [] : roles}
                          styles={selectStyles("small")}
                          simpleValue
                          isSearchable={true}
                          isLoading={roles === null}
                          className="select-input"
                          components={{
                            DropdownIndicator: DropdownIndicator,
                          }}
                          classNamePrefix="role_select"
                          noOptionsMessage={() => "Empty"}
                        />
                      </Td>
                      <Td textAlignRight className="toggle-column user-table-cell">
                        <ToggleButton
                          value={!user.disabled}
                          handleToggle={() => toggleDisabled(user)}
                        />
                      </Td>
                      <Td textAlignRight className="action-column two-icon user-table-cell">
                        <IconButton
                          color="black"
                          onClick={this.goToUser(user)}
                          size="xs"
                          icon="user-edit"
                          iconStyle="far"
                          tooltip="Edit"
                          variant="outlined"
                        />
                        <IconButton
                          color="red"
                          onClick={this.openDeleteUserModal(user)}
                          size="xs"
                          className="trash"
                          icon="trash-alt"
                          tooltip="Delete"
                          variant="outlined"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Paper>
          )}
          <CreateUserModal
            open={createUserModalOpen}
            handleClose={this.toggleCreateUserModal}
            initialValues={{ send_invitation_emails: true }}
            roles={roles}
          />
          <ConfirmModal
            open={deleteModal.open}
            type={MODAL.TYPE.DELETE}
            handleClose={this.closeDeleteUserModal}
            handleConfirm={this.deleteUser}
            title="Delete user"
            action="delete"
            what="user"
            item={_get(deleteModal, "item.name", "")}
          />
        </>
      </Page>
    )
  }
}

const useStore = create(set => ({
  orderBy: "name",
  orderDir: "ASC",
  // searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  // setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default props => {
  const filters = useStore()
  const dispatch = useDispatch()
  const { orderBy, orderDir } = filters
  const formValues = useSelector(getFormValues("UsersSearch"))

  const { data: users, isSuccess } = useFetchActiveUsers({
    orderBy,
    orderDir,
    searchTerm: formValues?.search.trim(),
  })

  const modifyMutation = useModifyUser()

  const toggleDisabled = user =>
    modifyMutation.mutateAsync({ id: user.id, data: { disabled: !user.disabled } })

  const setUserRole = (userId, roleId) =>
    modifyMutation.mutate({ id: userId, data: { role_id: roleId } })

  const deleteMutation = useDeleteUser()
  const deleteUser = id => deleteMutation.mutate({ id })

  return (
    <UsersList
      {...props}
      users={users}
      filters={filters}
      areUsersFulfilled={isSuccess}
      toggleDisabled={toggleDisabled}
      setUserRole={setUserRole}
      deleteUser={deleteUser}
      dispatch={dispatch}
    />
  )
}
