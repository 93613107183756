import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { onlyValidAttributesUsed, required } from "helpers/validators.helper"
import { pick } from "ramda"
import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { Prompt, useLocation } from "react-router-dom"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import {
  useFetchAllDeletedMobilePushNotifications,
  useFetchAllMobilePushNotifications,
  useModifyMobilePushNotification,
} from "resources/mobilePushNotification/mobilePushNotificationQueries"
import {
  MobilePushNotification,
  ModifyMobilePushNotificationPayload,
} from "resources/mobilePushNotification/mobilePushNotificationTypes"
import ActivationView from "./components/ActivationView/ActivationView"
import NotificationPreview from "./components/NotificationPreview/NotificationPreview"
import UsedAttributesList from "components/UsedAttributesList/UsedAttributesList"
import styles from "./MobilePushNotificationForm.module.scss"
import { replaceAttributePlaceholders } from "./utils"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHasAccess } from "resources/user/currentUserQueries"
import TextArea from "components/UI/elements/TextArea/TextArea"
import ActivationButton from "components/UI/components/ActivationButton/ActivationButton"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"

type MPNFormView = "content" | "activation"
export type FormValues = Pick<MobilePushNotification, "name" | "title_template" | "body_template">

type MobilePushNotificationFormProps = {
  notification?: MobilePushNotification
  onSubmit: (
    values: ModifyMobilePushNotificationPayload,
  ) => Promise<{ push_notification: MobilePushNotification }>
  onDelete?: () => void
}

const IOS_LIMIT = 178
const ANDROID_TITLE_LIMIT = 65
const ANDROID_BODY_LIMIT = 240
const SAFETY_MARGIN = 10

const truncate = (text: string, limit: number) =>
  text.length <= limit ? text : limit <= 0 ? "" : text.slice(0, limit - 1) + "…"

export default function MobilePushNotificationForm({
  notification,
  onDelete,
  onSubmit,
}: MobilePushNotificationFormProps) {
  const dispatch = useDispatch()

  const { data: attributesMapById = {} } = useFetchAttributesMap({ includeHidden: true })

  const location = useLocation<{ activation?: boolean }>()
  const [view, setView] = useState<MPNFormView>(
    location.state?.activation ? "activation" : "content",
  )

  const defaultValues = notification
    ? pick(["name", "title_template", "body_template"], notification)
    : { name: "", title_template: "", body_template: "" }

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
    watch,
    setValue,
    reset,
  } = useForm({ defaultValues })

  const activeMobilePushes = useFetchAllMobilePushNotifications()
  const deletedMobilePushes = useFetchAllDeletedMobilePushNotifications()
  const saveMutation = useModifyMobilePushNotification()

  const nameIsUnique = async (name: string) =>
    new Promise<string | undefined>(resolve => {
      while (true) {
        if (!activeMobilePushes.isLoading || !deletedMobilePushes.isLoading) break
      }

      const nameCollision = (existing: MobilePushNotification) =>
        existing.name === name && existing.id !== notification?.id

      if (
        activeMobilePushes.data?.push_notifications.some(nameCollision) ||
        deletedMobilePushes.data?.some(nameCollision)
      ) {
        resolve("This name is already used")
      }

      resolve(undefined)
    })

  const templateTitle = watch("title_template") ?? ""
  const titleFieldRef = useRef<HTMLInputElement | null>(null)
  const { ref: titleFieldOrigRef, ...titleRegister } = register("title_template", {
    validate: {
      required,
      onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMapById),
    },
  })
  const [showTitleAttrPicker, setShowTitleAttrPicker] = useState(false)
  function insertAttributeIdToTitle(attributeId: string | null) {
    if (!titleFieldRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = titleFieldRef.current
    setValue(
      "title_template",
      `${templateTitle.slice(0, selectionStart ?? 0)}{{${attributeId}}}${templateTitle.slice(
        selectionEnd ?? 0,
      )}`,
      {
        shouldDirty: true,
      },
    )
    setShowTitleAttrPicker(false)
  }

  const templateBody = watch("body_template") ?? ""
  const bodyFieldRef = useRef<HTMLTextAreaElement | null>(null)
  const { ref: bodyFieldOrigRef, ...bodyRegister } = register("body_template", {
    validate: {
      required,
      onlyValidAttributesUsed: value => onlyValidAttributesUsed(value, attributesMapById),
    },
  })
  const [showBodyAttrPicker, setShowBodyAttrPicker] = useState(false)
  function insertAttributeIdToBody(attributeId: string | null) {
    if (!bodyFieldRef.current || !attributeId) {
      return
    }
    const { selectionStart, selectionEnd } = bodyFieldRef.current
    setValue(
      "body_template",
      `${templateBody.slice(0, selectionStart ?? 0)}{{${attributeId}}}${templateBody.slice(
        selectionEnd ?? 0,
      )}`,
      {
        shouldDirty: true,
      },
    )
    setShowBodyAttrPicker(false)
  }

  const saveMobilePush = () => {
    if (notification && isDirty)
      handleSubmit(formValues =>
        saveMutation.mutate(
          { id: notification.id, data: formValues },
          {
            onSuccess: () => {
              dispatch(showToast("Mobile push saved."))
              setView("activation")
              reset(formValues)
            },
          },
        ),
      )()
    else setView("activation")
  }

  const hasAccess = useHasAccess()

  const isEditDisabled = !hasAccess.mobilePushNotifications.edit

  const titleWithExamples = replaceAttributePlaceholders(templateTitle, attributesMapById)
  const bodyWithExamples = replaceAttributePlaceholders(templateBody, attributesMapById)

  const isTitleCloseToAndroidLimit = titleWithExamples.length > ANDROID_TITLE_LIMIT - SAFETY_MARGIN
  const isBodyCloseToAndroidLimit = bodyWithExamples.length > ANDROID_BODY_LIMIT - SAFETY_MARGIN
  const isMessageCloseToIOSLimit =
    (titleWithExamples + bodyWithExamples).length > IOS_LIMIT - SAFETY_MARGIN

  const androidTitlePreview = truncate(titleWithExamples, ANDROID_TITLE_LIMIT)
  const androidBodyPreview = truncate(bodyWithExamples, ANDROID_BODY_LIMIT)
  const iOSTitlePreview = truncate(titleWithExamples, IOS_LIMIT)
  const iOSBodyPreview = truncate(bodyWithExamples, IOS_LIMIT - iOSTitlePreview.length)

  return (
    <>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />

      <Paper className={styles.tabs}>
        <button
          onClick={() => setView("content")}
          className={classNames(styles.tabButton, { [styles.active]: view === "content" })}
        >
          Content
        </button>
        <FontAwesomeIcon icon={["far", "chevron-right"]} />
        <ActivationButton
          active={view === "activation"}
          disabled={isEditDisabled}
          isLoading={saveMutation.isLoading}
          channel="push_notifications"
          onClick={() => saveMobilePush()}
        />
      </Paper>

      {view === "content" && (
        <form
          onSubmit={handleSubmit(async formValues => {
            await onSubmit(formValues)
            reset(formValues)
          })}
        >
          <PaperHeader
            size="small"
            className={styles.header}
            dataTestId="mobile-push-notification-detail-header"
          >
            <TextInput
              label="Name"
              labelPosition="left"
              {...register("name", { validate: { required, nameIsUnique } })}
              disabled={isEditDisabled}
              error={errors.name?.message}
              placeholder="Name"
              maxLength={100}
              className={styles.name}
            />
            <div className={styles.buttons}>
              {notification && onDelete && (
                <Button disabled={isEditDisabled} color="red" variant="outlined" onClick={onDelete}>
                  Delete
                </Button>
              )}
              <Button disabled={isEditDisabled} loading={isSubmitting} type="submit">
                {notification ? "Save" : "Create"}
              </Button>
            </div>
          </PaperHeader>
          <Paper className={styles.body} dataTestId="mobile-push-notifications-detail-paper">
            <div className={styles.formFields}>
              <div className={styles.textFieldWrapper}>
                {!isEditDisabled && (
                  <div
                    className={classNames(styles.attributePickerWrapper, {
                      [styles.open]: showTitleAttrPicker,
                    })}
                    onClick={showTitleAttrPicker ? undefined : () => setShowTitleAttrPicker(true)}
                  >
                    <div className={styles.icons}>
                      <FontAwesomeIcon
                        className={styles.bracketsIcon}
                        icon={["fas", "brackets-curly"]}
                      />
                      <FontAwesomeIcon
                        className={styles.ellipsisIcon}
                        icon={["far", "ellipsis-h"]}
                      />
                    </div>
                    {!showTitleAttrPicker && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} className={styles.caretIcon} />
                    )}
                    {showTitleAttrPicker && (
                      <AttributePicker
                        handleAttributeSelect={insertAttributeIdToTitle}
                        className={styles.attributePicker}
                        isEditable
                        withDimensions
                        onlyUnique
                        focusOnLoad
                        onClose={() => setShowTitleAttrPicker(false)}
                        rightAlign
                        autoFocus
                      />
                    )}
                  </div>
                )}
                <TextInput
                  {...titleRegister}
                  readOnly={isEditDisabled}
                  error={errors.title_template?.message}
                  label="Title"
                  ref={(el: HTMLInputElement) => {
                    titleFieldOrigRef(el)
                    titleFieldRef.current = el
                  }}
                />
              </div>
              {isTitleCloseToAndroidLimit && (
                <div className={styles.warning}>
                  Warning: the title might be truncated on Android devices if it exceeds{" "}
                  {ANDROID_TITLE_LIMIT} characters in length.
                </div>
              )}
              <UsedAttributesList text={templateTitle} attributesMapById={attributesMapById} />
              <div className={styles.textFieldWrapper}>
                {!isEditDisabled && (
                  <div
                    className={classNames(styles.attributePickerWrapper, styles.inTextarea, {
                      [styles.open]: showBodyAttrPicker,
                    })}
                    onClick={showBodyAttrPicker ? undefined : () => setShowBodyAttrPicker(true)}
                  >
                    <div className={styles.icons}>
                      <FontAwesomeIcon
                        className={styles.bracketsIcon}
                        icon={["fas", "brackets-curly"]}
                      />
                      <FontAwesomeIcon
                        className={styles.ellipsisIcon}
                        icon={["far", "ellipsis-h"]}
                      />
                    </div>
                    {!showBodyAttrPicker && (
                      <FontAwesomeIcon icon={["fas", "caret-down"]} className={styles.caretIcon} />
                    )}
                    {showBodyAttrPicker && (
                      <AttributePicker
                        handleAttributeSelect={insertAttributeIdToBody}
                        className={styles.attributePicker}
                        isEditable
                        withDimensions
                        onlyUnique
                        focusOnLoad
                        onClose={() => setShowBodyAttrPicker(false)}
                        rightAlign
                        autoFocus
                      />
                    )}
                  </div>
                )}
                <TextArea
                  {...bodyRegister}
                  readOnly={isEditDisabled}
                  error={errors.body_template?.message}
                  label="Body"
                  rows={10}
                  ref={(el: HTMLTextAreaElement) => {
                    bodyFieldOrigRef(el)
                    bodyFieldRef.current = el
                  }}
                />
              </div>
              {isMessageCloseToIOSLimit && (
                <div className={styles.warning}>
                  Warning: the notification might be truncated on iOS devices if the title and body
                  combined exceed {IOS_LIMIT} characters in length.
                </div>
              )}
              {isBodyCloseToAndroidLimit && (
                <div className={styles.warning}>
                  Warning: the body might be truncated on Android devices if it exceeds{" "}
                  {ANDROID_BODY_LIMIT} characters in length.
                </div>
              )}
              <UsedAttributesList text={templateBody} attributesMapById={attributesMapById} />
            </div>
            <NotificationPreview
              androidTitlePreview={androidTitlePreview}
              androidBodyPreview={androidBodyPreview}
              iOSTitlePreview={iOSTitlePreview}
              iOSBodyPreview={iOSBodyPreview}
            />
          </Paper>
        </form>
      )}

      {view === "activation" && <ActivationView notification={notification} />}
    </>
  )
}
