import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Radar, Polar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import _toInteger from "lodash/toInteger"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { whereEq } from "ramda"

// ui components
import Paper from "components/UI/elements/Paper"
import CustomerAvatar from "components/UI/elements/CustomerAvatar"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable/CompoundAttributeValuesTable"
import SystemMessage from "components/UI/elements/SystemMessage/SystemMessage"

// helpers
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { getRoutePath } from "routes"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { isJSONString } from "helpers/validators.helper"

import "./Header.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { useHasAccess } from "resources/user/currentUserQueries"

const radarOptions = {
  responsive: false,
  scale: {
    gridLines: {
      circular: true,
      color: "#efefef",
    },
    ticks: {
      display: false,
      stepSize: 25,
      suggestedMin: 0,
      suggestedMax: 100,
    },
    pointLabels: {
      fontFamily: "'Inter UI', sans-serif",
      fontSize: 12,
      color: "#777777",
      lineHeight: 1,
    },
    angleLines: {
      color: "#efefef",
    },
  },
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 3,
    },
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.borderColor
      },
      color: "white",
      borderRadius: 3,
      padding: 2,
      font: {
        size: 10,
        weight: "normal",
      },
      anchor: "start",
      align: "end",
      offset: 0,
      clamp: true,
    },
  },
}

const polarOptions = {
  responsive: false,
  scale: {
    ticks: {
      display: false,
    },
    pointLabels: {
      fontFamily: "'Inter UI', sans-serif",
      fontSize: 12,
      color: "#777777",
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 3,
    },
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
  plugins: {
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.borderColor
      },
      color: "black",
      borderRadius: 3,
      padding: 2,
      font: {
        size: 10,
        weight: "normal",
      },
      anchor: "end",
      align: "end",
      offset: -10,
    },
  },
}

const breakRadarLabelIfLong = label => {
  if (label && label.length > 20) {
    const indices = [...label].flatMap((char, i) => (char === " " ? i + 1 : []))
    let breakPoint = -1
    let diff = label.length
    indices.forEach(idx => {
      const iDiff = Math.abs(label.length / 2 - idx)
      if (iDiff < diff) {
        breakPoint = idx
        diff = iDiff
      }
    })
    if (breakPoint !== -1) {
      return [label.substring(0, breakPoint - 1), label.substring(breakPoint)]
    }
  }
  return label
}

class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showSystemMessage: props.showSystemMessage,
    }
  }

  render() {
    const {
      channelEngagementAttributes,
      customerId,
      contactInfoAttributes,
      customerAttributes,
      isGlobalSettingsFulfilled,
      hasAccess,
    } = this.props
    const { showSystemMessage } = this.state

    let data = {}
    if (channelEngagementAttributes.length < 3) {
      data = {
        labels: channelEngagementAttributes.map(attribute => attribute.name),
        datasets: [
          {
            data: channelEngagementAttributes.map(attribute => {
              const attributeValues =
                customerAttributes
                  .filter(whereEq({ attribute_id: attribute.id }))
                  .map(({ value }) => value) ?? []

              if (attributeValues.length > 0) {
                const val = _toInteger(attributeValues[0])
                return val > 100 || val === 0 ? null : val
              }

              return null
            }),
            backgroundColor: ["#FE7F66", "#FABE53"],
          },
        ],
      }
    } else {
      data = {
        labels: channelEngagementAttributes.map(attribute => breakRadarLabelIfLong(attribute.name)),
        datasets: [
          {
            backgroundColor: "rgba(254, 127, 102, 0.75)",
            borderColor: "#FE7F66",
            pointBackgroundColor: "#C4C4C4",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#FE7F66",
            data: channelEngagementAttributes.map(attribute => {
              const attributeValues =
                customerAttributes
                  .filter(whereEq({ attribute_id: attribute.id }))
                  .map(({ value }) => value) ?? []

              if (attributeValues.length > 0) {
                const val = _toInteger(attributeValues[0])
                return val > 100 ? 0 : val
              }

              return 0
            }),
          },
        ],
      }
    }

    const filteredContactInfoAttributes = contactInfoAttributes.filter(attribute => {
      const contactInfoAttributeValues =
        customerAttributes
          .filter(whereEq({ attribute_id: attribute.id }))
          .map(({ value }) => value) ?? []

      return !attribute.is_hidden && contactInfoAttributeValues.length > 0
    })

    return (
      <header
        className={classNames("customer-detail-header", {
          "show-system-message": showSystemMessage,
        })}
      >
        {showSystemMessage && (
          <SystemMessage
            text="This customer entity is suspiciously large, please check your rules for identity resolution."
            onDismiss={() => this.setState({ showSystemMessage: false })}
          />
        )}

        <div className="customer-detail-header-row2">
          <Paper className="basic-info">
            <div className="customer-title-avatar">
              <CustomerAvatar className="customer-avatar" />
              <h2>Profile {customerId}</h2>
            </div>
            {filteredContactInfoAttributes.length > 0 && (
              <>
                <h3 className="basic-info-title">Profile Highlights</h3>
                <div className="customer-attributes-values-list">
                  {filteredContactInfoAttributes.map(attribute => {
                    const attributeValues =
                      customerAttributes
                        .filter(whereEq({ attribute_id: attribute.id }))
                        .map(({ value }) => value) ?? []

                    if (attributeValues.length === 0) return null

                    const compoundAttributeView = isAttributeCompound(attribute.data_type)
                    const firstCompoundAttributeValue = attributeValues[0]

                    if (compoundAttributeView && isJSONString(firstCompoundAttributeValue)) {
                      const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
                      const values = attributeValues.map(val => JSON.parse(val)) ?? []

                      return (
                        <div className="row compound-attr-view" key={attribute.id}>
                          <div className="attrname">
                            <div className="name-tags">
                              <span className="name-wrapper">{attribute.name}</span>
                            </div>
                          </div>
                          <div className="attrvalue text-grey align-right">
                            <CompoundAttributeValuesTable
                              subAttributes={subAttributes}
                              values={values}
                            />
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="row" key={attribute.id}>
                          <div className="attrname">
                            <div className="name-tags">
                              <span className="name-wrapper">{attribute.name}</span>
                            </div>
                          </div>
                          <div className={"attrvalue text-grey align-right"}>
                            <div className="value-wrapper">
                              {attributeValues.map((customerAttributeValue, key) => (
                                <span className="customer-attribute-value" key={key}>
                                  {getUserFriendlyValueFormat(
                                    customerAttributeValue,
                                    attribute.data_type,
                                    ["date", "datetime"].includes(attribute.data_type)
                                      ? true
                                      : false,
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </>
            )}
          </Paper>
          <Paper className="chart-box">
            <h3>
              <InfoTooltip>
                This chart helps you to understand how data sources are popular among your clients.
              </InfoTooltip>{" "}
              Channel engagement
            </h3>
            <div className="chart-wrapper">
              {isGlobalSettingsFulfilled && channelEngagementAttributes.length === 0 && (
                <p className="chart-na">
                  {hasAccess.administration.globalSettings && (
                    <span>
                      The data source for this chart has not been set.
                      <br />
                      Please go to the{" "}
                      <Link to={getRoutePath("administration.settings")}>Settings tab</Link>.
                    </span>
                  )}
                  {!hasAccess.administration.globalSettings && (
                    <span>
                      The data source for this chart has not been set.
                      <br />
                      Please contact your administrator.
                    </span>
                  )}
                </p>
              )}
              {isGlobalSettingsFulfilled &&
                channelEngagementAttributes.length < 3 &&
                channelEngagementAttributes.length > 0 && (
                  <Polar
                    width={440}
                    height={250}
                    data={data}
                    options={polarOptions}
                    plugins={[ChartDataLabels]}
                  />
                )}
              {isGlobalSettingsFulfilled && channelEngagementAttributes.length >= 3 && (
                <Radar
                  width={440}
                  height={250}
                  data={data}
                  options={radarOptions}
                  plugins={[ChartDataLabels]}
                />
              )}
            </div>
          </Paper>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  customerId: PropTypes.string.isRequired,
  contactInfoAttributes: PropTypes.instanceOf(Array).isRequired,
  channelEngagementAttributes: PropTypes.instanceOf(Array).isRequired,
  customerAttributes: PropTypes.array.isRequired,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
  showSystemMessage: PropTypes.bool.isRequired,
}

export default props => {
  const hasAccess = useHasAccess()

  return <Header {...props} hasAccess={hasAccess} />
}
