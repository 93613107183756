import React from "react"
import SearchFormWithSelect, {
  SearchFormWithSelectDestroyable,
} from "components/UI/components/SearchFormWithSelect"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"

export default function EventsFilterForm({
  destroyOnUnmount = true,
  formName = "SearchEventForm",
  showHiddenSources,
}) {
  const { data: sourceOptions = [] } = useFetchDataSourceOptions({ showHidden: showHiddenSources })

  return destroyOnUnmount ? (
    <SearchFormWithSelectDestroyable
      form={formName}
      inputPlaceholder="Search for events"
      selectOptions={sourceOptions}
      selectPlaceholder="All sources"
    />
  ) : (
    <SearchFormWithSelect
      form={formName}
      inputPlaceholder="Search for events"
      selectOptions={sourceOptions}
      selectPlaceholder="All sources"
    />
  )
}
