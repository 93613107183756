import React, { useMemo, useState } from "react"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { useDeleteEmail, useFetchAllEmails } from "resources/email/emailQueries"
import { Email, EmailSelectionState } from "resources/email/emailTypes"

import styles from "./EmailsList.module.scss"
import create from "zustand"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Table, {
  RowMessage,
  SortButton,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "components/UI/elements/Table"
import { format } from "date-fns"
import { DATEFNS, EMAILS_CHANNEL_NAME } from "sharedConstants"
import Username from "components/Username/Username"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import {
  useFetchChannels,
  useFetchEmailChannel,
  useModifyEmailsChannel,
} from "resources/channel/channelQueries"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import EmailsImg from "./EmailsImg.jpg"
import { useHasAccess } from "resources/user/currentUserQueries"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Page from "components/UI/Page/Page"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EmailsChannel } from "resources/channel/channelTypes"
import { isEmpty, omit } from "ramda"
import ChannelFrequencyCap from "components/UI/components/ChannelFrequencyCap/ChannelFrequencyCap"
import { isLocalFrequencyConflicting } from "resources/channel/channelUtils"
import ChannelFrequencyCapModal from "components/UI/components/ChannelFrequencyCapModal/ChannelFrequencyCapModal"
import EmailTypeBadge from "../components/EmailTypeBadge/EmailTypeBadge"

const pageTitle = "Email campaigns"

const parseJsonContent = (contentJson: string) => {
  try {
    return JSON.parse(contentJson)
  } catch {
    return {}
  }
}

export const getContentType = (jsonContent: string | null) => {
  if (!jsonContent) return null

  const parsedJsonContent = parseJsonContent(jsonContent)
  return parsedJsonContent ? (isEmpty(parsedJsonContent) ? "html" : "json") : null
}

const useStore = create<EmailSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function EmailsList() {
  const [emailToDelete, setEmailToDelete] = useState<Email | null>()
  const [affectedCampaigns, setAffectedCampaigns] = useState<Array<Email>>([])
  const [frequencyCapToSave, setFrequencyCapToSave] = useState<
    EmailsChannel["frequency_cap"] | undefined
  >()

  const history = useHistory()

  const { data: channels, isLoading: isLoadingChannels } = useFetchChannels()
  const emailsEnabled = channels
    ? channels.find(({ name }) => name === EMAILS_CHANNEL_NAME)?.is_active
    : false

  const hasAccess = useHasAccess()
  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()

  const {
    data,
    isLoading: isLoadingEmails,
    isFetching: isFetchingEmails,
  } = useFetchAllEmails(
    {
      enabled: hasAccess.emails.view && emailsEnabled,
    },
    { orderBy, orderDir, searchTerm: searchTerm.trim() },
  )

  const { mutate: deleteEmail, isLoading: isDeleting } = useDeleteEmail()

  const { data: emailChannel, isLoading: isLoadingEmailChannel } = useFetchEmailChannel({
    enabled: hasAccess.setup.channels && emailsEnabled,
  })
  const modifyMutation = useModifyEmailsChannel()

  const conflictingCampaigns = useMemo(() => {
    if (!data || !emailChannel) return []

    return data.emails.filter(({ frequency_cap }) =>
      isLocalFrequencyConflicting(frequency_cap, emailChannel.frequency_cap),
    )
  }, [data, emailChannel])

  const closeDeleteModal = () => setEmailToDelete(null)

  const closeFrequencyCapModal = () => {
    setAffectedCampaigns([])
    setFrequencyCapToSave(undefined)
  }

  const modifyFrequencyCap = (formFields: EmailsChannel["frequency_cap"]) => {
    if (!emailChannel || modifyMutation.isLoading) return

    modifyMutation.mutate(
      {
        data: {
          ...omit(["is_active", "modified_by", "modified"], emailChannel),
          frequency_cap: formFields,
        },
      },
      {
        onSuccess: () => {
          closeFrequencyCapModal()
        },
      },
    )
  }

  if (isLoadingChannels || (isLoadingEmails && isFetchingEmails))
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )

  if (!emailsEnabled)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
          <h1>Emails are ready for use!</h1>
          <strong>
            To set this up, please contact{" "}
            <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
              support@meiro.io
            </a>{" "}
            or get in touch with your Meiro account manager!
          </strong>
          <p>
            Use the email channel to orchestrate{" "}
            <strong className={styles.primary}>personalized email campaigns</strong> in real-time by
            using customer attributes such as name, recommended products, items left in the basket,
            searched products, and more. Our visual email editor helps create email templates with a
            simple drag-and-drop interface. Alternatively, you can use our HTML builder to load
            pre-written HTML templates.
          </p>
          <p>
            Check out examples of possible{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
              target="_blank"
              rel="noreferrer"
            >
              use cases
            </a>{" "}
            for inspiration on leveraging email campaigns.
          </p>
        </MarketingContent>
      </Page>
    )

  if (!hasAccess.emails.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
          <h1>Emails</h1>
          <strong>
            It seems like you don't have access to the Emails channel. If you want to know more
            about your access settings, please contact your administrator.
          </strong>
          <p>
            In the Emails tab, you can create{" "}
            <strong className={styles.primary}>email campaigns</strong> and deliver them to desired
            audience groups with <strong className={styles.primary}>personalized</strong> content,
            such as names, recommended products, items left in the basket, searched products, and
            more.
          </p>
          <p>
            Check out possible{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
              target="_blank"
              rel="noreferrer"
            >
              use cases
            </a>{" "}
            and access{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/chapter/email-campaigns"
              target="_blank"
              rel="noreferrer"
            >
              tutorials
            </a>{" "}
            for the Email channel.
          </p>
        </MarketingContent>
      </Page>
    )

  const isEmpty = data?.emails.length === 0

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            wrapperClassName={styles.search}
          />
          <Button
            disabled={!hasAccess.emails.edit}
            onClick={() => history.push(getRoutePath("channels.emails.create"))}
          >
            + Create Email
          </Button>
        </>
      }
    >
      {emailChannel && (
        <ChannelFrequencyCap
          channel="emails"
          description="The rule applies to all email campaigns. It restricts the number of emails within a specific time interval. The limit is set to the email address, and if a customer profile has multiple email addresses, all recognized email addresses will be considered one count toward the global limit."
          disabled={!hasAccess.emails.edit}
          isLoading={isLoadingEmailChannel}
          isSaving={modifyMutation.isLoading}
          conflictingCampaigns={conflictingCampaigns.map(({ id, name }) => ({ id, name }))}
          frequencyCap={emailChannel.frequency_cap}
          onSubmit={formValues => {
            if (data?.emails) {
              const conflictingCampaignsToBe = data.emails.filter(({ frequency_cap }) =>
                isLocalFrequencyConflicting(frequency_cap, formValues),
              )

              if (conflictingCampaignsToBe.length > 0) {
                setAffectedCampaigns(conflictingCampaignsToBe)
                setFrequencyCapToSave(formValues)
                return
              }
            }

            modifyFrequencyCap(formValues)
          }}
        />
      )}
      <Paper className={styles.paper}>
        <div className={styles.trashButtonWrapper}>
          <Link to={getRoutePath("channels.emails.trash")}>
            <Button type="button" color="grey" variant="outlined" icon="trash-alt" iconStyle="far">
              Trash
            </Button>
          </Link>
        </div>
        {isEmpty ? (
          <RowMessage>No email found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>
                <SortButton
                  column="name"
                  label="Name"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("name")}
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="last_activation"
                  label="Last activation"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("last_activation")}
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="modified"
                  label="Modified at"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("modified")}
                />
              </Th>
              <Th>&nbsp;</Th>
            </Thead>
            <Tbody>
              {data?.emails.map(email => {
                const contentType = getContentType(email.content_json)

                return (
                  <React.Fragment key={email.id}>
                    <Tr>
                      <Td textBigger textBlack textBold className={styles.name}>
                        {email.frequency_cap.ignore_channel_frequency_cap && (
                          <Tippy content="Ignores global frequency cap." placement="bottom">
                            <FontAwesomeIcon icon={["fas", "bolt"]} className={styles.boltIcon} />
                          </Tippy>
                        )}
                        {conflictingCampaigns.find(({ id }) => email.id === id) && (
                          <Tippy
                            content="Conflicting with global frequency cap."
                            placement="bottom"
                          >
                            <FontAwesomeIcon
                              icon={["fas", "exclamation-triangle"]}
                              className={styles.warningIcon}
                            />
                          </Tippy>
                        )}
                        {email.name} {contentType && <EmailTypeBadge contentType={contentType} />}
                      </Td>
                      <Td textAlignRight className={styles.date}>
                        {email["last_activation"]
                          ? format(new Date(`${email.last_activation}Z`), DATEFNS.DATETIME_FORMAT)
                          : ""}
                      </Td>
                      <Td textAlignRight className={styles.date}>
                        <div>
                          {" "}
                          {format(new Date(`${email.modified}Z`), DATEFNS.DATETIME_FORMAT)}
                        </div>
                        <div className={styles.modifiedBy}>
                          by <Username userId={email.modified_by} />
                        </div>
                      </Td>
                      <Td textAlignRight className={styles.actions}>
                        <Link
                          to={getRoutePath("channels.emails.detail", {
                            id: email.id,
                          })}
                          className={styles.editLink}
                        >
                          <IconButton
                            color="black"
                            size="xs"
                            variant="outlined"
                            icon="pencil-alt"
                            tooltip="Edit"
                            data-testid="edit-email"
                          />
                        </Link>
                        <IconButton
                          disabled={!hasAccess.emails.edit}
                          color="red"
                          icon="trash-alt"
                          size="xs"
                          tooltip="Delete"
                          variant="outlined"
                          onClick={() => setEmailToDelete(email)}
                          data-testid="remove-email"
                        />
                      </Td>
                    </Tr>
                  </React.Fragment>
                )
              })}
            </Tbody>
          </Table>
        )}
      </Paper>
      {isEmpty && searchTerm === "" && (
        <MarketingContent img={{ alt: "Emails", src: EmailsImg }}>
          <h1>Emails</h1>
          <p>
            In the Emails tab, you can create{" "}
            <strong className={styles.primary}>email campaigns</strong> and deliver them to desired
            audience groups with <strong className={styles.primary}>personalized</strong> content,
            such as names, recommended products, items left in the basket, searched products, and
            more.
          </p>
          <p>
            Check out possible{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/emails-use-cases"
              target="_blank"
              rel="noreferrer"
            >
              use cases
            </a>{" "}
            and access{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/chapter/email-campaigns"
              target="_blank"
              rel="noreferrer"
            >
              tutorials
            </a>{" "}
            for the Email channel.
          </p>
        </MarketingContent>
      )}
      <ConfirmModal
        isLoading={isDeleting}
        open={!!emailToDelete}
        action="delete"
        item={emailToDelete?.name}
        title="Are you sure?"
        type="delete"
        what="email"
        handleClose={closeDeleteModal}
        handleConfirm={() => deleteEmail(emailToDelete!, { onSuccess: () => closeDeleteModal() })}
      />
      {affectedCampaigns.length > 0 && (
        <ChannelFrequencyCapModal
          affectedCampaigns={affectedCampaigns.map(({ id, name }) => ({ id, name }))}
          channel="emails"
          isLoading={modifyMutation.isLoading}
          open={affectedCampaigns.length > 0}
          onSubmit={() => {
            if (frequencyCapToSave) modifyFrequencyCap(frequencyCapToSave)
          }}
          onClose={closeFrequencyCapModal}
        />
      )}
    </Page>
  )
}
