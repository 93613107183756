import React, { useMemo, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"

import Button from "components/UI/elements/Button/Button"
import Modal from "components/UI/elements/Modal"
import { getRoutePath } from "routes"

import styles from "./ChannelFrequencyCapModal.module.scss"
import { ChannelType } from "resources/channel/channelTypes"

const SIMPLE_BAR_HEIGHT = 400

type ChannelFrequencyCapModalProps = {
  affectedCampaigns: Array<{ id: string; name: string }>
  channel: ChannelType
  isLoading: boolean
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export default function ChannelFrequencyCapModal({
  affectedCampaigns,
  channel,
  isLoading,
  open,
  onClose,
  onSubmit,
}: ChannelFrequencyCapModalProps) {
  const [isBottom, setIsBottom] = useState(false)

  const scrollableRef = useRef<HTMLElement>(null)
  const { current } = scrollableRef

  const isScrollVisible = useMemo(() => {
    if (!current) return false
    return current.clientHeight === SIMPLE_BAR_HEIGHT
  }, [current])

  return (
    <Modal open={open} title="Global frequency cap" handleClose={onClose}>
      <p className={styles.modalText}>
        You've setup global frequency cap that conflicts with frequency cap of some email campaigns:
      </p>
      <SimpleBar
        scrollableNodeProps={{ ref: scrollableRef }}
        onScrollCapture={() => {
          if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current
            if (Math.round(scrollTop + clientHeight) === scrollHeight) setIsBottom(true)
            else setIsBottom(false)
          }
        }}
        className={classNames(styles.bar, { [styles.notBottom]: !isBottom && isScrollVisible })}
        style={{ maxHeight: `${SIMPLE_BAR_HEIGHT}px` }}
      >
        <div className={styles.campaigns}>
          {affectedCampaigns.map((campaign, index) => (
            <Link
              key={campaign.id}
              to={getRoutePath(
                channel === "emails" ? "channels.emails.detail" : "channels.mobile-push.detail",
                { id: campaign.id },
              )}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames(styles.link, {
                [styles.noBorder]: affectedCampaigns.length - 1 === index,
              })}
            >
              <span>{campaign.name}</span>
              <FontAwesomeIcon icon="circle-arrow-right" />
            </Link>
          ))}
        </div>
      </SimpleBar>
      <div className={styles.buttons}>
        <Button color="grey" size="md" variant="text" onClick={_ => onClose()}>
          Cancel
        </Button>
        <Button loading={isLoading} color="green" size="md" onClick={_ => onSubmit()}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}
