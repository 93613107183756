import { isInteger } from "lodash"
import { ERROR, WBCondition, WBConditionError } from "resources/webBanner/webBannerConditionTypes"
import {
  evaluateRelativeDatetime,
  isArrayCondition,
  isCookieCondition,
  isGTMCondition,
  isHTTPCondition,
  isLSCondition,
  isRangeCondition,
  isSegmentCondition,
  isSingleValueCondition,
  isTypedCondition,
} from "./utils"
import { isEmptyField } from "helpers/validators.helper"

const isValidHour = (hour: number) => isInteger(hour) && hour >= 0 && hour <= 23
const isValidPageViewCount = (count: number) => isInteger(count) && count >= 0 && count <= 999

export function validateWebBannerCondition(condition: WBCondition): WBConditionError | null {
  if (condition.operator === "negation") {
    condition = condition.operand
  }

  const result: WBConditionError = {}

  if (condition.subject === null) {
    result.subject = ERROR.REQUIRED
  }
  if (condition.operator === null) {
    result.operator = ERROR.REQUIRED
  }

  if (isCookieCondition(condition) && isEmptyField(condition.name)) {
    result.name = ERROR.REQUIRED
  }

  if (isLSCondition(condition) && isEmptyField(condition.key)) {
    result.key = ERROR.REQUIRED
  }

  if (isGTMCondition(condition)) {
    if (isEmptyField(condition.dl_key)) {
      result.dl_key = ERROR.REQUIRED
    }
    if (isEmptyField(condition.dl_name)) {
      result.dl_name = ERROR.REQUIRED
    }
  }

  if (isHTTPCondition(condition)) {
    if (isEmptyField(condition.url_template)) {
      result.url_template = ERROR.REQUIRED
    }
    if (condition.operator !== "is_ok" && isEmptyField(condition.response_value_path)) {
      result.response_value_path = ERROR.REQUIRED
    }
  }

  if (isSegmentCondition(condition)) {
    if (!condition.segment_id) result.segment_id = ERROR.REQUIRED
    if (!condition.attribute_id) result.attribute_id = ERROR.REQUIRED
    if (!condition.attribute_location) result.attribute_location = ERROR.REQUIRED
    if (isEmptyField(condition.attribute_location_key))
      result.attribute_location_key = ERROR.REQUIRED
  }

  if (isSingleValueCondition(condition)) {
    if (isTypedCondition(condition) && condition.type === "relative_datetime") {
      if (
        condition.value === null ||
        condition.value.count === null ||
        condition.value.units === null
      ) {
        result.relative_datetime_value = {}

        if (condition.value === null || condition.value.count === null) {
          result.relative_datetime_value.count = ERROR.REQUIRED
        }

        if (condition.value === null || condition.value.units === null) {
          result.relative_datetime_value.units = ERROR.REQUIRED
        }
      }
    } else if (condition.value === null) {
      result.value = ERROR.REQUIRED
    }
  }

  if (isRangeCondition(condition)) {
    if (isTypedCondition(condition) && condition.type === "relative_datetime") {
      if (
        condition.min_value === null ||
        condition.min_value.count === null ||
        condition.min_value.units === null
      ) {
        result.relative_datetime_min_value = {}

        if (condition.min_value === null || condition.min_value.count === null) {
          result.relative_datetime_min_value.count = ERROR.REQUIRED
        }

        if (condition.min_value === null || condition.min_value.units === null) {
          result.relative_datetime_min_value.units = ERROR.REQUIRED
        }
      }

      if (
        condition.max_value === null ||
        condition.max_value.count === null ||
        condition.max_value.units === null
      ) {
        result.relative_datetime_max_value = {}

        if (condition.max_value === null || condition.max_value.count === null) {
          result.relative_datetime_max_value.count = ERROR.REQUIRED
        }

        if (condition.max_value === null || condition.max_value.units === null) {
          result.relative_datetime_max_value.units = ERROR.REQUIRED
        }
      }

      if (condition.max_value !== null && condition.min_value !== null) {
        const max = evaluateRelativeDatetime(condition.max_value)
        const min = evaluateRelativeDatetime(condition.min_value)
        if (max < min) {
          if (!result.relative_datetime_max_value) {
            result.relative_datetime_max_value = {}
          }

          result.relative_datetime_max_value.count = ERROR.MAX_DATE_LESS_THAN_MIN
        }
      }
    } else {
      if (condition.min_value === null) {
        result.min_value = ERROR.REQUIRED
      }

      if (condition.max_value === null) {
        result.max_value = ERROR.REQUIRED
      }

      if (
        condition.max_value !== null &&
        condition.min_value !== null &&
        condition.max_value <= condition.min_value
      ) {
        result.max_value = ERROR.MAX_VALUE_LESS_THAN_MIN
      }
    }
  }

  if (isArrayCondition(condition) && (!condition.values || condition.values.length === 0)) {
    result.values = ERROR.REQUIRED
  }

  if (condition.subject === "current_hour") {
    if (isSingleValueCondition(condition) && condition.value !== null) {
      if (!isValidHour(condition.value)) {
        result.value = ERROR.HOUR_OUT_OF_RANGE
      } else if (condition.operator === "greater" && condition.value === 23) {
        result.value = ERROR.HOUR_TOO_LARGE
      } else if (condition.operator === "lower" && condition.value === 0) {
        result.value = ERROR.HOUR_TOO_SMALL
      }
    }

    if (isRangeCondition(condition) && condition.min_value !== null) {
      if (!isValidHour(condition.min_value)) {
        result.min_value = ERROR.HOUR_OUT_OF_RANGE
      } else if (condition.min_value === 23) {
        result.min_value = ERROR.HOUR_TOO_LARGE
      }
    }

    if (isRangeCondition(condition) && condition.max_value !== null) {
      if (!isValidHour(condition.max_value)) {
        result.max_value = ERROR.HOUR_OUT_OF_RANGE
      } else if (condition.max_value === 0) {
        result.max_value = ERROR.HOUR_TOO_SMALL
      }
    }
  }

  if (condition.subject === "page_views_count") {
    if (isSingleValueCondition(condition) && condition.value !== null) {
      if (!isValidPageViewCount(condition.value)) {
        result.value = ERROR.PAGE_VIEWS_OUT_OF_RANGE
      } else if (condition.operator === "greater" && condition.value === 999) {
        result.value = ERROR.PAGE_VIEWS_TOO_LARGE
      } else if (condition.operator === "lower" && condition.value === 0) {
        result.value = ERROR.PAGE_VIEWS_TOO_SMALL
      }
    }

    if (isRangeCondition(condition) && condition.min_value !== null) {
      if (!isValidPageViewCount(condition.min_value)) {
        result.min_value = ERROR.PAGE_VIEWS_OUT_OF_RANGE
      } else if (condition.min_value === 999) {
        result.min_value = ERROR.PAGE_VIEWS_TOO_LARGE
      }
    }

    if (isRangeCondition(condition) && condition.max_value !== null) {
      if (!isValidPageViewCount(condition.max_value)) {
        result.max_value = ERROR.PAGE_VIEWS_OUT_OF_RANGE
      } else if (condition.max_value === 0) {
        result.max_value = ERROR.PAGE_VIEWS_TOO_SMALL
      }
    }
  }

  // TODO: regex to check url_template

  return Object.keys(result).length > 0 ? result : null
}
