import React, { useState, Fragment } from "react"
import { getFormValues } from "redux-form"
import moment from "moment"

// ui components
import AttributeId from "components/UI/components/AttributeId/AttributeId"
import Badge from "components/UI/elements/AttributeBadge"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable/CompoundAttributeValuesTable"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"

// helpers
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"

import "./Attributes.scss"
import { useFetchActiveLabels, useFetchAllAttributes } from "resources/attribute/attributeQueries"
import { isNil, transpose, whereEq, without } from "ramda"
import { useSelector } from "store"
import Page from "components/UI/Page/Page"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { AttributeFull, AttributeSort } from "resources/attribute/attributeTypes"
import { OrderDir, SelectOption } from "types/util"
import { Label } from "resources/attributeLabel/attributeLabelTypes"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchCustomersPerAttribute } from "resources/stats/customersPerAttribute"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table, { Cell } from "components/Table/Table"
import classNames from "classnames"

type FormValues = { search?: string; select?: SelectOption<Source["id"]> } | undefined

export default function Attributes() {
  const formValues = useSelector<FormValues>(
    // @ts-ignore
    getFormValues("SearchAttributeForm"),
  )

  const [{ orderBy, orderDir }, setSortState] = useState<{
    orderBy: AttributeSort
    orderDir: OrderDir
  }>({ orderBy: "name", orderDir: "ASC" })
  const [labelIds, setLabelIds] = useState<Label["id"][]>([])

  const setSort = (orderBy: AttributeSort) =>
    setSortState(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    }))
  const addLabelId = (labelId: Label["id"]) =>
    setLabelIds(labelIds => (labelIds.includes(labelId) ? labelIds : labelIds.concat(labelId)))
  const removeLabelId = (labelId: Label["id"]) => setLabelIds(without([labelId]))

  const attributesQuery = useFetchAllAttributes(
    {
      orderBy,
      orderDir,
      searchTerm: formValues?.search?.trim(),
      labelIds,
      sourceId: formValues?.select?.value,
    },
    { refetchOnMount: "always" },
  )

  const { data: attributesTagsList } = useFetchActiveLabels()
  const { data: customersCounts } = useFetchCustomersPerAttribute()

  const columns = [
    { id: "name", label: "Name", onSort: () => setSort("name"), gridTemplate: "1.2fr" },
    { id: "source", label: "Data source", onSort: () => setSort("source"), gridTemplate: "1fr" },
    { id: "labels", label: "Labels", onSort: () => setSort("labels"), gridTemplate: "1fr" },
    { id: "description", label: "Description", gridTemplate: "1fr" },
  ]

  return (
    <Page
      title="Attributes"
      headerContent={
        <div className={"attributes-header-container"}>
          <Tippy
            placement="right"
            interactive
            content={
              <>
                <p>
                  Attributes are <strong>characteristics of a customer profile</strong> that help to
                  build a more holistic picture of a customer (e.g. information about personal data,
                  revenue spent, opened campaigns etc).
                </p>
                <p>
                  Attributes are extracted from events.{" "}
                  <strong>When a customer action happens</strong> (e.g. customer visits a web page,
                  makes a payment transaction, etc.){" "}
                  <strong>
                    it is recorded as an event. Meiro is able to extract data from the events and
                    based on it create attributes
                  </strong>{" "}
                  like "Date of the last visit to the web" or "Total revenue from all transactions".
                </p>
                <p>
                  To learn more about Attributes tab, please refer to{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data-library#bkmrk-attributes-tab"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    this article
                  </a>
                  .
                </p>
              </>
            }
          >
            <span className="info-tooltip-icon">
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </span>
          </Tippy>
          <AttributesFilterForm />
        </div>
      }
      className="attributes-container"
    >
      {attributesQuery.isLoading && <LoadingIndicator />}

      {attributesQuery.isSuccess && (
        <Paper>
          <div className="tag-filter">
            <div className="label-tags">
              <span className="selected-tags">Filter by:</span>
              <span>
                {labelIds.map(tagId => {
                  const tag = attributesTagsList!.find(val => val.id === tagId)
                  if (tag) {
                    return (
                      <Tag
                        key={tagId}
                        clickable={true}
                        color="primary"
                        onClick={() => removeLabelId(tagId)}
                      >
                        {tag.name}
                      </Tag>
                    )
                  }
                  return null
                })}
              </span>
            </div>
            <TagPicker
              selectedTagIds={labelIds}
              allTags={attributesTagsList!}
              onTagSelect={addLabelId}
              className="selected-tags-picker"
              type="label"
            />
          </div>

          <Table
            columns={columns}
            data={attributesQuery.data}
            sortBy={orderBy}
            sortDir={orderDir}
            renderRow={attribute => (
              <AttributeRow
                attribute={attribute}
                addLabelId={addLabelId}
                customersCount={
                  customersCounts?.find(whereEq({ attribute_id: attribute.id }))?.count
                }
              />
            )}
          />
        </Paper>
      )}
    </Page>
  )
}

type AttributeRowProps = {
  attribute: AttributeFull
  customersCount?: number
  addLabelId: (tagId: Label["id"]) => void
}

function AttributeRow({ attribute, customersCount, addLabelId }: AttributeRowProps) {
  let examplesJSX = null

  if (attribute.examples) {
    if (Array.isArray(attribute.examples) && attribute.examples.length > 0) {
      examplesJSX = (
        <>
          {attribute.examples.slice(0, 3).map((value, key) => (
            <Tag
              key={key}
              color="white"
              size="small"
              clickable={false}
              className="attribute-example-value"
              uniqueTooltipId={`tooltip-${attribute.id}_${key}`}
            >
              {getUserFriendlyValueFormat(value, attribute.data_type)}
            </Tag>
          ))}
        </>
      )
    } else if (!Array.isArray(attribute.examples) && isAttributeCompound(attribute.data_type)) {
      const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)

      // [ [examples for subAttr 1], [examples for subAttr 2], ... ]
      const examplesMatrix = subAttributes.map(
        ({ id }) => (attribute.examples as Record<string, string[]>)[id] ?? [],
      )

      // Pad table rows with empty values
      const maxExamplesLength = examplesMatrix.reduce((acc, curr) => Math.max(acc, curr.length), 0)
      const paddedExamplesMatrix = examplesMatrix.map(examples =>
        examples.concat(Array(maxExamplesLength - examples.length).fill("")),
      )

      if (paddedExamplesMatrix.some(subAttributeExamples => subAttributeExamples.length > 0)) {
        // [ [first example for all subAttrs], [second example for all subAttrs], ... ]
        const transposedMatrix = transpose(paddedExamplesMatrix).slice(0, 3)

        examplesJSX = (
          <CompoundAttributeValuesTable subAttributes={subAttributes} values={transposedMatrix} />
        )
      }
    }
  }

  return (
    <>
      <Cell className="attribute-name-cell" style={examplesJSX ? { gridRow: "span 2" } : undefined}>
        <div className="attribute-name">
          {attribute.name}
          {moment().diff(attribute.created, "days") < 8 && <Badge text="New" />}
        </div>
        {isAttributeCompound(attribute.data_type) && (
          <ul className="sub-attrs">
            {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
              <li key={subAttr.id}>{subAttr.name}</li>
            ))}
          </ul>
        )}
        <AttributeId id={attribute.id} />
      </Cell>
      <Cell className={classNames("attribute-source", { "no-border": !!examplesJSX })}>
        <SrcDstIcon source={attribute.source} className="source-icon" />

        <div className="source-n-count">
          <div>{attribute.source.name}</div>
          <div className="count">{!isNil(customersCount) && `${customersCount} profiles`}</div>
        </div>
      </Cell>
      <Cell className={classNames("attribute-tags", { "no-border": !!examplesJSX })}>
        {attribute.tags?.filter(Boolean).map(tag => (
          <Tag
            key={tag.id}
            color="primary"
            onClick={() => addLabelId(tag.id)}
            className="attribute-label"
          >
            {tag.name}
          </Tag>
        ))}
      </Cell>
      <Cell className={classNames("attribute-description", { "no-border": !!examplesJSX })}>
        {attribute.description}
      </Cell>
      {examplesJSX && (
        <Cell className="attribute-examples" style={{ gridColumn: "span 3" }}>
          <div className="examples-outer-wrapper">
            <div className="examples-title">Examples</div>
            <div className="examples-wrapper">{examplesJSX}</div>
          </div>
        </Cell>
      )}
    </>
  )
}
