import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { featureFlags } from "featureFlags"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { useHasAccess } from "resources/user/currentUserQueries"
import { getRoutePath } from "routes"
import EmailsComingSoon from "./Emails/EmailsComingSoon/EmailsComingSoon"
import Emails from "./Emails/EmailsRoutes"
import EmailTemplates from "./EmailTemplates/EmailTemplatesRoutes"
import EmbeddedWebBanners from "./EmbeddedWebBanners/EmbeddedWebBannersRoutes"
import MobilePushNotifications from "./MobilePushNotifications/MobilePushNotificationsRoutes"
import PopupWebBanners from "./PopupWebBanners/PopupWebBannersRoutes"
import PromoCodesLists from "./PromoCodes/PromoCodesLists/PromoCodesLists"

function redirectToAllowedPersonalizationTab() {
  return <Redirect to={getRoutePath("channels.popup-banners")} />
}

export default function Channels() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("channels")} exact render={redirectToAllowedPersonalizationTab} />

      <Route path={getRoutePath("channels.popup-banners")} component={PopupWebBanners} />
      <Route path={getRoutePath("channels.native-banners")} component={EmbeddedWebBanners} />
      <Route path={getRoutePath("channels.mobile-push")} component={MobilePushNotifications} />
      <Route path={getRoutePath("channels.promo-codes")} component={PromoCodesLists} />
      <AuthorizedRoute
        hasAccess={hasAccess.emails.edit}
        path={getRoutePath("channels.emails.templates")}
        component={EmailTemplates}
      />
      <Route
        path={getRoutePath("channels.emails")}
        component={featureFlags.EMAILS ? Emails : EmailsComingSoon}
      />
    </Switch>
  )
}
