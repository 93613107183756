import React, { lazy, Suspense } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useCreateEmail } from "resources/email/emailQueries"
import { CreateEmailPayload } from "resources/email/emailTypes"
import { getRoutePath } from "routes"
import Page from "components/UI/Page/Page"
import styles from "./EmailCreate.module.scss"
import ChatGPT from "components/ChatGPT/ChatGPT"
import { useFetchDefaultSenderAddress } from "resources/channel/emailSenders/emailSendersQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { EmailTemplatePayload } from "resources/email/emailTemplate/emailTemplateTypes"
const EmailForm = lazy(() => import("../components/EmailForm/EmailForm"))

export default function EmailCreate() {
  const history = useHistory()
  const mutation = useCreateEmail()
  const emailTemplate = useLocation<{ emailTemplate: EmailTemplatePayload } | undefined>().state
    ?.emailTemplate

  const create = (data: CreateEmailPayload) =>
    mutation.mutateAsync(
      { data },
      {
        onSuccess: ({ email: { id } }) => {
          history.push(getRoutePath("channels.emails.detail", { id }))
        },
      },
    )

  const { data: defaultSenderAddress, isLoading } = useFetchDefaultSenderAddress()

  return (
    <Page
      title="Create email"
      contentClassName={styles.container}
      backRouteFallback={getRoutePath("channels.emails")}
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Suspense fallback={<LoadingIndicator />}>
          <EmailForm
            onSubmit={create}
            initialValues={{
              from_email: { email_address: defaultSenderAddress },
              reply_to_email: defaultSenderAddress,
              content_html: emailTemplate?.content_html,
              content_json: emailTemplate?.content_json,
            }}
          />
        </Suspense>
      )}
    </Page>
  )
}
