import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import FeaturedSegmentDetail from "./FeaturedSegmentDetail/FeaturedSegmentDetail"
import FeaturedSegmentsList from "./FeaturedSegmentsList/FeaturedSegmentsList"

export default function FeaturedSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.featured")} exact component={FeaturedSegmentsList} />
      <Route
        path={getRoutePath("segments.featured.trash")}
        exact
        render={() => <Trash itemType="featured_segments" />}
      />
      <Route path={getRoutePath("segments.featured.detail")} component={FeaturedSegmentDetail} />
    </Switch>
  )
}
