import React, { useState, useCallback } from "react"
import { format } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import Button from "components/UI/elements/Button/Button"
import CreatePromoCodesListModal from "../components/CreatePromoCodesListModal/CreatePromoCodesListModal"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Image from "./promo-codes.svg"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import Paper from "components/UI/elements/Paper"
import Table, { RowMessage, Tbody, Td, Th, Thead, Tr } from "components/UI/elements/Table"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import { DATEFNS } from "sharedConstants"
import { showToast } from "actions/toast.action"
import { SearchFormDestroyable } from "components/UI/components/SearchForm"
import UpdatePromoCodesListModal from "../components/UpdatePromoCodesListModal/UpdatePromoCodesListModal"
import { copyStringToClipboard } from "helpers/string.helper"
import styles from "./PromoCodesLists.module.scss"
import Username from "components/Username/Username"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  useCreatePromoCodesList,
  useDeletePromoCodesList,
  useFetchAllPromoCodesLists,
  useRenamePromoCodesList,
  useUploadFileToPromoCodesList,
} from "resources/promoCodesList/promoCodesListQueries"

export const MAX_UPLOADED_FILE_SIZE = 2097152

const pageTitle = "Promo codes"

export default function PromoCodesLists() {
  const dispatch = useDispatch()
  const hasAccess = useHasAccess()
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [updateModalItem, setUpdateModalItem] = useState<PromoCodesList | null>(null)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [deleteModalItem, setDeleteModalItem] = useState<PromoCodesList | null>(null)

  const searchTerm: string | undefined = useSelector(
    // @ts-ignore
    state => state.form?.PromoCodesListSearchForm?.values?.search,
  )
  const { data: promoCodesLists, isLoading } = useFetchAllPromoCodesLists({ searchTerm })
  const createMutation = useCreatePromoCodesList()
  const renameMutation = useRenamePromoCodesList()
  const uploadMutation = useUploadFileToPromoCodesList()
  const deleteMutation = useDeletePromoCodesList()

  const copyListIdToClipboard = useCallback(
    id => () => {
      copyStringToClipboard(id)
      dispatch(showToast("ID copied to clipboard."))
    },
    [dispatch],
  )

  if (!hasAccess.webBanners.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent img={{ alt: "Promo codes", src: Image }}>
          <h1>Promo codes</h1>
          <strong>
            It seems like you don't have access to the Promo codes tab. If you want to know more
            about your access settings, please contact your administrator.
          </strong>
          <p>
            The Personalization tab offers pop-up web banners, embedded web banners and promo codes
            for your orchestrated omnichannel personalization strategy.
          </p>
          <p>
            In the Promo codes tab, you can upload a list of promo codes and display them in web
            banners to the audience of your choice.
          </p>
          <p>
            Learn more about{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes"
              target="_blank"
              rel="noreferrer"
            >
              promo codes in detail
            </a>
            {" & "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-how-to-set-promo-codes-in-web-banners"
              target="_blank"
              rel="noreferrer"
            >
              how to set them for web banners
            </a>
            .
          </p>
        </MarketingContent>
      </Page>
    )

  if (isLoading) {
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )
  }

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchFormDestroyable
            // @ts-ignore TODO: Why is TS unhappy with this?
            placeholder="Search for id or name"
            form="PromoCodesListSearchForm"
          />
          <Button onClick={() => setCreateModalIsOpen(true)} disabled={!hasAccess.webBanners.edit}>
            + Create list
          </Button>
        </>
      }
    >
      <Paper className={styles.content}>
        {promoCodesLists?.length === 0 ? (
          <RowMessage>No promo codes lists found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th className={styles.idColumn}>ID</Th>
              <Th>Name</Th>
              <Th className={styles.availablePromoCodesColumn}>Available promo codes</Th>
              <Th className={styles.createdByColumn}>Created by</Th>
              <Th className={styles.dateAddedColumn}>Date added</Th>
              <Th className={styles.dateModifiedColumn}>Date modified</Th>
              <Th className={styles.actionButtonsColumn}>&nbsp;</Th>
            </Thead>
            <Tbody>
              {promoCodesLists?.map(promoCodesList => (
                <Tr key={promoCodesList.id}>
                  <Td>
                    <IconButton
                      icon="clone"
                      iconStyle="fas"
                      color="grey"
                      className={styles.copyButton}
                      tooltip="Copy id to a clipboard."
                      onClick={copyListIdToClipboard(promoCodesList.id)}
                      variant="transparent"
                    />
                  </Td>
                  <Td textBigger textBlack textBold>
                    {promoCodesList.name}
                  </Td>
                  <Td>{promoCodesList.promo_codes_count}</Td>
                  <Td>
                    <Username userId={promoCodesList.author_id} />
                  </Td>
                  <Td>
                    {promoCodesList.created_at
                      ? format(new Date(promoCodesList.created_at), DATEFNS.DATE_FORMAT)
                      : "—"}
                  </Td>
                  <Td>
                    {promoCodesList.last_uploaded_at
                      ? format(new Date(promoCodesList.last_uploaded_at), DATEFNS.DATE_FORMAT)
                      : "—"}
                  </Td>
                  <Td textAlignRight>
                    <IconButton
                      color="black"
                      size="xs"
                      onClick={() => {
                        setUpdateModalIsOpen(true)
                        setUpdateModalItem(promoCodesList)
                      }}
                      variant="outlined"
                      icon="pencil-alt"
                      tooltip="Edit"
                      disabled={!hasAccess.webBanners.edit}
                    />
                    <IconButton
                      color="red"
                      size="xs"
                      onClick={() => {
                        setDeleteModalIsOpen(true)
                        setDeleteModalItem(promoCodesList)
                      }}
                      variant="outlined"
                      icon="trash-alt"
                      tooltip="Delete"
                      className={styles.actionButtonMargin}
                      disabled={!hasAccess.webBanners.edit}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      {promoCodesLists?.length === 0 && (
        <MarketingContent img={{ alt: "Promo codes", src: Image }}>
          <h1>Promo codes</h1>
          <p>
            Promo codes are the perfect tool to use in your campaigns to reward your customers with
            specific deals or encourage hesitant visitors to become first time shoppers. Sometimes
            little discount is all it takes!
          </p>
          <p>
            Upload a list of promo codes and display them to the audience of your choice. Learn more
            about{" "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes"
              target="_blank"
              rel="noreferrer"
            >
              Promo codes in detail
            </a>
            {" & "}
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-how-to-set-promo-codes-in-web-banners"
              target="_blank"
              rel="noreferrer"
            >
              how to set them for web banners
            </a>
            .
          </p>
        </MarketingContent>
      )}
      <CreatePromoCodesListModal
        open={createModalIsOpen}
        handleClose={() => setCreateModalIsOpen(false)}
        onSubmit={values =>
          createMutation.mutateAsync(values, {
            onSuccess() {
              setCreateModalIsOpen(false)
            },
          })
        }
      />
      <UpdatePromoCodesListModal
        open={updateModalIsOpen}
        promoCodesList={updateModalItem}
        handleClose={() => {
          setUpdateModalIsOpen(false)
        }}
        onFileUpload={(id, file) =>
          uploadMutation.mutateAsync(
            { id, file },
            {
              onSuccess() {
                setUpdateModalIsOpen(false)
              },
            },
          )
        }
        onNameChange={(id, name) =>
          renameMutation.mutateAsync(
            { id, name },
            {
              onSuccess() {
                setUpdateModalIsOpen(false)
              },
            },
          )
        }
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalIsOpen}
        type="delete"
        text={`Do you really want to delete promo codes list: ${deleteModalItem?.name}?`}
        handleClose={() => setDeleteModalIsOpen(false)}
        handleConfirm={() =>
          deleteMutation.mutateAsync(
            { id: deleteModalItem!.id },
            {
              onSuccess() {
                setDeleteModalIsOpen(false)
              },
            },
          )
        }
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}
