import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import { api } from "api"
import { queryClient } from "app/queryClient"
import { equals, groupBy, isNil, length, path, pipe, prop, sort, uniq, update } from "ramda"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { Source } from "resources/dataSource/dataSourceTypes"
import { Label } from "../attributeLabel/attributeLabelTypes"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  Attribute,
  AttributeCreatePayload,
  AttributeFull,
  AttributeModifyPayload,
  AttributeSort,
} from "./attributeTypes"

const ATTRIBUTE_ALL_QK: QueryKey = ["attribute", "all"]

export const prefetchAttributes = () => {
  queryClient.prefetchQuery(ATTRIBUTE_ALL_QK, api.attribute.listAll)
}

export const refetchAttributes = () =>
  queryClient.invalidateQueries(
    { queryKey: ATTRIBUTE_ALL_QK, refetchType: "all" },
    { throwOnError: false },
  )

function useAttributesQuery<T>(config?: UseQueryOptions<AttributeFull[], unknown, T, QueryKey>) {
  return useQuery(ATTRIBUTE_ALL_QK, api.attribute.listAll, {
    staleTime: 60 * 1000,
    ...config,
  })
}

type FetchAllAttributesOpts = {
  includeHidden?: boolean
  orderBy?: AttributeSort
  orderDir?: OrderDir
  searchTerm?: string
  labelIds?: Label["id"][]
  sourceId?: Source["id"]
}

export function useFetchAllAttributes(
  { includeHidden, labelIds, orderBy, orderDir, searchTerm, sourceId }: FetchAllAttributesOpts = {},
  config: UseQueryOptions<AttributeFull[], unknown, AttributeFull[], QueryKey> = {},
) {
  const select = useCallback(
    (attributes: AttributeFull[]) => {
      let selectedAttributes = attributes

      if (!includeHidden) {
        selectedAttributes = selectedAttributes.filter(({ is_hidden }) => !is_hidden)
      }

      if (labelIds && labelIds.length > 0) {
        selectedAttributes = selectedAttributes.filter(({ tags }) =>
          tags?.some(label => labelIds.includes(label.id)),
        )
      }

      if (sourceId) {
        selectedAttributes = selectedAttributes.filter(({ source }) => source.id === sourceId)
      }

      if (searchTerm) {
        selectedAttributes = selectedAttributes.filter(
          ({ id, name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            id.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      }

      if (orderBy && orderDir) {
        const comparator = orderDir === "ASC" ? ascend : descend
        const getSortingProperty =
          orderBy === "labels"
            ? ({ tags }: AttributeFull) =>
                (tags && sort(comparator(prop("name")), tags)[0]?.name) ?? "zzz"
            : orderBy === "source"
            ? path(["source", "name"])
            : prop("name")

        selectedAttributes = sort(comparator(getSortingProperty), selectedAttributes)
      }

      return selectedAttributes
    },
    [includeHidden, labelIds, orderBy, orderDir, searchTerm, sourceId],
  )

  return useAttributesQuery({ select, ...config })
}

type FetchAttributesMapOpts = {
  includeHidden?: boolean
  excludeStitching?: boolean
  includeId?: AttributeFull["id"]
}

export function useFetchAttributesMap({
  includeHidden,
  excludeStitching,
  includeId,
}: FetchAttributesMapOpts = {}) {
  const select = useCallback(
    (attributes: AttributeFull[]) => {
      let filteredAttributes = attributes

      if (!includeHidden) {
        filteredAttributes = filteredAttributes.filter(
          ({ id, is_hidden }) => id === includeId || !is_hidden,
        )
      }

      if (excludeStitching) {
        filteredAttributes = filteredAttributes.filter(
          ({ id, is_used_in_stitching }) => id === includeId || !is_used_in_stitching,
        )
      }

      return Object.fromEntries(filteredAttributes.map(attribute => [attribute.id, attribute]))
    },
    [excludeStitching, includeHidden, includeId],
  )

  return useAttributesQuery({ select })
}

export function useFetchAttributeById(
  id?: Attribute["id"] | null,
  config: UseQueryOptions<AttributeFull[], unknown, AttributeFull | null, QueryKey> = {},
) {
  const select = useCallback(
    (attributes: AttributeFull[]) => attributes.find(attribute => attribute.id === id) ?? null,
    [id],
  )

  return useAttributesQuery({ select, enabled: !isNil(id), ...config })
}

const toMapBySourceId = pipe(
  sort<AttributeFull>(ascend(prop("name"))),
  sort(ascend(prop("order_index"))),
  groupBy(({ source }) => source.id),
)

export function useFetchAttributesMapBySourceId() {
  return useAttributesQuery({ select: toMapBySourceId })
}

export function useFetchAttributesCount() {
  return useAttributesQuery({ select: length })
}

export const IDENTITY_GRAPH_COLORS = [
  "#F15555",
  "#136067",
  "#7F44F3",
  "#49BB0F",
  "#E4C143",
  "#189FAB",
  "#410074",
  "#8ACDA6",
  "#0D3FA3",
  "#6C7EA3",
]

const selectStitchingAttributes = (attributes: AttributeFull[]) =>
  attributes.filter(prop("is_used_in_stitching")).map((attribute, index) => ({
    ...attribute,
    identity_graph_color: IDENTITY_GRAPH_COLORS[index] ?? "fe7f66",
  }))

export function useFetchStitchingAttributes() {
  return useAttributesQuery({ select: selectStitchingAttributes })
}

const selectActiveLabels = (attributes: AttributeFull[]) =>
  sort(ascend(prop("name")), uniq(attributes.flatMap(({ tags }) => tags ?? [])))

export function useFetchActiveLabels() {
  return useAttributesQuery({ select: selectActiveLabels })
}

export function useCreateAttribute() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ data }: { data: AttributeCreatePayload }) => api.attribute.create(data), {
    onSuccess: ({ attribute }) => {
      queryClient.setQueryData<AttributeFull[]>(ATTRIBUTE_ALL_QK, data => {
        if (!data) {
          return
        }

        return data.concat(attribute)
      })
      dispatch(showToast("Attribute created."))
    },
  })
}

export function useModifyAttribute() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: Attribute["id"]; data: AttributeModifyPayload }) =>
      api.attribute.modify(id, data),
    {
      onSuccess: ({ attribute }, { data }) => {
        queryClient.setQueryData<AttributeFull[]>(ATTRIBUTE_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === attribute.id)

          return index === -1 ? data.concat(attribute) : update(index, attribute, data)
        })
        dispatch(
          showToast(
            equals(data, { is_hidden: 1 })
              ? "Attribute hidden."
              : equals(data, { is_hidden: 0 })
              ? "Attribute visible."
              : "Attribute modified.",
          ),
        )
      },
    },
  )
}
