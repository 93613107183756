import requestFactory from "api/request"

const image = {
  post(data: FormData): Promise<{ image_url: string }> {
    return requestFactory("post", "/images", data, false, true, "", false, false, {
      "Content-Type": "multipart/form-data",
    })
  },
}

export default image
