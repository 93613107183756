import React, { Component } from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"

import IconButton from "../IconButton/IconButton"

import "./Modal.scss"

class Modal extends Component {
  componentDidMount() {
    if (this.props.open) {
      window.addEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      window.addEventListener("keyup", this.handleKeyUp, false)
    } else if (!this.props.open && prevProps.open) {
      window.removeEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleKeyUp = evt => {
    const { handleClose } = this.props
    const keys = {
      27: () => {
        evt.preventDefault()
        handleClose()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  render() {
    const {
      handleClose,
      children,
      className,
      hideCloseButton,
      title,
      open,
      size,
      hideHeader,
      customCloseIconName,
      customCloseIconStyle,
      customHeader,
    } = this.props
    return (
      <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
        <div className="modal-wrapper">
          <div
            className={`modal ${className ? className : ""} ${size ? size : ""}`}
            data-testid="modal"
          >
            {!hideHeader &&
              (customHeader ?? (
                <header>
                  <h3>{title}</h3>
                  {!hideCloseButton && (
                    <IconButton
                      color="grey"
                      icon={customCloseIconName ?? "times"}
                      iconStyle={customCloseIconStyle ?? "fas"}
                      tooltip="Close"
                      variant="transparent"
                      onClick={handleClose}
                      data-testid="close-button"
                      className="close-button"
                    />
                  )}
                </header>
              ))}
            <section className="modal-body" data-testid="modal-content">
              {children}
            </section>
          </div>
          <div
            className="modal-overlay"
            data-testid="modal-overlay"
            onClick={() => {
              if (open) {
                handleClose()
              }
            }}
          />
        </div>
      </CSSTransition>
    )
  }
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  hideHeader: PropTypes.bool,
  customHeader: PropTypes.node,
}

export default Modal
