import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Attribute } from "resources/attribute/attributeTypes"
import { StitchingRule } from "./stitchingAttributeTypes"

const stitchingAttribute = {
  listAll(): Promise<StitchingRule[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/stitching_attribute_configs", { offset, limit }),
      key: "stitching_attribute_configs",
    })
  },
  set(
    attributeId: Attribute["id"],
    rules: Omit<StitchingRule, "attribute_id">[],
  ): Promise<{
    stitching_attribute_configs: StitchingRule[]
  }> {
    return requestFactory("patch", `/stitching_attribute_configs/attributes/${attributeId}`, {
      stitching_attribute_configs: rules,
    })
  },
}

export default stitchingAttribute
