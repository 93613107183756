import React from "react"

import TextArea, { TextAreaProps } from "./TextArea"

type ReduxFormFieldTextAreaProps = {
  input: {
    value: string
    onChange: (
      event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => React.ChangeEventHandler<HTMLTextAreaElement>
  }
  meta: { touched: boolean; error?: string }
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => React.ChangeEventHandler<HTMLTextAreaElement>
  setFieldToBeFocused?: (element: HTMLTextAreaElement | null) => void
} & Omit<TextAreaProps, "error">

export default function ReduxFormTextArea({
  input: { value, onChange: onInputChange, ...restInput },
  meta: { error, touched },
  onChange,
  setFieldToBeFocused,
  ...restProps
}: ReduxFormFieldTextAreaProps) {
  const errorMessage = touched && error ? error : undefined

  return (
    <TextArea
      {...restProps}
      {...restInput}
      ref={el => setFieldToBeFocused?.(el)}
      value={value}
      error={errorMessage}
      onChange={event => {
        onInputChange(event)
        onChange?.(event)
      }}
    />
  )
}
