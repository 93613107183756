import React, { PureComponent } from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import _get from "lodash/get"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./IdentityStitchingList.scss"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteStitchingAttribute,
  useFetchStitchingAttributesMap,
} from "resources/stitchingAttribute/stitchingAttributeQueries"
import { sort } from "ramda"
import { ascend } from "utilities/comparators"
import { useHistory } from "react-router-dom"

class IdentityStitchingList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteStitchingAttribute: {
        modalOpen: false,
        stitchingAttribute: null,
        isLoading: false,
      },
    }
  }

  goToStitchingAttributeDetail = stitchingAttributeId => () => {
    this.props.history.push(
      getRoutePath("administration.identity-stitching.detail", { id: stitchingAttributeId }),
    )
  }

  toggleDeleteModal =
    (stitchingAttribute = null) =>
    () => {
      if (!this.state.deleteStitchingAttribute.isLoading) {
        this.setState(prevState => ({
          deleteStitchingAttribute: {
            ...prevState.deleteStitchingAttribute,
            modalOpen: !prevState.deleteStitchingAttribute.modalOpen,
            stitchingAttribute: stitchingAttribute
              ? stitchingAttribute
              : prevState.deleteStitchingAttribute.stitchingAttribute,
          },
        }))
      }
    }

  deleteStitchingAttribute = async () => {
    const { deleteStitchingAttribute: modalState } = this.state
    const { deleteStitchingAttribute } = this.props
    this.setState(prevState => ({
      deleteStitchingAttribute: {
        ...prevState.deleteStitchingAttribute,
        isLoading: true,
      },
    }))
    try {
      await deleteStitchingAttribute(modalState.stitchingAttribute.attribute_id)
      this.setState(prevState => ({
        deleteStitchingAttribute: {
          ...prevState.deleteStitchingAttribute,
          isLoading: false,
          modalOpen: false,
        },
      }))
    } catch {
      this.setState(prevState => ({
        deleteStitchingAttribute: {
          ...prevState.deleteStitchingAttribute,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { deleteStitchingAttribute } = this.state
    const {
      history,
      attributesMap,
      stitchingAttributes,
      areAttributesFulfilled,
      areStitchingAttributesFulfilled,
    } = this.props

    return (
      <Page
        className="setup-identity-stitching"
        title="Identity stitching"
        headerContent={
          <Button
            onClick={() => {
              history.push(getRoutePath("administration.identity-stitching.create"))
            }}
          >
            + Create stitching rule
          </Button>
        }
      >
        {!areAttributesFulfilled && <LoadingIndicator />}

        {areAttributesFulfilled && stitchingAttributes.length > 0 && (
          <Paper noPaddingTop>
            <Table className="setup-stitching-table">
              <Thead stickyHeader>
                <Th className="attribute-name">Attribute</Th>
                <Th className="attribute-rules">Rules</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {stitchingAttributes.map((stitchingAttribute, index) => {
                  return (
                    <Tr key={index}>
                      <Td textBold textBigger textBlack className="attribute-name">
                        {attributesMap[stitchingAttribute.attribute_id]?.name}
                      </Td>
                      <Td className="attribute-rules">{stitchingAttribute.configs.length}</Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color="black"
                          size="xs"
                          onClick={this.goToStitchingAttributeDetail(
                            stitchingAttribute.attribute_id,
                          )}
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />
                        <IconButton
                          color="red"
                          size="xs"
                          onClick={this.toggleDeleteModal(stitchingAttribute)}
                          className="left-margin"
                          icon="trash-alt"
                          tooltip="Delete"
                          variant="outlined"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <ConfirmModal
              open={deleteStitchingAttribute.modalOpen}
              type={MODAL.TYPE.DELETE}
              handleClose={this.toggleDeleteModal()}
              handleConfirm={this.deleteStitchingAttribute}
              title="Are you sure?"
              action="delete"
              what="identinty stitching rule for attribute"
              item={
                attributesMap[_get(deleteStitchingAttribute, "stitchingAttribute.attribute_id", "")]
                  ?.name ?? _get(deleteStitchingAttribute, "stitchingAttribute.attribute_id")
              }
              isLoading={deleteStitchingAttribute.isLoading}
            />
          </Paper>
        )}
        {areAttributesFulfilled &&
          areStitchingAttributesFulfilled &&
          stitchingAttributes.length === 0 && (
            <p className="info-message">Click on the "Create Stitching Rule" to get started.</p>
          )}
      </Page>
    )
  }
}

export default props => {
  const { data: attributesMap = {}, isSuccess: areAttributesFulfilled } = useFetchAttributesMap({
    includeHidden: true,
  })
  const { data: stitchingAttributesMap = {}, isSuccess: areStitchingAttributesFulfilled } =
    useFetchStitchingAttributesMap()
  const stitchingAttributes =
    areAttributesFulfilled && areStitchingAttributesFulfilled
      ? sort(
          ascend(({ attribute_id }) => attributesMap[attribute_id]?.name ?? attribute_id),
          Object.values(stitchingAttributesMap),
        )
      : []
  const history = useHistory()
  const deleteMutation = useDeleteStitchingAttribute()
  const deleteStitchingAttribute = attributeId =>
    deleteMutation.mutateAsync(
      { attributeId },
      {
        onSuccess() {
          history.push(getRoutePath("administration.identity-stitching"))
        },
      },
    )

  return (
    <IdentityStitchingList
      {...props}
      attributesMap={attributesMap}
      areAttributesFulfilled={areAttributesFulfilled}
      stitchingAttributes={stitchingAttributes}
      deleteStitchingAttribute={deleteStitchingAttribute}
    />
  )
}
