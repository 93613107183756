import React from "react"

import TextInput, { TextInputProps } from "./TextInput"

type ReduxFormTextFieldProps = {
  input: {
    value: string
    onChange: (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => React.ChangeEventHandler<HTMLInputElement>
  }
  meta: { touched: boolean; error?: string }
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => React.ChangeEventHandler<HTMLInputElement>
  setFieldToBeFocused?: (element: HTMLInputElement | null) => void
} & Omit<TextInputProps, "error">

export default function ReduxFormTextField({
  input: { value, onChange: onInputChange, ...restInput },
  meta: { error, touched },
  onChange,
  setFieldToBeFocused,
  ...restProps
}: ReduxFormTextFieldProps) {
  const errorMessage = touched && error ? error : undefined

  return (
    <TextInput
      {...restProps}
      {...restInput}
      ref={el => setFieldToBeFocused?.(el)}
      value={value}
      error={errorMessage}
      onChange={event => {
        onInputChange(event)
        onChange?.(event)
      }}
    />
  )
}
