import React, { useCallback, useEffect, useState } from "react"
import styles from "./SegmentDetail.module.scss"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import { Prompt, useHistory, useParams } from "react-router-dom"
import { getRoutePath } from "routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"
import { useDispatch } from "react-redux"
import { SegmentType } from "resources/segment/segment/segmentTypes"
import Paper from "components/UI/elements/Paper"
import { View } from "./types"
import { getNewPathName } from "./utils"
import classNames from "classnames"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import ConditionBuilder from "components/ConditionBuilder/ConditionBuilder"
import SegmentConditionComponent, {
  getNewCondition,
} from "./components/SegmentCondition/SegmentCondition"
import PaperHeader from "components/UI/elements/PaperHeader"
import ConditionTreeOverview from "components/ConditionBuilder/components/ConditionTreeOverview/ConditionTreeOverview"
import { dec, equals, inc, without } from "ramda"
import { showToast } from "actions/toast.action"
import EditableName from "./components/EditableName/EditableName"
import TagPicker from "components/UI/components/TagPicker"
import TagComponent from "components/UI/elements/Tag"
import SegmentUsersModal from "./components/SegmentUsersModal/SegmentUsersModal"
import { noop } from "lodash"
import SegmentInsights from "./components/SegmentInsights/SegmentInsights"
import SegmentExports from "./components/SegmentExports/SegmentExports"
import { PERMISSION, TOAST } from "sharedConstants"
import SegmentNotes from "./components/SegmentNotes/SegmentNotes"
import SmartSegmentSettings from "./components/SmartSegmentSettings/SmartSegmentSettings"
import ConfirmModal from "components/UI/components/ConfirmModal"
import CopyModal from "./components/CopyModal/CopyModal"
import { api } from "api"
import SegmentSummary from "./components/SegmentSummary/SegmentSummary"
import { ConditionNumbersContext } from "./conditionNumbersContext"
import {
  SegmentCondition,
  SegmentConditionError,
} from "resources/segment/segment/segmentConditionsTypes"
import { makeConditionTreeValidator } from "components/ConditionBuilder/validation"
import { validateSegmentCondition } from "./components/SegmentCondition/validation"
import { ConditionPath, ConditionTree } from "types/conditionTree"
import { CSSTransition } from "react-transition-group"
import SegmentGuide from "./components/SegmentGuide/SegmentGuide"
import SegmentCustomersPreview from "./components/SegmentCustomersPreview/SegmentCustomersPreview"
import SegmentNumbers from "./components/SegmentNumbers/SegmentNumbers"
import SegmentUsers from "./components/SegmentUsers/SegmentUsers"
import InsightsFilterForm from "components/UI/components/InsightsFilterForm"
import { useFetchAllAttributes } from "resources/attribute/attributeQueries"
import { useFetchAllUsersMap, useModifyUser } from "resources/user/userQueries"
import {
  useFetchCurrentUser,
  useHasAccess,
  useHasSegmentPermission,
} from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  refetchSegmentPermissionsForCurrentUser,
  useFetchSegmentPermissionsBySegmentId,
} from "resources/segmentPermission/segmentPermissionQueries"
import { TAG_ALL_QK, useFetchAllTags } from "resources/tag/tagQueries"
import { queryClient } from "app/queryClient"
import SegmentChannelNumbers from "./components/SegmentChannelNumbers/SegmentChannelNumbers"
import { CHANNEL_SEGMENT_COUNTS_QK } from "resources/channel/channelQueries"
import {
  SEGMENT,
  useDeleteSegment,
  useFetchSegmentById,
  useFetchSegmentCustomers,
  useModifySegment,
} from "resources/segment/segment/segmentQueries"
import {
  useFetchSegmentNumbers,
  useSegmentCountsStore,
} from "resources/segment/segment/segmentCounts"

const validateConditionTree = makeConditionTreeValidator(validateSegmentCondition)

type SegmentDetailProps = {
  type?: SegmentType
  view?: View
  isSmartSetup?: boolean
}

export default function SegmentDetail({
  type = "regular",
  view = "builder",
  isSmartSetup,
}: SegmentDetailProps) {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()

  // SEGMENT
  const { data: segment } = useFetchSegmentById(+id, {
    onSuccess: ({ featured, prebuilt }) => {
      if (prebuilt && type !== "smart") {
        history.push(getRoutePath("segments.smart.detail", { id }))
      } else if (featured && type !== "featured") {
        history.push(getRoutePath("segments.featured.detail", { id }))
      }
    },
  })
  const { mutateAsync: modifySegment } = useModifySegment()

  // SEGMENT NUMBERS

  const { refetch: refetchSegmentNumbers } = useFetchSegmentNumbers(+id)
  const {
    conditionsNumbersFlat: conditionsNumbers,
    conditionsResultsCount,
    customersTotalCount,
    clear: clearSegmentNumbers,
  } = useSegmentCountsStore()

  // SEGMENT CUSTOMERS

  const [customersListOffset, setCustomersListOffset] = useState(0)
  const { refetch: refetchSegmentCustomers } = useFetchSegmentCustomers(+id)

  // CONDITIONS

  const conditionsFromStore = segment?.settings?.conditions_operation ?? null
  const [conditions, setConditions] = useState<ConditionTree<SegmentCondition> | null>(null)

  useEffect(() => {
    if (segment?.settings?.conditions_operation)
      setConditions(segment.settings.conditions_operation)
  }, [segment])

  const [shouldValidate, setShouldValidate] = useState(false)
  const [error, setError] = useState<ConditionTree<SegmentConditionError | null>>(null)

  const setConditionsAndValidate = useCallback(
    conditions => {
      setConditions(conditions)
      if (shouldValidate) {
        setError(validateConditionTree(conditions))
      }
    },
    [shouldValidate],
  )

  const [hoveredPath, setHoveredPath] = useState<ConditionPath>()
  const [isSavingConditions, setIsSavingConditions] = useState(false)

  const saveConditions = useCallback(async () => {
    setShouldValidate(true)
    const error = validateConditionTree(conditions)
    setError(error)
    if (error) {
      return
    } else {
      setShouldValidate(false)
    }

    setIsSavingConditions(true)
    const settings = segment?.settings ?? {}
    try {
      await modifySegment(
        {
          id: +id,
          data: {
            settings: { ...settings, conditions_operation: conditions ?? undefined },
          },
        },
        {
          onSuccess: () => {
            dispatch(showToast("Conditions saved."))
            refetchSegmentNumbers()
            refetchSegmentCustomers()
            queryClient.refetchQueries(CHANNEL_SEGMENT_COUNTS_QK)
          },
        },
      )
    } catch {
    } finally {
      setIsSavingConditions(false)
    }
  }, [
    conditions,
    id,
    segment?.settings,
    dispatch,
    modifySegment,
    refetchSegmentNumbers,
    refetchSegmentCustomers,
  ])

  const hasUnsavedConditions = !equals(conditionsFromStore, conditions)

  const undoChanges = useCallback(() => {
    setConditions(conditionsFromStore)
    setShouldValidate(false)
    setError(null)
    refetchSegmentNumbers()
    refetchSegmentCustomers()
  }, [conditionsFromStore, refetchSegmentNumbers, refetchSegmentCustomers])

  // NAME

  const editName = useCallback(
    async newName => {
      try {
        await modifySegment(
          { id: +id, data: { name: newName } },
          {
            onSuccess: () => {
              dispatch(showToast("Segment name edited."))
            },
          },
        )
      } catch {}
    },
    [id, dispatch, modifySegment],
  )

  // SEGMENT PERMISSIONS

  const { data: segmentPermissions, isSuccess: areSegmentPermissionsFulfilled } =
    useFetchSegmentPermissionsBySegmentId(+id)
  const { data: usersMap, isSuccess: areUsersFulfilled } = useFetchAllUsersMap()

  // TAGS

  const { data: allTags = [], isSuccess: areTagsFulfilled } = useFetchAllTags()

  const assignTag = useCallback(
    async tagId => {
      try {
        await modifySegment(
          { id: +id, data: { tag_ids: segment!.tag_ids.concat(tagId) } },
          {
            onSuccess: () => {
              dispatch(showToast("Tag assigned."))
              queryClient.refetchQueries(TAG_ALL_QK)
            },
          },
        )
      } catch {}
    },
    [id, segment, dispatch, modifySegment],
  )

  const unassignTag = useCallback(
    async tagId => {
      try {
        await modifySegment(
          { id: +id, data: { tag_ids: without([tagId], segment!.tag_ids) } },
          {
            onSuccess: () => {
              dispatch(showToast("Tag unassigned."))
              queryClient.refetchQueries(TAG_ALL_QK)
            },
          },
        )
      } catch {}
    },
    [id, segment, dispatch, modifySegment],
  )

  // IS EDITABLE

  const [isEditable, setIsEditable] = useState(true)

  useEffect(() => {
    setIsEditable(
      isSmartSetup ||
        (type !== "smart" &&
          hasSegmentPermission(+id, {
            segmentType: type === "featured" ? "featured" : "custom",
          }) === PERMISSION.WRITE),
    )
  }, [segmentPermissions, id, isSmartSetup, hasSegmentPermission, type])

  // UI STATE

  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false)
  const [isSummaryOpen, setIsSummaryOpen] = useState(true)
  const [isNotesOpen, setIsNotesOpen] = useState(false)
  const [isCustomersOpen, setIsCustomersOpen] = useState(false)

  // Reset UI state when id changes
  useEffect(() => {
    setIsUsersModalOpen(false)
    setIsSummaryOpen(true)
    setIsNotesOpen(false)
    setIsCustomersOpen(false)
    setCustomersListOffset(0)
    clearSegmentNumbers()
  }, [id, clearSegmentNumbers])

  // ROUTING
  useEffect(() => {
    if (view !== "builder") {
      if (hasUnsavedConditions) {
        undoChanges()
      }
    }
  }, [view]) // eslint-disable-line react-hooks/exhaustive-deps

  const goToView = (newView: View) => {
    history.push(getRoutePath(getNewPathName(newView, type, isSmartSetup), { id }))
  }

  const backLink = getRoutePath(
    type === "featured"
      ? "segments.featured"
      : type === "smart"
      ? "segments.smart"
      : "segments.custom",
  )

  const toggleViewOptions = isSmartSetup
    ? [{ value: "settings" }, { value: "builder" }, { value: "insights" }, { value: "exports" }]
    : [{ value: "builder" }, { value: "insights" }, { value: "exports" }]

  // DELETE

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { mutate: deleteSegment, isLoading: isDeleting } = useDeleteSegment(type)

  // COPY

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [isCopyingToFeatured, setIsCopyingToFeatured] = useState(false)

  const copyToCustomSegments = useCallback(async () => {
    if (isCopying) return

    setIsCopying(true)
    try {
      const response = await api.segment.clone(+id, { featured: 0 })
      refetchSegmentPermissionsForCurrentUser()
      // @ts-ignore
      dispatch(showToast("Segment copied.", TOAST.TYPE.SUCCESS))
      setIsCopyModalOpen(false)
      history.push(getRoutePath("segments.custom.detail", { id: response.segment.id }))
    } catch {
    } finally {
      setIsCopying(false)
    }
  }, [dispatch, history, id, isCopying])

  const copyToFeaturedSegments = useCallback(async () => {
    if (isCopyingToFeatured) return

    setIsCopyingToFeatured(true)
    try {
      const response = await api.segment.clone(+id, { featured: 1 })
      // @ts-ignore
      dispatch(showToast("Segment copied.", TOAST.TYPE.SUCCESS))
      setIsCopyModalOpen(false)
      history.push(getRoutePath("segments.featured.detail", { id: response.segment.id }))
    } catch {
    } finally {
      setIsCopyingToFeatured(false)
    }
  }, [dispatch, history, id, isCopyingToFeatured])

  // SMART SEGMENT FE SETTINGS

  const changeSmartSegmentFESettings = useCallback(
    async newFESettings => {
      // TODO: indication of loading?
      try {
        await modifySegment(
          { id: +id, data: { frontend_settings: newFESettings } },
          {
            onSuccess: () => {
              dispatch(showToast("Segment's visual attributes were updated."))
            },
          },
        )
      } catch {}
    },
    [id, dispatch, modifySegment],
  )

  // ONBOARDING GUIDE

  const { frontend_settings, id: userId } = currentUser!

  const [isGuideOpen, setIsGuideOpen] = useState(!frontend_settings?.hasSeenSegmentGuide)
  const [guideStep, setGuideStep] = useState(1)

  const guideStepBack = useCallback(() => setGuideStep(dec), [])
  const guideStepForward = useCallback(() => setGuideStep(inc), [])

  const closeGuide = useCallback(() => {
    setIsGuideOpen(false) // Hide immediately, don't wait for the BE response
    modifyUser({
      id: userId,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          hasSeenSegmentGuide: true,
        },
      },
    })
  }, [frontend_settings, modifyUser, userId])

  const { isSuccess: areAttributesFulfilled } = useFetchAllAttributes()

  const isReady =
    segment &&
    areSegmentPermissionsFulfilled &&
    areUsersFulfilled &&
    areTagsFulfilled &&
    areAttributesFulfilled

  if (!isReady) {
    return (
      <Page
        contentClassName={styles.container}
        title={`Edit ${type === "regular" ? "custom" : type} segment`}
        backRouteFallback={backLink}
      >
        <LoadingIndicator />
      </Page>
    )
  }

  if (!usersMap) {
    return null
  }

  const { name, tag_ids, author_id } = segment

  const reducedOpacity = { opacity: 0.3 }

  return (
    <Page
      contentClassName={styles.container}
      title={`Edit ${type === "regular" ? "custom" : type} segment`}
      headerContent={
        <div
          className={styles.buttonsWrapper}
          style={
            isGuideOpen && (guideStep !== 3 || view !== "builder") ? reducedOpacity : undefined
          }
        >
          {isEditable && view === "builder" && (
            <Button
              className={styles.button}
              color="grey"
              variant="outlined"
              disabled={!hasUnsavedConditions || isSavingConditions}
              onClick={undoChanges}
            >
              undo changes
            </Button>
          )}
          {isEditable && (
            <Button
              className={styles.button}
              color="red"
              variant="outlined"
              onClick={() => setIsDeleteModalOpen(true)}
              disabled={!isEditable}
            >
              delete
            </Button>
          )}
          <Button
            className={styles.button}
            color="grey"
            variant="outlined"
            onClick={() => setIsCopyModalOpen(true)}
            disabled={!hasAccess.segments.create && !hasAccess.segments.featured.edit}
          >
            copy
          </Button>
          {isEditable && view === "builder" && (
            <Button
              className={styles.button}
              color="green"
              loading={isSavingConditions}
              onClick={saveConditions}
              disabled={!hasUnsavedConditions}
            >
              save conditions
            </Button>
          )}
        </div>
      }
      backRouteFallback={backLink}
    >
      <Prompt
        when={hasUnsavedConditions && !isDeleting}
        message="Changes to conditions you made will not be saved."
      />

      <CSSTransition
        in={isGuideOpen && guideStep === 1}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <SegmentGuide
          onClose={closeGuide}
          onPrev={guideStepBack}
          onNext={guideStepForward}
          step={1}
          style={{ top: "50%", left: "50%", transform: "translate(-50%)", position: "fixed" }}
        />
      </CSSTransition>

      {type !== "smart" && (
        <SegmentUsersModal
          isOpen={isUsersModalOpen}
          onClose={() => setIsUsersModalOpen(false)}
          segmentId={+id}
          authorId={author_id}
          acl={segmentPermissions!}
          allUsers={usersMap}
          isEditable={isEditable}
          segmentType={type}
        />
      )}

      <CopyModal
        open={isCopyModalOpen}
        handleClose={() => setIsCopyModalOpen(false)}
        type={type}
        onCopyClick={copyToCustomSegments}
        onCopyToFeaturedClick={copyToFeaturedSegments}
        copying={isCopying}
        copyingToFeatured={isCopyingToFeatured}
      />

      <ConfirmModal
        open={isDeleteModalOpen}
        type="delete"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() =>
          deleteSegment(+id, {
            onSuccess: () => {
              history.push(backLink)
              queryClient.removeQueries([SEGMENT, id])
            },
          })
        }
        title="delete segment"
        isLoading={isDeleting}
        text="Do you really want to delete the current segment?"
      />

      <div
        className={styles.viewSwitchWrapper}
        style={isGuideOpen && guideStep !== 4 ? reducedOpacity : undefined}
      >
        <CSSTransition
          in={isGuideOpen && guideStep === 4}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <SegmentGuide
            onClose={closeGuide}
            onPrev={guideStepBack}
            onNext={guideStepForward}
            step={4}
            style={{ top: "calc(100% + 10px)", left: "50%", transform: "translateX(-50%)" }}
          />
        </CSSTransition>

        <div className={styles.line}></div>
        <ToggleSwitchMultiple
          width={isSmartSetup ? "380px" : "280px"}
          name="view-switch"
          buttons={toggleViewOptions}
          checked={view}
          handleToggle={goToView}
          className={styles.viewSwitch}
        />
        <div className={styles.line}></div>
      </div>

      <div className={styles.mainContent}>
        <div
          className={styles.leftPanel}
          style={isGuideOpen && guideStep !== 2 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 2}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={2}
              style={{ top: "0", left: "calc(100% + 10px)" }}
            />
          </CSSTransition>

          <Paper className={styles.card}>
            <div className={styles.nameWrapper}>
              <div className={styles.title}>name</div>
              {isEditable ? (
                <EditableName initValue={name} onChange={editName} className={styles.name} />
              ) : (
                <div className={styles.name}>{name}</div>
              )}
              <div className={styles.stretch}></div>
              <div className={styles.segmentId}>ID: {id}</div>
            </div>
            {type !== "smart" && (
              <div className={styles.usersWrapper}>
                <div className={styles.title}>users</div>
                <SegmentUsers
                  acl={segmentPermissions!}
                  allUsers={usersMap}
                  authorId={author_id}
                  openUsersModal={() => setIsUsersModalOpen(true)}
                />
              </div>
            )}
          </Paper>

          <Paper className={styles.tagsCard}>
            <div className={styles.title}>tags</div>
            <div className={styles.tagPickerWrapper}>
              {tag_ids.map(tagId => {
                const tag = allTags.find(({ id }) => id === tagId)

                return tag ? (
                  <TagComponent
                    key={tag.id}
                    clickable={isEditable}
                    color={tag.color ? tag.color : "primary"}
                    onClick={isEditable ? () => unassignTag(tagId) : noop}
                  >
                    {tag.name}
                  </TagComponent>
                ) : null
              })}
              <TagPicker
                className={styles.tagPicker}
                selectedTagIds={tag_ids}
                allTags={allTags}
                onTagSelect={assignTag}
                disabled={!isEditable}
              />
            </div>
          </Paper>

          <Paper className={classNames(styles.card, styles.segmented)}>
            <div className={styles.title}>segmented</div>
            <SegmentNumbers hasUnsavedConditions={hasUnsavedConditions} />
          </Paper>
          <Paper className={classNames(styles.card, styles.availableInChannels)}>
            <div className={styles.title}>Available in channels</div>
            <div className={styles.channels}>
              <SegmentChannelNumbers channelType="emails" segmentId={+id} />
              <SegmentChannelNumbers channelType="push_notifications" segmentId={+id} />
            </div>
          </Paper>

          <Paper className={styles.card}>
            <div className={styles.flexWrapper}>
              <div className={classNames(styles.title, styles.stretch)}>summary</div>
              <button className={styles.openButton} onClick={() => setIsSummaryOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isSummaryOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentSummary
              conditionTree={conditions}
              segmentedCustomers={conditionsResultsCount ?? null}
              totalCustomers={customersTotalCount ?? null}
              hasUnsavedConditions={hasUnsavedConditions}
              isOpen={isSummaryOpen}
            />
          </Paper>

          <Paper className={styles.notesCard}>
            <div className={styles.notesCardHeader}>
              <div className={classNames(styles.title, styles.stretch)}>notes</div>
              <button className={styles.openButton} onClick={() => setIsNotesOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isNotesOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentNotes
              segmentId={+id}
              isEditable={isEditable}
              toggleNotes={() => {}}
              isOpen={isNotesOpen}
            />
          </Paper>

          <Paper className={styles.card}>
            <div className={styles.flexWrapper}>
              <div className={classNames(styles.title, styles.stretch)}>preview profiles</div>
              <button className={styles.openButton} onClick={() => setIsCustomersOpen(s => !s)}>
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  flip={isCustomersOpen ? "vertical" : undefined}
                />
              </button>
            </div>
            <SegmentCustomersPreview
              hasUnsavedConditions={hasUnsavedConditions}
              customersListOffset={customersListOffset}
              setCustomersListOffset={setCustomersListOffset}
              isOpen={isCustomersOpen}
            />
          </Paper>
        </div>

        <div
          className={styles.viewWrapper}
          style={isGuideOpen && guideStep !== 3 ? reducedOpacity : undefined}
        >
          <CSSTransition
            in={isGuideOpen && guideStep === 3}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <SegmentGuide
              onClose={closeGuide}
              onPrev={guideStepBack}
              onNext={guideStepForward}
              step={3}
              style={{ top: "0", right: "calc(100% + 10px)" }}
            />
          </CSSTransition>

          {view === "settings" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <SmartSegmentSettings
                id={id}
                segment={segment}
                handleSmartSegmentFeSettingsChange={changeSmartSegmentFESettings}
              />
            </div>
          )}
          {view === "builder" && (
            <>
              <PaperHeader
                titleText="conditions"
                size="small"
                className={styles.conditionsOverviewWrapper}
              >
                <ConditionTreeOverview
                  conditionTree={conditions}
                  onChange={setConditionsAndValidate}
                  highlightedPath={hoveredPath}
                  onHover={setHoveredPath}
                />
              </PaperHeader>
              <Paper className={styles.conditionBuilderWrapper}>
                <ConditionNumbersContext.Provider
                  value={{
                    totalCustomers: customersTotalCount ?? null,
                    conditionNumbers: conditionsNumbers ?? [],
                    isInvalidNumbers: hasUnsavedConditions,
                  }}
                >
                  <ConditionBuilder<SegmentCondition, SegmentConditionError | null>
                    conditionTree={conditions}
                    onChange={setConditionsAndValidate}
                    conditionComponent={SegmentConditionComponent}
                    getNewCondition={getNewCondition}
                    isEditable={isEditable}
                    error={error}
                    highlightedPath={hoveredPath}
                    onHover={setHoveredPath}
                  />
                </ConditionNumbersContext.Provider>
              </Paper>
            </>
          )}
          {view === "insights" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              <PaperHeader titleText="insights" size="small" className={styles.insightsHeader}>
                <div className={styles.insightsSearchWrapper}>
                  <InsightsFilterForm />
                </div>
              </PaperHeader>
              <Paper>
                {hasAccess.segments.insights ? (
                  <SegmentInsights id={id} />
                ) : (
                  <div className={styles.noAccessMessage}>
                    You don't have permission to view segment insights.
                  </div>
                )}
              </Paper>
            </div>
          )}
          {view === "exports" && (
            <div style={isGuideOpen ? reducedOpacity : undefined}>
              {hasAccess.segments.export ? (
                <SegmentExports
                  segment={segment}
                  isEditable={isEditable}
                  isSegmentDeleting={isDeleting}
                  isSmartSegment={type === "smart"}
                  isFeaturedSegment={type === "featured"}
                />
              ) : (
                <>
                  <PaperHeader titleText="exports" size="small" />
                  <Paper className={styles.noAccessMessage}>
                    You don't have permission to view segment exports.
                  </Paper>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}
