import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import EmailsComingSoonImg from "./EmailsComingSoonImg.jpg"
import styles from "./EmailsComingSoon.module.scss"
import Page from "components/UI/Page/Page"

export default function EmailsComingSoon() {
  return (
    <Page title="Email campaigns">
      <MarketingContent img={{ alt: "Emails", src: EmailsComingSoonImg }}>
        <h1>Coming soon!</h1>
        <p>
          The new channel for activation allows you to trigger your audience with{" "}
          <strong className={styles.primary}>personalized email messages</strong> in real-time. By
          using attributes in the messages, you can target the segments of your customers with
          emails holding personal information like name, recommended products, items left in the
          basket, searched products, etc.
        </p>
        <p>
          A visual email editor helps in creating email templates with a simple drag-and-drop
          interface.
        </p>
        <p>
          Meiro is excited to enable one of the most popular direct marketing channels in the first
          quarter of 2023. To access the beta tests, please email{" "}
          <a href="mailto:product@meiro.io" target="_blank" rel="noreferrer">
            product@meiro.io
          </a>{" "}
          or get in touch with your account manager!
        </p>
      </MarketingContent>
    </Page>
  )
}
