import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, reduxForm, Field, formValues } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"

// ui components
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"

import "./NameForm.scss"
import Tippy from "@tippyjs/react"

class NameForm extends Component {
  constructor(props) {
    super(props)
    const name = _get(this.props, "initialValues.name", "Undefined name")
    this.state = {
      editMode: false,
      fontSize: this.getFontSizeBasedOnName(name),
    }
  }

  componentDidUpdate(prevProps) {
    const namePrev = _get(prevProps, "initialValues.name")
    const nameNow = _get(this.props, "initialValues.name")
    if (namePrev !== nameNow) {
      this.updateFontSize(nameNow)
    }
  }

  getFontSizeBasedOnName = name => {
    const { fontSizeDecreaseOffset } = this.props
    return name.length > 30 + fontSizeDecreaseOffset ? "14" : "15"
  }

  updateFontSize = name => {
    const fontSize = this.getFontSizeBasedOnName(name)
    if (this.state.fontSize !== fontSize) {
      this.setState({
        fontSize,
      })
    }
  }

  onSubmit = values => {
    const { allowEmptyValue } = this.props
    if (!values.name && !allowEmptyValue) {
      this.setState({
        error: true,
      })
    } else {
      const { handleNameChange } = this.props
      this.toggleNameForm()
      if (handleNameChange) {
        handleNameChange(values.name)
      }
    }
  }

  onChange = evt => {
    const { allowEmptyValue } = this.props
    const value = _get(evt, "target.value")
    if (!value && !allowEmptyValue) {
      this.setState({
        error: true,
      })
    } else {
      if (this.state.error) {
        this.setState({
          error: false,
        })
      }
      this.updateFontSize(value)
    }
  }

  toggleNameForm = () => {
    const { toggleEditMode, editMode } = this.props
    if (toggleEditMode) {
      toggleEditMode(() => {
        if (!editMode) {
          // will be opened now
          this.fieldRef.focus()
        } else {
          this.setState({
            error: false,
          })
        }
      })()
    } else {
      this.setState(
        prevState => ({
          editMode: !prevState.editMode,
          error: false,
        }),
        () => {
          this.fieldRef.focus()
        },
      )
    }
  }

  cancelEditing = () => {
    const name = _get(this.props, "initialValues.name", "Undefined name")
    this.props.reset()
    this.toggleNameForm()
    this.updateFontSize(name)
  }

  render() {
    const {
      handleSubmit,
      isEditable,
      form,
      size,
      label = "",
      readOnlySign,
      name,
      placeholder = "",
    } = this.props
    const { error, fontSize } = this.state
    const editMode = this.props.editMode ? this.props.editMode : this.state.editMode

    let showTooltipOnHover = false
    if (this.fieldRef) {
      if (this.fieldRef.scrollWidth > this.fieldRef.clientWidth) {
        showTooltipOnHover = true
      }
    }
    return (
      <React.Fragment>
        {isEditable && (
          <Form
            name={form}
            className={`name-form ${size === "big" ? "big" : "small"}`}
            autoComplete="off"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <Tippy content={name} disabled={!(showTooltipOnHover && !editMode)}>
              <div className={`tooltip-trigger ${editMode ? "edit" : ""}`} />
            </Tippy>
            <div
              className={
                editMode
                  ? `name edit-mode fs-${fontSize}`
                  : `name fs-${fontSize} ${name ? "" : "placeholder"}`
              }
            >
              {name ? name : placeholder}
            </div>
            <Field
              name="name"
              component={TextField}
              label={label}
              disabled={!editMode}
              setFieldToBeFocused={input => {
                this.fieldRef = input
              }}
              className={
                editMode ? `text-field edit-mode fs-${fontSize}` : `text-field fs-${fontSize}`
              }
              onChange={this.onChange}
              maxLength={65}
            />
            {editMode && (
              <React.Fragment>
                <button type="button" className="close-icon-btn" onClick={this.cancelEditing}>
                  <FontAwesomeIcon className="icon" icon={["far", "times"]} />
                </button>
                {!error && (
                  <button type="submit" className="link-button edit-mode">
                    Save
                  </button>
                )}
              </React.Fragment>
            )}
            {!editMode && isEditable && (
              <button type="button" className="link-button" onClick={this.toggleNameForm}>
                Edit
              </button>
            )}
          </Form>
        )}
        {!isEditable && (
          <div className={`name-not-editable fs-${fontSize}`}>
            {label !== "" && <label className="label">{label}</label>}
            <h2>
              {name}
              {readOnlySign ? (
                <React.Fragment>
                  {" "}
                  <span className="read-only-sign">
                    <FontAwesomeIcon icon={["fal", "eye"]} className="eye" /> View-only
                  </span>
                </React.Fragment>
              ) : (
                ""
              )}
            </h2>
          </div>
        )}
      </React.Fragment>
    )
  }
}

NameForm.defaultProps = {
  fontSizeDecreaseOffset: 0,
}

NameForm.propTypes = {
  form: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  handleNameChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  readOnlySign: PropTypes.bool,
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  fontSizeDecreaseOffset: PropTypes.number,
  placeholder: PropTypes.string,
  allowEmptyValue: PropTypes.bool,
}

export default reduxForm({
  enableReinitialize: true,
})(formValues("name")(NameForm))
