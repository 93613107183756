import React, { lazy, Suspense, useEffect } from "react"
import classNames from "classnames"
import { pick } from "ramda"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import { maxLength, pythonVariable, required } from "helpers/validators.helper"
import { MetaAttribute } from "resources/metaAttributes/metaAttributesTypes"
import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"

import styles from "./MetaAttributesForm.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))

type FormValues = Pick<MetaAttribute, "id" | "definition">

type Props = {
  onCancel: () => void
  onSubmit: (data: FormValues) => void
  metaAttribute?: MetaAttribute
  isLoading?: boolean
  isCreate?: boolean
}

export default function MetaAttributesForm({
  metaAttribute,
  onCancel,
  onSubmit,
  isLoading,
  isCreate,
}: Props) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting, isSubmitted },
    reset,
  } = useForm<FormValues>({
    defaultValues: metaAttribute && pick(["id", "definition"], metaAttribute),
  })

  useEffect(() => {
    if (metaAttribute) {
      reset({
        ...pick(["id", "definition"], metaAttribute),
      })
    }
  }, [metaAttribute, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page
        contentClassName={styles.wrapper}
        title={isCreate ? "Create meta attribute" : "Edit meta attribute"}
        headerContent={
          !isLoading && (
            <div>
              <div className={styles.buttons}>
                <Button color="grey" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit">
                  Save
                </Button>
              </div>
            </div>
          )
        }
      >
        <Prompt
          when={isDirty && !isSubmitting && !isSubmitted}
          message="Changes you made will not be saved."
        />

        {isLoading && <LoadingIndicator />}

        {!isLoading && (
          <Paper hasHeader className={styles.paper}>
            <div className={styles.row}>
              <div className={styles.left}>
                <h2>General</h2>
              </div>
              <div className={styles.id}>
                <TextInput
                  {...register("id", {
                    validate: { pythonVariable, required, maxLength: maxLength(255) },
                  })}
                  disabled={!!metaAttribute}
                  error={errors.id?.message}
                  label="Meta Attribute ID"
                  placeholder="Meta Attribute ID"
                />
              </div>
            </div>
            <div className={classNames(styles.row, styles.grey)}>
              <div className={styles.left}>
                <h2>Definition</h2>
              </div>
              <div className={styles.right}>
                <Controller
                  control={control}
                  name="definition"
                  rules={{ validate: { required } }}
                  render={({ field: { onChange, value } }) => (
                    <div className={styles.aceEditorWrapper}>
                      <label>SQL query</label>
                      <Suspense fallback={<LoadingIndicator />}>
                        <AceEditor
                          wrapEnabled
                          showPrintMargin={false}
                          className={classNames(styles.aceEditor, {
                            [styles.error]: !!errors.definition?.message,
                          })}
                          editorProps={{ $blockScrolling: true }}
                          mode="pgsql"
                          height="400px"
                          theme="github"
                          width="100%"
                          value={value}
                          onChange={onChange}
                          errorMessage={errors.definition?.message}
                        />
                      </Suspense>
                    </div>
                  )}
                />
              </div>
            </div>
          </Paper>
        )}
      </Page>
    </form>
  )
}
