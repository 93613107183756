import React, { PureComponent } from "react"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import { selectStyles } from "helpers/customSelectStyle.helper"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import DestinationAttributes from "pages/Data/Destinations/components/DestinationAttributes/DestinationAttributes"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./DestinationsList.scss"
import Tippy from "@tippyjs/react"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteDestination,
  useFetchAllDestinations,
  useModifyDestination,
} from "resources/exportDestination/exportDestinationQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { Link } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { isNil } from "ramda"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"

class DestinationsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteModalState: {
        modalOpen: false,
        destination: null,
        isLoading: false,
      },
    }
  }

  goToDestinationDetail = destinationId => () => {
    this.props.history.push(
      getRoutePath("administration.destinations.detail", { id: destinationId }),
    )
  }

  changeDestinationWorkspace = destinationId => workspace => {
    this.props.modifyExportDestination({
      id: destinationId,
      data: {
        mi_workspace_id: workspace.value,
      },
    })
  }

  renderWorkspaceSelect = destination => {
    const { workspaces, isMiApiSet, areWorkspacesFulfilled } = this.props
    if (isMiApiSet && destination.id > 1000) {
      let selectedWorkspace = null
      if (destination.mi_workspace_id) {
        const foundWs = workspaces.find(ws => ws.value === destination.mi_workspace_id)
        selectedWorkspace = foundWs
          ? foundWs
          : { label: destination.mi_workspace_id, value: destination.mi_workspace_id }
      }
      return (
        <Select
          value={selectedWorkspace}
          onChange={this.changeDestinationWorkspace(destination.id)}
          options={workspaces}
          styles={selectStyles("tag")}
          simpleValue
          isSearchable={true}
          isLoading={!areWorkspacesFulfilled}
          className="select-input"
          components={{
            DropdownIndicator: DropdownIndicator,
          }}
          classNamePrefix="tag_select"
          noOptionsMessage={() => "Empty"}
        />
      )
    }
    return null
  }

  toggleDeleteModal =
    (newDestination = null) =>
    () => {
      if (this.state.deleteModalState.isLoading) return

      this.setState(({ deleteModalState: { isLoading, modalOpen, destination } }) => ({
        deleteModalState: {
          isLoading,
          modalOpen: !modalOpen,
          destination: newDestination ?? destination,
        },
      }))
    }

  deleteDestination = async () => {
    const { deleteModalState } = this.state
    const { deleteDestination } = this.props
    this.setState(prevState => ({
      deleteModalState: {
        ...prevState.deleteModalState,
        isLoading: true,
      },
    }))
    try {
      await deleteDestination({ id: deleteModalState.destination.id })
      this.setState(prevState => ({
        deleteModalState: {
          ...prevState.deleteModalState,
          isLoading: false,
          modalOpen: false,
        },
      }))
    } catch {
      this.setState(prevState => ({
        deleteModalState: {
          ...prevState.deleteModalState,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { areDestinationsFulfilled, destinations } = this.props
    const { deleteModalState } = this.state

    return (
      <Page className="setup-destinations" title="Destinations">
        {!areDestinationsFulfilled && <LoadingIndicator />}

        {areDestinationsFulfilled && destinations.length > 0 && (
          <Paper>
            <div className="trash-button-wrapper">
              <Link to={getRoutePath("administartion.destinations.trash")}>
                <Button
                  type="button"
                  color="grey"
                  variant="outlined"
                  icon="trash-alt"
                  iconStyle="far"
                >
                  Trash
                </Button>
              </Link>
            </div>
            <Table className="setup-destinations-table">
              <Thead stickyHeader>
                <Th className="icon" />
                <Th className="id">ID</Th>
                <Th className="name">Name</Th>
                <Th className="exported-attributes">Exported attributes</Th>
                <Th className="mandatory-attributes">Mandatory attributes</Th>
                <Th className="mi-workspace">
                  MI Workspace{" "}
                  <Tippy content="Setting available only for certain destinations if MI connection is defined.">
                    <span>
                      <FontAwesomeIcon icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>
                </Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {destinations.map(destination => {
                  return (
                    <Tr key={destination.id}>
                      <Td className="icon">
                        <div className="icon-container">
                          <SrcDstIcon destination={destination} />
                        </div>
                      </Td>
                      <Td className="id">{destination.id}</Td>
                      <Td className="name" textBold textBigger textBlack>
                        {destination.name}
                      </Td>
                      <Td>
                        <DestinationAttributes
                          destination={destination}
                          showAttributes="exported"
                          showHiddenAttributes
                        />
                      </Td>
                      <Td>
                        <DestinationAttributes
                          destination={destination}
                          showAttributes="mandatory"
                          showHiddenAttributes
                        />
                      </Td>
                      <Td className="mi-workspace">{this.renderWorkspaceSelect(destination)}</Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color="black"
                          size="xs"
                          onClick={this.goToDestinationDetail(destination.id)}
                          iconStyle="fas"
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />
                        <IconButton
                          color="red"
                          size="xs"
                          onClick={this.toggleDeleteModal(destination)}
                          className="left-margin"
                          icon="trash-alt"
                          tooltip="Delete"
                          variant="outlined"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <ConfirmModal
              open={deleteModalState.modalOpen}
              type={MODAL.TYPE.DELETE}
              handleClose={this.toggleDeleteModal()}
              handleConfirm={this.deleteDestination}
              title="Are you sure?"
              action="delete"
              what="destination"
              item={deleteModalState.destination?.name ?? ""}
              isLoading={deleteModalState.isLoading}
            />
          </Paper>
        )}
      </Page>
    )
  }
}

export default props => {
  const { data = [], isSuccess } = useFetchAllDestinations()
  const { mutateAsync: modifyDestination } = useModifyDestination()
  const { mutateAsync: deleteDestination } = useDeleteDestination()
  const { data: globalSettings } = useFetchGlobalSettings()
  const isMiApiSet = !isNil(globalSettings?.["mi_api"]?.value)
  const { data: workspaces = [], isSuccess: areWorkspacesFulfilled } = useFetchWorkspaceOptions()

  return (
    <DestinationsList
      {...props}
      destinations={data}
      areDestinationsFulfilled={isSuccess}
      modifyDestination={modifyDestination}
      deleteDestination={deleteDestination}
      isMiApiSet={isMiApiSet}
      workspaces={workspaces}
      areWorkspacesFulfilled={areWorkspacesFulfilled}
    />
  )
}
