import React, { ComponentProps } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import styles from "./Checkbox.module.scss"

export type CheckboxProps = {
  label: string | JSX.Element
  centered?: boolean
  errorMessage?: string
  size?: "sm" | "lg"
  variant?: "primary" | "secondary"
} & Omit<ComponentProps<"input">, "size">

const Checkbox = ({
  checked,
  className,
  disabled,
  errorMessage,
  label,
  onChange,
  centered = true,
  size = "lg",
  variant = "primary",
  ...inputAttrs
}: CheckboxProps) => (
  <>
    <label
      className={classNames(styles.label, styles[size], styles[variant], className, {
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.error]: errorMessage,
      })}
    >
      {label}
      <input
        {...inputAttrs}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      {variant === "primary" && (
        <div
          className={classNames(styles.checkmark, styles[size], {
            [styles.centered]: centered,
            [styles.checked]: checked,
            [styles.disabled]: disabled,
            [styles.error]: errorMessage,
          })}
        >
          <FontAwesomeIcon icon={["fas", "check"]} />
        </div>
      )}
    </label>
    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
  </>
)

export default Checkbox
