import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import EmbeddedWebBannersCreate from "./EmbeddedWebBannersCreate/EmbeddedWebBannersCreate"
import EmbeddedWebBannersDetail from "./EmbeddedWebBannersDetail/EmbeddedWebBannersDetail"
import EmbeddedWebBannersList from "./EmbeddedWebBannersList/EmbeddedWebBannersList"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"

export default function EmbeddedWebBanners() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("channels.native-banners")}
        exact
        component={EmbeddedWebBannersList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.webBanners.view}
        path={getRoutePath("channels.native-banners.trash")}
        exact
        render={() => <Trash itemType="embedded_web_banners" />}
      />
      <Route
        path={getRoutePath("channels.native-banners.create")}
        component={EmbeddedWebBannersCreate}
      />
      <Route
        path={getRoutePath("channels.native-banners.detail")}
        component={EmbeddedWebBannersDetail}
      />
    </Switch>
  )
}
