import React from "react"
import styles from "./UserMenuDropdown.module.scss"
import logo from "images/meiro-symbol-white-transparent.svg"
import Avatar from "components/UI/elements/Avatar"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import { logout } from "auth/auth"
import { useAppVersionState } from "../AuthenticatedLayout/appVersionState"
import useClickOutHandler from "hooks/useClickOutHandler"
import classNames from "classnames"

const meiroLocation =
  process.env.NODE_ENV === "production"
    ? "[[MEIRO_LOCATION]]"
    : process.env.REACT_APP_MEIRO_LOCATION

export default function UserMenuDropdown({ isCollapsed }: { isCollapsed: boolean }) {
  const { data: currentUser } = useFetchCurrentUser()
  const history = useHistory()
  const hasAccess = useHasAccess()
  const { version: meiroVersion } = useAppVersionState()
  const { isOpen, ref, toggle, close, buttonRef } = useClickOutHandler()

  if (!currentUser) {
    return null // TODO:
  }

  return (
    <div className={classNames(styles.container, { [styles.collapsed]: isCollapsed })}>
      <button className={styles.button} onClick={toggle} ref={buttonRef}>
        <img className={styles.logo} src={logo} alt="logo" />
        <div className={styles.title}>Meiro CDP</div>
        {/* <Avatar className={styles.avatar} email={currentUser.email} name={currentUser.name} /> */}
        <FontAwesomeIcon
          className={styles.caret}
          icon={["fas", "caret-down"]}
          flip={isOpen ? "vertical" : undefined}
        />
      </button>
      {isOpen && (
        <div className={styles.dropdown} ref={ref}>
          <div className={styles.topWrapper}>
            <div className={styles.user}>
              <div className={styles.text}>
                <div className={styles.email}>{currentUser.email}</div>
                <div className={styles.role}>{currentUser.role.name}</div>
              </div>
              <Avatar className={styles.avatar} email={currentUser.email} name={currentUser.name} />
            </div>
          </div>
          <div className={styles.menu}>
            <Link to={getRoutePath("user-settings")} className={styles.menuItem} onClick={close}>
              <FontAwesomeIcon className={styles.menuItemIcon} icon={["fas", "user"]} />
              User settings
            </Link>

            {hasAccess.logs && (
              <a
                href="/logs"
                target="_blank"
                rel="noreferrer"
                className={styles.menuItem}
                onClick={close}
              >
                <FontAwesomeIcon className={styles.menuItemIcon} icon={["fas", "terminal"]} />
                Logs
              </a>
            )}

            <button
              onClick={() => {
                logout()
                close()
                history.push(getRoutePath("logout"))
              }}
              className={styles.menuItem}
            >
              <FontAwesomeIcon className={styles.menuItemIcon} icon={["fas", "sign-out"]} />
              Log out
            </button>
          </div>
          <div className={styles.bottomWrapper}>
            <div className={styles.bottomItem}>
              <FontAwesomeIcon className={styles.bottomIcon} icon={["fas", "map-marker-alt"]} />
              {meiroLocation}
            </div>
            <div className={styles.bottomItem}>
              <FontAwesomeIcon className={styles.bottomIcon} icon={["fas", "code"]} />
              Version {meiroVersion}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
