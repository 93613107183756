import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import { CustomerDestinations } from "resources/customer/destination/customerDestinationTypes"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"

import styles from "./DestinationParticipation.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

type DestinationParticipationProps = {
  destinations: Array<SegmentExportDestination>
  customerDestinations: CustomerDestinations["customer_destinations"]
}

export default function DestinationParticipation({
  destinations,
  customerDestinations,
}: DestinationParticipationProps) {
  return (
    <div className={styles.root}>
      <span className={styles.title}>
        <b>Eligible activations</b>
      </span>
      <div className={styles.items}>
        {destinations.map(({ id, icon, name }: SegmentExportDestination) => {
          const isExported = customerDestinations.find(
            ({ destination_id }) => id === destination_id,
          )

          return (
            <div key={id} className={classNames(styles.item, { [styles.disabled]: !isExported })}>
              {isExported && (
                <FontAwesomeIcon icon={["fas", "circle-check"]} className={styles.icon} />
              )}
              <SrcDstIcon iconName={icon} />
              <span className={styles.name}>
                <b>{name}</b>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
