import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SourceCreate from "./SourceCreate/SourceCreate"
import SourceDetail from "./SourceDetail/SourceDetail"
import SourcesList from "./SourcesList/SourcesList"

export default function SourcesSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("administration.sources")} exact component={SourcesList} />
      <Route path={getRoutePath("administration.sources.create")} component={SourceCreate} />
      <Route path={getRoutePath("administration.sources.detail")} component={SourceDetail} />
    </Switch>
  )
}
