import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React from "react"
import { Switch } from "react-router-dom"
import { useHasAccess } from "resources/user/currentUserQueries"
import { getRoutePath } from "routes"
import ProfileDetail from "./ProfileDetail/ProfileDetail"
import ProfilesList from "./ProfilesList/ProfilesList"

export default function Profiles() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <AuthorizedRoute
        hasAccess={hasAccess.customers.search}
        path={getRoutePath("profiles")}
        exact
        component={ProfilesList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.customers.detail}
        path={getRoutePath("profiles.detail")}
        component={ProfileDetail}
      />
    </Switch>
  )
}
