import React, { useEffect, useState } from "react"
import { getRoutePath } from "routes"
import { api } from "api"
import UserSettings from "components/UserSettings"
import { useFetchUserById, useModifyUser, useDeleteUser } from "resources/user/userQueries"
import { Redirect, useParams } from "react-router-dom"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { User, UserModifyPayload } from "resources/user/userTypes"
import { UserRole } from "resources/userRole/userRoleTypes"
import { SelectOption } from "types/util"
import fetchAll from "helpers/fetchAll.helper"
import { SYSTEM_ROLE_ID } from "sharedConstants"

export default function UserDetailPage() {
  const { id } = useParams<{ id: string }>()
  const { data: user, isSuccess: isUserFetched } = useFetchUserById(parseInt(id, 10))

  const [roleOptions, setRoleOptions] = useState<SelectOption<UserRole["id"]>[] | null>(null)

  async function fetchAllRoles() {
    try {
      const roles = await fetchAll({
        fetchFn: (offset, limit) => api.userRole.list(offset, limit),
        key: "roles",
      })

      setRoleOptions(
        roles
          .filter(role => role.id !== SYSTEM_ROLE_ID)
          .map(role => ({ label: role.name, value: role.id, features: role.features })),
      )
    } catch {}
  }

  useEffect(() => {
    fetchAllRoles()
  }, [])

  const { mutateAsync } = useModifyUser()
  const modifyUser = (id: User["id"], data: UserModifyPayload) => mutateAsync({ id, data })

  const deleteMutation = useDeleteUser()
  const deleteUser = (id: User["id"]) => deleteMutation.mutateAsync({ id })

  if (!isUserFetched) {
    return <LoadingIndicator />
  }

  if (!user) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  return (
    <UserSettings
      user={user}
      modifyUser={modifyUser}
      editablePassword={!user.okta_enabled}
      backButton
      deleteUser={deleteUser}
      deletableUser
      roles={roleOptions}
      editableRole
      showBackButton
    />
  )
}
