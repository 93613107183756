import { OrderDir } from "types/util"

export const sortByDateProp = <T extends object>(
  items: Array<T>,
  orderDir: OrderDir,
  sortBy: keyof T,
) =>
  items.sort(({ [sortBy]: a }, { [sortBy]: b }) => {
    if (a === null) return 1
    if (b === null) return -1
    if (a === b) return 0

    if (typeof a !== "string" || typeof b !== "string") return 0

    return new Date(a).getTime() < new Date(b).getTime()
      ? orderDir === "ASC"
        ? -1
        : 1
      : orderDir === "ASC"
      ? 1
      : -1
  })
