import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import fetchAll from "helpers/fetchAll.helper"
import { equals, path, prop, sort } from "ramda"
import { useDispatch } from "react-redux"
import api from "resources/endpoints"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerSort,
} from "./embeddedWBTypes"

const EMBEDDED_WEB_BANNER = "embeddedWebBanner" as const
const EMBEDDED_WEB_BANNER_ALL_QK: QueryKey = [EMBEDDED_WEB_BANNER, "all"]

export function useFetchAllEmbeddedWB(
  opts: {
    orderBy: EmbeddedWebBannerSort
    orderDir: OrderDir
    searchTerm: string
  },
  config: UseQueryOptions<EmbeddedWebBanner[], unknown, EmbeddedWebBanner[], QueryKey> = {},
) {
  const queryClient = useQueryClient()
  return useQuery(
    EMBEDDED_WEB_BANNER_ALL_QK,
    () =>
      fetchAll({
        fetchFn: (offset, limit) => api.embeddedWebBanner.list(offset, limit),
        key: "web_banners",
      }),
    {
      ...config,
      onSuccess: webBanners => {
        webBanners.forEach(web_banner =>
          queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner }),
        )
      },
      select: webBanners => {
        const filteredBanners = opts.searchTerm
          ? webBanners.filter(
              ({ name, element_id }) =>
                name.toLowerCase().includes(opts.searchTerm.toLowerCase()) ||
                element_id.toLowerCase().includes(opts.searchTerm.toLowerCase()),
            )
          : webBanners

        const getSortingProperty =
          opts.orderBy === "priority" ? path(["settings", "priority"]) : prop(opts.orderBy)
        const comparator = opts.orderDir === "ASC" ? ascend : descend

        return sort(comparator(getSortingProperty), filteredBanners)
      },
    },
  )
}

export function useFetchEmbeddedWBById(id: EmbeddedWebBanner["id"]) {
  return useQuery([EMBEDDED_WEB_BANNER, id], () => api.embeddedWebBanner.retrieve(id), {
    select: ({ web_banner }) => web_banner,
  })
}

export function useCreateEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: EmbeddedWebBannerCreatePayload }) => api.embeddedWebBanner.create(data),
    {
      onSuccess: ({ web_banner }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          data.push(web_banner)
          return data
        })
        queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
        dispatch(showToast("Web banner created."))
      },
    },
  )
}

export function useCopyEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.copy(id), {
    onSuccess: ({ web_banner }) => {
      queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
        if (!data) {
          return
        }

        data.push(web_banner)
        return data
      })
      queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
      dispatch(showToast("Web banner copied."))
    },
  })
}

export function useModifyEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: EmbeddedWebBanner["id"]; data: EmbeddedWebBannerModifyPayload }) =>
      api.embeddedWebBanner.modify(id, data),
    {
      onSuccess: ({ web_banner }, { data }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === web_banner.id)
          if (index === -1) {
            data.push(web_banner)
          } else {
            data[index] = web_banner
          }
          return data
        })
        queryClient.setQueryData([EMBEDDED_WEB_BANNER, web_banner.id], { web_banner })
        dispatch(
          showToast(
            equals(data, { disabled: true })
              ? "Web banner disabled."
              : equals(data, { disabled: false })
              ? "Web banner enabled."
              : "Web banner modified.",
          ),
        )
      },
    },
  )
}

export function useDeleteEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(EMBEDDED_WEB_BANNER_ALL_QK, data => {
          return data?.filter(el => el.id !== id)
        })
        dispatch(showToast("Web banner deleted."))
      },
    },
  )
}
