import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, formValueSelector, getFormSyncErrors } from "redux-form"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _set from "lodash/set"
import _has from "lodash/has"
import _isNil from "lodash/isNil"
import _toInteger from "lodash/toInteger"
import moment from "moment"

// ui component
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import SelectField from "components/UI/elements/SelectField"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import DummyInsight from "./DummyInsight"
import CustomDatePicker from "components/UI/components/CustomDatePicker"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import ToggleIconSwitchField from "components/UI/elements/ToggleSwitch/ToggleIconSwitchField"
import NumberSliderField from "components/UI/elements/NumberSliderField"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"

// constants, helpers
import { SEGMENT, SEGMENT_ANALYTICS_FUNCTIONS, MOMENT, OPTION_GROUP_COLORS } from "sharedConstants"
import { required } from "helpers/validators.helper"
import { fetchCustomerAttributeValues } from "helpers/attributeValue.helper"
import Whisperer from "components/UI/components/Whisperer/Whisperer"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"

class InsightForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      datePickerOpen: 0,
    }
  }

  toggleDatePicker = id => () => {
    if (this.state.datePickerOpen === id) {
      this.setState({ datePickerOpen: 0 })
    } else {
      this.setState({ datePickerOpen: id })
    }
  }

  handleDateValueChange = selector => obj => {
    let result = null
    if (_has(obj, "value")) {
      result = obj.value
    } else {
      if (moment.isMoment(obj)) {
        const { attributesMapById, attributeId, subAttribute } = this.props
        const attribute = attributesMapById[attributeId]
        const dataType = attribute
          ? subAttribute
            ? subAttribute.data_type
            : attribute.data_type
          : "date"

        result = obj
          .utc()
          .format(dataType === "datetime" ? MOMENT.DB_DATETIME_FORMAT : MOMENT.DB_DATE_FORMAT)
      } else {
        result = obj
      }
    }
    this.setState({
      valueFieldError: 0,
    })
    this.props.change(selector, result)
  }

  handleStringValueChange = (evt, { newValue }) => {
    if (evt) {
      this.setState({
        valueFieldError: 0,
      })
      this.props.change("value", newValue)
    }
  }

  selectAttribute = newAttributeId => {
    const { attributeId: oldAttributeId, change, attributesMapById, funcType } = this.props
    const clearForm = () => {
      change("function", null)
      change("value", null)
      change("value_from", null)
      change("value_to", null)
      change("subattribute", null)
    }

    if (newAttributeId !== oldAttributeId) {
      change("attribute_id", newAttributeId)
      const newAttrDataType = attributesMapById[oldAttributeId]?.data_type
      const oldAttrDataType = attributesMapById[newAttributeId]?.data_type
      if (newAttrDataType !== oldAttrDataType) {
        // attribute types changed, check if new attribute supports functType or if change
        // was from/to compound
        if (isAttributeCompound(newAttrDataType) || isAttributeCompound(oldAttrDataType)) {
          clearForm()
        } else {
          const setFuncType = _get(funcType, "value")
          const attribute = attributesMapById[newAttributeId]
          if (
            !attribute ||
            (setFuncType &&
              SEGMENT.ANALYTICS.FUNCTIONS[attribute.data_type.toUpperCase()].find(
                item => item.value === setFuncType,
              ) === undefined) ||
            setFuncType === "between"
          ) {
            clearForm()
          }
        }
      }
    }
  }

  onDimensionChange = newSubAttribute => {
    const { subAttribute, funcType, change } = this.props
    if (subAttribute && subAttribute.data_type !== newSubAttribute.data_type) {
      const setFuncType = _get(funcType, "value")
      if (
        setFuncType &&
        (SEGMENT.ANALYTICS.FUNCTIONS[newSubAttribute.data_type.toUpperCase()].find(
          item => item.value === setFuncType,
        ) === undefined ||
          setFuncType === "between")
      ) {
        change("function", null)
        change("value", null)
        change("value_from", null)
        change("value_to", null)
      }
    }
  }

  clearColor = () => {
    this.props.change("color", null)
  }

  _onSubmit = data => {
    const { onSubmit, attributesMapById, value, value_from, value_to } = this.props
    const attribute = attributesMapById[_get(data, "attribute_id")]
    if (attribute) {
      const dataType = _get(data, "subattribute")
        ? _get(data, "subattribute.data_type")
        : attribute.data_type
      if (["date", "string"].includes(dataType)) {
        if (_get(data, "function.value") === "count") {
          // validate date existence, because the component is not redux form field
          if (!value) {
            this.setState({
              valueFieldError: 1,
            })
            return
          }
        }
        if (_get(data, "function.value") === "contains") {
          if (!value || (value && value.length < 3)) {
            this.setState({
              valueFieldError: 1,
            })
            return
          }
        }
      }
      if (["date", "datetime"].includes(dataType)) {
        if (_get(data, "function.value") === "between") {
          // validate dates existence, because the component is not redux form field
          if (!value_from) {
            this.setState({
              valueFieldError: 1,
            })
            return
          } else if (!value_to) {
            this.setState({
              valueFieldError: 2,
            })
            return
          }
        }
      }
    }

    onSubmit(data)
  }

  fetchAttributeValues = (attributeId, subAttribute) => () => {
    const finalAttribute = subAttribute ? `${attributeId}.${subAttribute.value}` : attributeId
    return fetchCustomerAttributeValues(finalAttribute)
  }

  renderCompoundAttributeFields = (attribute, functionOptions) => {
    const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
    return (
      <div className="compound-attr-form-row">
        <div className="left-field">
          <Field
            component={SelectField}
            name="subattribute"
            label="Dimension *"
            validate={required}
            isSearchable={false}
            options={subAttributes.map(sattr => ({
              label: sattr.name,
              value: sattr.id,
              data_type: sattr.data_type,
            }))}
            placeholder="Select dimension"
            inputId="dimension"
            onChange={this.onDimensionChange}
          />
        </div>
        <div className="right-field">
          <Field
            component={SelectField}
            name="function"
            label="Condition *"
            validate={required}
            isSearchable={false}
            options={functionOptions}
            placeholder="Select condition"
            noOptionsMessage="First select a dimension"
            inputId="condition"
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      handleSubmit,
      attributeId,
      name,
      value,
      value_from,
      value_to,
      color,
      tileType,
      funcType,
      description,
      attributesMapById,
      submitErrors,
      submitFailed,
      count,
      subAttribute,
    } = this.props
    const { datePickerOpen, valueFieldError } = this.state

    const attribute = attributesMapById[attributeId]
    let conditionDataType = ""
    if (attribute) {
      conditionDataType = subAttribute ? subAttribute.data_type : attribute.data_type
    }

    const functionOptions = conditionDataType
      ? SEGMENT.ANALYTICS.FUNCTIONS[conditionDataType.toUpperCase()]
      : []

    const funcTypeValue = funcType ? funcType.value : null

    const hasValueField = [
      SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
      SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
      SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
      SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
      SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
      SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
      SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
    ].includes(funcTypeValue)

    const hasTwoValuesFields = [SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value].includes(funcTypeValue)

    const hasDisplayToggle = [
      SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
      SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
    ].includes(funcTypeValue)

    return (
      <React.Fragment>
        <Paper hasHeader className="admin-segment-analytics-content form-content">
          <div className="analytics-form-section">
            <Form
              className="analytics-form"
              autoComplete="off"
              onSubmit={handleSubmit(this._onSubmit)}
            >
              <div className="form-row">
                <Field
                  component={TextField}
                  placeholder="Name"
                  name="name"
                  label="Name *"
                  validate={required}
                  maxLength={50}
                />
              </div>
              <div className="form-row">
                <Field
                  component={TextField}
                  placeholder="Description"
                  name="description"
                  label="Description"
                />
              </div>
              <div className="two-fields">
                <div
                  className={`form-row attribute-row text-field ${
                    submitFailed && !_isNil(submitErrors.attribute_id) ? "error" : ""
                  }`}
                >
                  <label>Attribute *</label>
                  <ErrorTippy
                    disabled={!submitFailed || _isNil(submitErrors.attribute_id)}
                    content={submitErrors.attribute_id}
                  >
                    <div>
                      <AttributePicker
                        attributeId={attributeId}
                        handleAttributeSelect={this.selectAttribute}
                        showSource={true}
                        fixedSize="medium-size"
                        isEditable={true}
                        compoundAttributesHidden={false}
                        placeholder="Select attribute"
                      />
                    </div>
                  </ErrorTippy>
                </div>
                <div className="form-row">
                  {attribute && !isAttributeCompound(attribute.data_type) && (
                    <Field
                      component={SelectField}
                      name="function"
                      label="Condition *"
                      validate={required}
                      fullWidth
                      isSearchable={false}
                      options={functionOptions}
                      placeholder="Select condition"
                      noOptionsMessage="First select an attribute"
                    />
                  )}
                </div>
              </div>
              {attribute &&
                isAttributeCompound(attribute.data_type) &&
                this.renderCompoundAttributeFields(
                  attribute,
                  functionOptions ? functionOptions : [],
                )}
              {attribute && hasValueField && (
                <div className={hasDisplayToggle ? "two-fields" : ""}>
                  <div
                    className={`form-row date-value last-row ${
                      hasDisplayToggle ? "two-fields-count" : ""
                    }`}
                  >
                    {conditionDataType === "date" &&
                      ![
                        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
                        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
                      ].includes(funcTypeValue) && (
                        <div className={`text-field ${valueFieldError === 1 ? "error" : ""}`}>
                          <label>Value *</label>
                          <CustomDatePicker
                            value={
                              value && moment(value).isValid() ? moment.utc(value).local() : value
                            }
                            open={datePickerOpen === 1}
                            isEditable={true}
                            onChange={this.handleDateValueChange("value")}
                            toggle={this.toggleDatePicker(1)}
                            dataType={conditionDataType}
                          />
                          {valueFieldError === 1 && (
                            <p className="error-message">Please fill in the field</p>
                          )}
                        </div>
                      )}
                    {conditionDataType === "string" &&
                      ![
                        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
                        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
                        SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
                      ].includes(funcTypeValue) && (
                        <div
                          className={`whisperer text-field ${valueFieldError === 1 ? "error" : ""}`}
                        >
                          <label>Value *</label>
                          <Whisperer
                            fetchOptions={this.fetchAttributeValues(attributeId, subAttribute)}
                            value={value}
                            onChange={this.handleStringValueChange}
                            placeholder="Search..."
                          />
                          {valueFieldError === 1 && (
                            <p className="error-message">Please fill in at least 3 characters</p>
                          )}
                        </div>
                      )}
                    {!["date", "string"].includes(conditionDataType) &&
                      ![
                        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
                        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
                      ].includes(funcTypeValue) && (
                        <Field
                          component={TextField}
                          type={["int", "float"].includes(conditionDataType) ? "number" : "text"}
                          placeholder="Value fulfilling selected condition"
                          name="value"
                          label="Value *"
                        />
                      )}
                    {[
                      SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
                      SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
                      SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value,
                    ].includes(funcTypeValue) && (
                      <Field
                        key={hasDisplayToggle ? "1" : "2"}
                        component={NumberSliderField}
                        min={1}
                        max={5}
                        name="count"
                        label="Show"
                        marks={[1, 2, 3, 4, 5]}
                      />
                    )}
                  </div>
                  {hasDisplayToggle && (
                    <div className="form-row tile-display last-row">
                      <ToggleIconSwitchField
                        name="tile_type"
                        leftValue="chart"
                        rightValue="list"
                        leftIcon={["far", "chart-line"]}
                        rightIcon={["far", "bars"]}
                        width="100px"
                        className="tile-display-toggle"
                      />
                    </div>
                  )}
                </div>
              )}
              {attribute && hasTwoValuesFields && (
                <div className="two-values-fields">
                  <div className="form-row date-value last-row two-fields">
                    {["date", "datetime"].includes(conditionDataType) && (
                      <React.Fragment>
                        <div className={`text-field ${valueFieldError === 1 ? "error" : ""}`}>
                          <label>Since *</label>
                          <CustomDatePicker
                            value={
                              value_from && moment(value_from).isValid()
                                ? moment.utc(value_from).local()
                                : value_from
                            }
                            open={datePickerOpen === 1}
                            isEditable={true}
                            onChange={this.handleDateValueChange("value_from")}
                            toggle={this.toggleDatePicker(1)}
                            dataType={conditionDataType}
                          />
                          {valueFieldError === 1 && (
                            <p className="error-message">Please fill in the field</p>
                          )}
                        </div>
                        <div className={`text-field ${valueFieldError === 2 ? "error" : ""}`}>
                          <label>Until *</label>
                          <CustomDatePicker
                            value={
                              value_to && moment(value_to).isValid()
                                ? moment.utc(value_to).local()
                                : value_to
                            }
                            open={datePickerOpen === 2}
                            isEditable={true}
                            onChange={this.handleDateValueChange("value_to")}
                            toggle={this.toggleDatePicker(2)}
                            dataType={conditionDataType}
                          />
                          {valueFieldError === 2 && (
                            <p className="error-message">Please fill in the field</p>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                    {["int"].includes(conditionDataType) && (
                      <div className="int-values-fields">
                        <Field
                          component={TextField}
                          type="number"
                          placeholder="From"
                          name="value_from"
                          label="From *"
                        />
                        <Field
                          component={TextField}
                          type="number"
                          placeholder="To"
                          name="value_to"
                          label="To *"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="form-row"></div>
            </Form>
            <div className="preview">
              <span className="label">Preview example:</span>
              <DummyInsight
                id={1}
                name={name}
                funcType={funcTypeValue}
                compareValue={
                  SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value === funcTypeValue
                    ? [value_from, value_to]
                    : value
                }
                description={description}
                attribute={attribute}
                subAttribute={
                  subAttribute
                    ? {
                        id: subAttribute.value,
                        name: subAttribute.label,
                        data_type: subAttribute.data_type,
                      }
                    : null
                }
                color={color}
                displayType={tileType}
                count={count ? _toInteger(count) : null}
                hideDescription={false}
              />
            </div>
          </div>
        </Paper>
        <div className="insight-form-color-picker">
          <div className="colors-box">
            <p className="label-like">Pick color</p>
            <Field name="color" colors={OPTION_GROUP_COLORS} component={ColorRadioGroup} />
          </div>
          <div className="buttons">
            <Button color="grey" size="md" variant="outlined" onClick={this.clearColor}>
              Clear
            </Button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const validate = values => {
  let errors = {}

  if (!_get(values, "attribute_id")) {
    _set(errors, "attribute_id", "Please select the attribute")
  }
  if (!_get(values, "name")) {
    _set(errors, "name", "Please fill in the field")
  }
  const funcType = _get(values, "function")
  if (!funcType) {
    _set(errors, "function", "Please select the function")
  } else {
    if (["lower_than", "greater_than", "contains", "count"].includes(_get(funcType, "value"))) {
      if (!_get(values, "value")) {
        _set(errors, "value", "Please fill in the field")
      }
    }
    if (["most_common", "least_common", "unique_values"].includes(_get(funcType, "value"))) {
      const countValue = _get(values, "count")
      if (!countValue) {
        _set(errors, "count", "Please fill in the field")
      } else if (countValue < 1) {
        _set(errors, "count", "Count must be greater than 0")
      } else if (countValue > 5) {
        _set(errors, "count", "Count must be lower than 6")
      }
    }
    if (["between"].includes(_get(funcType, "value"))) {
      const fromValue = _get(values, "value_from")
      const toValue = _get(values, "value_to")
      if (!fromValue) {
        _set(errors, "value_from", "Please fill in the field")
      }
      if (!toValue) {
        _set(errors, "value_to", "Please fill in the field")
      }
    }
  }
  return errors
}

InsightForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  attributesMapById: PropTypes.object.isRequired,
}

const selector = formValueSelector("CreateInsightForm")
InsightForm = connect(state => ({
  attributeId: selector(state, "attribute_id"),
  subAttribute: selector(state, "subattribute"),
  name: selector(state, "name"),
  funcType: selector(state, "function"),
  description: selector(state, "description"),
  value: selector(state, "value"),
  value_from: selector(state, "value_from"),
  value_to: selector(state, "value_to"),
  color: selector(state, "color"),
  count: selector(state, "count"),
  tileType: selector(state, "tile_type"),
  submitErrors: getFormSyncErrors("CreateInsightForm")(state),
}))(InsightForm)

InsightForm = reduxForm({
  form: "CreateInsightForm",
  touchOnBlur: false,
  enableReinitialize: true,
  validate,
})(InsightForm)

export default props => {
  const { data: attributesMap = {} } = useFetchAttributesMap()

  return <InsightForm {...props} attributesMapById={attributesMap} />
}
