import React from "react"
import { Route, Switch } from "react-router-dom"

import { getRoutePath } from "routes"
import MobilePushNotificationCreate from "./MobilePushNotificationCreate/MobilePushNotificationsCreate"
import MobilePushNotificationDetail from "./MobilePushNotificationDetail/MobilePushNotificationDetail"
import MobilePushNotificationsList from "./MobilePushNotificationsList/MobilePushNotificationsList"
import Trash from "pages/Trash/Trash"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"

export default function MobilePushNotificationsRoutes() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("channels.mobile-push")}
        exact
        component={MobilePushNotificationsList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.mobilePushNotifications.view}
        path={getRoutePath("channels.mobile-push.trash")}
        exact
        render={() => <Trash itemType="push_notifications" />}
      />
      <Route
        path={getRoutePath("channels.mobile-push.create")}
        component={MobilePushNotificationCreate}
      />
      <Route
        path={getRoutePath("channels.mobile-push.detail")}
        exact
        component={MobilePushNotificationDetail}
      />
    </Switch>
  )
}
