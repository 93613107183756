import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import _get from "lodash/get"
import { Iterable } from "immutable"

import "./SourceCreate.scss"
import Page from "components/UI/Page/Page"
import { useCreateDataSource } from "resources/dataSource/dataSourceQueries"

class SourceCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating: false,
    }
  }

  createSource = async data => {
    if (!this.state.creating) {
      this.setState({ creating: true })
      const { history } = this.props
      try {
        await this.props.createDataSource({ data })
        history.push(getRoutePath("administration.sources"))
      } catch {
        this.setState({ creating: false })
      }
    }
  }

  render() {
    const { history, location } = this.props
    const { creating } = this.state

    let formInitValues = {}
    const source = _get(location, "state.source")
    if (Iterable.isIterable(source)) {
      formInitValues.id = source.id
      formInitValues.name = source.name
      formInitValues.type = source.type
      formInitValues.description = source.description
      formInitValues.icon = source.frontend_settings?.icon ?? source.type
      formInitValues.color = source.frontend_settings?.color ?? "primary"
    }

    return (
      <Page
        title="Create source"
        className="create-source"
        headerContent={
          <div className="header-buttons">
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.sources"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={creating} formName="SourceForm" />
          </div>
        }
      >
        <Paper className="create-source-content">
          <SourceForm onSubmit={this.createSource} initialValues={formInitValues} />
        </Paper>
      </Page>
    )
  }
}

export default props => {
  const { mutateAsync } = useCreateDataSource()

  return <SourceCreate {...props} createDataSource={mutateAsync} />
}
