import React, { Component } from "react"
import moment from "moment"
import Paper from "components/UI/elements/Paper"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"
import { MOMENT } from "sharedConstants"
import "./Sources.scss"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

class SourcesDestinations extends Component {
  render() {
    const { sources, areSourcesFulfilled } = this.props

    let dataSourcesList = null

    if (areSourcesFulfilled) {
      if (sources.length > 0) {
        dataSourcesList = sources.map(source => {
          return (
            <Tr key={source.id}>
              <Td className="icon">
                <SrcDstIcon source={source} />
              </Td>
              <Td
                className="name"
                textBigger
                textBlack
                textBold
                withNewBadge={moment().diff(source.created, "days") < 8}
              >
                {source.name}
              </Td>
              <Td className="description">{source.description ? `${source.description}` : "—"}</Td>
              <Td textAlignRight className="created">
                {moment.utc(source.created).local().format(MOMENT.DATE_FORMAT)}
              </Td>
            </Tr>
          )
        })
      }
    }

    return (
      <Page className="sources-destinations" title="Sources">
        {!areSourcesFulfilled && <LoadingIndicator />}
        {areSourcesFulfilled && (
          <Paper noPaddingTop>
            {dataSourcesList !== null && (
              <Table className="sources-table">
                <Thead stickyHeader="sources-table-sticky-header">
                  <Th />
                  <Th className="service-name">Source</Th>
                  <Th className="description-col">description</Th>
                  <Th className="align-right date-added">date added</Th>
                </Thead>
                <Tbody>{dataSourcesList}</Tbody>
              </Table>
            )}
            {!dataSourcesList && <RowMessage>No connected source found.</RowMessage>}
          </Paper>
        )}
      </Page>
    )
  }
}

export default props => {
  const { data = [], isSuccess } = useFetchAllDataSources()

  return <SourcesDestinations {...props} sources={data} areSourcesFulfilled={isSuccess} />
}
