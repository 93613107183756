import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import styles from "./SystemMessage.module.scss"

type Props = {
  text: string
  onDismiss: () => void
  className?: string
}

const SystemMessage = ({ className, text, onDismiss }: Props) => (
  <div className={classNames(styles.root, className)}>
    <div className={styles.textWrapper}>
      <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className={styles.icon} />
      <span>{text}</span>
    </div>
    <button onClick={onDismiss}>Dismiss</button>
  </div>
)

export default SystemMessage
