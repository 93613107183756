import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, formValueSelector } from "redux-form"
import PropTypes from "prop-types"

// ui components
import TagTextField from "components/UI/elements/TagTextField/TagTextField"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"

// constants helpers
import { required } from "helpers/validators.helper"
import { OPTION_GROUP_COLORS } from "sharedConstants"

class TagForm extends PureComponent {
  submitForm = values => {
    const { onSubmit } = this.props
    const dataToSend = { name: values.name, color: values.color }
    onSubmit(dataToSend)
  }

  render() {
    const { handleSubmit, type, selectedColor } = this.props
    const isCreate = type === "CREATE"

    return (
      <Form className="tag-form" autoComplete="off" onSubmit={handleSubmit(this.submitForm)}>
        <div className="custom-text-field">
          <Field
            placeholder="Tag name"
            name="name"
            component={TagTextField}
            label={isCreate ? "Create tag" : "Modify tag"}
            validate={required}
            color={selectedColor ?? "white"}
            autoFocus
          />
        </div>
        <div className="colors-buttons">
          <div className="colors-box">
            <p className="label-like">Pick color</p>
            <Field
              name="color"
              colors={OPTION_GROUP_COLORS}
              component={ColorRadioGroup}
              validate={required}
            />
          </div>
        </div>
      </Form>
    )
  }
}

TagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  type: PropTypes.string.isRequired,
}

const selector = formValueSelector("TagForm")
TagForm = connect(state => ({
  selectedColor: selector(state, "color"),
}))(TagForm)

TagForm = reduxForm({
  form: "TagForm",
  touchOnBlur: false,
  enableReinitialize: true,
})(TagForm)

export default TagForm
