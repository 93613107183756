import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Segment } from "resources/segment/segment/segmentTypes"
import { User } from "resources/user/userTypes"
import { Permission } from "types/util"
import { SegmentPermission, SegmentUserListResponse } from "./segmentPermissionTypes"

const segmentPermission = {
  listAllBySegmentId(segmentId: Segment["id"]): Promise<SegmentPermission[]> {
    return fetchAll<SegmentPermission, SegmentUserListResponse, "segment_users">({
      fetchFn: (offset, limit) =>
        requestFactory("get", `/acl/segments/${segmentId}`, {
          offset,
          limit,
        }),
      key: "segment_users",
    })
  },
  async listAllByUserId(userId: User["id"]): Promise<SegmentPermission[]> {
    return (await requestFactory("get", `/acl/users/${userId}`)).user_segments
  },
  createManyByUserId(
    userId: User["id"],
    segmentPermissions: Record<Segment["id"], Permission>[],
  ): Promise<void> {
    return requestFactory("post", `/acl/users/${userId}`, segmentPermissions)
  },
  async createManyBySegmentId(
    segmentId: Segment["id"],
    segmentPermissions: { user_id: User["id"]; permission: Permission }[],
  ): Promise<SegmentPermission[]> {
    return (
      await Promise.all(
        segmentPermissions.map(segmentPermission =>
          requestFactory("post", `/acl/segments/${segmentId}`, segmentPermission),
        ),
      )
    ).map(({ segment_user }) => segment_user)
  },
  async modifyManyBySegmentId(
    segmentId: Segment["id"],
    segmentPermissions: { user_id: User["id"]; permission: Permission }[],
  ): Promise<SegmentPermission[]> {
    return (
      await Promise.all(
        segmentPermissions.map(({ user_id, permission }) =>
          requestFactory("patch", `/acl/segments/${segmentId}/users/${user_id}`, { permission }),
        ),
      )
    ).map(({ segment_user }) => segment_user)
  },
  async deleteManyBySegmentId(segmentId: Segment["id"], userIds: User["id"][]): Promise<void> {
    return void (await Promise.all(
      userIds.map(userId => requestFactory("delete", `/acl/segments/${segmentId}/users/${userId}`)),
    ))
  },
  // create(userId: User["id"], segmentId: Segment["id"], permission: Permission) {
  //   return requestFactory("post", `/acl/segments/${segmentId}`, { user_id: userId, permission })
  // },
  // modify(userId: User["id"], segmentId: Segment["id"], permission: Permission) {
  //   return requestFactory("patch", `/acl/segments/${segmentId}/users/${userId}`, { permission })
  // },
  // delete(userId: User["id"], segmentId: Segment["id"]): Promise<void> {
  //   return requestFactory("delete", `/acl/segments/${segmentId}/users/${userId}`)
  // },
}

export default segmentPermission
