import React from "react"
import PropTypes from "prop-types"

import IconButton from "../IconButton/IconButton"

import "./SearchField.scss"

const SearchField = ({
  input,
  placeholder,
  onClear,
  fullWidth = false,
  onChange = undefined,
  autoFocus = false,
  size = "large",
  wrapperClassName = "",
  ...fieldAttrs
}) => {
  return (
    <div className={`search-field-wraper ${size} ${wrapperClassName}`} data-testid="wrapper">
      <div className="text-field search-field">
        <input
          {...input}
          onChange={evt => {
            input.onChange(evt.target.value)
            onChange?.()
          }}
          placeholder={placeholder}
          autoFocus={autoFocus}
          data-testid="input-field"
          className={fullWidth ? "full-width" : "shrinked"}
          autoComplete="off"
          {...fieldAttrs}
        />
        {input.value && onClear && (
          <IconButton
            color="black"
            className="clear-button"
            data-testid="clear-button"
            onClick={onClear}
            icon="times"
            iconStyle="far"
            variant="transparent"
          />
        )}
        <IconButton
          icon="search"
          className="search-button"
          data-testid="search-button"
          size="md"
          type="submit"
        />
      </div>
    </div>
  )
}

SearchField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
}

export default SearchField
