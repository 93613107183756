import React, { useMemo } from "react"
import { Field } from "redux-form"
import TextField from "../../elements/TextInput/ReduxFormTextField"
import { required, minLength } from "helpers/validators.helper"
import "./PasswordField.scss"

type PasswordFieldProps = {
  passwordFieldLabel: string
  autoFocus: boolean
  placeholder?: string
}

export default function PasswordField({
  passwordFieldLabel,
  autoFocus,
  placeholder,
}: PasswordFieldProps) {
  // redux-form needs stable reference for validation fn, otherwise it ends up in infinite loop
  const minLength12 = useMemo(() => minLength(12), [])

  return (
    <div className="password-field-wrapper">
      <Field
        name="password"
        component={TextField}
        label={passwordFieldLabel}
        validate={[required, minLength12]}
        placeholder={placeholder ?? "Password"}
        type="password"
        autoFocus={autoFocus}
      />
    </div>
  )
}
