import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import SelectField from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { required } from "helpers/validators.helper"
import { useCallback, useEffect, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import styles from "./PopupWebBannerSettingsForm.module.scss"
import _toInteger from "lodash/toInteger"
import classnames from "classnames"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import {
  useFetchPopupWBSettings,
  useModifyPopupWBSettings,
} from "resources/webBanner/popupWebBanner/popupWBQueries"
import {
  PopupWebBannerSettingsModifyPayload,
  PWBFrequencyCapType,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import { FREQUENCY_CAP_TYPE_OPTIONS } from "pages/Channels/PopupWebBanners/utilities/dropdownOptions"
import { SelectOption } from "types/util"
import { useHasAccess } from "resources/user/currentUserQueries"
import IconButton from "components/UI/elements/IconButton/IconButton"

type SettingsFormValues = {
  enabled: boolean
  frequency_cap_max_count: number
  frequency_cap_size: number | null
  frequency_cap_type: SelectOption<PWBFrequencyCapType>
  page_views_count: number
}

export default function PopupWebBannerSettingsForm() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<SettingsFormValues>()
  const [settingsExpanded, setSettingsExpanded] = useState(false)
  const frequencyCapType = watch("frequency_cap_type")

  const { data: settings, isLoading } = useFetchPopupWBSettings()
  const modifyMutation = useModifyPopupWBSettings()

  const isEditable = useHasAccess().webBanners.edit

  const setFrequencyCapType = frequencyCapType
    ? frequencyCapType
    : FREQUENCY_CAP_TYPE_OPTIONS.find(opt => opt.value === settings?.frequency_cap.type)

  useEffect(() => {
    if (frequencyCapType?.value === "session") {
      setValue("frequency_cap_size", null)
      clearErrors("frequency_cap_size")
    }
  }, [frequencyCapType, setValue, clearErrors])

  const frequencyCapPeriodValidation = useCallback(
    value => {
      if (!setFrequencyCapType || setFrequencyCapType?.value === "session") {
        return undefined
      } else {
        return required(value)
      }
    },
    [setFrequencyCapType],
  )

  const submitForm: SubmitHandler<SettingsFormValues> = useCallback(
    ({
      enabled,
      frequency_cap_max_count,
      frequency_cap_size,
      frequency_cap_type,
      page_views_count,
    }) => {
      if (modifyMutation.isLoading) {
        return
      }

      const data: PopupWebBannerSettingsModifyPayload = {
        frequency_cap:
          frequency_cap_type.value === "session"
            ? {
                enabled,
                max_display_count: _toInteger(frequency_cap_max_count),
                type: frequency_cap_type.value,
              }
            : {
                enabled,
                max_display_count: _toInteger(frequency_cap_max_count),
                type: frequency_cap_type.value,
                size: frequency_cap_size!,
              },
        page_views_count,
      }

      modifyMutation.mutate({ data })
    },
    [modifyMutation],
  )

  return (
    <Paper className={styles.settingsForm}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form className={styles.formContent} onSubmit={handleSubmit(submitForm)}>
          <div className={styles.formHeader}>
            <h3>Global settings</h3>
            <div>
              {settingsExpanded && (
                <Button
                  className={styles.submitButton}
                  loading={modifyMutation.isLoading}
                  type="submit"
                  color="green"
                  disabled={!isEditable}
                >
                  Save
                </Button>
              )}
              <IconButton
                color="grey"
                icon={settingsExpanded ? "caret-up" : "caret-down"}
                variant="transparent"
                onClick={() => setSettingsExpanded(prevState => !prevState)}
                className={styles.expandButton}
              />
            </div>
          </div>
          {settingsExpanded && (
            <>
              <div className={styles.formRow}>
                <div className={styles.formContentLeft}>
                  <div>
                    <Controller
                      name="enabled"
                      control={control}
                      defaultValue={settings?.frequency_cap.enabled}
                      render={({ field }) => (
                        <ToggleButton
                          value={field.value}
                          handleToggle={() => {
                            setValue("enabled", !field.value)
                          }}
                          size="sm"
                          disabled={!isEditable}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.settingTexts}>
                    <h3>
                      Global count for all pop-up web banners per user{" "}
                      <InfoTooltip placement="top" className={styles.tooltip} interactive>
                        <div className={styles.tooltipWarning}>
                          <p>
                            <strong>Warning: </strong>the number of impressions is stored in the
                            browser's local storage. If the local storage is cleared, the user might
                            see the banner more times. More info{" "}
                            <a
                              href="https://www.cookiestatus.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </InfoTooltip>
                    </h3>
                    <p>
                      The rule works across all banners. It limits the number of banners displayed
                      for 1 user.
                    </p>
                  </div>
                </div>
                <div className={styles.formFields}>
                  <div className={styles.frequencyCapCountFieldWrapper}>
                    <TextInput
                      type="number"
                      error={errors.frequency_cap_max_count?.message}
                      min={1}
                      max={50}
                      step={1}
                      className={styles.frequencyCapCountField}
                      defaultValue={settings?.frequency_cap.max_display_count}
                      disabled={!isEditable}
                      {...register("frequency_cap_max_count", { validate: required })}
                    />
                    <div className={styles.afterFieldLabel}>times</div>
                  </div>
                  <span>during period</span>
                  <TextInput
                    type="number"
                    error={errors.frequency_cap_size?.message}
                    placeholder={
                      setFrequencyCapType?.value === "hours"
                        ? "From 1 to 168"
                        : setFrequencyCapType?.value === "days"
                        ? "From 1 to 7"
                        : ""
                    }
                    min={1}
                    max={setFrequencyCapType?.value === "hours" ? 168 : 7}
                    step={1}
                    disabled={
                      !isEditable ||
                      (setFrequencyCapType
                        ? setFrequencyCapType.value === "session"
                        : settings?.frequency_cap.type === "session")
                    }
                    className={styles.frequencyCapSizeField}
                    defaultValue={
                      settings?.frequency_cap.type === "session" ? "" : settings?.frequency_cap.size
                    }
                    {...register("frequency_cap_size", {
                      validate: frequencyCapPeriodValidation,
                    })}
                  />
                  <Controller
                    name="frequency_cap_type"
                    control={control}
                    rules={{ validate: required }}
                    defaultValue={FREQUENCY_CAP_TYPE_OPTIONS.find(
                      opt => opt.value === settings?.frequency_cap.type,
                    )}
                    render={({ field }) => (
                      <SelectField
                        input={field}
                        label=""
                        options={FREQUENCY_CAP_TYPE_OPTIONS}
                        className={styles.frequencyCapTypeField}
                        disabled={!isEditable}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formContentLeft}>
                  <div
                    className={classnames(styles.settingTexts, styles.pageViewsCountSettingText)}
                  >
                    <h3>Show pop-up web banner after number of page views</h3>
                    <p>
                      The rule works across all banners. It specifies after how many page views
                      during 1 session banners will be displayed.
                    </p>
                  </div>
                </div>
                <div className={styles.formFields}>
                  <div className={styles.inlineLabelField}>
                    <label>After</label>
                    <div className={styles.pageViewsCountFieldWrapper}>
                      <TextInput
                        type="number"
                        error={errors.page_views_count?.message}
                        min={0}
                        max={999}
                        step={1}
                        className={styles.pageViewsCountField}
                        defaultValue={settings?.page_views_count}
                        disabled={!isEditable}
                        {...register("page_views_count", { validate: required })}
                      />
                      <div className={styles.afterFieldLabel}>page view(s)</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </Paper>
  )
}
