import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { differenceInDays } from "date-fns"
import { append, update, whereEq } from "ramda"
import { useParams } from "react-router-dom"

import AttributeBadge from "components/UI/elements/AttributeBadge"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { isJSONString } from "helpers/validators.helper"
import { useFetchAttributesMapBySourceId } from "resources/attribute/attributeQueries"
import { AttributeFull } from "resources/attribute/attributeTypes"
import {
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import {
  useFetchCustomerAttribute,
  useFetchCustomerAttributePaginated,
  useFetchCustomerAttributes,
} from "resources/customer/attribute/customerAttributeQueries"
import { Source } from "resources/dataSource/dataSourceTypes"
import { Layout } from "../types"

import styles from "./SourceBoxes.module.scss"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable/CompoundAttributeValuesTable"

type SourceBoxesProps = {
  layout: Layout
  sources: Array<Source>
}

export const ATTRIBUTE_VALUES_MAX_COUNT = 10

export default function SourceBoxes({ layout, sources }: SourceBoxesProps) {
  const [boxHeights, setBoxHeights] = useState<Array<{ id: Source["id"]; height: number }>>([])

  const setSourceHeight = (sourceId: Source["id"], height: number) => {
    setBoxHeights(prev => {
      const index = prev.findIndex(whereEq({ id: sourceId }))

      return index !== -1
        ? update(index, { height, id: sourceId }, prev)
        : append({ height, id: sourceId }, prev)
    })
  }

  if (sources.length === 0)
    return (
      <Paper className={styles.noResultsFound}>
        <p>No source selected.</p>
      </Paper>
    )

  const invisible = boxHeights.length === 0

  if (invisible)
    return (
      <>
        {sources.map(source => (
          <SourceBox
            key={source.id}
            invisible={invisible}
            source={source}
            setHeight={height => setSourceHeight(source.id, height)}
          />
        ))}
      </>
    )
  else {
    if (layout === "two-cols") {
      let leftHeight = 0,
        rightHeight = 0

      const left: Array<Source> = [],
        right: Array<Source> = []

      sources.forEach(source => {
        const height = boxHeights.find(whereEq({ id: source.id }))?.height ?? 0

        if (leftHeight <= rightHeight) {
          left.push(source)
          leftHeight += height
        } else {
          right.push(source)
          rightHeight += height
        }
      })

      return (
        <div className={styles.twoCols}>
          <div className={styles.left}>
            {left.map(source => {
              const height = boxHeights.find(whereEq({ id: source.id }))?.height ?? 0

              return (
                <SourceBox
                  key={source.id}
                  height={height}
                  source={source}
                  setHeight={newHeight => setSourceHeight(source.id, newHeight)}
                />
              )
            })}
          </div>
          <div className={styles.right}>
            {right.map(source => {
              const height = boxHeights.find(whereEq({ id: source.id }))?.height ?? 0

              return (
                <SourceBox
                  key={source.id}
                  height={height}
                  source={source}
                  setHeight={newHeight => setSourceHeight(source.id, newHeight)}
                />
              )
            })}
          </div>
        </div>
      )
    } else
      return (
        <>
          {sources.map(source => {
            const height = boxHeights.find(whereEq({ id: source.id }))?.height ?? 0

            return (
              <SourceBox
                key={source.id}
                height={height}
                source={source}
                setHeight={newHeight => setSourceHeight(source.id, newHeight)}
              />
            )
          })}
        </>
      )
  }
}

type SourceBoxProps = {
  source: Source
  height?: number
  invisible?: boolean
  setHeight?: (height: number) => void
}

const SourceBox = ({ height, setHeight, source, invisible = false }: SourceBoxProps) => {
  const { id } = useParams<{ id: string }>()

  const [expanded, setExpanded] = useState(true)
  const [showEmpty, setShowEmpty] = useState(false)

  const paperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const toggle = (expand: boolean) => {
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight

      if (expand) {
        contentRef.current.style.height = `${scrollHeight}px`
        contentRef.current.style.borderColor = ""
        contentRef.current.addEventListener("transitionend", function callback() {
          contentRef.current!.removeEventListener("transitionend", callback)
          contentRef.current!.style.height = ""
        })

        setExpanded(true)
      } else {
        requestAnimationFrame(() => {
          contentRef.current!.style.height = `${scrollHeight}px`
          requestAnimationFrame(() => {
            contentRef.current!.style.height = "0px"
            contentRef.current!.style.borderColor = "transparent"
          })
        })

        setExpanded(false)
      }
    }
  }

  const { data: attributesMapBySourceId = {} } = useFetchAttributesMapBySourceId()
  const { data: customerAttributes } = useFetchCustomerAttributes({
    attribute_values_max_count: ATTRIBUTE_VALUES_MAX_COUNT,
    customer_entity_id: id,
    load_full_structure: 0,
  })

  const sourceAttributes = attributesMapBySourceId[source.id]
  const noFilledAttributes = !sourceAttributes.some(({ id }) => {
    const attributeValues =
      customerAttributes?.customer_attributes
        .filter(whereEq({ attribute_id: id }))
        .map(({ value }) => value) ?? []

    return attributeValues.length > 0
  })

  if (!sourceAttributes) return null

  return (
    <Paper
      key={source.id}
      className={classNames(
        styles.customerAttributesPaper,
        { [styles.invisible]: invisible },
        styles[source.frontend_settings?.color],
      )}
      ref={paperRef}
      onInit={() => {
        if (!height && paperRef.current && setHeight)
          setHeight(paperRef.current.getBoundingClientRect().height)
      }}
    >
      <div className={styles.boxHeader}>
        <h3>{source.name}</h3>
        <div className={styles.boxHeaderActions}>
          <div className={styles.hideEmptyFields}>
            <span className={classNames(styles.label, { [styles.orange]: showEmpty })}>
              Show empty fields
            </span>
            <ToggleButton
              value={showEmpty}
              handleToggle={() => setShowEmpty(prev => !prev)}
              size="xs"
            />
          </div>
          <IconButton
            color="black"
            className={styles.caret}
            onClick={() => toggle(!expanded)}
            icon={!expanded ? "caret-up" : "caret-down"}
            variant="transparent"
          />
        </div>
      </div>
      <div className={styles.boxContent} ref={contentRef}>
        {sourceAttributes.length > 0 && (
          <div className={styles.customerAttributesValues}>
            {!showEmpty && noFilledAttributes && (
              <div className={styles.row}>No filled attributes in data source.</div>
            )}

            {sourceAttributes.map(sourceAttribute => {
              const values =
                customerAttributes?.customer_attributes
                  .filter(whereEq({ attribute_id: sourceAttribute.id }))
                  .map(({ value }) => value) ?? []

              return (
                <AttributeRow
                  key={sourceAttribute.id}
                  attribute={sourceAttribute}
                  showEmpty={showEmpty}
                  values={values}
                />
              )
            })}
          </div>
        )}

        {sourceAttributes.length === 0 && <p>No attribute was found.</p>}
      </div>
    </Paper>
  )
}

type AttributeRowProps = {
  attribute: AttributeFull
  values: Array<any>
  showEmpty?: boolean
}

const AttributeRow = ({ attribute, values, showEmpty = false }: AttributeRowProps) => {
  const hasValue = values.length > 0
  if (!hasValue && !showEmpty) return null

  const compoundAttributeView = isAttributeCompound(attribute.data_type)
  const firstCompoundAttributeValue = values[0]

  let valueDOM = null
  if (compoundAttributeView && isJSONString(firstCompoundAttributeValue))
    valueDOM = (
      <>
        {hasValue ? (
          <CompoundAttributeValue
            data_type={attribute.data_type}
            id={attribute.id}
            initialValues={values}
          />
        ) : (
          "-"
        )}
      </>
    )
  else {
    valueDOM = (
      <div className={styles.valueWrapper}>
        {hasValue && values.length > 1 ? (
          <AttributeArrayValue
            data_type={attribute.data_type}
            id={attribute.id}
            initialValues={values}
          />
        ) : hasValue ? (
          <AttributeValue data_type={attribute.data_type} value={values[0]} />
        ) : (
          "—"
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.row, {
        [styles.compoundAttrView]: compoundAttributeView && hasValue,
      })}
      key={attribute.id}
    >
      <div className={classNames(styles.attrname, { [styles.noValue]: !hasValue })}>
        <div className={styles.nameTags}>
          <span className={styles.nameWrapper}>
            <span className={styles.name}>{attribute.name}</span>
            {differenceInDays(new Date(), new Date(attribute.created)) < 8 && (
              <AttributeBadge text="new" />
            )}
          </span>
        </div>
      </div>
      <div
        className={classNames(styles.attrvalue, "text-grey", "align-right", {
          "no-value": !hasValue,
        })}
      >
        {valueDOM}
      </div>
    </div>
  )
}

type CompoundAttributeValueProps = Pick<AttributeFull, "data_type" | "id"> & {
  initialValues: Array<any>
}

const CompoundAttributeValue = ({
  data_type,
  initialValues,
  id: attributeId,
}: CompoundAttributeValueProps) => {
  const { id } = useParams<{ id: string }>()

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState<number | undefined>()

  const { data, isFetching, isPreviousData } = useFetchCustomerAttributePaginated(
    {
      attribute_id: attributeId,
      customer_entity_id: id,
      limit: ATTRIBUTE_VALUES_MAX_COUNT,
      offset: (page - 1) * ATTRIBUTE_VALUES_MAX_COUNT,
    },
    {
      enabled: page > 1,
      placeholderData: {
        customer_attribute_values: initialValues,
        selection_settings: {
          limit: ATTRIBUTE_VALUES_MAX_COUNT,
          offset: 0,
        },
      },
    },
  )

  useEffect(() => {
    if (!data) return

    if (data?.customer_attribute_values.length === 0) {
      if (page > 1) {
        setLastPage(page - 1)
        setPage(page - 1)
      }
    } else if (data?.customer_attribute_values.length < ATTRIBUTE_VALUES_MAX_COUNT) {
      if (page > 1) setLastPage(page)
    }
  }, [data, page, lastPage])

  const subAttributes = getCompoundAttributeSubAttributes(data_type)
  const values = page === 1 ? initialValues : data?.customer_attribute_values ?? []
  const valuesList = values.map((val: any) => JSON.parse(val)) ?? []

  const isFirstPageLastPage = initialValues.length < ATTRIBUTE_VALUES_MAX_COUNT || lastPage === 1

  return (
    <CompoundAttributeValuesTable
      subAttributes={subAttributes}
      values={valuesList}
      page={isFirstPageLastPage ? undefined : page}
      lastPage={lastPage}
      setPage={setPage}
      isLoading={isPreviousData && isFetching}
    />
  )
}

type AttributeArrayValueProps = Pick<AttributeFull, "data_type" | "id"> & {
  initialValues: Array<any>
}

const AttributeArrayValue = ({
  data_type,
  id: attributeId,
  initialValues,
}: AttributeArrayValueProps) => {
  const { id } = useParams<{ id: string }>()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useFetchCustomerAttribute(
    {
      attribute_id: attributeId,
      customer_entity_id: id,
      limit: ATTRIBUTE_VALUES_MAX_COUNT,
    },
    {
      initialData: {
        pageParams: [0],
        pages: [
          {
            customer_attribute_values: initialValues,
            selection_settings: { limit: ATTRIBUTE_VALUES_MAX_COUNT, offset: 0 },
          },
        ],
      },
    },
  )

  return (
    <div className={styles.valueWrapper}>
      {data.map((value: any, index: number) => (
        <AttributeValue key={index} data_type={data_type} value={value} />
      ))}
      {hasNextPage && (
        <Button
          loading={isFetchingNextPage}
          color="grey"
          variant="outlined"
          className={styles.showMoreAttributes}
          onClick={_ => fetchNextPage()}
        >
          Show more
        </Button>
      )}
    </div>
  )
}

type AttributeValueProps = Pick<AttributeFull, "data_type"> & {
  value: any
}

const AttributeValue = ({ data_type, value }: AttributeValueProps) => {
  const imgPrefix = "img::"
  const linkPrefix = "link::"
  const isImg = value?.startsWith?.(imgPrefix)
  const isLink = value?.startsWith?.(linkPrefix)
  let content = getUserFriendlyValueFormat(value, data_type)

  if (isImg) {
    content = <img src={value.replace(new RegExp(`^${imgPrefix}`), "")} alt="" />
  }
  if (isLink) {
    const link = value.replace(new RegExp(`^${linkPrefix}`), "")
    content = (
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    )
  }

  return <span className="customer-attribute-value">{content}</span>
}
