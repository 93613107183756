import { useInfiniteQuery } from "@tanstack/react-query"

import { api } from "api"
import { CustomerSegmentListResponse } from "./customerSegmentTypes"

export const useFetchCustomerSegments = (id: string) => {
  const { data, ...rest } = useInfiniteQuery<CustomerSegmentListResponse>(
    ["customer", id, "segments"],
    ({ pageParam }) => api.customerSegment.list({ id, limit: 10, offset: pageParam ?? 0 }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_segments.length < last.selection_settings.limit ||
          (last.customer_segments.length === last.selection_settings.limit &&
            last.customer_segments.length + last.selection_settings.offset === last.total_count)
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.customer_segments) : [] }
}
