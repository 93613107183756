import create from "zustand"

import { Segment } from "resources/segment/segment/segmentTypes"

type ProfileIteratorStore = {
  index: number | null
  segmentId: Segment["id"] | null
  setIndex: (index: number) => void
  setSegmentId: (id: Segment["id"]) => void
  reset: () => void
}

export const useProfileIteratorStore = create<ProfileIteratorStore>(set => ({
  index: null,
  segmentId: null,
  setIndex: index =>
    set(() => ({
      index,
    })),
  setSegmentId: id =>
    set({
      segmentId: id,
    }),
  reset: () =>
    set(() => ({
      index: null,
      segmentId: null,
    })),
}))
