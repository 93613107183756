import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm from "../components/AttributeForm/AttributeForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { connect } from "react-redux"
import { showToast } from "actions/toast.action"
import { getInitialAttributeFormValues } from "../components/AttributeForm/attributeInitialValues"
import "./AttributeDetail.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributeById, useModifyAttribute } from "resources/attribute/attributeQueries"
import { useHistory, useParams } from "react-router-dom"
import Page from "components/UI/Page/Page"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"
import { useFetchAllEvents } from "resources/event/eventQueries"

class AttributeDetail extends Component {
  cloneAttribute = () => {
    const { history, attribute } = this.props
    history.push({
      pathname: getRoutePath("administration.attributes.create"),
      state: { attribute },
    })
  }

  render() {
    const {
      history,
      areAttributesFulfilled,
      attribute,
      areSourcesFulfilled,
      isSaving,
      modifyAttribute,
      toggleHidden,
      sourceOptions,
      areEventsFulfilled,
    } = this.props

    const formInitValues = getInitialAttributeFormValues(attribute, sourceOptions)

    return (
      <Page
        className="detail-attribute"
        title="Edit attribute"
        headerContent={
          <div className="header-buttons">
            {attribute && (
              <>
                <Button
                  color="red"
                  icon={attribute.is_hidden === 1 ? "eye" : "eye-slash"}
                  iconStyle="far"
                  variant="outlined"
                  onClick={toggleHidden}
                  disabled={isSaving}
                >
                  {attribute.is_hidden === 1 ? "Show" : "Hide"}
                </Button>
                <Button color="grey" icon="clone" variant="outlined" onClick={this.cloneAttribute}>
                  Copy
                </Button>
                <Button
                  color="grey"
                  variant="outlined"
                  onClick={() => {
                    history.push(getRoutePath("administration.attributes"))
                  }}
                >
                  Cancel
                </Button>
                <RemoteSubmitButton isLoading={isSaving} formName="AttributeForm" />
              </>
            )}
          </div>
        }
      >
        {areAttributesFulfilled && areSourcesFulfilled && areEventsFulfilled ? (
          <Paper className="detail-attribute-content">
            <AttributeForm
              onSubmit={modifyAttribute}
              initialValues={formInitValues}
              disabledFields={["id", "source_id", "is_unique", "data_type"]}
            />
          </Paper>
        ) : (
          <LoadingIndicator />
        )}
      </Page>
    )
  }
}

AttributeDetail = connect(null, { showToast })(AttributeDetail)

export default props => {
  const { id } = useParams()
  const history = useHistory()
  const { data: attribute, isSuccess } = useFetchAttributeById(id, {
    refetchOnMount: "always",
  })
  const { mutate, isLoading: isSaving } = useModifyAttribute()

  const modifyAttribute = data =>
    mutate(
      { id, data },
      { onSuccess: () => history.push(getRoutePath("administration.attributes")) },
    )
  const toggleHidden = () =>
    attribute && mutate({ id: attribute.id, data: { is_hidden: +!attribute.is_hidden } })

  const { data: sourceOptions, isSuccess: areSourcesFulfilled } = useFetchDataSourceOptions({
    showHidden: true,
  })

  const { isSuccess: areEventsFulfilled } = useFetchAllEvents()

  return (
    <AttributeDetail
      {...props}
      attribute={attribute}
      areAttributesFulfilled={isSuccess}
      modifyAttribute={modifyAttribute}
      toggleHidden={toggleHidden}
      isSaving={isSaving}
      sourceOptions={sourceOptions}
      areSourcesFulfilled={areSourcesFulfilled}
      areEventsFulfilled={areEventsFulfilled}
    />
  )
}
