import Tippy from "@tippyjs/react"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { abbreviateNumber } from "helpers/number.helper"
import { timeAgoFormatter } from "helpers/timeAgo.helper"
import moment from "moment"
import React, { useCallback } from "react"
import { useParams } from "react-router"
import ReactTimeago from "react-timeago"
import styles from "./SegmentNumbers.module.scss"
import { useQueryClient } from "@tanstack/react-query"
import { CHANNEL_SEGMENT_COUNTS_QK } from "resources/channel/channelQueries"
import {
  useFetchSegmentNumbers,
  useSegmentCountsStore,
} from "resources/segment/segment/segmentCounts"

function percentageToArcCoordinates(percentage: number) {
  const angle = percentage * (Math.PI / 100)
  const dX = (-Math.cos(angle) + 1) / 2
  const dY = Math.sin(angle)
  return { dX, dY }
}

type SegmentNumbersProps = {
  hasUnsavedConditions: boolean
}

export default function SegmentNumbers({ hasUnsavedConditions }: SegmentNumbersProps) {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  const { refetch } = useFetchSegmentNumbers(+id)
  const { conditionsResultsCount, customersTotalCount, dataLoadEndTime, dataLoadStartTime } =
    useSegmentCountsStore()

  const segmentedPercentage =
    conditionsResultsCount === undefined || customersTotalCount === undefined
      ? null
      : Math.round(100 * (conditionsResultsCount / customersTotalCount))

  const percentageDisplayString =
    conditionsResultsCount !== undefined &&
    customersTotalCount !== undefined &&
    segmentedPercentage !== null &&
    `${
      segmentedPercentage === 0 && conditionsResultsCount !== 0
        ? "<1"
        : segmentedPercentage === 100 && conditionsResultsCount < customersTotalCount
        ? ">99"
        : segmentedPercentage
    } %`

  // SVG for percentage arcs
  const { dX = 0, dY = 0 } =
    (segmentedPercentage && percentageToArcCoordinates(segmentedPercentage)) || {}

  const VIEWBOX_MARGIN = 15
  const ARC_HEIGHT = 140
  const ARC_WIDTH = 300

  const X1 = VIEWBOX_MARGIN
  const Y1 = VIEWBOX_MARGIN + ARC_HEIGHT
  const rX = ARC_WIDTH / 2
  const rY = ARC_HEIGHT
  const X2grey = VIEWBOX_MARGIN + ARC_WIDTH
  const Y2grey = VIEWBOX_MARGIN + ARC_HEIGHT
  const X2primary = VIEWBOX_MARGIN + ARC_WIDTH * dX
  const Y2primary = VIEWBOX_MARGIN + ARC_HEIGHT * (1 - dY)

  const greyArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2grey} ${Y2grey}`
  const primaryArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2primary} ${Y2primary}`
  const viewBox = `0 0 ${2 * VIEWBOX_MARGIN + ARC_WIDTH} ${2 * VIEWBOX_MARGIN + ARC_HEIGHT}`

  const refreshSegmentNumbers = useCallback(() => {
    refetch({ refreshCache: true })
    queryClient.refetchQueries(CHANNEL_SEGMENT_COUNTS_QK)
  }, [queryClient, refetch])

  return (
    <div className={styles.container}>
      <IconButton
        icon="sync-alt"
        iconStyle="far"
        tooltip="Refresh segment numbers"
        variant="outlined"
        className={styles.refreshIcon}
        disabled={dataLoadEndTime === undefined || hasUnsavedConditions}
        onClick={refreshSegmentNumbers}
      />
      <div className={styles.segmentedNumberWrapper}>
        <div className={styles.segmentedNumberInlineWrapper}>
          <span className={styles.segmentedNumber}>
            {conditionsResultsCount === undefined && <LoadingIndicator size="xs" fixedWidth />}

            {conditionsResultsCount !== undefined && hasUnsavedConditions && (
              <Tippy content="Save segment conditions to see segmentation numbers.">
                <span>N/A</span>
              </Tippy>
            )}

            {conditionsResultsCount !== undefined && !hasUnsavedConditions && (
              <span>{abbreviateNumber(conditionsResultsCount)}</span>
            )}
          </span>
          <span>out of </span>
          {customersTotalCount === undefined && <LoadingIndicator size="xs" fixedWidth />}

          {customersTotalCount !== undefined && hasUnsavedConditions && (
            <Tippy content="Save segment conditions to see segmentation numbers.">
              <span>N/A</span>
            </Tippy>
          )}

          {customersTotalCount !== undefined && !hasUnsavedConditions && (
            <span>{abbreviateNumber(customersTotalCount)}</span>
          )}
        </div>
        <div className={styles.dataRefreshed}>
          {dataLoadEndTime && (
            <>
              Data refreshed:&nbsp;
              <ReactTimeago
                key="finished"
                date={moment.utc(dataLoadEndTime).local().format("YYYY-MM-DD HH:mm:ss")}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
          {dataLoadEndTime === undefined && dataLoadStartTime && (
            <>
              Data refresh started:&nbsp;
              <ReactTimeago
                key="inProgress"
                date={moment.utc(dataLoadStartTime).local().format("YYYY-MM-DD HH:mm:ss")}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.percentageWrapper}>
        <div className={styles.percentage}>
          <div className={styles.percentageArcWrapper}>
            <svg viewBox={viewBox}>
              <path
                d={greyArcPath}
                strokeLinecap="round"
                strokeWidth="30"
                fill="none"
                stroke="#dddddd"
              />
              {!hasUnsavedConditions && segmentedPercentage && (
                <path
                  d={primaryArcPath}
                  strokeLinecap="round"
                  strokeWidth="30"
                  fill="none"
                  stroke="#fe7f66"
                />
              )}
            </svg>
          </div>

          {segmentedPercentage === null && <LoadingIndicator size="xs" fixedWidth />}

          {percentageDisplayString && hasUnsavedConditions && (
            <Tippy content="Save segment conditions to see segmentation numbers.">
              <span>N/A %</span>
            </Tippy>
          )}

          {percentageDisplayString && !hasUnsavedConditions && (
            <span>{percentageDisplayString}</span>
          )}
        </div>
        <div className={styles.percentageSubtitle}>of all customers</div>
      </div>
    </div>
  )
}
