import Paper from "components/UI/elements/Paper"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import Username from "components/Username/Username"
import styles from "./ChannelsList.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useFetchEmailChannel,
  useFetchPushNotificationsChannel,
} from "resources/channel/channelQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Page from "components/UI/Page/Page"
import { featureFlags } from "featureFlags"

export default function ChannelsList() {
  const { data: pushNotificationsChannel, isLoading: isLoadingPushNotifications } =
    useFetchPushNotificationsChannel()
  const { data: emailsChannel, isLoading: isLoadingEmails } = useFetchEmailChannel()

  const isLoading = isLoadingPushNotifications || isLoadingEmails
  return (
    <Page title="Channels">
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper hasHeader noPaddingTop={!!pushNotificationsChannel || !!emailsChannel}>
          {!pushNotificationsChannel && !emailsChannel ? (
            <RowMessage>No channels found.</RowMessage>
          ) : (
            <Table>
              <Thead stickyHeader>
                <Th>Name</Th>
                <Th>Is active</Th>
                <Th>Last modified</Th>
                <Th> </Th>
              </Thead>
              <Tbody>
                {pushNotificationsChannel && (
                  <Tr>
                    <Td textBold textBigger textBlack>
                      Mobile Push
                    </Td>
                    <Td>
                      {pushNotificationsChannel.is_active ? (
                        <FontAwesomeIcon className={styles.yesIcon} icon={["fas", "check"]} />
                      ) : (
                        <FontAwesomeIcon className={styles.noIcon} icon={["fas", "times"]} />
                      )}
                    </Td>
                    <Td>
                      {pushNotificationsChannel.modified && (
                        <span className={styles.modifiedAt}>
                          {format(
                            new Date(`${pushNotificationsChannel.modified}Z`),
                            DATEFNS.DATETIME_FORMAT,
                          )}
                        </span>
                      )}
                      {pushNotificationsChannel.modified_by && (
                        <span className={styles.modifiedBy}>
                          by <Username userId={pushNotificationsChannel.modified_by} />
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight>
                      <Link to={getRoutePath("administration.channels.push-notifications")}>
                        <IconButton
                          color="black"
                          size="xs"
                          iconStyle="fas"
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />
                      </Link>
                    </Td>
                  </Tr>
                )}
                {emailsChannel && featureFlags.EMAILS && (
                  <Tr>
                    <Td textBold textBigger textBlack>
                      Email
                    </Td>
                    <Td>
                      {emailsChannel.is_active ? (
                        <FontAwesomeIcon className={styles.yesIcon} icon={["fas", "check"]} />
                      ) : (
                        <FontAwesomeIcon className={styles.noIcon} icon={["fas", "times"]} />
                      )}
                    </Td>
                    <Td>
                      {emailsChannel.modified && (
                        <span className={styles.modifiedAt}>
                          {format(new Date(`${emailsChannel.modified}Z`), DATEFNS.DATETIME_FORMAT)}
                        </span>
                      )}
                      {emailsChannel.modified_by && (
                        <span className={styles.modifiedBy}>
                          by <Username userId={emailsChannel.modified_by} />
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight>
                      <Link to={getRoutePath("administration.channels.emails")}>
                        <IconButton
                          color="black"
                          size="xs"
                          iconStyle="fas"
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />
                      </Link>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </Paper>
      )}
    </Page>
  )
}
