import React from "react"
import { connect } from "react-redux"
import { submit } from "redux-form"

import Button from "components/UI/elements/Button/Button"

const RemoteSubmitButton = connect()(({ dispatch, isLoading, formName }) => (
  <Button icon="save" loading={isLoading} onClick={() => dispatch(submit(formName))}>
    Save
  </Button>
))

export default RemoteSubmitButton
