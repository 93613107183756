import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"

import "./SourceDetail.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import { useFetchAllDataSources, useModifyDataSource } from "resources/dataSource/dataSourceQueries"
import { useParams } from "react-router-dom"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"

class SourceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
    }
  }

  updateSource = async data => {
    if (!this.state.updating) {
      this.setState({ updating: true })
      const { history, modifyDataSource, source } = this.props
      try {
        await modifyDataSource({ id: source.id, data })
        history.push(getRoutePath("administration.sources"))
      } catch {
        this.setState({ updating: false })
      }
    }
  }

  toggleHiddenAttribute = async () => {
    const { source, modifyDataSource } = this.props
    await modifyDataSource({ id: source.id, data: { is_hidden: source.is_hidden === 1 ? 0 : 1 } })
    refetchAttributes()
    refetchEvents()
  }

  cloneSource = () => {
    const { history, source } = this.props
    history.push({
      pathname: getRoutePath("administration.sources.create"),
      state: { source },
    })
  }

  render() {
    const { history, areSourcesFulfilled, source } = this.props
    const { updating } = this.state

    const formInitValues = {}
    if (source) {
      formInitValues.id = source.id
      formInitValues.name = source.name
      formInitValues.type = source.type
      formInitValues.description = source.description
      formInitValues.icon = source.frontend_settings?.icon ?? source.type
      formInitValues.color = source.frontend_settings?.color ?? "primary"
    }

    return (
      <Page
        className="detail-source"
        title="Edit source"
        headerContent={
          <div className="header-buttons">
            {source && (
              <>
                <Button
                  color="red"
                  icon={source.is_hidden === 1 ? "eye" : "eye-slash"}
                  iconStyle="far"
                  variant="outlined"
                  onClick={this.toggleHiddenAttribute}
                >
                  {source.is_hidden === 1 ? "Show" : "Hide"}
                </Button>
                <Button color="grey" icon="clone" variant="outlined" onClick={this.cloneSource}>
                  Copy
                </Button>
              </>
            )}
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.sources"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={updating} formName="SourceForm" />
          </div>
        }
      >
        {!areSourcesFulfilled && <LoadingIndicator />}
        {areSourcesFulfilled && (
          <Paper hasHeader className="detail-source-content">
            <SourceForm
              onSubmit={this.updateSource}
              initialValues={formInitValues}
              disabledFields={["id"]}
            />
          </Paper>
        )}
      </Page>
    )
  }
}

export default props => {
  const { id } = useParams()
  const { mutateAsync } = useModifyDataSource()
  const { data = [], isSuccess } = useFetchAllDataSources({ showHidden: true })
  const source = data.find(source => source.id === id)

  return (
    <SourceDetail
      {...props}
      source={source}
      modifyDataSource={mutateAsync}
      areSourcesFulfilled={isSuccess}
    />
  )
}
