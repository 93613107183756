import React, { Component } from "react"
import { connect } from "react-redux"
import { Router, Route, Switch, Link } from "react-router-dom"
import { QueryClientProvider } from "@tanstack/react-query"
import { createBrowserHistory } from "history"
import { ToastContainer, toast as toastShow } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Bowser from "bowser"
import _get from "lodash/get"

import "./fontAwesomeImports"
import { getRoutePath } from "routes"
import getUserConfirmation from "./getUserConfirmation"
import Login from "pages/Login/Login"
import OktaCallback from "pages/OktaCallback/OktaCallback"
import AuthenticatedLayout from "./components/AuthenticatedLayout/AuthenticatedLayout"
import PasswordSet from "pages/PasswordSet/PasswordSet"
import PasswordReset from "pages/PasswordReset/PasswordReset"
import LogoutPage from "pages/Logout/Logout"
import { TOAST } from "sharedConstants"

import "./App.scss"
import "react-toastify/dist/ReactToastify.css"
import { queryClient } from "./queryClient"

// own history instance
export const history = createBrowserHistory({
  getUserConfirmation: getUserConfirmation,
})

/*
 * Toast custom close button
 */
const CloseButton = ({ closeToast }) => (
  <span className="toast-close" onClick={closeToast}>
    <FontAwesomeIcon icon={["fas", "times"]} />
  </span>
)

const ToastContent = ({ text = "", route, closeToast, type, link, routes }) => {
  const renderToastIcon = () => {
    let faIcon
    let testDataId
    if (type === TOAST.TYPE.ERROR) {
      faIcon = "exclamation-triangle"
      testDataId = "error-message-toast"
    } else if (type === TOAST.TYPE.SUCCESS) {
      faIcon = "check"
      testDataId = "success-message-toast"
    } else {
      faIcon = "cogs"
      testDataId = "info-message-toast"
    }
    return (
      <span data-testid={testDataId} className="toast-icon">
        <FontAwesomeIcon icon={["fas", faIcon]} />
      </span>
    )
  }

  if (link) {
    return (
      <React.Fragment>
        {renderToastIcon()}
        <div className="toast-text">
          {text}{" "}
          <a href={link.url} className="external-link" target="_blank" rel="noreferrer">
            {link.title || "Learn more"}
          </a>
        </div>
      </React.Fragment>
    )
  }

  if (routes) {
    return (
      <React.Fragment>
        {renderToastIcon()}
        <div className="toast-text">
          {text}{" "}
          {routes.map(({ path, title }) => (
            <Link to={path} onClick={closeToast}>
              <span className="route">{title}</span>
            </Link>
          ))}
        </div>
      </React.Fragment>
    )
  }

  if (route) {
    return (
      <React.Fragment>
        {renderToastIcon()}
        <span className="toast-text">
          {text}
          <Link to={route} className="link" onClick={closeToast}>
            {["/administration/settings"].includes(route) ? "Settings" : "View"}{" "}
            <FontAwesomeIcon icon={["far", "chevron-right"]} />
          </Link>
        </span>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderToastIcon()}
      <span className="toast-text">{text}</span>
    </React.Fragment>
  )
}

class App extends Component {
  constructor(props) {
    super(props)
    this.registration = false
    this.state = {
      isValidBrowser: true,
      invalidBrowserWarningClosed: false,
    }
    window.addEventListener("blur", this.dismissAllToasts, false)
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.dismissAllToasts, false)
  }

  dismissAllToasts = () => {
    toastShow.dismiss()
  }

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies({
      chrome: ">=58",
      edge: ">=16",
      firefox: ">=54",
      chromium: ">=58",
      safari: ">=10.1",
      opera: ">=44",
    })
    this.setState({
      isValidBrowser,
    })
  }

  componentDidUpdate(prevProps) {
    const { toast } = this.props
    if (toast.id !== prevProps.toast.id && toast.message) {
      if ((this.toastId && toast.message === prevProps.toast.message) || toast.hidePrevious) {
        toastShow.dismiss(this.toastId)
      }
      this.toastId = this.notify(toast)
    }
  }

  notify = data => {
    const message = _get(data, "message", "")
    const wider = message.length > 120
    switch (data.type) {
      case TOAST.TYPE.ERROR:
        return toastShow.error(
          <ToastContent
            text={message}
            route={data.route}
            type={data.type}
            link={data.link}
            routes={data.routes}
          />,
          {
            position: toastShow.POSITION.TOP_RIGHT,
            className: `toast toast-error ${wider ? "wide" : ""} ${data.route ? "with-route" : ""}`,
            bodyClassName: "toast-body",
            closeButton: <CloseButton />,
            autoClose: false,
            draggable: false,
            closeOnClick: false,
          },
        )
      case TOAST.TYPE.SUCCESS:
        return toastShow.success(
          <ToastContent
            text={message}
            route={data.route}
            type={data.type}
            link={data.link}
            routes={data.routes}
          />,
          {
            position: toastShow.POSITION.TOP_RIGHT,
            className: `toast toast-success ${wider ? "wide" : ""} ${
              data.route ? "with-route" : ""
            }`,
            bodyClassName: "toast-body",
            closeButton: <CloseButton />,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            autoClose: 5000,
            draggable: false,
            closeOnClick: false,
          },
        )
      case TOAST.TYPE.INFO:
      default:
        return toastShow.info(
          <ToastContent
            text={message}
            route={data.route}
            type={data.type}
            link={data.link}
            routes={data.routes}
          />,
          {
            position: toastShow.POSITION.TOP_RIGHT,
            className: `toast toast-info ${wider ? "wide" : ""} ${data.route ? "with-route" : ""}`,
            bodyClassName: "toast-body",
            closeButton: <CloseButton />,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            autoClose: false,
            draggable: false,
            closeOnClick: false,
          },
        )
    }
  }

  closeInvalidBrowserWarning = () => {
    this.setState({
      invalidBrowserWarningClosed: true,
    })
  }

  render() {
    const { isValidBrowser, invalidBrowserWarningClosed } = this.state
    return (
      <QueryClientProvider client={queryClient}>
        <Router getUserConfirmation={getUserConfirmation} history={history}>
          <div className="app">
            {!isValidBrowser && !invalidBrowserWarningClosed && (
              <div className="invalid-browser-message">
                <div className="wrapper">
                  <div className="left-part">
                    <FontAwesomeIcon
                      icon={["far", "exclamation-triangle"]}
                      className="exclamation-icon"
                    />
                    <p>
                      Bear in mind that you use old and unsupported browser. We recommend upgrading
                      to the latest{" "}
                      <a
                        href="https://www.apple.com/safari/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safari
                      </a>
                      ,{" "}
                      <a
                        href="https://www.google.com/chrome/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chrome
                      </a>{" "}
                      or{" "}
                      <a
                        href="https://www.mozilla.org/en-US/firefox/new/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Firefox
                      </a>
                      .
                    </p>
                  </div>
                  <div className="right-part">
                    <span className="warning-close" onClick={this.closeInvalidBrowserWarning}>
                      Dismiss
                    </span>
                  </div>
                </div>
              </div>
            )}
            <Switch>
              <Route path={getRoutePath("password.set")} component={PasswordSet} />
              <Route path={getRoutePath("password.reset")} component={PasswordReset} />
              <Route path={getRoutePath("login")} component={Login} />
              <Route path={getRoutePath("okta.callback")} component={OktaCallback} />
              <Route path={getRoutePath("logout")} component={LogoutPage} />
              <Route path={getRoutePath("home")} component={AuthenticatedLayout} />
            </Switch>
            <ToastContainer className="custom-toastify" hideProgressBar={true} />
          </div>
        </Router>
      </QueryClientProvider>
    )
  }
}

function mapStateToProps({ toast }) {
  return { toast }
}

export default connect(mapStateToProps)(App)
