import React, { forwardRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import classNames from "classnames"

import Button from "components/UI/elements/Button/Button"

import styles from "./ScheduleButton.module.scss"

export type ScheduleButtonProps = {
  toggled: boolean
  onClick: () => void
  onToggle: () => void
  daysCount?: number
  disabled?: boolean
  past?: boolean
  schedule?: string
  tooltip?: string
  tooltipDisabled?: boolean
  withToggle?: boolean
}

const ScheduleButton = forwardRef<HTMLButtonElement, ScheduleButtonProps>(
  (
    {
      daysCount,
      past,
      schedule,
      toggled,
      tooltip,
      onClick,
      onToggle,
      disabled = false,
      tooltipDisabled = true,
      withToggle = false,
    },
    ref,
  ) => {
    const hasNextSchedule = schedule && !past
    const hasPastSchedule = schedule && past

    return (
      <Tippy
        disabled={tooltipDisabled || !tooltip}
        content={tooltip}
        delay={[700, 0]}
        placement="bottom"
      >
        <span>
          <Button
            disabled={disabled}
            ref={ref}
            onClick={_ => onClick()}
            className={classNames(styles.button, {
              [styles.hasNextSchedule]: hasNextSchedule,
              [styles.hasPastSchedule]: hasPastSchedule,
            })}
          >
            <React.Fragment>
              <span
                className={classNames(styles.iconWrapper, {
                  [styles.hasNextSchedule]: hasNextSchedule,
                  [styles.hasPastSchedule]: hasPastSchedule,
                })}
              >
                <FontAwesomeIcon
                  icon={["far", past ? "check" : "clock"]}
                  className={styles.clockIcon}
                />
                {hasNextSchedule && daysCount && daysCount > 0 && (
                  <span className={styles.daysCount}>{daysCount}</span>
                )}
              </span>
              {schedule && (
                <span className={styles.text}>
                  <strong>{schedule}</strong>
                </span>
              )}
              {!schedule && <span className={styles.text}>Schedule</span>}
              {withToggle && (
                <div
                  className={classNames(styles.toggleIconWrapper, {
                    [styles.hasNextSchedule]: hasNextSchedule,
                    [styles.hasPastSchedule]: hasPastSchedule,
                  })}
                  onClick={event => {
                    event.stopPropagation()
                    onToggle()
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", toggled ? "caret-up" : "caret-down"]}
                    className={classNames(styles.toggleIcon, {
                      [styles.hasNextSchedule]: hasNextSchedule,
                      [styles.hasPastSchedule]: hasPastSchedule,
                    })}
                  />
                </div>
              )}
            </React.Fragment>
          </Button>
        </span>
      </Tippy>
    )
  },
)

export default ScheduleButton
