import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useState } from "react"
import Waypoint from "react-waypoint"

type InfiniteLoaderProps = {
  hasMoreDataToLoad: boolean
  onLoadMoreData: () => Promise<void>
}
export default function InfiniteLoader({ hasMoreDataToLoad, onLoadMoreData }: InfiniteLoaderProps) {
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)

  const loadMoreData = async () => {
    if (isLoadingMoreData) return

    setIsLoadingMoreData(true)
    await onLoadMoreData()
    setIsLoadingMoreData(false)
  }

  if (!hasMoreDataToLoad) return null

  if (isLoadingMoreData) {
    return <LoadingIndicator />
  } else {
    return <Waypoint onEnter={loadMoreData} bottomOffset={-200} />
  }
}
