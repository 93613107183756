import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"
import _toInteger from "lodash/toInteger"
import Select from "react-select"

// ui components
import NameForm from "components/UI/components/NameForm"
import SmartSegmentTile from "components/UI/elements/SmartSegmentTile"
import { DropdownIndicator } from "components/UI/elements/SelectField"

// helpers, constants
import { selectStyles } from "helpers/customSelectStyle.helper"
import {
  getSmartSegmentsOptions,
  smartSegmentGroups,
  getSegmentIconSrc,
} from "resources/segment/segment/utilities/smartSegmentUtils"

import "./SmartSegmentSettings.scss"

class SmartSegmentSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positionInputValue: props.segment ? props.segment.frontend_settings?.smart?.position : null,
    }
  }

  _getOriginFeSettings = () => {
    const { segment } = this.props
    return segment?.frontend_settings ?? {}
  }

  changeSmartSegmentGroup = group => {
    const originFeSettings = this._getOriginFeSettings()

    this.props.handleSmartSegmentFeSettingsChange({
      ...originFeSettings,
      smart: {
        ...originFeSettings.smart,
        icon: null,
        group_id: group.value,
      },
    })
  }

  changeSmartSegmentDescription = description => {
    const originFeSettings = this._getOriginFeSettings()

    this.props.handleSmartSegmentFeSettingsChange({
      ...originFeSettings,
      smart: {
        ...originFeSettings.smart,
        description,
      },
    })
  }

  changeSmartSegmentIcon = icon => () => {
    const originFeSettings = this._getOriginFeSettings()

    if (originFeSettings?.smart?.icon !== icon)
      this.props.handleSmartSegmentFeSettingsChange({
        ...originFeSettings,
        smart: {
          ...originFeSettings.smart,
          icon,
        },
      })
  }

  changeSmartSegmentPosition = () => {
    const { positionInputValue } = this.state

    const numPositionInputValue = _toInteger(positionInputValue)
    const originFeSettings = this._getOriginFeSettings()

    if (originFeSettings?.smart?.position !== numPositionInputValue)
      this.props.handleSmartSegmentFeSettingsChange({
        ...originFeSettings,
        smart: {
          ...originFeSettings.smart,
          position: numPositionInputValue,
        },
      })
  }

  _getSmartSegmentGroup = () => {
    const { segment } = this.props
    return smartSegmentGroups.get(segment.frontend_settings?.smart?.group_id)
  }

  renderPicturesPicker = () => {
    const { segment } = this.props

    const group = this._getSmartSegmentGroup()
    const selectedPicture = segment.frontend_settings?.smart?.icon

    if (group)
      return group.icons.map(icon => (
        <div
          onClick={this.changeSmartSegmentIcon(icon)}
          className={`icon-container ${selectedPicture === icon ? "selected" : ""}`}
          key={icon}
        >
          <img src={getSegmentIconSrc(icon)} alt="" />
          {selectedPicture === icon && (
            <span className="checkmark">
              <FontAwesomeIcon icon={["far", "check"]} />
            </span>
          )}
        </div>
      ))
  }

  getSmartSegmentColor = () => {
    const group = this._getSmartSegmentGroup()
    if (group) return group.color

    return ""
  }

  getSmartSegmentGroupName = () => {
    const group = this._getSmartSegmentGroup()
    if (group) return group.name

    return "Segment group"
  }

  onPositionChange = evt => {
    this.setState({
      positionInputValue: evt.target.value,
    })
  }

  render() {
    const { segment, smartSegmentDescriptionEditMode, smartSegmentDescriptionToggleFunc } =
      this.props
    const { positionInputValue } = this.state

    return (
      <div className="smart-segment-attributes">
        <div className="smart-segment-form">
          <div className="form-row flex">
            <div className="segment-group-field">
              <label>Segment group</label>
              <Select
                value={getSmartSegmentsOptions().find(
                  option => option.value === segment.frontend_settings?.smart?.group_id,
                )}
                onChange={this.changeSmartSegmentGroup}
                options={getSmartSegmentsOptions()}
                styles={selectStyles("big", "all", "200px")}
                simpleValue
                isSearchable={true}
                className="select-input"
                components={{
                  DropdownIndicator: DropdownIndicator,
                }}
                inputId="group-select"
              />
            </div>
            <div className="position-field">
              <label>Position in group</label>
              <div className="text-field">
                <input
                  type="number"
                  value={positionInputValue}
                  onChange={this.onPositionChange}
                  onBlur={this.changeSmartSegmentPosition}
                  autoComplete="off"
                  className="position-input"
                />
              </div>
            </div>
          </div>
          <div className="form-row flex">
            <NameForm
              initialValues={{
                name: segment.frontend_settings?.smart?.description,
              }}
              handleNameChange={this.changeSmartSegmentDescription}
              size="big"
              form="smart-segment-description-form"
              label="Short description"
              editMode={smartSegmentDescriptionEditMode}
              toggleEditMode={smartSegmentDescriptionToggleFunc}
              isEditable
              placeholder="Description"
              allowEmptyValue
            />
          </div>
          {!_isNil(segment.frontend_settings?.smart?.group_id) && (
            <div className="form-row no-margin-bottom">
              <label>Choose picture</label>
              <div className="pictures">{this.renderPicturesPicker()}</div>
            </div>
          )}
        </div>
        <div className="smart-segment-preview">
          <div className="label">Preview</div>
          <div className={`segment-group ${this.getSmartSegmentColor()}`}>
            {this.getSmartSegmentGroupName()}
          </div>
          <SmartSegmentTile
            name={segment.name}
            description={segment.frontend_settings?.smart?.description}
            className="preview-tile"
            color={this.getSmartSegmentColor()}
            icon={segment.frontend_settings?.smart?.icon}
          />
        </div>
      </div>
    )
  }
}

SmartSegmentSettings.propTypes = {
  segment: PropTypes.object.isRequired,
  smartSegmentDescriptionEditMode: PropTypes.bool,
  smartSegmentDescriptionToggleFunc: PropTypes.func,
  handleSmartSegmentFeSettingsChange: PropTypes.func,
}

export default SmartSegmentSettings
