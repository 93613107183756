export const SYSTEM_USER_ID = 2
export const SYSTEM_ROLE_ID = 2

export const SEGMENT_ANALYTICS_FUNCTIONS = {
  UNIQUE_VALUES: { label: "Unique values", value: "unique_values" },
  MOST_COMMON: { label: "Most common", value: "most_common" },
  LEAST_COMMON: { label: "Least common", value: "least_common" },
  COUNT: { label: "Count of", value: "count" },
  IS_TRUE_COUNT: { label: "Count of yes", value: "is_true_count" },
  IS_FALSE_COUNT: { label: "Count of no", value: "is_false_count" },
  IS_SET_COUNT: { label: "Is known", value: "is_set_count" },
  IS_NOT_SET_COUNT: { label: "Is unknown", value: "is_not_set_count" },
  MIN: { label: "Minimum", value: "min" },
  EARLIEST: { label: "Earliest", value: "min" },
  MAX: { label: "Maximum", value: "max" },
  LATEST: { label: "Latest", value: "max" },
  AVG: { label: "Average", value: "avg" },
  SUM: { label: "Sum", value: "sum" },
  LOWER_THAN: { label: "Lower than", value: "lower_than" },
  GREATER_THAN: { label: "Greater than", value: "greater_than" },
  CONTAINS: { label: "Contains", value: "contains" },
  MATCHES_CURRENT_DAY: { label: "Matches current day", value: "matches_current_day" },
  MATCHES_CURRENT_MONTH: { label: "Matches current month", value: "matches_current_month" },
  MATCHES_CURRENT_YEAR: { label: "Matches current year", value: "matches_current_year" },
  BETWEEN: { label: "Between", value: "between" },
} as const

export const ACL = {
  ACTION: {
    LIST: "SEGMENTS_ACL_LIST",
    CREATE: "SEGMENTS_ACL_CREATE",
    RETRIEVE: "SEGMENTS_USER_ACL_RETRIEVE",
    MODIFY: "SEGMENTS_USER_ACL_MODIFY",
    DELETE: "SEGMENTS_USER_ACL_DELETE",
  },
  USER: {
    ACTION: {
      LIST: "USERS_ACL_LIST",
    },
  },
} as const

export const CUSTOMER_ITERATOR = {
  ACTION: {
    SET: "SET_CUSTOMER_ITERATOR",
    RESET: "RESET_CUSTOMER_ITERATOR",
    SET_INDEX: "SET_CUSTOMER_ITERATOR_INDEX",
  },
} as const

export const SEGMENT = {
  EXPORT: {
    LOG: {
      ITEMS_PER_PAGE: 30,
    },
    REFRESH_INTERVAL: 5000,
  },
  CUSTOMER: {
    ITEMS_PER_PAGE: 10,
  },
  ANALYTICS: {
    FUNCTIONS: {
      STRING: [
        SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT,
      ],
      INT: [
        SEGMENT_ANALYTICS_FUNCTIONS.MIN,
        SEGMENT_ANALYTICS_FUNCTIONS.MAX,
        SEGMENT_ANALYTICS_FUNCTIONS.AVG,
        SEGMENT_ANALYTICS_FUNCTIONS.SUM,
        SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN,
        SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN,
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT,
      ],
      FLOAT: [
        SEGMENT_ANALYTICS_FUNCTIONS.MIN,
        SEGMENT_ANALYTICS_FUNCTIONS.MAX,
        SEGMENT_ANALYTICS_FUNCTIONS.AVG,
        SEGMENT_ANALYTICS_FUNCTIONS.SUM,
        SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN,
        SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT,
      ],
      DATE: [
        SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST,
        SEGMENT_ANALYTICS_FUNCTIONS.LATEST,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT,
      ],
      DATETIME: [
        SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST,
        SEGMENT_ANALYTICS_FUNCTIONS.LATEST,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR,
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT,
      ],
      BOOL: [SEGMENT_ANALYTICS_FUNCTIONS.IS_TRUE_COUNT, SEGMENT_ANALYTICS_FUNCTIONS.IS_FALSE_COUNT],
    },
  },
} as const

export const LABEL = {
  ACTION: {
    LIST: "LABEL_LIST",
    CREATE: "LABEL_CREATE",
    MODIFY: "LABEL_MODIFY",
    DELETE: "LABEL_DELETE",
  },
} as const

export const STITCHING_ATTRIBUTE = {
  ACTION: {
    LIST: "LIST_STITCHING_ATTRIBUTES",
    UPDATE: "UPDATE_STITCHING_ATTRIBUTE",
    DELETE: "DELETE_STITCHING_ATTRIBUTE",
  },
} as const

export const ATTRIBUTE = {
  ACTION: {
    LIST: "ATTRIBUTE_LIST",
    SEARCH_RESULTS_RESET: "ATTRIBUTE_SEARCH_RESET",
    MODIFY: "ATTRIBUTE_MODIFY",
  },
} as const

export const CUSTOMER_ATTRIBUTE = {
  ACTION: {
    RESET_FILTER_FORM_SOURCES: "CUSTOMER_ATTRIBUTE_FILTER_FORM_RESET_SOURCES",
  },
  COUNTS: {
    ACTION: {
      LIST: "CUSTOMER_ATTRIBUTE_COUNTS_LIST",
    },
  },
} as const

export const CUSTOMER = {
  ACTION: {
    SEARCH_VALUE: "CUSTOMER_FULLTEXT_SEARCH",
    SEARCH_VALUE_IN_ATTRIBUTE: "CUSTOMER_ATTRIBUTE_VALUE_SEARCH",
    LIST_FAVORITE_CUSTOMERS: "LIST_FAVORITE_CUSTOMERS",
    SEARCH_RESULTS_RESET: "CUSTOMER_SEARCH_RESET",
  },
} as const

export const WORKSPACE = {
  ACTION: {
    LIST: "WORKSPACE_LIST",
  },
} as const

export const TOAST = {
  ACTION: {
    SHOW: "SHOW_TOAST",
  },
  TYPE: {
    ERROR: "TOAST_ERROR",
    SUCCESS: "TOAST_SUCCESS",
    INFO: "TOAST_INFO",
  },
} as const

export const TOASTS = {
  FAVORITE_CUSTOMERS: {
    ADDED: "Customer added to favorites.",
    REMOVED: "Customer removed from favorites.",
  },
}

export const MOMENT = {
  DATE_TIME_WITH_SECONDS: "HH:mm:ss D.M.YYYY",
  DATE_FORMAT: "D.M.YYYY",
  DATE_MONTH_WORD_FORMAT: "D MMM YYYY",
  DATETIME_MONTH_WORD_FORMAT: "D MMM YYYY HH:mm",
  DATETIME_FORMAT: "D.M.YYYY HH:mm:ss",
  DB_DATE_FORMAT: "YYYY-MM-DD",
  DB_DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
} as const

export const DATEFNS = {
  DATE_FORMAT: "d.M.y",
  TIME_FORMAT: "HH:mm",
  DATETIME_FORMAT: "d.M.y, HH:mm",
  DATETIME_WITH_SECONDS: "HH:mm:ss d.M.yyyy",
}

export const MODAL = {
  TYPE: {
    DELETE: "delete",
    SUCCESS: "success",
    CANCEL: "cancel",
  },
} as const

export const PASSWORD_SET = {
  REFERRER: {
    INVITATION: "invitation",
    PASSWORD_SET: "password-set",
  },
} as const

export const PERMISSION = {
  READ: "read",
  WRITE: "write",
} as const

export const LOGICAL_OPERATOR = {
  AND: "and",
  OR: "or",
} as const

export const TABLE = {
  ACTION: {
    SET_SORT_OPTIONS: "SET_TABLE_SORT",
    SET_SELECTED_TAGS: "SET_SELECTED_TAGS",
    SET_SORTING_AND_TAGS: "SET_SORTING_AND_TAGS",
  },
} as const

export const LONG_LOADING_BAR = {
  ACTION: {
    SHOW: "SHOW_LONG_LOADING_BAR",
    HIDE: "HIDE_LONG_LOADING_BAR",
    LIST_CUSTOMERS_PER_ATTRIBUTE_COUNTS: "LIST_CUSTOMERS_PER_ATTRIBUTE_COUNTS",
    FETCH_TOTAL_CUSTOMERS_COUNT: "FETCH_TOTAL_CUSTOMERS_COUNT",
  },
} as const

export const OPERATION = {
  STRING_EQUALS: "equals",
  STRING_NOT_EQUAL: "not_equal",
  CONTAINS: "contains",
  NOT_CONTAIN: "not_contain",
  STRING_IN: "in",
  STRING_NOT_IN: "not_in",
  CONTAINS_ANY_OF: "contains_any_of",
  NOT_CONTAIN_ANY_OF: "not_contain_any_of",

  NUMBER_EQUALS: "number_equals",
  NUMBER_NOT_EQUAL: "number_not_equal",
  NUMBER_LOWER_THAN: "number_lower_than",
  NUMBER_GREATER_THAN: "number_greater_than",
  NUMBER_BETWEEN: "number_between",
  NUMBER_IN: "number_in",
  NUMBER_NOT_IN: "number_not_in",

  DATE_EQUALS: "date_equals",
  DATE_LOWER_THAN: "date_lower_than",
  DATE_GREATER_THAN: "date_greater_than",
  DATE_BETWEEN: "date_between",
  DATE_MATCHES_CURRENT_DAY: "date_matches_current_day",
  DATE_MATCHES_CURRENT_MONTH: "date_matches_current_month",
  DATE_MATCHES_CURRENT_YEAR: "date_matches_current_year",

  IS_TRUE: "is_true",
  IS_FALSE: "is_false",
  IS_SET: "is_set",
  IS_NOT_SET: "is_not_set",
} as const

export const OPERATION_CONTEXT_TYPE = {
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
  OTHER: "other",
} as const

export const PROTOCOLS = [
  { label: "http", value: "http" },
  { label: "https", value: "https" },
] as const

export const INVITE_FORM = {
  ACTION: {
    SET_DEFAULT_READ_PERMISSIONS: "SET_DEFAULT_READ_PERMISSIONS",
    MARK_ALL_SEGMENTS: "MARK_ALL_SEGMENTS",
    SET_PERMISSIONS_MARK_ALL: "SET_PERMISSIONS_MARK_ALL",
    SET_ACTIVE_USER: "SET_ACTIVE_USER",
    TOGGLE_ALL_SWITCH: "TOGGLE_ALL_SWITCH",
    TOGGLE_ALL_USER_PERMISSIONS: "TOGGLE_ALL_USER_PERMISSIONS",
  },
} as const

export const NOTIFICATION = {
  PASSWORD_RESET:
    "The email with instructions has been sent. If you did not receive an email please contact your administrator.",
} as const

export const OPTION_GROUP_ICONS = [
  { icon: "appsflyer", name: "Appsflyer" },
  { icon: "analytics", name: "Google Analytics" },
  { icon: "axform", name: "Adform" },
  { icon: "bank_app", name: "Bank application" },
  { icon: "bigquery", name: "BigQuery" },
  { icon: "bl", name: "BL" },
  { icon: "csv_file", name: "CSV file" },
  { icon: "customer_support", name: "Customer support" },
  { icon: "db_table", name: "Database table" },
  { icon: "dummy", name: "Dummy placeholder" },
  { icon: "e_wallet", name: "E-wallet" },
  { icon: "envelope", name: "Envelope" },
  { icon: "exponea", name: "Exponea" },
  { icon: "facebook", name: "Facebook" },
  { icon: "firebase", name: "Firebase" },
  { icon: "gads", name: "Google Ads" },
  { icon: "gam", name: "GAM" },
  { icon: "hubspot", name: "HubSpot" },
  { icon: "kafka", name: "Kafka" },
  { icon: "klaviyo", name: "Klaviyo" },
  { icon: "kly", name: "KLY" },
  { icon: "ldap", name: "LDAP" },
  { icon: "locatorlogic", name: "Locator Logic" },
  { icon: "magento", name: "Magento" },
  { icon: "magnews", name: "Magnews" },
  { icon: "mailchimp", name: "Mailchimp" },
  { icon: "marketo", name: "Marketo" },
  { icon: "meiro_events", name: "Meiro Events" },
  { icon: "mobile_app", name: "Mobile App" },
  { icon: "ms_dynamic_365", name: "MS Dynamics 365" },
  { icon: "optimizely", name: "Optimizely" },
  { icon: "pipedrive", name: "Pipedrive" },
  { icon: "prestashop", name: "Prestashop" },
  { icon: "push_notifications", name: "Mobile Push" },
  { icon: "pure_cloud", name: "PureCloud" },
  { icon: "salesforce", name: "Salesforce" },
  { icon: "sitecore", name: "Sitecore" },
  { icon: "sklik", name: "Sklik" },
  { icon: "smart_emailing", name: "Smart Emailing" },
  { icon: "snowplow", name: "Snowplow" },
  { icon: "sport", name: "Sport" },
  { icon: "tiktok", name: "TikTok Ads" },
  { icon: "trade_desk", name: "The Trade Desk" },
  { icon: "twitter", name: "Twitter Ads" },
  { icon: "vidio", name: "Vidio" },
  { icon: "web", name: "Web" },
  { icon: "woo", name: "WooCommerce" },
  { icon: "yellow_bot", name: "Yellow.ai" },
  { icon: "yellow_canary", name: "Yellow canary" },
  { icon: "a_1" },
  { icon: "a_2" },
  { icon: "a_3" },
  { icon: "a_4" },
  { icon: "b_1" },
  { icon: "b_2" },
  { icon: "b_3" },
  { icon: "b_4" },
  { icon: "c_1" },
  { icon: "c_2" },
  { icon: "c_3" },
  { icon: "c_4" },
  { icon: "d_1" },
  { icon: "d_2" },
  { icon: "d_3" },
  { icon: "d_4" },
] as const

export const OPTION_GROUP_COLORS = [
  "wasabi",
  "olivine",
  "sprout",
  "fun-blue",
  "persian-blue",
  "jordy-blue",
  "cerulean-blue",
  "rich-blue",
  "ship-cove",
  "cabaret",
  "charm",
  "can-can",
  "bronze",
  "california",
  "whiskey",
  "apple",
  "fuego",
  "granny-smith-apple",
  "atoll",
  "light-sea-green",
  "turquoise-blue",
  "royal-blue",
  "portage",
  "nepal",
  "fire-engine-red",
  "old-rose",
  "contessa",
  "broom",
  "macaroni-and-cheese",
  "golden-glow",
  "sea-green",
  "mountain-meadow",
  "silver-tree",
  "gossamer",
  "medium-aquamarine",
  "magic-mint",
  "medium-purple",
  "dull-lavender",
  "perano",
  "free-speech-magenta",
  "persian-pink",
  "cupid",
  "purple-heart",
  "heliotrope",
  "perfume",
  "gumbo",
] as const

export const COLOR = {
  primary: "#fe7f66",
  "#fe7f66": "primary",
  white: "#ffffff",
  "#ffffff": "white",
  green: "#3aa545",
  "#3aa545": "green",
  red: "#cc4545",
  "#cc4545": "red",
  blue: "#01579b",
  "#01579b": "blue",
  "light-blue": "#4285f4",
  "#4285f4": "light-blue",
  yellow: "#fbb962",
  "#fbb962": "yellow",
  purple: "#7c2b73",
  "#7c2b73": "purple",
  "lighting-yellow": "#f8ac33",
  "#f8ac33": "lighting-yellow",
  wasabi: "#7f9d43",
  "#7f9d43": "wasabi",
  "medium-aquamarine": "#57c785",
  "#57c785": "medium-aquamarine",
  "persian-blue": "#2258c3",
  "#2258c3": "persian-blue",
  "medium-purple": "#8855db",
  "#8855db": "medium-purple",
  contessa: "#f5a3a3",
  "#f5a3a3": "contessa",
  "fire-engine-red": "#e05252",
  "#e05252": "fire-engine-red",
  cabaret: "#bf4040",
  "#bf4040": "cabaret",
  "macaroni-and-cheese": "#e6c100",
  "#e6c100": "macaroni-and-cheese",
  broom: "#ccac00",
  "#ccac00": "broom",
  fuego: "#6dc144",
  "#6dc144": "fuego",
  gossamer: "#45a16b",
  "#45a16b": "gossamer",
  "silver-tree": "#5cd685",
  "#5cd685": "silver-tree",
  nepal: "#abb6ed",
  "#abb6ed": "nepal",
  gumbo: "#aaaaaa",
  "#aaaaaa": "gumbo",
  olivine: "#8cba5e",
  "#8cba5e": "olivine",
  sprout: "#99cc66",
  "#99cc66": "sprout",
  "cerulean-blue": "#2929a3",
  "#2929a3": "cerulean-blue",
  "rich-blue": "#5959a6",
  "#5959a6": "rich-blue",
  "ship-cove": "#8484ad",
  "#8484ad": "ship-cove",
  "old-rose": "#f07575",
  "#f07575": "old-rose",
  charm: "#cc6677",
  "#cc6677": "charm",
  "can-can": "#d27979",
  "#d27979": "can-can",
  california: "#e88c30",
  "#e88c30": "california",
  bronze: "#cc8033",
  "#cc8033": "bronze",
  whiskey: "#cc9966",
  "#cc9966": "whiskey",
  atoll: "#2d7e86",
  "#2d7e86": "atoll",
  "light-sea-green": "#22b6c3",
  "#22b6c3": "light-sea-green",
  "turquoise-blue": "#6cd6e0",
  "#6cd6e0": "turquoise-blue",
  "fun-blue": "#2d4b86",
  "#2d4b86": "fun-blue",
  "jordy-blue": "#6c92e0",
  "#6c92e0": "jordy-blue",
  apple: "#5c8547",
  "#5c8547": "apple",
  "granny-smith-apple": "#85d65c",
  "#85d65c": "granny-smith-apple",
  "royal-blue": "#556cdb",
  "#556cdb": "royal-blue",
  portage: "#8191e4",
  "#8191e4": "portage",
  "golden-glow": "#f5d73d",
  "#f5d73d": "golden-glow",
  "sea-green": "#17823b",
  "#17823b": "sea-green",
  "mountain-meadow": "#1ab34d",
  "#1ab34d": "mountain-meadow",
  "magic-mint": "#67e49b",
  "#67e49b": "magic-mint",
  "dull-lavender": "#a281e4",
  "#a281e4": "dull-lavender",
  perano: "#c1abed",
  "#c1abed": "perano",
  "free-speech-magenta": "#e052b1",
  "#e052b1": "free-speech-magenta",
  "persian-pink": "#f075c7",
  "#f075c7": "persian-pink",
  cupid: "#f5a3da",
  "#f5a3da": "cupid",
  "grey-lighter": "#777777",
  "#777777": "grey-lighter",
  "very-light-grey": "#cccccc",
  "#cccccc": "very-light-grey",
  "primary-lighter": "#fea172",
  "#fea172": "primary-lighter",
  lavender: "#e0d5f6",
  "#e0d5f6": "lavender",
  "purple-heart": "#8d26de",
  "#8d26de": "purple-heart",
  heliotrope: "#af5def",
  "#af5def": "heliotrope",
  perfume: "#d9b2f8",
  "#d9b2f8": "perfume",
} as const

export const IDENTITY_GRAPH_COLORS = [
  "#F15555",
  "#136067",
  "#7F44F3",
  "#49BB0F",
  "#E4C143",
  "#189FAB",
  "#410074",
  "#8ACDA6",
  "#0D3FA3",
  "#6C7EA3",
] as const

export const ITEMS_PER_PAGE = 20

export const DEFAULT_BASE_URL = "http://cdp.meiro.local:3000"
export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? window.location.origin
    : process.env.REACT_APP_BASE_URL ?? DEFAULT_BASE_URL

export const SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD = 10000
export const REDIRECT_AFTER_OKTA_LOGIN_LS_KEY = "redirectAfterOktaLoginTo"

export const PUSH_NOTIFICATIONS_CHANNEL_NAME = "push_notifications_channel"
export const EMAILS_CHANNEL_NAME = "emails_channel"
