import React, { PureComponent } from "react"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { getFormValues } from "redux-form"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import moment from "moment"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { getRoutePath } from "routes"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"
import AttributeId from "components/UI/components/AttributeId/AttributeId"
import Badge from "components/UI/elements/AttributeBadge"
import "./AttributesList.scss"
import {
  useFetchActiveLabels,
  useFetchAllAttributes,
  useModifyAttribute,
} from "resources/attribute/attributeQueries"
import create from "zustand"
import { without } from "ramda"
import { useSelector } from "store"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import Table from "components/Table/Table"
import classNames from "classnames"

class AttributesList extends PureComponent {
  renderSelectedLabels = () => {
    const {
      filters: { labelIds, removeLabelId },
      attributesLabels,
    } = this.props

    if (!labelIds || labelIds.langth === 0) {
      return null
    }

    return labelIds.map(labelId => {
      const label = attributesLabels.find(label => label.id === labelId)
      if (label) {
        return (
          <Tag key={label.id} clickable color="primary" onClick={() => removeLabelId(label.id)}>
            {label.name}
          </Tag>
        )
      }
      return null
    })
  }

  goToAttributeDetail = attributeId => () => {
    this.props.history.push(getRoutePath("administration.attributes.detail", { id: attributeId }))
  }

  render() {
    const {
      attributes,
      areAttributesFulfilled,
      attributesLabels,
      history,
      filters: { setSort, orderBy, orderDir, addLabelId, labelIds },
      toggleHidden,
    } = this.props

    const columns = [
      {
        id: "name",
        label: "Name",
        onSort: () => setSort("name"),
        gridTemplate: "1.2fr",
        renderCell: attribute => (
          <div className="attribute-name-cell">
            <div className="attribute-name">
              {attribute.name}
              {moment().diff(attribute.created, "days") < 8 && <Badge text="New" />}
            </div>
            {isAttributeCompound(attribute.data_type) && (
              <ul className="sub-attrs">
                {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                  <li key={subAttr.id}>{subAttr.name}</li>
                ))}
              </ul>
            )}
            <AttributeId id={attribute.id} />
          </div>
        ),
      },
      {
        id: "source",
        label: "Data source",
        onSort: () => setSort("source"),
        gridTemplate: "1fr",
        renderCell: attribute => (
          <div className="attribute-source">
            <SrcDstIcon source={attribute.source} className="source-icon" />
            <div className="source-name">{attribute.source.name}</div>
          </div>
        ),
      },
      {
        id: "labels",
        label: "Labels",
        onSort: () => setSort("labels"),
        gridTemplate: "1fr",
        renderCell: attribute => (
          <div className="attribute-tags">
            {attribute.tags?.map(tag => (
              <Tag
                key={tag.id}
                color="primary"
                onClick={() => addLabelId(tag.id)}
                className="attribute-label"
              >
                {tag.name}
              </Tag>
            ))}
          </div>
        ),
      },
      {
        id: "description",
        label: "Description",
        gridTemplate: "1fr",
        renderCell: attribute => (
          <div className="attribute-description">{attribute.description}</div>
        ),
      },
      {
        id: "actions",
        gridTemplate: "max-content",
        renderCell: attribute => (
          <div className="actions">
            <IconButton
              color="black"
              variant="outlined"
              size="xs"
              onClick={this.goToAttributeDetail(attribute.id)}
              icon="pencil-alt"
              tooltip="Edit"
            />
            <IconButton
              color="red"
              size="xs"
              variant="outlined"
              onClick={() => toggleHidden(attribute)}
              className="left-margin"
              disabled={attribute.source.is_hidden === 1}
              iconStyle="far"
              icon={attribute.is_hidden ? "eye" : "eye-slash"}
              tooltip={
                attribute.source.is_hidden === 1
                  ? "Hidden source"
                  : attribute.is_hidden
                  ? "Show"
                  : "Hide"
              }
            />
          </div>
        ),
      },
    ]

    return (
      <Page
        className="setup-attributes"
        title="Attributes"
        headerContent={
          <>
            <AttributesFilterForm
              destroyOnUnmount={false}
              formName="SetupSearchAttributeForm"
              showHiddenSources={true}
            />
            <Button
              onClick={() => {
                history.push(getRoutePath("administration.attributes.create"))
              }}
            >
              + Create attribute
            </Button>
          </>
        }
      >
        {!areAttributesFulfilled && <LoadingIndicator />}

        {areAttributesFulfilled && attributes.length > 0 && (
          <Paper>
            <div className="tag-filter">
              <div className="label-tags">
                <span className="selected-tags">Filter by:</span>
                <span>{this.renderSelectedLabels()}</span>
              </div>
              <TagPicker
                selectedTagIds={labelIds}
                allTags={attributesLabels}
                onTagSelect={addLabelId}
                className="selected-labels-picker"
                type="label"
              />
            </div>
            <Table
              columns={columns}
              data={attributes}
              sortBy={orderBy}
              sortDir={orderDir}
              getRowClassName={attribute => classNames({ isHidden: attribute.is_hidden })}
            />
          </Paper>
        )}
      </Page>
    )
  }
}

const useStore = create(set => ({
  orderBy: "name",
  orderDir: "ASC",
  // searchTerm: "",
  labelIds: [],
  // sourceId: null,
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  // setSearchTerm: searchTerm => set({ searchTerm }),
  addLabelId: labelId =>
    set(({ labelIds }) => ({
      labelIds: labelIds.includes(labelId) ? labelIds : labelIds.concat(labelId),
    })),
  removeLabelId: labelId => set(({ labelIds }) => ({ labelIds: without([labelId], labelIds) })),
  // setSourceId: sourceId => set({ sourceId }),
}))

export default props => {
  const filters = useStore()
  const { orderBy, orderDir, labelIds } = filters
  const formValues = useSelector(getFormValues("SetupSearchAttributeForm"))

  const { data: attributesLabels } = useFetchActiveLabels()

  // Only filter by labels that are assigned to some attributes to prevent a bug where a label is
  // removed from all labels and the filter by label is maintained, returning empty table
  const filteredLabelIds = attributesLabels
    ? labelIds.filter(id => attributesLabels.some(label => label.id === id))
    : labelIds

  const { data: attributes, isSuccess } = useFetchAllAttributes(
    {
      includeHidden: true,
      orderBy,
      orderDir,
      searchTerm: formValues?.search?.trim(),
      labelIds: filteredLabelIds,
      sourceId: formValues?.select?.value,
    },
    { refetchOnMount: "always" },
  )

  const modifyMutation = useModifyAttribute()
  const toggleHidden = attribute =>
    modifyMutation.mutate({ id: attribute.id, data: { is_hidden: +!attribute.is_hidden } })

  return (
    <AttributesList
      {...props}
      attributes={attributes}
      attributesLabels={attributesLabels}
      filters={filters}
      areAttributesFulfilled={isSuccess}
      toggleHidden={toggleHidden}
    />
  )
}
