import {
  MetaAttribute,
  MetaAttributesListResponse,
  MetaAttributeModifyPayload,
  MetaAttributeCreatePayload,
  MetaAttributeResponse,
} from "./metaAttributesTypes"
import requestFactory from "api/request"

export const metaAttributes = {
  list({
    limit = 100,
    offset = 0,
    order_by = "id",
    order_dir = "ASC",
  }: Partial<
    MetaAttributesListResponse["selection_settings"]
  >): Promise<MetaAttributesListResponse> {
    return requestFactory("GET", "/meta_attributes", { offset, limit, order_by, order_dir })
  },
  retrieve(id: MetaAttribute["id"]): Promise<MetaAttributeResponse> {
    return requestFactory("GET", `/meta_attributes/${id}`)
  },
  create(data: MetaAttributeCreatePayload): Promise<MetaAttributeResponse> {
    return requestFactory("POST", "/meta_attributes", data)
  },
  modify(
    id: MetaAttribute["id"],
    data: MetaAttributeModifyPayload,
  ): Promise<MetaAttributeResponse> {
    return requestFactory("PATCH", `/meta_attributes/${id}`, data)
  },
}
