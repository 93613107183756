import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _round from "lodash/round"
import _maxBy from "lodash/maxBy"
import _sum from "lodash/sum"
import _get from "lodash/get"
import _isNumber from "lodash/isNumber"
import _isArray from "lodash/isArray"
import { Cell, PolarAngleAxis, RadialBar, RadialBarChart, Tooltip } from "recharts"

import IconButton from "../IconButton/IconButton"
import { getFunctionName, getPercentageText } from "helpers/insight.helper"
import { abbreviateNumber } from "helpers/number.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { SEGMENT_ANALYTICS_FUNCTIONS, COLOR } from "sharedConstants"

import "./InsightTile.scss"
import Tippy from "@tippyjs/react"
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator"
import { shortenText } from "helpers/string.helper"
import { useModifyUser } from "resources/user/userQueries"
import { ascend, prop, reverse, sort, without } from "ramda"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import classNames from "classnames"
import SrcDstIcon from "../SrcDstIcon/SrcDstIcon"

const MOST_COMMON_LEAST_COMMON_GRAPH_HEIGHT = 170

const renderCustomizedTooltipContent = ({ payload, active }) => {
  if (active) {
    const name = _get(payload, "[0].payload.name")
    const label = getPercentageText(_get(payload, "[0].payload.value"), 2)
    return (
      <div className="custom-tooltip">
        <p>
          <strong>{label}</strong>:&nbsp;{name}
        </p>
      </div>
    )
  }
  return null
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (
  { fill, fillOpacity, index, value, viewBox: { cx, cy } },
  labelCount,
) => {
  const allAngles = [90, 70, 50, 30, 10]
  let angle = allAngles[0]

  if (labelCount > 1) {
    const angles = allAngles.splice(0, labelCount)
    angle = reverse(angles)[index]
  }

  const radius = MOST_COMMON_LEAST_COMMON_GRAPH_HEIGHT / 2 - 4
  const x = cx + radius * Math.cos(-angle * RADIAN)
  const y = cy + radius * Math.sin(-angle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor="start"
      dominantBaseline="central"
      className="chart-label"
      fillOpacity={fillOpacity}
    >
      {getPercentageText(value)}
    </text>
  )
}

class InsightTile extends PureComponent {
  renderValue = value => {
    const { funcType, attribute, subAttribute, displayType, mode, percentage, attributeDeleted } =
      this.props
    if (!funcType || !attribute) {
      if (mode === "preview") {
        if (attributeDeleted) {
          return <p className="selection-message">Attribute was deleted</p>
        }
        return <p className="selection-message">Select attribute and condition</p>
      } else {
        return <p className="selection-message">Attribute was deleted</p>
      }
    }

    let dataType = ""
    if (attribute) {
      dataType = subAttribute ? subAttribute.data_type : attribute.data_type
    }
    if (
      ["date", "datetime"].includes(dataType) &&
      [
        SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LATEST.value,
      ].includes(funcType)
    ) {
      const userFriendlyValue = getUserFriendlyValueFormat(value, dataType)
      const fs = this._valueFontSizeEvaluate(userFriendlyValue)
      return <p className={`value ${fs} result-value`}>{userFriendlyValue}</p>
    } else if (
      [
        SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MIN.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MAX.value,
        SEGMENT_ANALYTICS_FUNCTIONS.AVG.value,
        SEGMENT_ANALYTICS_FUNCTIONS.SUM.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
        SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_TRUE_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_FALSE_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR.value,
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value,
      ].includes(funcType)
    ) {
      return (
        <p className="value fs-40 result-value">
          {_isNumber(value) ? abbreviateNumber(value) : ""}
        </p>
      )
    } else if ([SEGMENT_ANALYTICS_FUNCTIONS.UNIQUE_VALUES.value].includes(funcType)) {
      const userFriendlyValues = getUserFriendlyValueFormat(value, dataType)
      const fs = this._valuesFontSizeEvaluate(userFriendlyValues)
      return (
        <div className={`unique-values ${fs} result-value`}>
          {_isArray(userFriendlyValues) &&
            userFriendlyValues.map((v, idx) => {
              const val = this._shortenTextInMiddleWithTooltip(v, idx)
              if (idx > 0 && userFriendlyValues.length !== idx) {
                return <React.Fragment key={idx}>, {val}</React.Fragment>
              } else {
                return <React.Fragment key={idx}>{val}</React.Fragment>
              }
            })}
        </div>
      )
    } else if (
      [
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
      ].includes(funcType)
    ) {
      if (displayType === "chart" && _isArray(value)) {
        let data = []

        if (value.length > 0) {
          const temp = value.map((v, idx) => ({
            name: getUserFriendlyValueFormat(v, dataType),
            value: percentage[idx],
          }))

          const opacityMultiplier = 100 / (value.length + 5) / 100
          const sortedData = sort(ascend(prop("value")), temp)
          data = sortedData.map((v, idx) => ({
            ...v,
            color: this._getColor(),
            opacity: 1 - (value.length - 1 - idx) * opacityMultiplier,
          }))
        }

        const functionName = funcType && attribute ? getFunctionName(funcType, dataType) : ""
        const innerRadius = data.length < 4 ? 100 - data.length * 15 : 50

        return (
          <div className="common-values chart result-value">
            <p className="func-name">{functionName}</p>
            <RadialBarChart
              data={data}
              width={200}
              height={MOST_COMMON_LEAST_COMMON_GRAPH_HEIGHT}
              innerRadius={`${innerRadius}%`}
              outerRadius="100%"
              startAngle={90}
              endAngle={-270}
              barSize={10}
            >
              <PolarAngleAxis tick={false} domain={[0, 100]} type="number" />
              <RadialBar
                background
                isAnimationActive={false}
                dataKey="value"
                minAngle={6}
                label={label => renderCustomizedLabel(label, value.length)}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={COLOR[entry.color ?? "primary"]}
                    fillOpacity={entry.opacity}
                  />
                ))}
              </RadialBar>
              <Tooltip content={renderCustomizedTooltipContent} />
            </RadialBarChart>
          </div>
        )
      } else if (_isArray(value)) {
        const userFriendlyValues = value.map(v => getUserFriendlyValueFormat(v, dataType))
        const fs = this._valuesFontSizeEvaluate(userFriendlyValues)
        return (
          <div className={`common-values ${fs} result-value`}>
            {userFriendlyValues.map((v, idx) => {
              const val = this._shortenTextInMiddleWithTooltip(v, idx)
              if (idx > 0 && userFriendlyValues.length !== idx) {
                return <React.Fragment key={idx}>, {val}</React.Fragment>
              } else {
                return <React.Fragment key={idx}>{val}</React.Fragment>
              }
            })}
          </div>
        )
      }
    }
    return null
  }

  _valueFontSizeEvaluate = value => {
    const length = value ? value.length : 1000
    if (length <= 10) {
      return "fs-40"
    } else if (length <= 13) {
      return "fs-32"
    } else if (length <= 16) {
      return "fs-26"
    } else if (length <= 19) {
      return "fs-22"
    } else if (length <= 22) {
      return "fs-18"
    } else {
      return "fs-14"
    }
  }

  _valuesFontSizeEvaluate = values => {
    const maxValue = _maxBy(values, v => v.length)
    let fs = this._valueFontSizeEvaluate(maxValue)
    if (fs !== "fs-14") {
      const joinedText = values.join(", ")
      const length = joinedText.length
      if (length > 15 && length <= 19 && fs !== "fs-32") {
        fs = "fs-32"
      } else if (length > 19 && length <= 32 && fs !== "fs-26") {
        fs = "fs-26"
      } else if (length > 32 && length <= 38 && fs !== "fs-22") {
        fs = "fs-22"
      } else if (length > 38 && length <= 44 && fs !== "fs-18") {
        fs = "fs-18"
      } else if (length > 44 && fs !== "fs-14") {
        fs = "fs-14"
      }
    }
    return fs
  }

  _shortenTextInMiddleWithTooltip = text => {
    if (text.length <= 28) {
      return text
    }
    const start = text.substr(0, 11)
    const end = text.substr(-12)
    return (
      <React.Fragment>
        {start}
        <Tippy content={text} placement="bottom">
          <span className="shortening">[...]</span>
        </Tippy>

        {end}
      </React.Fragment>
    )
  }

  renderPercentageLine = () => {
    const { outOf, value, funcType, percentage, displayType, mode, attribute } = this.props
    if (mode !== "preview" && !attribute) {
      return null
    }

    if (
      outOf &&
      [
        SEGMENT_ANALYTICS_FUNCTIONS.IS_SET_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_NOT_SET_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
        SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
        SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_TRUE_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.IS_FALSE_COUNT.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_DAY.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_MONTH.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MATCHES_CURRENT_YEAR.value,
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value,
      ].includes(funcType)
    ) {
      const percentage = (value / outOf) * 100
      return (
        <p className="out-of" data-testid="func-out-of">
          {getPercentageText(percentage)} out of {abbreviateNumber(outOf)}
        </p>
      )
    }

    if (
      outOf &&
      percentage &&
      [
        SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
        SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
      ].includes(funcType) &&
      displayType !== "chart"
    ) {
      const sum = _sum(percentage)
      return (
        <p className="out-of" data-testid="func-out-of">
          {getPercentageText(sum)} out of {abbreviateNumber(outOf)}
        </p>
      )
    }
    return null
  }

  renderAttributeValue = () => {
    const { attributePercentage, outOf, attribute, funcType, mode } = this.props
    const exactCount = _round(outOf * (attributePercentage / 100))
    if (!attribute || !funcType) {
      if (mode === "preview") {
        return <React.Fragment>100% (93k out of 93k)</React.Fragment>
      } else {
        return "N/A"
      }
    }
    return `${getPercentageText(attributePercentage)} (${abbreviateNumber(
      exactCount,
    )} out of ${abbreviateNumber(outOf)})`
  }

  _getColor = () => {
    let { color, attribute } = this.props

    return color ?? attribute?.source?.frontend_settings?.color ?? null
  }

  _getNameFontSize = () => {
    const { name, showNewBadge } = this.props
    const stringArray = name.split(" ")
    const maxLength = _maxBy(stringArray, part => part.length)
    const totalMaxLength = showNewBadge ? 25 : 28
    if (maxLength.length > 18 || name.length > totalMaxLength) {
      return "small-size"
    }
    return "regular-size"
  }

  renderCompareValue = () => {
    const { compareValue, attribute, funcType, subAttribute } = this.props
    const valueDataType = subAttribute ? subAttribute.data_type : attribute?.data_type
    if (compareValue) {
      if (
        [
          SEGMENT_ANALYTICS_FUNCTIONS.LOWER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.GREATER_THAN.value,
          SEGMENT_ANALYTICS_FUNCTIONS.COUNT.value,
          SEGMENT_ANALYTICS_FUNCTIONS.CONTAINS.value,
        ].includes(funcType)
      ) {
        if (["int", "float"].includes(valueDataType)) {
          return ` ${abbreviateNumber(compareValue)}`
        } else if (["date", "datetime"].includes(valueDataType)) {
          const dateValueToDisplay = getUserFriendlyValueFormat(compareValue, valueDataType)
          return dateValueToDisplay !== "Invalid date"
            ? ` ${dateValueToDisplay}`
            : ` ${compareValue}`
        } else if (valueDataType === "string") {
          if (compareValue.length > 26) {
            return (
              <span>
                {" "}
                <Tippy content={compareValue}>
                  <span>{shortenText(compareValue, 26)}</span>
                </Tippy>
              </span>
            )
          } else {
            return <span> {compareValue}</span>
          }
        }
      } else if (
        SEGMENT_ANALYTICS_FUNCTIONS.BETWEEN.value === funcType &&
        compareValue[0] &&
        compareValue[1]
      ) {
        if (["int", "float"].includes(valueDataType)) {
          return (
            <span>
              {" "}
              {abbreviateNumber(compareValue[0])} and {abbreviateNumber(compareValue[1])}
            </span>
          )
        }
        if (["date", "datetime"].includes(valueDataType)) {
          const leftValue = getUserFriendlyValueFormat(compareValue[0], valueDataType)
          const rightValue = getUserFriendlyValueFormat(compareValue[1], valueDataType)
          return (
            <span>
              {" "}
              {leftValue === "Invalid date" ? compareValue[0] : leftValue} and{" "}
              {rightValue === "Invalid date" ? compareValue[1] : rightValue}
            </span>
          )
        }
      }
    }
    return null
  }

  render() {
    const {
      id,
      className,
      name,
      attribute,
      subAttribute,
      attributePercentage,
      hideActionButtons,
      showNewBadge,
      funcType,
      isSticky,
      displayType,
      isLoading,
      toggleInsightSticky,
      hasAccess,
    } = this.props
    let { value } = this.props
    const source = attribute ? attribute.source : null

    let dataType = ""
    if (attribute) {
      dataType = subAttribute ? subAttribute.data_type : attribute.data_type
    }
    const functionName = funcType && attribute ? getFunctionName(funcType, dataType) : ""
    const color = this._getColor()
    const nameSize = this._getNameFontSize()

    return (
      <div className={classNames("insight-tile", color, className)}>
        <div className="header-part">
          <h4 className={`tile-name ${showNewBadge ? "has-new-badge" : ""}`}>
            {source && <SrcDstIcon source={source} className="source-icon" />}
            {!source && <SrcDstIcon iconName="meiro_events" className="source-icon" />}
            <div className={`name-wrapper ${nameSize}`}>
              <span className="name">{name}</span>
              {showNewBadge && <span className="new">New</span>}
            </div>
          </h4>
          <div className={`action-buttons ${hideActionButtons || isLoading ? "hidden" : ""}`}>
            <IconButton
              color="grey"
              icon="star"
              iconStyle="fas"
              onClick={() => toggleInsightSticky(id)}
              tooltip={isSticky ? "Remove from favorites" : "Add to favorites"}
              variant="outlined"
              className={classNames("favorite-btn", { favorite: isSticky })}
            />
            <IconButton
              disabled={!hasAccess.data.insights}
              color="grey"
              onClick={attribute ? this.props.onExpandClick : () => {}}
              icon="expand-alt"
              iconStyle="fas"
              tooltip={
                hasAccess.data.insights
                  ? "Expand"
                  : "You don't have permission to view this insight."
              }
              variant="outlined"
              className="expand-btn"
            />
          </div>
        </div>
        {!isLoading && (
          <>
            <div className="main-content">
              {attributePercentage === 0 && (
                <p className="no-sufficient-data">
                  There is insufficient data to view this insight.
                </p>
              )}
              {attributePercentage !== 0 && (
                <React.Fragment>
                  {(![
                    SEGMENT_ANALYTICS_FUNCTIONS.MOST_COMMON.value,
                    SEGMENT_ANALYTICS_FUNCTIONS.LEAST_COMMON.value,
                  ].includes(funcType) ||
                    displayType !== "chart") && (
                    <p className="func-name">
                      {functionName}
                      {this.renderCompareValue()}
                    </p>
                  )}
                  {this.renderValue(value)}
                  {this.renderPercentageLine()}
                </React.Fragment>
              )}
            </div>
            <div className="attribute-info">
              <p className="label">Attribute is known for</p>
              <p className="value" data-testid="attribute-value">
                {this.renderAttributeValue()}
              </p>
            </div>
          </>
        )}
        {isLoading && <LoadingIndicator className="insight-loading" />}
      </div>
    )
  }
}

InsightTile.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
  compareValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  percentage: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  attributePercentage: PropTypes.number,
  outOf: PropTypes.number,
  funcType: PropTypes.string,
  className: PropTypes.string,
  attribute: PropTypes.object,
  attributeDeleted: PropTypes.bool,
  color: PropTypes.string,
  showNewBadge: PropTypes.bool,
  hideTooltips: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
  displayType: PropTypes.string,
  mode: PropTypes.string,
  onExpandClick: PropTypes.func,
  subAttribute: PropTypes.object,
  isSticky: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default props => {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const hasAccess = useHasAccess()

  const dispatch = useDispatch()

  const toggleInsightSticky = insightId => {
    const { id, frontend_settings } = currentUser
    const { stickyInsightsIds = [] } = frontend_settings ?? {}
    const isSticky = stickyInsightsIds.includes(insightId)

    return modifyUser(
      {
        id,
        data: {
          frontend_settings: {
            ...(frontend_settings ?? {}),
            stickyInsightsIds: isSticky
              ? without([insightId], stickyInsightsIds)
              : stickyInsightsIds.concat(insightId),
          },
        },
      },
      {
        onSuccess: () => {
          dispatch(showToast(isSticky ? "Removed from favorites." : "Added to favorites."))
        },
      },
    )
  }

  return <InsightTile {...props} hasAccess={hasAccess} toggleInsightSticky={toggleInsightSticky} />
}
