import React from "react"
import logo from "images/meiro-symbol-white-transparent.svg"

import "./AuthFormLayout.scss"

const AuthFormLayout: React.FC = ({ children }) => (
  <section className="user-form-screens">
    <div className="form-wrapper">
      <img src={logo} className="logo" alt="meiro cdp logo" />
      {children}
    </div>
    <header className="caption">
      <div className="content-wrapper">
        <h1>Meiro Business Explorer</h1>
        <h2>
          All of your customer <span>data</span>: connected and <span>activated</span>.
        </h2>
      </div>
    </header>
  </section>
)

export default AuthFormLayout
