const mapping = {
  home: {
    path: "/",
    title: "Homepage",
  },

  login: {
    path: "/login",
    title: "Login",
  },
  "okta.callback": {
    path: "/okta_login",
    title: "Okta Callback Page",
  },
  logout: {
    path: "/logout",
    title: "Logout",
  },
  "password.reset": {
    path: "/password-reset",
    title: "Password reset",
  },
  "password.set": {
    path: "/set_password",
    title: "Set your password",
  },

  profiles: {
    path: "/profiles",
    title: "Customers",
  },
  "profiles.detail": {
    path: "/profiles/:id",
    title: "Detail - Customers",
  },

  segments: {
    path: "/segments",
    title: "Segments",
  },
  "segments.tags": {
    path: "/segments/tags",
    title: "Tags - Segments",
  },
  "segments.featured": {
    path: "/segments/featured",
    title: "Featured segments",
  },
  "segments.featured.trash": {
    path: "/segments/featured/trash",
    title: "Trash - Featured segments",
  },
  "segments.featured.detail": {
    path: "/segments/featured/:id",
    title: "Detail - Featured segments",
  },
  "segments.featured.detail.insights": {
    path: "/segments/featured/:id/insights",
    title: "Segment Analytics - Featured segments",
  },
  "segments.featured.detail.exports": {
    path: "/segments/featured/:id/exports",
    title: "Segment Exports - Featured segments",
  },
  "segments.featured.export.detail": {
    path: "/segments/featured/:id/exports/:eid",
    title: "Export detail - Featured segments",
  },
  "segments.smart": {
    path: "/segments/smart",
    title: "Smart segments",
  },
  "segments.smart.trash": {
    path: "/segments/smart/trash",
    title: "Trash - Smart segments",
  },
  "segments.smart.detail": {
    path: "/segments/smart/:id",
    title: "Segment Settings - Smart segments",
  },
  "segments.smart.detail.builder": {
    path: "/segments/smart/:id/builder",
    title: "Segment Condition Builder - Smart segments",
  },
  "segments.smart.detail.insights": {
    path: "/segments/smart/:id/insights",
    title: "Segment Analytics - Smart segments",
  },
  "segments.smart.detail.exports": {
    path: "/segments/smart/:id/exports",
    title: "Segment Exports - Smart segments",
  },
  "segments.smart.export.detail": {
    path: "/segments/smart/:id/exports/:eid/detail",
    title: "Detail - Smart segment export",
  },
  "segments.custom": {
    path: "/segments/custom",
    title: "Custom segments",
  },
  "segments.custom.trash": {
    path: "/segments/custom/trash",
    title: "Trash - Custom segments",
  },
  "segments.custom.detail": {
    path: "/segments/custom/:id",
    title: "Detail - Custom segments",
  },
  "segments.custom.detail.insights": {
    path: "/segments/custom/:id/insights",
    title: "Segment Analytics - Custom segments",
  },
  "segments.custom.detail.exports": {
    path: "/segments/custom/:id/exports",
    title: "Segment Exports - Custom segments",
  },
  "segments.custom.export.detail": {
    path: "/segments/custom/:id/exports/:eid/detail",
    title: "Detail - Custom segment Export",
  },

  analytics: {
    path: "/analytics",
    title: "Analytics",
  },
  "analytics.dashboard": {
    path: "/analytics/diagnostic-dashboard",
    title: "Dashboard - Analytics",
  },
  "analytics.insights": {
    path: "/analytics/insights",
    title: "Insights - Analytics",
  },
  "analytics.reporting": {
    path: "/analytics/reporting",
    title: "Reporting - Analytics",
  },

  data: {
    path: "/data",
    title: "Data",
  },
  "data.attributes": {
    path: "/data/attributes",
    title: "Attributes - Data",
  },
  "data.events": {
    path: "/data/events",
    title: "Events - Data",
  },
  "data.sources": {
    path: "/data/sources",
    title: "Sources - Data",
  },
  "data.destinations": {
    path: "/data/destinations",
    title: "Destinations - Data",
  },

  channels: {
    path: "/channels",
    title: "Channels",
  },
  "channels.popup-banners": {
    path: "/channels/popup-banners",
    title: "Popup Banners",
  },
  "channels.popup-banners.trash": {
    path: "/channels/popup-banners/trash",
    title: "Trash - Popup Banners",
  },
  "channels.popup-banners.create": {
    path: "/channels/popup-banners/create",
    title: "Create - Popup Banners",
  },
  "channels.popup-banners.detail": {
    path: "/channels/popup-banners/:id",
    title: "Detail - Popup Banners",
  },
  "channels.native-banners": {
    path: "/channels/native-banners",
    title: "Native Banners",
  },
  "channels.native-banners.trash": {
    path: "/channels/native-banners/trash",
    title: "Trash - Native Banners",
  },
  "channels.native-banners.create": {
    path: "/channels/native-banners/create",
    title: "Create - Native Banners",
  },
  "channels.native-banners.detail": {
    path: "/channels/native-banners/:id",
    title: "Detail - Native Banners",
  },
  "channels.mobile-push": {
    path: "/channels/mobile-push",
    title: "Mobile Push",
  },
  "channels.mobile-push.trash": {
    path: "/channels/mobile-push/trash",
    title: "Trash - Mobile Push",
  },
  "channels.mobile-push.create": {
    path: "/channels/mobile-push/create",
    title: "Create - Mobile Push",
  },
  "channels.mobile-push.detail": {
    path: "/channels/mobile-push/:id",
    title: "Detail - Mobile Push",
  },
  "channels.emails": {
    path: "/channels/emails/campaigns",
    title: "Emails",
  },
  "channels.emails.trash": {
    path: "/channels/emails/campaigns/trash",
    title: "Trash - Emails",
  },
  "channels.emails.create": {
    path: "/channels/emails/campaigns/create",
    title: "Create - Email",
  },
  "channels.emails.detail": {
    path: "/channels/emails/campaigns/:id",
    title: "Detail - Email",
  },
  "channels.emails.templates": {
    path: "/channels/emails/templates",
    title: "Email templates",
  },
  "channels.emails.templates.create": {
    path: "/channels/emails/templates/create",
    title: "Create - Email template",
  },
  "channels.emails.templates.detail": {
    path: "/channels/emails/templates/:id",
    title: "Detail - Email template",
  },
  "channels.promo-codes": {
    path: "/channels/codes",
    title: "Promo Codes",
  },

  administration: {
    path: "/administration",
    title: "Admin",
  },
  "administration.users": {
    path: "/administration/users",
    title: "Users - Administration",
  },
  "administration.users.trash": {
    path: "/administration/users/trash",
    title: "Trash - Users - Administration",
  },
  "administration.users.detail": {
    path: "/administration/users/:id",
    title: "Detail - Users - Administration",
  },
  "administration.roles": {
    path: "/administration/roles",
    title: "Roles - Administration",
  },
  "administration.roles.trash": {
    path: "/administration/roles/trash",
    title: "Trash - Roles - Administration",
  },
  "administration.settings": {
    path: "/administration/settings",
    title: "Settings - Administration",
  },
  "administration.users-activity": {
    path: "/administration/users-activity",
    title: "Users Activity - Administration",
  },
  "administration.sources": {
    path: "/administration/sources",
    title: "Sources - Setup",
  },
  "administration.sources.create": {
    path: "/administration/sources/create",
    title: "Create source - Setup",
  },
  "administration.sources.detail": {
    path: "/administration/sources/:id",
    title: "Source detail - Setup",
  },
  "administration.events": {
    path: "/administration/events",
    title: "Events - Setup",
  },
  "administration.events.create": {
    path: "/administration/events/create",
    title: "Event create - Setup",
  },
  "administration.events.detail": {
    path: "/administration/events/:id",
    title: "Event detail - Setup",
  },
  "administration.identity-stitching": {
    path: "/administration/identity-stitching",
    title: "Identity Stitching - Setup",
  },
  "administration.identity-stitching.create": {
    path: "/administration/identity-stitching/create",
    title: "Identity Stitching create - Setup",
  },
  "administration.identity-stitching.detail": {
    path: "/administration/identity-stitching/:id",
    title: "Identity Stitching detail - Setup",
  },
  "administration.attributes": {
    path: "/administration/attributes",
    title: "Attributes - Setup",
  },
  "administration.attributes.detail": {
    path: "/administration/attributes/:id",
    title: "Attribute detail - Setup",
  },
  "administration.attributes.create": {
    path: "/administration/attributes/create",
    title: "Attribute create - Setup",
  },
  "administration.metaAttributes": {
    path: "/administration/meta-attributes",
    title: "Meta attributes - Setup",
  },
  "administration.metaAttributes.detail": {
    path: "/administration/meta-attributes/:id",
    title: "Meta attribute detail - Setup",
  },
  "administration.metaAttributes.create": {
    path: "/administration/meta-attributes/create",
    title: "Meta attribute create - Setup",
  },
  "administration.labels": {
    path: "/administration/labels",
    title: "Labels - Setup",
  },
  "administration.insights": {
    path: "/administration/insights",
    title: "Insights - Setup",
  },
  "administration.destinations": {
    path: "/administration/destinations",
    title: "Destinations - Setup",
  },
  "administartion.destinations.trash": {
    path: "/administration/destinations/trash",
    title: "Destinations - Trash",
  },
  "administration.destinations.detail": {
    path: "/administration/destinations/:id",
    title: "Destination detail - Setup",
  },
  "administration.channels": {
    path: "/administration/channels",
    title: "Channels - Setup",
  },
  "administration.channels.push-notifications": {
    path: "/administration/channels/push-notifications",
    title: "Push notifications channel - Setup",
  },
  "administration.channels.emails": {
    path: "/administration/channels/emails",
    title: "Emails channel - Setup",
  },
  "administration.reports": {
    path: "/administration/reports",
    title: "Reports - Setup",
  },
  "administration.reports.create": {
    path: "/administration/reports/create",
    title: "Report create - Setup",
  },
  "administration.reports.detail": {
    path: "/administration/reports/:id",
    title: "Report detail - Setup",
  },
  "user-settings": {
    path: "/user-settings",
    title: "User Settings",
  },
  "not-found": {
    path: "/not-found",
    title: "404 - Not found",
  },
  "not-authorized": {
    path: "/not-authorized",
    title: "403 - Not authorized",
  },

  "DEPRECATED.customers": {
    path: "/customers",
  },
  "DEPRECATED.customers.detail": {
    path: "/customers/:id",
  },

  "DEPRECATED.segments.detail": {
    path: "/segments/:id",
  },
  "DEPRECATED.segments.detail.insights": {
    path: "/segments/:id/insights",
  },
  "DEPRECATED.segments.detail.exports": {
    path: "/segments/:id/exports",
  },
  "DEPRECATED.segments.export.detail": {
    path: "/segments/:id/exports/:eid/detail",
  },

  "DEPRECATED.reporting": {
    path: "/reporting",
  },

  "DEPRECATED.personalization": {
    path: "/personalization",
  },
  "DEPRECATED.personalization.popup-web-banners": {
    path: "/personalization/popup-web-banners",
  },
  "DEPRECATED.personalization.popup-web-banners.create": {
    path: "/personalization/popup-web-banners/create",
  },
  "DEPRECATED.personalization.popup-web-banners.detail": {
    path: "/personalization/popup-web-banners/:id",
  },
  "DEPRECATED.personalization.embedded-web-banners": {
    path: "/personalization/embedded-web-banners",
  },
  "DEPRECATED.personalization.embedded-web-banners.create": {
    path: "/personalization/embedded-web-banners/create",
  },
  "DEPRECATED.personalization.embedded-web-banners.detail": {
    path: "/personalization/embedded-web-banners/:id",
  },
  "DEPRECATED.personalization.mobile-push-notifications": {
    path: "/personalization/mobile-push-notifications",
  },
  "DEPRECATED.personalization.mobile-push-notifications.create": {
    path: "/personalization/mobile-push-notifications/create",
  },
  "DEPRECATED.personalization.mobile-push-notifications.detail": {
    path: "/personalization/mobile-push-notifications/:id",
  },
  "DEPRECATED.personalization.mobile-push-notifications.export-detail": {
    path: "/personalization/mobile-push-notifications/:id/segments/:segment_id/exports/:eid",
  },
  "DEPRECATED.personalization.emails": {
    path: "/personalization/emails",
  },
  "DEPRECATED.personalization.promo-codes": {
    path: "/personalization/codes",
  },

  "DEPRECATED.setup.sources": {
    path: "/setup/sources",
  },
  "DEPRECATED.setup.sources.create": {
    path: "/setup/sources/create",
  },
  "DEPRECATED.setup.sources.detail": {
    path: "/setup/sources/:id",
  },
  "DEPRECATED.setup.events": {
    path: "/setup/events",
  },
  "DEPRECATED.setup.events.create": {
    path: "/setup/events/create",
  },
  "DEPRECATED.setup.events.detail": {
    path: "/setup/events/:id",
  },
  "DEPRECATED.setup.identity-stitching": {
    path: "/setup/identity-stitching",
  },
  "DEPRECATED.setup.identity-stitching.create": {
    path: "/setup/identity-stitching/create",
  },
  "DEPRECATED.setup.identity-stitching.detail": {
    path: "/setup/identity-stitching/:id",
  },
  "DEPRECATED.setup.attributes": {
    path: "/setup/attributes",
  },
  "DEPRECATED.setup.attributes.detail": {
    path: "/setup/attributes/:id",
  },
  "DEPRECATED.setup.attributes.create": {
    path: "/setup/attributes/create",
  },
  "DEPRECATED.setup.metaAttributes": {
    path: "/setup/meta-attributes",
  },
  "DEPRECATED.setup.metaAttributes.detail": {
    path: "/setup/meta-attributes/:id",
  },
  "DEPRECATED.setup.metaAttributes.create": {
    path: "/setup/meta-attributes/create",
  },
  "DEPRECATED.setup.labels": {
    path: "/setup/labels",
  },
  "DEPRECATED.setup.segments.smart.detail.builder": {
    path: "/setup/smart-segments/:id/builder",
  },
  "DEPRECATED.setup.segments.smart.detail.exports": {
    path: "/setup/smart-segments/:id/exports",
  },
  "DEPRECATED.setup.segments.smart.detail": {
    path: "/setup/smart-segments/:id",
  },
  "DEPRECATED.setup.segments.smart": {
    path: "/setup/smart-segments",
  },
  "DEPRECATED.setup.insights": {
    path: "/setup/insights",
  },
  "DEPRECATED.setup.destinations": {
    path: "/setup/destinations",
  },
  "DEPRECATED.setup.destinations.detail": {
    path: "/setup/destinations/:id",
  },
  "DEPRECATED.setup.reports": {
    path: "/setup/reports",
  },
  "DEPRECATED.setup.reports.create": {
    path: "/setup/reports/create",
  },
  "DEPRECATED.setup.reports.detail": {
    path: "/setup/reports/:id",
  },

  "DEPRECATED.data.sources-destinations": {
    path: "/data/sources-destinations",
  },

  "DEPREDACTED.administration.accounts": {
    path: "/administration/accounts",
  },
}

export type RouteName = keyof typeof mapping

export const getRoutesMapping = () => mapping

export const getRoutePath = (...args: Parameters<typeof getRoute>) => getRoute(...args).path

// export const getRouteRegex = (name: Parameters<typeof getRoute>[0]) => getRoute(name).regex

export const getRoute = (name: RouteName, params = {}, prefix = "") => {
  let route = { ...mapping[name] }
  route.path = replaceParamsInPath(route.path, params)
  route.path = applyPrefixToPath(route.path, prefix)
  return route
}

export const replaceParamsInPath = (path: string, params: { [key: string]: string } = {}) => {
  const paramsKeys = Object.keys(params)
  if (!paramsKeys.length) {
    return path
  }

  paramsKeys.forEach(property => {
    const reg = new RegExp(`:${property}(?![\\w\\d])`, "i")
    path = path.replace(reg, params[property])
  })

  return path
}

export const applyPrefixToPath = (path: string, prefix = "") => {
  if (!prefix.length) {
    return path
  }

  return `${prefix}${path}`
}
