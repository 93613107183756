import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { Segment, SegmentCountsStore, SegmentCountsMessage } from "./segmentTypes"
import { useContext, useEffect } from "react"
import { SocketContext } from "context/socket"
import { TOAST } from "sharedConstants"
import create from "zustand"
import { isNil, update, whereEq } from "ramda"
import { set as _set } from "lodash"

export const useSegmentCountsStore = create<SegmentCountsStore>(set => ({
  conditionsNumbers: [],
  conditionsNumbersFlat: [],
  exportDestinationResultsCount: [],
  clear: () =>
    set(() => ({
      conditionsNumbers: [],
      conditionsNumbersFlat: [],
      conditionsResultsCount: undefined,
      customersTotalCount: undefined,
      dataLoadEndTime: undefined,
      dataLoadStartTime: undefined,
      error: undefined,
      exportDestinationResultsCount: [],
      segmentId: undefined,
    })),
  setConditionsNumbers: (conditionPosition, count) =>
    set(state => {
      const path =
        conditionPosition.length === 0 ? "[0]" : conditionPosition.map(v => `[${v}]`).join("")
      const conditionsNumbersCopy = [...state.conditionsNumbers]
      _set(conditionsNumbersCopy, path, count)
      return {
        conditionsNumbers: conditionsNumbersCopy,
        // @ts-ignore
        conditionsNumbersFlat: conditionsNumbersCopy.flat(Infinity),
      }
    }),
  setConditionsResultsCount: conditionsResultsCount =>
    set(() => ({
      conditionsResultsCount: conditionsResultsCount,
    })),
  setCustomersTotalCount: customersTotalCount =>
    set(() => ({
      customersTotalCount,
    })),
  setDataLoadEndTime: dataLoadEndTime =>
    set(() => ({
      dataLoadEndTime,
    })),
  setDataLoadStartTime: dataLoadStartTime =>
    set(() => ({
      dataLoadStartTime,
    })),
  setError: error =>
    set(() => ({
      error,
    })),
  setExportDestinationResultsCount: args =>
    set(state => {
      const index = state.exportDestinationResultsCount.findIndex(
        whereEq({ exportDestinationId: args.exportDestinationId }),
      )

      return {
        exportDestinationResultsCount:
          index !== -1
            ? update(index, args, state.exportDestinationResultsCount)
            : [...state.exportDestinationResultsCount, args],
      }
    }),
  setSegmentId: id =>
    set(() => ({
      segmentId: id,
    })),
}))

export const useFetchSegmentNumbers = (id: Segment["id"]) => {
  const dispatch = useDispatch()

  const socket = useContext(SocketContext)

  const {
    segmentId,
    clear,
    setConditionsNumbers,
    setConditionsResultsCount,
    setCustomersTotalCount,
    setDataLoadEndTime,
    setDataLoadStartTime,
    setExportDestinationResultsCount,
    setError,
    setSegmentId,
  } = useSegmentCountsStore()

  useEffect(() => {
    socket.on("segment_counts_response", (msg: SegmentCountsMessage) => {
      if (msg.segment_id === id) {
        setSegmentId(msg.segment_id)

        if (msg.error && msg.count_type !== "export_destination_results_count") {
          setError(msg.error)
          dispatch(showToast(msg.error, TOAST.TYPE.ERROR))
        } else {
          if (!msg.count_type) {
            setDataLoadEndTime(msg.data_load_end_time)
            setDataLoadStartTime(msg.data_load_start_time)
          } else {
            switch (msg.count_type) {
              case "condition_results_count": {
                if (Array.isArray(msg.condition_position) && !isNil(msg.count)) {
                  setConditionsNumbers(msg.condition_position, msg.count)
                }
                break
              }
              case "customers_total_count": {
                setCustomersTotalCount(msg.count)
                break
              }
              case "export_destination_results_count": {
                if (!isNil(msg.count) && !isNil(msg.destination_id) && !isNil(msg.total_count))
                  setExportDestinationResultsCount({
                    count: msg.count,
                    exportDestinationId: msg.destination_id,
                    totalCount: msg.total_count,
                  })

                break
              }
              case "segment_results_count": {
                setConditionsResultsCount(msg.count)
                break
              }
            }
          }
        }
      }
    })

    if (!segmentId) socket.emit("segment_counts", { segment_id: id })

    return () => {
      socket.off("segment_counts_response")
    }
  }, [
    id,
    segmentId,
    socket,
    dispatch,
    setConditionsNumbers,
    setConditionsResultsCount,
    setCustomersTotalCount,
    setDataLoadEndTime,
    setDataLoadStartTime,
    setError,
    setExportDestinationResultsCount,
    setSegmentId,
  ])

  const refetch = ({ refreshCache }: { refreshCache?: boolean } = {}) => {
    clear()

    if (refreshCache) socket.emit("segment_counts", { segment_id: id, refresh_cache: true })
    else socket.emit("segment_counts", { segment_id: id })
  }

  return { refetch }
}
