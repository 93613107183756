import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { api } from "api"
import {
  FeaturedSegmentListResponse,
  Segment,
  SegmentListResponse,
  RegularSegmentSelectionState,
  SmartSegmentListResponse,
  SegmentSelectionState,
  SegmentType,
  SegmentCustomerEntitiesMessage,
  SegmentModifyPayload,
  SegmentCreatePayload,
} from "./segmentTypes"
import { useContext } from "react"
import { SocketContext } from "context/socket"
import { SEGMENT as _SEGMENT } from "sharedConstants"
import fetchAll from "helpers/fetchAll.helper"

export const SEGMENT = "segment" as const
export const CUSTOM = "custom" as const
export const FEATURED = "featured" as const
export const SMART = "smart" as const
export const CUSTOMERS = "customers" as const
export const ALL = "all" as const

export const useFetchRegularSegments = (
  options?: Partial<
    Pick<
      RegularSegmentSelectionState,
      | "orderBy"
      | "orderDir"
      | "searchTerm"
      | "selectedTags"
      | "showMy"
      | "showSharedWithMe"
      | "showForeign"
    > & { limit: number }
  >,
) => {
  const { data, ...rest } = useInfiniteQuery<
    SegmentListResponse<0>,
    string,
    SegmentListResponse<0>
  >(
    [
      SEGMENT,
      CUSTOM,
      options?.limit,
      options?.orderBy,
      options?.orderDir,
      options?.searchTerm,
      options?.selectedTags,
      options?.showMy,
      options?.showSharedWithMe,
      options?.showForeign,
    ],
    ({ pageParam }) =>
      api.segment.list(
        pageParam,
        options?.limit,
        options?.orderBy,
        options?.orderDir,
        options?.searchTerm,
        options?.selectedTags,
        0,
        options?.showMy ? 1 : 0,
        options?.showSharedWithMe ? 1 : 0,
        options?.showForeign ? 1 : 0,
      ),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.segments) : [] }
}

export const useFetchFeaturedSegments = (
  options?: Partial<
    Pick<SegmentSelectionState, "orderBy" | "orderDir" | "searchTerm" | "selectedTags"> & {
      limit: number
    }
  >,
) => {
  const { data, ...rest } = useInfiniteQuery<
    FeaturedSegmentListResponse<0>,
    string,
    FeaturedSegmentListResponse<0>
  >(
    [
      SEGMENT,
      FEATURED,
      options?.limit,
      options?.orderBy,
      options?.orderDir,
      options?.searchTerm,
      options?.selectedTags,
    ],
    ({ pageParam }) =>
      api.featuredSegment.list(
        pageParam,
        options?.limit,
        options?.searchTerm,
        options?.orderBy,
        options?.orderDir,
        options?.selectedTags,
        0,
      ),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.featured_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.featured_segments) : [] }
}

export const useFetchSmartSegments = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<SmartSegmentListResponse<0>, string>(
    [SEGMENT, SMART],
    ({ pageParam }) => api.smartSegment.list(pageParam, 50, "name", "ASC", 0),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.prebuilt_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      // BE doesn't offer possibility to filter by name
      select: !searchTerm
        ? undefined
        : ({ pages, ...restPages }) => ({
            ...restPages,
            pages: pages.map(({ prebuilt_segments, ...restSegment }) => ({
              ...restSegment,
              prebuilt_segments: prebuilt_segments.filter(({ name }) =>
                name.toLowerCase().includes(searchTerm.toLowerCase()),
              ),
            })),
          }),
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.prebuilt_segments) : [] }
}

export const useFetchAllSmartSegments = () =>
  useQuery([SEGMENT, SMART, ALL], () =>
    fetchAll({
      fetchFn: (offset, limit) => api.smartSegment.list(offset, limit, "id", "ASC", 0),
      key: "prebuilt_segments",
    }),
  )

export function useFetchSegmentById(
  id: Segment["id"],
  config?: UseQueryOptions<{ segment: Segment }, unknown, Segment, QueryKey>,
) {
  return useQuery([SEGMENT, id] as QueryKey, () => api.segment.retrieve(id!), {
    ...config,
    select: ({ segment }) => segment,
  })
}

export function useFetchSegmentsByIds(
  ids: Segment["id"][],
  queryConfig?: UseQueryOptions<{ segment: Segment }, unknown, { segment: Segment }, QueryKey>,
) {
  return useQueries({
    queries: ids.map(id => ({
      queryKey: [SEGMENT, id] as QueryKey,
      queryFn: () => api.segment.retrieve(id),
      ...queryConfig,
    })),
  })
}

export function useDeleteSegment(type: SegmentType = "regular") {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const toast =
    type === "regular"
      ? "Segment deleted."
      : type === "featured"
      ? "Featured segment deleted."
      : "Smart segment deleted."

  return useMutation((id: Segment["id"]) => api.segment.delete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([SEGMENT, CUSTOM])
      queryClient.invalidateQueries([SEGMENT, FEATURED])
      queryClient.invalidateQueries([SEGMENT, SMART])
      dispatch(showToast(toast))
    },
  })
}

export const useFetchSegmentCustomers = (
  id: Segment["id"],
  config?: UseInfiniteQueryOptions<SegmentCustomerEntitiesMessage, unknown>,
) => {
  const socket = useContext(SocketContext)

  const { data, ...rest } = useInfiniteQuery(
    [SEGMENT, id, CUSTOMERS] as QueryKey,
    ({ pageParam }) =>
      new Promise<SegmentCustomerEntitiesMessage>((resolve, reject) => {
        socket.on("segment_customer_entities_response", (msg: SegmentCustomerEntitiesMessage) => {
          if (msg.error) reject(msg)
          else resolve(msg)
        })

        socket.emit("segment_customer_entities", {
          segment_id: id,
          offset: pageParam,
          limit: _SEGMENT.CUSTOMER.ITEMS_PER_PAGE,
        })
      }),
    {
      ...config,
      refetchOnWindowFocus: false,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_entities === undefined ||
          last.customer_entities?.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      onSuccess: () => {
        socket.off("segment_customer_entities_response")
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.customer_entities) : [] }
}

export const useModifySegment = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, data }: { id: Segment["id"]; data: SegmentModifyPayload }) =>
      api.segment.modify(id, data),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([SEGMENT, CUSTOM])
        queryClient.invalidateQueries([SEGMENT, FEATURED])
        queryClient.invalidateQueries([SEGMENT, SMART])
        queryClient.invalidateQueries([SEGMENT, id])
      },
    },
  )
}

export const useCreateSegment = (type: SegmentType) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation((data: SegmentCreatePayload) => api.segment.create(data), {
    onSuccess: () => {
      switch (type) {
        case "regular":
          queryClient.invalidateQueries([SEGMENT, CUSTOM])
          dispatch(showToast("Custom segment created."))
          break
        case "featured":
          queryClient.invalidateQueries([SEGMENT, FEATURED])
          dispatch(showToast("Featured segment created."))
          break
        case "smart":
          queryClient.invalidateQueries([SEGMENT, SMART])
          dispatch(showToast("Smart segment created."))
          break
        default:
          queryClient.invalidateQueries([SEGMENT, CUSTOM])
          queryClient.invalidateQueries([SEGMENT, FEATURED])
          queryClient.invalidateQueries([SEGMENT, SMART])
          dispatch(showToast("Segment created."))
      }
    },
  })
}
