import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import classNames from "classnames"

import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import Button from "components/UI/elements/Button/Button"
import { required, email } from "helpers/validators.helper"
import { TOAST, NOTIFICATION } from "sharedConstants"
import { showToast } from "actions/toast.action"
import { getRoutePath } from "routes"
import { api } from "api"

import styles from "./PasswordReset.module.scss"

let recaptchaSiteKey = null
if (process.env.NODE_ENV === "production") {
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recaptchaToken: "",
    }
  }

  componentDidMount() {
    document.body.classList.add("toastify-on-top")
  }

  componentWillUnmount() {
    document.body.classList.remove("toastify-on-top")
  }

  onSubmit = async values => {
    if (!this.state.loading) {
      const { email } = values
      const { showToast, history } = this.props
      const { recaptchaToken: recaptcha_token } = this.state

      this.setState({ loading: true })
      await api.user
        .resetPassword({ email, recaptcha_token })
        .then(() => {
          showToast(NOTIFICATION.PASSWORD_RESET, TOAST.TYPE.SUCCESS)
          history.push(getRoutePath("login"))
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  setRecaptchaToken = recaptchaToken => {
    this.setState({ recaptchaToken })
  }

  render() {
    const { recaptchaToken } = this.state
    const { handleSubmit } = this.props

    return (
      <AuthFormLayout>
        <form className={styles.form} autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>
          <h2 className={styles.formTitle}>Password reset</h2>
          <div className="form-row">
            <Field
              name="email"
              component={TextField}
              label="Email"
              validate={[required, email]}
              placeholder="Email"
              autoFocus
            />
          </div>
          {recaptchaSiteKey && (
            <div className={classNames("form-row", styles.recaptcha)}>
              <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={this.setRecaptchaToken} />
            </div>
          )}
          <Button
            fullWidth
            disabled={recaptchaSiteKey && !recaptchaToken}
            loading={this.state.loading}
            size="big"
            type="submit"
            data-testid="reset-password-button"
          >
            Reset Password
          </Button>
          <Link className={styles.underformLink} to={getRoutePath("login")}>
            Back to login
          </Link>
        </form>
      </AuthFormLayout>
    )
  }
}

PasswordReset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

PasswordReset = reduxForm({
  form: "PasswordResetForm",
  touchOnBlur: false,
})(connect(null, { showToast })(PasswordReset))

export default PasswordReset
