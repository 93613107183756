import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import { api } from "api"
import { isNil, prop, reject, sort, update, whereEq } from "ramda"
import { useDispatch } from "react-redux"
import { ascend, descend } from "utilities/comparators"
import {
  EmailTemplateSelectionOptions,
  EmailTemplate,
  EmailTemplateListResponse,
  EmailTemplatePayload,
} from "./emailTemplateTypes"

const EMAIL_TEMPLATE_ALL_QK: QueryKey = ["emailTemplate", "all"]

function useEmailTemplatesQuery<T>(
  config?: UseQueryOptions<EmailTemplateListResponse, unknown, T, QueryKey>,
) {
  return useQuery(EMAIL_TEMPLATE_ALL_QK, api.email.template.list, config)
}

export function useFetchAllEmailTemplates(options?: EmailTemplateSelectionOptions) {
  return useEmailTemplatesQuery({
    select({ email_templates }) {
      if (!options) {
        return sort(ascend(prop("name")), email_templates)
      }

      const { orderBy, orderDir, searchTerm } = options

      const filteredTemplates = searchTerm
        ? email_templates.filter(({ name }) =>
            name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
          )
        : email_templates
      const comparator = orderDir === "ASC" ? ascend : descend

      return sort(comparator(prop(orderBy)), filteredTemplates)
    },
  })
}

export function useFetchEmailTemplateById(id: EmailTemplate["id"]) {
  return useEmailTemplatesQuery({
    enabled: !isNil(id),
    select: ({ email_templates }) => email_templates.find(whereEq({ id })) ?? null,
  })
}

export function useCreateEmailTemplate() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: EmailTemplatePayload }) => api.email.template.create(data),
    {
      onSuccess: ({ email_template }) => {
        queryClient.setQueryData<EmailTemplateListResponse>(EMAIL_TEMPLATE_ALL_QK, data => {
          if (!data) {
            return
          }

          return { ...data, email_templates: data.email_templates.concat(email_template) }
        })
        dispatch(showToast("Email template created."))
      },
    },
  )
}

export function useModifyEmailTemplate() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: EmailTemplate["id"]; data: EmailTemplatePayload }) =>
      api.email.template.modify(id, data),
    {
      onSuccess: ({ email_template }) => {
        queryClient.setQueryData<EmailTemplateListResponse>(EMAIL_TEMPLATE_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.email_templates.findIndex(({ id }) => id === email_template.id)

          const email_templates =
            index === -1
              ? data.email_templates.concat(email_template)
              : update(index, email_template, data.email_templates)

          return { ...data, email_templates }
        })
        dispatch(showToast("Email template modified."))
      },
    },
  )
}

export function useDeleteEmailTemplate() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id }: { id: EmailTemplate["id"] }) => api.email.template.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<EmailTemplateListResponse>(EMAIL_TEMPLATE_ALL_QK, data => {
        if (!data) return

        const email_templates = reject(whereEq({ id }), data.email_templates)

        return { ...data, email_templates }
      })

      dispatch(showToast("Email template deleted."))
    },
  })
}
