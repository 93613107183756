import React, { useState } from "react"
import styles from "./EmailTemplatesList.module.scss"
import {
  EmailTemplate,
  EmailTemplateSelectionState,
} from "resources/email/emailTemplate/emailTemplateTypes"
import create from "zustand"
import Page from "components/UI/Page/Page"
import SearchField from "components/UI/elements/SearchField"
import Button from "components/UI/elements/Button/Button"
import { Link, useHistory } from "react-router-dom"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import {
  useDeleteEmailTemplate,
  useFetchAllEmailTemplates,
} from "resources/email/emailTemplate/emailTemplateQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Table, { Column, Modified, Name } from "components/Table/Table"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { whereEq } from "ramda"
import IconButton from "components/UI/elements/IconButton/IconButton"
import EmailTypeBadge from "pages/Channels/Emails/components/EmailTypeBadge/EmailTypeBadge"

const useStore = create<EmailTemplateSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function EmailTemplatesList() {
  const history = useHistory()
  const { orderBy, orderDir, searchTerm, setSearchTerm, setSort } = useStore()
  const { data: emailTemplates = [], isLoading } = useFetchAllEmailTemplates({
    orderBy,
    orderDir,
    searchTerm,
  })
  const deleteMutation = useDeleteEmailTemplate()
  const [idToDelete, setIdToDelete] = useState<EmailTemplate["id"] | null>(null)
  const nameToDelete = emailTemplates.find(whereEq({ id: idToDelete }))?.name

  const columns: Column<EmailTemplate>[] = [
    {
      id: "name",
      label: "Name",
      gridTemplate: "auto",
      onSort: () => setSort("name"),
      renderCell: ({ name, content_json }) => (
        <>
          <Name name={name} />
          <EmailTypeBadge contentType={content_json === "{}" ? "html" : "json"} />
        </>
      ),
    },
    {
      id: "modified",
      label: "Modified at",
      gridTemplate: "max-content",
      onSort: () => setSort("modified"),
      renderCell: ({ modified, modified_by }) => (
        <Modified modifiedAt={`${modified}Z`} modifiedBy={modified_by} />
      ),
    },
    {
      id: "actions",
      gridTemplate: "max-content",
      renderCell: ({ id, name, content_html, content_json }) => (
        <div className={styles.buttons}>
          <Link
            to={{
              pathname: getRoutePath("channels.emails.create"),
              state: {
                emailTemplate: { name, content_html, content_json },
              },
            }}
          >
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="envelope-open-text"
              tooltip="Use template"
            />
          </Link>
          <Link
            to={{
              pathname: getRoutePath("channels.emails.templates.create"),
              state: {
                emailTemplate: { name: "copy of " + name, content_html, content_json },
              },
            }}
          >
            <IconButton color="black" size="xs" variant="outlined" icon="copy" tooltip="Copy" />
          </Link>
          <Link
            to={getRoutePath("channels.emails.templates.detail", {
              id,
            })}
          >
            <IconButton
              color="black"
              size="xs"
              variant="outlined"
              icon="pencil-alt"
              tooltip="Edit"
            />
          </Link>
          <IconButton
            color="red"
            icon="trash-alt"
            size="xs"
            tooltip="Delete"
            variant="outlined"
            onClick={_ => setIdToDelete(id)}
          />
        </div>
      ),
    },
  ]

  return (
    <Page
      title="Email templates"
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            wrapperClassName={styles.search}
          />
          <Button onClick={() => history.push(getRoutePath("channels.emails.templates.create"))}>
            + Create Template
          </Button>
        </>
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper>
          <Table columns={columns} data={emailTemplates} sortBy={orderBy} sortDir={orderDir} />
        </Paper>
      )}
      <ConfirmModal
        isLoading={deleteMutation.isLoading}
        open={!!idToDelete}
        action="delete"
        item={nameToDelete}
        title="Are you sure?"
        type="delete"
        what="email"
        handleClose={() => setIdToDelete(null)}
        handleConfirm={() =>
          deleteMutation.mutate(
            { id: idToDelete! },
            {
              onSuccess() {
                setIdToDelete(null)
              },
            },
          )
        }
      />
    </Page>
  )
}
