import React, { useEffect } from "react"
import { useParams, useRouteMatch } from "react-router-dom"

import ExportLog from "components/UI/components/ExportLog/ExportLog"
import {
  useFetchSegmentExportById,
  useFetchSegmentExportLogs,
} from "resources/segment/segmentExport/segmentExportQueries"
import { getRoutePath } from "routes"
import { SEGMENT } from "sharedConstants"
import { segmentConditionFromAPIFormat } from "resources/segment/segment/utilities/segmentConditionsAPITransform"

export default function SegmentExportLog() {
  const { id, eid } = useParams<{ id: string; eid: string }>()
  const featuredSegmentRouteMatch = useRouteMatch("/segments/featured/:id/exports/:eid/detail")
  const smartSegmentRouteMatch = useRouteMatch("/segments/smart/:id/exports/:eid/detail")

  const numSegmentId = parseInt(id)
  const numExportId = parseInt(eid)

  const {
    data: segmentExport,
    isLoading: isLoadingSegmentExport,
    refetch: refetchSegmentExport,
  } = useFetchSegmentExportById(numSegmentId, numExportId)
  const {
    data: logs,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingExportLogs,
    isLoading: isLoadingExportLogs,
    refetch: refetchExportLogs,
  } = useFetchSegmentExportLogs(numSegmentId, numExportId)

  useEffect(() => {
    if (segmentExport && ["waiting", "running"].includes(segmentExport.status)) {
      setTimeout(() => {
        refetchSegmentExport()
        refetchExportLogs()
      }, SEGMENT.EXPORT.REFRESH_INTERVAL)
    }
  }, [segmentExport, refetchSegmentExport, refetchExportLogs])

  if (!segmentExport) return null

  const goBackRoute = getRoutePath(
    smartSegmentRouteMatch
      ? "segments.smart.detail"
      : featuredSegmentRouteMatch
      ? "segments.featured.detail"
      : "segments.custom.detail",
    {
      id: segmentExport.segment_id,
    },
  )

  return (
    <ExportLog
      hasMoreLogs={hasNextPage}
      isFetchingLogs={isFetchingExportLogs}
      isLoadingLogs={isLoadingExportLogs}
      isLoadingExport={isLoadingSegmentExport}
      conditionsOperation={segmentConditionFromAPIFormat(
        segmentExport?.settings?.conditions_operation,
      )}
      goBackRoute={goBackRoute}
      logs={logs}
      name={segmentExport.name}
      stats={segmentExport.stats}
      userId={segmentExport.user_id}
      loadMoreLogs={fetchNextPage}
    />
  )
}
