import React, { Component } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { path } from "ramda"

// ui components
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import TagPicker from "components/UI/components/TagPicker"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SearchField from "components/UI/elements/SearchField"

// constants, helpers
import { MOMENT, MODAL, PERMISSION, DATEFNS } from "sharedConstants"
import { getRoutePath } from "routes"
import { getScheduleText } from "resources/segment/segment/utilities/segmentSchedulesUtils"
import Username from "components/Username/Username"
import {
  useCreateSegment,
  useDeleteSegment,
  useFetchRegularSegments,
} from "resources/segment/segment/segmentQueries"
import { useStore } from "./segmentSelectionStore"

import "./CustomSegmentsList.scss"
import Tippy from "@tippyjs/react"
import { withRouter } from "react-router"
import { format } from "date-fns"
import { useHasAccess, useHasSegmentPermission } from "resources/user/currentUserQueries"
import { getCurrentUserId, useAuthTokenStore } from "auth/auth"
import { abbreviateNumber } from "helpers/number.helper"
import Page from "components/UI/Page/Page"
import { Link } from "react-router-dom"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import Table from "components/Table/Table"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import { useFetchAllTags } from "resources/tag/tagQueries"

class SegmentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteModal: {
        open: false,
        item: null,
      },
    }
  }

  createSegment = async () => {
    const { createSegment, history } = this.props

    await createSegment(
      { name: "Untitled segment" },
      {
        onSuccess: ({ segment: { id } }) => {
          history.push(getRoutePath("segments.custom.detail", { id }))
        },
      },
    )
  }

  deleteSegment = () => {
    const { item } = this.state.deleteModal
    const { deleteSegment } = this.props

    deleteSegment(item.id)
    this.closeDeleteSegmentModal()
  }

  openDeleteSegmentModal = segment => evt => {
    evt.preventDefault()
    this.setState({
      deleteModal: {
        open: true,
        item: segment,
      },
    })
  }

  closeDeleteSegmentModal = () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        open: false,
      },
    })
  }

  renderSchedulingInfo = (scheduling, destinationParameters) => {
    const { destinations, areDestinationsFulfilled } = this.props

    if (!areDestinationsFulfilled) {
      return null
    }

    let tooltipContent = []
    const isScheduled = scheduling && scheduling.length > 0
    let schedules = {}
    if (isScheduled) {
      scheduling.forEach(entry => {
        if (entry.destination_ids && entry.destination_ids.length > 0) {
          const schedulesTexts = entry.schedules.map(schedule => getScheduleText(schedule))
          if (schedulesTexts && schedulesTexts.length > 0) {
            entry.destination_ids.forEach(destinationId => {
              schedules[destinationId] = schedulesTexts
            })
          }
        }
      })
      destinations.forEach(dst => {
        if (schedules[dst.id] && schedules[dst.id].length > 0) {
          let destinationName = dst.name

          tooltipContent.push(
            <div className={`destination-schedule ${dst.frontend_settings?.color}`} key={dst.id}>
              <SrcDstIcon destination={dst} white />
              <p>
                {destinationName} – {schedules[dst.id].join("; ")}
              </p>
            </div>,
          )
        }
      })
    }

    const hasTooltip = isScheduled && tooltipContent.length > 0

    return (
      <React.Fragment>
        <Tippy
          className="scheduling-tooltip"
          content={<>{hasTooltip ? tooltipContent : "Not scheduled"}</>}
        >
          <span className={`scheduling-icon ${hasTooltip ? "active" : ""}`}>
            <FontAwesomeIcon className="icon" icon={["far", "clock"]} />
          </span>
        </Tippy>
      </React.Fragment>
    )
  }

  render() {
    const { deleteModal } = this.state
    const {
      segments,
      tags,
      store,
      isLoading,
      isDeleting,
      hasAccess,
      hasSegmentPermission,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isCreatingSegment,
    } = this.props
    const {
      orderBy,
      orderDir,
      searchTerm,
      selectedTags,
      showMy,
      showSharedWithMe,
      showForeign,
      setSort,
      setSearchTerm,
      addTag,
      removeTag,
      toggleShowMy,
      toggleShowSharedWithMe,
      toggleShowForeign,
    } = store

    const columns = [
      {
        id: "name",
        label: "Name",
        gridTemplate: "2fr",
        onSort: () => setSort("name"),
        renderCell: segment => {
          const isForbidden = !hasSegmentPermission(segment.id)

          return (
            <div className="segment-name">
              {!isForbidden &&
                this.renderSchedulingInfo(
                  path(["settings", "scheduling"], segment),
                  path(["settings", "destination_parameters"], segment),
                )}
              <Tippy content="You have no permission to access." disabled={!isForbidden}>
                <span className="segment-name-text">{segment.name}</span>
              </Tippy>
            </div>
          )
        },
      },
      {
        id: "tags",
        label: "Tags",
        gridTemplate: "1.5fr",
        renderCell: segment => (
          <div className="tags-cell">
            {segment.tag_ids.map(tagId => {
              const tag = tags.find(({ id }) => id === tagId)
              if (tag) {
                return (
                  <Tag
                    key={tag.id}
                    color={tag.color ?? "primary"}
                    onClick={event => {
                      event.preventDefault()
                      addTag(tag.id)
                    }}
                  >
                    {tag.name}
                  </Tag>
                )
              }
              return null
            })}
          </div>
        ),
      },
      {
        id: "author_name",
        label: "Author",
        gridTemplate: "max-content",
        onSort: () => setSort("author_name"),
        renderCell: segment => <Username userId={segment.author_id} />,
      },
      {
        id: "segment_count",
        label: "Segmented",
        gridTemplate: "max-content",
        renderCell: segment =>
          typeof segment.customers_count === "number"
            ? `${abbreviateNumber(segment.customers_count)} profile${
                segment.customers_count !== 1 ? "s" : ""
              }`
            : null,
      },
      {
        id: "last_export",
        label: "Last export",
        gridTemplate: "max-content",
        onSort: () => setSort("last_export"),
        renderCell: segment =>
          segment.last_export === null
            ? "—"
            : moment.utc(segment.last_export).local().format(MOMENT.DATETIME_FORMAT),
      },
      {
        id: "created",
        label: "Modified",
        gridTemplate: "max-content",
        onSort: () => setSort("created"),
        renderCell: segment => (
          <div>
            <div>
              {format(new Date(`${segment.created.replace(/\s/g, "T")}Z`), DATEFNS.DATETIME_FORMAT)}
            </div>
            {segment.user_id && (
              <div className="modified-by">
                by <Username userId={segment.user_id} />
              </div>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        gridTemplate: "min-content",
        renderCell: segment => (
          <IconButton
            color="red"
            size="xs"
            onClick={this.openDeleteSegmentModal(segment)}
            disabled={hasSegmentPermission(segment.id) !== PERMISSION.WRITE}
            icon="trash-alt"
            tooltip="Delete"
            variant="outlined"
          />
        ),
      },
    ]

    return (
      <Page
        title="Custom segments"
        headerContent={
          <>
            <SearchField
              input={{ value: searchTerm, onChange: setSearchTerm }}
              placeholder="Search for name or tag"
              onClear={() => setSearchTerm("")}
              wrapperClassName="segments-search"
              data-testid="segments-search-form"
            />
            <Button
              onClick={_ => this.createSegment()}
              disabled={!hasAccess.segments.create}
              loading={isCreatingSegment}
            >
              + Create segment
            </Button>
          </>
        }
        className="segments-list"
      >
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <Paper>
            <div className="tag-filter">
              <div className="tags-wrapper">
                <div className="label-tags">
                  <span className="selected-tags">Filter by:</span>
                  <span className="tags-and-picker">
                    {selectedTags.map(tagId => {
                      const tag = tags.find(({ id }) => id === tagId)
                      return (
                        <Tag
                          key={tagId}
                          clickable={true}
                          color={tag?.color ?? "primary"}
                          onClick={() => removeTag(tagId)}
                        >
                          {tag?.name ?? "Deleted tag"}
                        </Tag>
                      )
                    })}
                    <TagPicker
                      selectedTagIds={selectedTags}
                      allTags={tags}
                      onTagSelect={tagId => addTag(tagId)}
                      className="selected-tags-picker"
                    />
                  </span>
                </div>
              </div>
              <div className="segment-filters">
                <span className="segment-filters-label">Filter:</span>
                <Button
                  className="segment-filter-button"
                  color={showMy && !showSharedWithMe && !showForeign ? "primary" : "grey"}
                  icon="user"
                  iconStyle="far"
                  variant={showMy && !showSharedWithMe && !showForeign ? "solid" : "outlined"}
                  onClick={() => toggleShowMy()}
                >
                  My segments
                </Button>
                <Button
                  className="segment-filter-button"
                  color={showSharedWithMe && !showMy && !showForeign ? "primary" : "grey"}
                  icon="users"
                  iconStyle="far"
                  variant={showSharedWithMe && !showMy && !showForeign ? "solid" : "outlined"}
                  onClick={() => toggleShowSharedWithMe()}
                >
                  Shared with me
                </Button>
                <Button
                  className="segment-filter-button"
                  color={showForeign && !showMy && !showSharedWithMe ? "primary" : "grey"}
                  icon="list"
                  iconStyle="far"
                  variant={showForeign && !showMy && !showSharedWithMe ? "solid" : "outlined"}
                  onClick={() => toggleShowForeign()}
                  disabled={!hasAccess.segments.listForeign}
                >
                  Others
                </Button>
              </div>
              <Link to={getRoutePath("segments.custom.trash")}>
                <Button
                  icon="trash-alt"
                  iconStyle="far"
                  type="button"
                  color="grey"
                  variant="outlined"
                >
                  Trash
                </Button>
              </Link>
            </div>
            <Table
              columns={columns}
              data={segments}
              sortBy={orderBy}
              sortDir={orderDir}
              getRowLink={({ id }) =>
                hasSegmentPermission(id)
                  ? getRoutePath("segments.custom.detail", { id })
                  : undefined
              }
              getRowClassName={({ id }) =>
                hasSegmentPermission(id) ? undefined : "disabled-segment"
              }
              emptyMessage="No segments found."
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </Paper>
        )}
        <ConfirmModal
          isLoading={isDeleting}
          open={deleteModal.open}
          type={MODAL.TYPE.DELETE}
          handleClose={this.closeDeleteSegmentModal}
          handleConfirm={this.deleteSegment}
          title="Delete segment"
          action="delete"
          what="segment"
          item={_get(deleteModal, "item.name", "")}
        />
      </Page>
    )
  }
}

SegmentsList.propTypes = {
  segments: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  deleteSegment: PropTypes.func.isRequired,
  mobilePushNotifications: PropTypes.array,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
}

SegmentsList = withRouter(SegmentsList)

export default props => {
  const store = useStore()
  const hasAccess = useHasAccess()

  const {
    data: segments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useFetchRegularSegments({
    limit: 20,
    orderBy: store.orderBy,
    orderDir: store.orderDir,
    searchTerm: store.searchTerm.trim(),
    selectedTags: store.selectedTags,
    showMy: store.showMy,
    showSharedWithMe: store.showSharedWithMe,
    showForeign: hasAccess.segments.listForeign ? store.showForeign : false,
  })

  const { mutateAsync: createSegment, isLoading: isCreatingSegment } = useCreateSegment("regular")
  const { mutate: deleteSegment, isLoading: isDeleting } = useDeleteSegment()

  const hasSegmentPermission = useHasSegmentPermission()
  const currentUserId = useAuthTokenStore(getCurrentUserId)
  const { data: destinations = [], isSuccess: areDestinationsFulfilled } = useFetchAllDestinations({
    sortByName: true,
  })
  const { data: tags = [] } = useFetchAllTags()

  return (
    <SegmentsList
      {...props}
      hasNextPage={hasNextPage}
      isDeleting={isDeleting}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      segments={segments}
      store={store}
      deleteSegment={deleteSegment}
      fetchNextPage={fetchNextPage}
      hasSegmentPermission={hasSegmentPermission}
      currentUserId={currentUserId}
      hasAccess={hasAccess}
      destinations={destinations}
      areDestinationsFulfilled={areDestinationsFulfilled}
      tags={tags}
      createSegment={createSegment}
      isCreatingSegment={isCreatingSegment}
    />
  )
}
