import { api } from "api"
import { queryClient } from "app/queryClient"
import { append, propEq, update } from "ramda"
import { NOTIFICATION_QK } from "resources/notification/notificationQueries"
import { USER_ALL_QK } from "resources/user/userQueries"
import { User, UserFull } from "resources/user/userTypes"
import store from "store"
import create from "zustand"

export const TOKEN_LS_KEY = "auth_token"

export let refreshTokenTimestamp: number

// Provides reactive state to react components, which cannot update based on changes in localStorage
export const useAuthTokenStore = create<{ authToken: string | null }>(set => ({
  authToken: window.localStorage.getItem(TOKEN_LS_KEY),
  setAuthToken: (authToken: string | null) => set({ authToken }),
}))

export function getAuthToken(): string | null {
  return window.localStorage.getItem(TOKEN_LS_KEY)
}

function setAuthToken(authToken: string) {
  window.localStorage.setItem(TOKEN_LS_KEY, authToken)
  useAuthTokenStore.setState({ authToken })
}

function removeAuthToken() {
  window.localStorage.removeItem(TOKEN_LS_KEY)
  useAuthTokenStore.setState({ authToken: null })
}

function getUserIdFromToken(token: string | null): User["id"] | null {
  if (!token) {
    return null
  }

  try {
    return JSON.parse(atob(token.split(".")[1])).user_id as User["id"]
  } catch {
    return null
  }
}

export function getCurrentUserId(state: { authToken: string | null }) {
  return getUserIdFromToken(state.authToken)
}

export function useCurrentUserId() {
  return useAuthTokenStore(getCurrentUserId)
}

export async function login(data: { email: string; password: string; recaptcha_token?: string }) {
  const response = await api.user.login(data)

  if (response?.token) {
    refreshTokenTimestamp = Date.now()
    setAuthToken(response.token)
  }
}

export async function oktaLogin(data: { code: string }) {
  const response = await api.user.oktaLogin(data)

  if (response?.token) {
    refreshTokenTimestamp = Date.now()
    setAuthToken(response.token)
  }
}

export async function logout() {
  api.user.logout()
  store.dispatch({ type: "CLEAN_UP" })
  queryClient.removeQueries(NOTIFICATION_QK)
  removeAuthToken()
}

export async function refreshToken() {
  const response = await api.user.refreshToken()

  if (response?.token) {
    refreshTokenTimestamp = Date.now()
    setAuthToken(response.token)
  }
}

export async function verifyUser() {
  const response = await api.user.me()

  if (response) {
    const currentUser = response.me

    queryClient.setQueryData<UserFull[]>(USER_ALL_QK, data => {
      if (!data) return [currentUser]

      const index = data.findIndex(propEq(currentUser.id, "id"))

      if (index === -1) {
        return append(currentUser, data)
      }

      return update(index, currentUser, data)
    })
  }
}
