import moment from "moment"
import humanizeDuration from "humanize-duration"

export const timeResolutionRangeText = (startDate, endDate, timeResolution) => {
  switch (timeResolution) {
    case "hour": {
      return `${moment.utc(startDate).local().format("D.M.YYYY HH:mm")}‑${moment
        .utc(endDate)
        .local()
        .format("HH:mm")}`
    }
    case "day": {
      return moment.utc(startDate).local().format("D.M.YYYY")
    }
    case "week": {
      return `${moment.utc(startDate).local().format("D.M.")}‑${moment
        .utc(endDate)
        .local()
        .format("D.M YYYY")}`
    }
    case "month": {
      return moment.utc(startDate).local().format("MMM YYYY")
    }
    case "year": {
      return moment.utc(startDate).local().format("YYYY")
    }
    default: {
      return ""
    }
  }
}

export const timeResolutionRangeArrayText = (startDate, endDate, timeResolution) => {
  switch (timeResolution) {
    case "hour": {
      return [
        `${moment.utc(startDate).local().format("HH:mm")}‑${moment
          .utc(endDate)
          .local()
          .format("HH:mm")}`,
        moment.utc(endDate).local().format("D.M.YYYY"),
      ]
    }
    case "day": {
      return moment.utc(startDate).local().format("D.M.YYYY")
    }
    case "week": {
      return [
        `${moment.utc(startDate).local().format("D.M.")}‑${moment
          .utc(endDate)
          .local()
          .format("D.M.")}`,
        moment.utc(endDate).local().format("YYYY"),
      ]
    }
    case "month": {
      return moment.utc(startDate).local().format("MMM YYYY")
    }
    case "year": {
      return moment.utc(startDate).local().format("YYYY")
    }
    default: {
      return ""
    }
  }
}

export const durationStringFromSeconds = (seconds, precision = "seconds", shortFormat = false) => {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)

  let dDisplay = "",
    hDisplay = "",
    mDisplay = "",
    sDisplay = ""
  if (shortFormat) {
    if (d > 0) dDisplay = `${d}d `
    if ((h > 0 && ["hours", "minutes", "seconds"].includes(precision)) || d === 0)
      hDisplay = `${h}h `
    if ((m > 0 && ["minutes", "seconds"].includes(precision)) || (d === 0 && h === 0))
      mDisplay = `${m}m `
    if ((s > 0 && precision === "seconds") || (d === 0 && h === 0 && s === 0)) sDisplay = `${s}s`
  } else {
    if (d > 0) dDisplay = d === 1 ? `${d} day ` : `${d} days `
    if ((h > 0 && ["hours", "minutes", "seconds"].includes(precision)) || d === 0)
      hDisplay = h === 1 ? `${h} hr ` : `${h} hrs `
    if ((m > 0 && ["minutes", "seconds"].includes(precision)) || (d === 0 && h === 0))
      mDisplay = m === 1 ? `${m} min ` : `${m} mins `
    if ((s > 0 && precision === "seconds") || (d === 0 && h === 0 && s === 0))
      sDisplay = s === 1 ? `${s} sec` : `${s} secs`
  }

  return dDisplay + hDisplay + mDisplay + sDisplay
}

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  delimiter: " ",
  spacer: "",
  maxDecimalPoints: 0,
})

// If a user is e.g. in UTC-2, doing `new Date("2022-02-28")` returns a Date object which is 28th
// February 00:00 in UTC, i.e. 27th February 22:00 in the user's local timezone. When formatting
// such a date back to YYYY-MM-DD, we get 2022-02-27. By adding `T00:00` we force the string to be
// interpreted as local timezone, giving us a Date object which is 28th February 00:00 in the user's
// local timezone, which then gets correctly converted back to a date string.
export const toLocalDate = dateString => new Date(`${dateString}T00:00`)

export const dbDateTimeToISO = dbDateTime => dbDateTime.replace(" ", "T") + "Z"
