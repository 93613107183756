import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import CustomSegmentDetail from "./CustomSegmentDetail/CustomSegmentDetail"
import CustomSegmentsList from "./CustomSegmentsList/CustomSegmentsList"

export default function CustomSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.custom")} exact component={CustomSegmentsList} />
      <Route
        path={getRoutePath("segments.custom.trash")}
        exact
        render={() => <Trash itemType="custom_segments" />}
      />
      <Route path={getRoutePath("segments.custom.detail")} component={CustomSegmentDetail} />
    </Switch>
  )
}
