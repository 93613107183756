import { Segment } from "resources/segment/segment/segmentTypes"
import { Attribute } from "resources/attribute/attributeTypes"
import { ConditionTree } from "types/conditionTree"
import { ISO8601DateTime } from "types/util"

export type DataType = "boolean" | "string" | "number" | "datetime" | "relative_datetime"

type CookieObject<T extends DataType | undefined> = {
  subject: "cookie"
  name: string | null
  type: T
}
type LSObject<T extends DataType | undefined> = {
  subject: "local_storage"
  key: string | null
  type: T
}
type GTMObject<T extends DataType | undefined> = {
  subject: "gtm"
  dl_name: string | null
  dl_key: string | null
  type: T
}
type HTTPObject<T extends DataType | undefined> = {
  subject: "http_request"
  url_template: string | null
  response_value_path: string | null
  type: T
}

export type AttributeLocation = "cookie" | "ls"

export const typedSubjects: ConditionSubject[] = ["cookie", "local_storage", "gtm", "http_request"]

export type HTTPIsOkCondition = {
  operator: "is_ok"
  subject: "http_request"
  url_template: string | null
  response_value_path: undefined
  type: undefined
}

export type SegmentCondition = {
  subject: "segment"
  operator: "in_segment" | "not_in_segment"
  segment_id: Segment["id"] | null
  attribute_id: Attribute["id"] | null
  attribute_location: AttributeLocation | null
  attribute_location_key: string | null
  type: undefined
}

export const settableStringSubjects = [
  "referrer",
  "page_title",
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_term",
  "utm_content",
] as const
export type SettableStringSubject = typeof settableStringSubjects[number]

type SettableOp = { operator: "is_set" | "is_not_set" }
type SettableSimpleCondition = SettableOp & { subject: SettableStringSubject }
type SettableCookieCondition = SettableOp & CookieObject<undefined>
type SettableLSCondition = SettableOp & LSObject<undefined>
type SettableGTMCondition = SettableOp & GTMObject<undefined>
type SettableHTTPCondition = SettableOp & HTTPObject<undefined>
type SettableCondition =
  | SettableSimpleCondition
  | SettableCookieCondition
  | SettableLSCondition
  | SettableGTMCondition
  | SettableHTTPCondition

type BooleanOp = { operator: "equals"; value: boolean }
type BooleanCookieCondition = BooleanOp & CookieObject<"boolean">
type BooleanLSCondition = BooleanOp & LSObject<"boolean">
type BooleanGTMCondition = BooleanOp & GTMObject<"boolean">
type BooleanHTTPCondition = BooleanOp & HTTPObject<"boolean">
export type BooleanCondition =
  | BooleanCookieCondition
  | BooleanLSCondition
  | BooleanGTMCondition
  | BooleanHTTPCondition

export const stringSubjects = ["hostname", "ip_address", "pathname", "url"] as const
export type StringSubject = typeof stringSubjects[number]

type StringSingleValueOp = {
  operator: "equals" | "not_equals" | "contains" | "not_contains"
  value: string | null
}
type StringSingleValueSimpleCondition = StringSingleValueOp & {
  subject: StringSubject | SettableStringSubject
}
type StringSingleValueCookieCondition = StringSingleValueOp & CookieObject<"string">
type StringSingleValueLSCondition = StringSingleValueOp & LSObject<"string">
type StringSingleValueGTMCondition = StringSingleValueOp & GTMObject<"string">
type StringSingleValueHTTPCondition = StringSingleValueOp & HTTPObject<"string">
export type StringSingleValueCondition =
  | StringSingleValueSimpleCondition
  | StringSingleValueCookieCondition
  | StringSingleValueLSCondition
  | StringSingleValueGTMCondition
  | StringSingleValueHTTPCondition

export const numberEnumSubjects = ["current_day_of_week"] as const
export type NumberEnumSubject = typeof numberEnumSubjects[number]

export const stringEnumSubjects = [
  "device",
  "os",
  "browser",
  "browser_language",
  "country_code",
] as const
export type StringEnumSubject = typeof stringEnumSubjects[number]

export const enumSubjects = (numberEnumSubjects as readonly ConditionSubject[]).concat(
  stringEnumSubjects,
)
export type EnumSubject = NumberEnumSubject | StringEnumSubject

type EnumSingleValueCondition = {
  operator: "equals" | "not_equals"
} & (
  | {
      value: string | null
      subject: StringEnumSubject
    }
  | {
      value: number | null
      subject: NumberEnumSubject
    }
)

export const numberSubjects = ["current_hour", "page_views_count"] as const
export type NumberSubject = typeof numberSubjects[number]

type NumberSingleValueOp = {
  operator: "equals" | "not_equals" | "lower" | "greater"
  value: number | null
}
type NumberSingleValueSimpleCondition = NumberSingleValueOp & { subject: NumberSubject }
type NumberSingleValueCookieCondition = NumberSingleValueOp & CookieObject<"number">
type NumberSingleValueLSCondition = NumberSingleValueOp & LSObject<"number">
type NumberSingleValueGTMCondition = NumberSingleValueOp & GTMObject<"number">
type NumberSingleValueHTTPCondition = NumberSingleValueOp & HTTPObject<"number">
type NumberSingleValueCondition =
  | NumberSingleValueSimpleCondition
  | NumberSingleValueCookieCondition
  | NumberSingleValueLSCondition
  | NumberSingleValueGTMCondition
  | NumberSingleValueHTTPCondition

export const datetimeSubjects = ["current_datetime"] as const
export type DatetimeSubject = typeof datetimeSubjects[number]

type DatetimeSingleValueOp = { operator: "lower" | "greater"; value: ISO8601DateTime | null }
type DatetimeSingleValueSimpleCondition = DatetimeSingleValueOp & { subject: DatetimeSubject }
type DatetimeSingleValueCookieCondition = DatetimeSingleValueOp & CookieObject<"datetime">
type DatetimeSingleValueLSCondition = DatetimeSingleValueOp & LSObject<"datetime">
type DatetimeSingleValueGTMCondition = DatetimeSingleValueOp & GTMObject<"datetime">
type DatetimeSingleValueHTTPCondition = DatetimeSingleValueOp & HTTPObject<"datetime">
type DatetimeSingleValueCondition =
  | DatetimeSingleValueSimpleCondition
  | DatetimeSingleValueCookieCondition
  | DatetimeSingleValueLSCondition
  | DatetimeSingleValueGTMCondition
  | DatetimeSingleValueHTTPCondition

export type RelativeDatetimeUnits = "minutes" | "hours" | "days"
export type RelativeDatetimeObject = { count: number | null; units: RelativeDatetimeUnits | null }

type RelativeDatetimeSingleValueOp = {
  operator: "lower" | "greater"
  value: RelativeDatetimeObject | null
}
type RelativeDatetimeSingleValueCookieCondition = RelativeDatetimeSingleValueOp &
  CookieObject<"relative_datetime">
type RelativeDatetimeSingleValueLSCondition = RelativeDatetimeSingleValueOp &
  LSObject<"relative_datetime">
type RelativeDatetimeSingleValueGTMCondition = RelativeDatetimeSingleValueOp &
  GTMObject<"relative_datetime">
type RelativeDatetimeSingleValueHTTPCondition = RelativeDatetimeSingleValueOp &
  HTTPObject<"relative_datetime">
type RelativeDatetimeSingleValueCondition =
  | RelativeDatetimeSingleValueCookieCondition
  | RelativeDatetimeSingleValueLSCondition
  | RelativeDatetimeSingleValueGTMCondition
  | RelativeDatetimeSingleValueHTTPCondition

type NumberRangeOp = { operator: "between"; min_value: number | null; max_value: number | null }
type NumberRangeSimpleCondition = NumberRangeOp & { subject: NumberSubject }
type NumberRangeCookieCondition = NumberRangeOp & CookieObject<"number">
type NumberRangeLSCondition = NumberRangeOp & LSObject<"number">
type NumberRangeGTMCondition = NumberRangeOp & GTMObject<"number">
type NumberRangeHTTPCondition = NumberRangeOp & HTTPObject<"number">
type NumberRangeCondition =
  | NumberRangeSimpleCondition
  | NumberRangeCookieCondition
  | NumberRangeLSCondition
  | NumberRangeGTMCondition
  | NumberRangeHTTPCondition

type DatetimeRangeOp = {
  operator: "between"
  min_value: ISO8601DateTime | null
  max_value: ISO8601DateTime | null
}
type DatetimeRangeSimpleCondition = DatetimeRangeOp & { subject: DatetimeSubject }
type DatetimeRangeCookieCondition = DatetimeRangeOp & CookieObject<"datetime">
type DatetimeRangeLSCondition = DatetimeRangeOp & LSObject<"datetime">
type DatetimeRangeGTMCondition = DatetimeRangeOp & GTMObject<"datetime">
type DatetimeRangeHTTPCondition = DatetimeRangeOp & HTTPObject<"datetime">
type DatetimeRangeCondition =
  | DatetimeRangeSimpleCondition
  | DatetimeRangeCookieCondition
  | DatetimeRangeLSCondition
  | DatetimeRangeGTMCondition
  | DatetimeRangeHTTPCondition

type RelativeDatetimeRangeOp = {
  operator: "between"
  min_value: RelativeDatetimeObject | null
  max_value: RelativeDatetimeObject | null
}
type RelativeDatetimeRangeCookieCondition = RelativeDatetimeRangeOp &
  CookieObject<"relative_datetime">
type RelativeDatetimeRangeLSCondition = RelativeDatetimeRangeOp & LSObject<"relative_datetime">
type RelativeDatetimeRangeGTMCondition = RelativeDatetimeRangeOp & GTMObject<"relative_datetime">
type RelativeDatetimeRangeHTTPCondition = RelativeDatetimeRangeOp & HTTPObject<"relative_datetime">
type RelativeDatetimeRangeCondition =
  | RelativeDatetimeRangeCookieCondition
  | RelativeDatetimeRangeLSCondition
  | RelativeDatetimeRangeGTMCondition
  | RelativeDatetimeRangeHTTPCondition

type StringArrayOp = {
  operator: "in" | "not_in" | "contains_any" | "not_contain_any"
  values: string[] | null
}
type StringArraySimpleCondition = StringArrayOp & {
  subject: StringSubject | SettableStringSubject | StringEnumSubject
}
type StringArrayCookieCondition = StringArrayOp & CookieObject<"string">
type StringArrayLSCondition = StringArrayOp & LSObject<"string">
type StringArrayGTMCondition = StringArrayOp & GTMObject<"string">
type StringArrayHTTPCondition = StringArrayOp & HTTPObject<"string">
export type StringArrayCondition =
  | StringArraySimpleCondition
  | StringArrayCookieCondition
  | StringArrayLSCondition
  | StringArrayGTMCondition
  | StringArrayHTTPCondition

type NumberArrayOp = { operator: "in" | "not_in"; values: number[] | null }
type NumberArraySimpleCondition = NumberArrayOp & { subject: NumberSubject | NumberEnumSubject }
type NumberArrayCookieCondition = NumberArrayOp & CookieObject<"number">
type NumberArrayLSCondition = NumberArrayOp & LSObject<"number">
type NumberArrayGTMCondition = NumberArrayOp & GTMObject<"number">
type NumberArrayHTTPCondition = NumberArrayOp & HTTPObject<"number">
type NumberArrayCondition =
  | NumberArraySimpleCondition
  | NumberArrayCookieCondition
  | NumberArrayLSCondition
  | NumberArrayGTMCondition
  | NumberArrayHTTPCondition

export type NoValueCondition =
  | SettableCondition
  | BooleanCondition
  | HTTPIsOkCondition
  | SegmentCondition

export type SingleValueCondition =
  | StringSingleValueCondition
  | EnumSingleValueCondition
  | NumberSingleValueCondition
  | DatetimeSingleValueCondition
  | RelativeDatetimeSingleValueCondition

export type RangeCondition =
  | NumberRangeCondition
  | DatetimeRangeCondition
  | RelativeDatetimeRangeCondition

export type ArrayCondition = StringArrayCondition | NumberArrayCondition

type NonEmptyLeafCondition =
  | NoValueCondition
  | SingleValueCondition
  | RangeCondition
  | ArrayCondition

export type StringCondition = StringSingleValueCondition | StringArrayCondition
export type NumberCondition =
  | NumberSingleValueCondition
  | NumberArrayCondition
  | NumberRangeCondition
export type DatetimeCondition = DatetimeSingleValueCondition | DatetimeRangeCondition
export type RelativeDatetimeCondition =
  | RelativeDatetimeSingleValueCondition
  | RelativeDatetimeRangeCondition

export type CookieCondition = { subject: "cookie" } & NonEmptyLeafCondition

export type LSCondition = { subject: "local_storage" } & NonEmptyLeafCondition

export type GTMCondition = { subject: "gtm" } & NonEmptyLeafCondition

export type HTTPCondition = { subject: "http_request" } & NonEmptyLeafCondition

export type TypedCondition = CookieCondition | LSCondition | GTMCondition | HTTPCondition

export type ConditionSubject = NonEmptyLeafCondition["subject"]
export type LeafOperator = NonEmptyLeafCondition["operator"]

export type EmptyLeafCondition = { operator: null; subject: ConditionSubject | null }
export type LeafCondition = EmptyLeafCondition | NonEmptyLeafCondition
export type NegationCondition = { operator: "negation"; operand: LeafCondition }
export type WBCondition = LeafCondition | NegationCondition

export type WBConditionObject = ConditionTree<WBCondition>

export enum ERROR {
  REQUIRED = "Cannot be empty",
  HOUR_OUT_OF_RANGE = "Must be integer between 0 and 23",
  HOUR_TOO_LARGE = "Hour cannot be larger than 23",
  HOUR_TOO_SMALL = "Hour cannot be smaller than 0",
  MAX_DATE_LESS_THAN_MIN = "Must be before the 'since' value",
  MAX_VALUE_LESS_THAN_MIN = "Must be higher than the minimum value",
  PAGE_VIEWS_OUT_OF_RANGE = "Must be integer between 0 and 999",
  PAGE_VIEWS_TOO_LARGE = "Page view count cannot be larger than 999",
  PAGE_VIEWS_TOO_SMALL = "Page view count cannot be smaller than 0",
}

export type WBConditionError = {
  subject?: ERROR | string
  name?: ERROR | string
  key?: ERROR | string
  dl_name?: ERROR | string
  dl_key?: ERROR | string
  // type?: ERROR | string
  operator?: ERROR | string
  url_template?: ERROR | string
  response_value_path?: ERROR | string
  min_value?: ERROR | string
  max_value?: ERROR | string
  value?: ERROR | string
  values?: ERROR | string
  relative_datetime_value?: { count?: ERROR | string; units?: ERROR | string }
  relative_datetime_min_value?: { count?: ERROR | string; units?: ERROR | string }
  relative_datetime_max_value?: { count?: ERROR | string; units?: ERROR | string }
  segment_id?: ERROR | string
  attribute_id?: ERROR | string
  attribute_location?: ERROR | string
  attribute_location_key?: ERROR | string
}
