import {
  ArrayCondition,
  BooleanCondition,
  ConditionSubject,
  CookieCondition,
  datetimeSubjects,
  EmptyLeafCondition,
  GTMCondition,
  LeafCondition,
  LeafOperator,
  LSCondition,
  NegationCondition,
  NoValueCondition,
  numberSubjects,
  RangeCondition,
  settableStringSubjects,
  SingleValueCondition,
  TypedCondition,
  DataType,
  stringSubjects,
  WBConditionObject,
  HTTPCondition,
  typedSubjects,
  RelativeDatetimeObject,
  SegmentCondition,
  StringSingleValueCondition,
  StringArrayCondition,
  numberEnumSubjects,
  stringEnumSubjects,
} from "resources/webBanner/webBannerConditionTypes"

export const isEmptyCondition = (condition: LeafCondition): condition is EmptyLeafCondition =>
  condition.operator === null

export const isCookieCondition = (condition: LeafCondition): condition is CookieCondition =>
  condition.subject === "cookie"

export const isLSCondition = (condition: LeafCondition): condition is LSCondition =>
  condition.subject === "local_storage"

export const isGTMCondition = (condition: LeafCondition): condition is GTMCondition =>
  condition.subject === "gtm"

export const isHTTPCondition = (condition: LeafCondition): condition is HTTPCondition =>
  condition.subject === "http_request"

export const isSegmentCondition = (condition: LeafCondition): condition is SegmentCondition =>
  condition.subject === "segment"

export const isTypedCondition = (condition: LeafCondition): condition is TypedCondition =>
  isCookieCondition(condition) ||
  isLSCondition(condition) ||
  isGTMCondition(condition) ||
  isHTTPCondition(condition)

const isNoValueOp = (operator: LeafOperator | null) =>
  operator === "is_set" ||
  operator === "is_not_set" ||
  operator === "is_ok" ||
  operator === "in_segment" ||
  operator === "not_in_segment"

export const isNoValueCondition = (condition: LeafCondition): condition is NoValueCondition =>
  isNoValueOp(condition.operator)

const isSingleValueOp = (operator: LeafOperator | null) =>
  operator === "equals" ||
  operator === "not_equals" ||
  operator === "contains" ||
  operator === "not_contains" ||
  operator === "lower" ||
  operator === "greater"

export const isSingleValueCondition = (
  condition: LeafCondition,
): condition is SingleValueCondition => isSingleValueOp(condition.operator)

const isBooleanCondition = (condition: LeafCondition): condition is BooleanCondition => {
  const value = (condition as BooleanCondition).value
  return Boolean(value) === value
}

const isStringCondition = (condition: SingleValueCondition): condition is SingleValueCondition =>
  (stringSubjects as readonly string[]).includes(condition.subject) ||
  (settableStringSubjects as readonly string[]).includes(condition.subject) ||
  (isTypedCondition(condition) && condition.type === "string")

const isRangeOp = (operator: LeafOperator | null) => operator === "between"

export const isRangeCondition = (condition: LeafCondition): condition is RangeCondition =>
  isRangeOp(condition.operator)

const isArrayOp = (operator: LeafOperator | null) =>
  operator === "in" ||
  operator === "not_in" ||
  operator === "contains_any" ||
  operator === "not_contain_any"

export const isArrayCondition = (condition: LeafCondition): condition is ArrayCondition =>
  isArrayOp(condition.operator)

export const isNegationCondition = (condition: WBConditionObject): condition is NegationCondition =>
  condition.operator === "negation"

export const areOperatorsSameKind = (op1: LeafOperator | null, op2: LeafOperator | null) =>
  (op1 === null && op2 === null) ||
  (isNoValueOp(op1) && isNoValueOp(op2)) ||
  (isSingleValueOp(op1) && isSingleValueOp(op2)) ||
  (isRangeOp(op1) && isRangeOp(op2)) ||
  (isArrayOp(op1) && isArrayOp(op2))

export const settableOperators: LeafOperator[] = ["is_set", "is_not_set"]
const booleanOperators: LeafOperator[] = ["equals"]
const stringOperators: LeafOperator[] = [
  "equals",
  "not_equals",
  "contains",
  "not_contains",
  "in",
  "not_in",
  "contains_any",
  "not_contain_any",
]
const stringEnumOperators: LeafOperator[] = [
  "equals",
  "not_equals",
  "in",
  "not_in",
  "contains_any",
  "not_contain_any",
]
const numberEnumOperators: LeafOperator[] = ["equals", "not_equals", "in", "not_in"]
const numberOperators: LeafOperator[] = ["equals", "not_equals", "lower", "greater", "between"]
const datetimeOperators: LeafOperator[] = ["lower", "greater", "between"]
const segmentOperators: LeafOperator[] = ["in_segment", "not_in_segment"]

export function getOperatorsForSubject(
  subject: ConditionSubject | null,
  type?: DataType | null,
): LeafOperator[] | null {
  if (subject === null) return null
  if (typedSubjects.includes(subject)) {
    switch (type) {
      case null:
      case undefined:
        if (subject === "http_request") {
          return [...settableOperators, "is_ok"] as LeafOperator[]
        }
        return settableOperators
      case "boolean":
        return booleanOperators
      case "string":
        return stringOperators
      case "number":
        return numberOperators
      case "datetime":
      case "relative_datetime":
        return datetimeOperators
    }
  }
  if (subject === "segment") {
    return segmentOperators
  }
  if ((settableStringSubjects as readonly string[]).includes(subject)) {
    return settableOperators.concat(stringOperators)
  }
  if ((stringSubjects as readonly string[]).includes(subject)) {
    return stringOperators
  }
  if ((numberEnumSubjects as readonly string[]).includes(subject)) {
    return numberEnumOperators
  }
  if ((stringEnumSubjects as readonly string[]).includes(subject)) {
    return stringEnumOperators
  }
  if ((numberSubjects as readonly string[]).includes(subject)) {
    return numberOperators
  }
  if ((datetimeSubjects as readonly string[]).includes(subject)) {
    return datetimeOperators
  }

  return null
}

export function isOperatorAllowed(
  operator: LeafOperator | null,
  subject: ConditionSubject | null,
  type?: DataType,
) {
  return operator === null || getOperatorsForSubject(subject, type)?.includes(operator)
}

export function getConditionWithDefaultValues(condition: LeafCondition): LeafCondition {
  if (isBooleanCondition(condition)) {
    return condition
  }

  if (isEmptyCondition(condition) || isNoValueCondition(condition)) {
    if (isCookieCondition(condition)) {
      const { name, operator, subject, type } = condition
      return { name, operator, subject, type } as CookieCondition
    }
    if (isLSCondition(condition)) {
      const { key, operator, subject, type } = condition
      return { key, operator, subject, type } as LSCondition
    }
    if (isGTMCondition(condition)) {
      const { dl_key, dl_name, operator, subject, type } = condition
      return { dl_key, dl_name, operator, subject, type } as GTMCondition
    }
    if (isHTTPCondition(condition)) {
      if (condition.operator === "is_ok") {
        const { operator, subject, url_template } = condition
        return { operator, subject, url_template, response_value_path: undefined, type: undefined }
      }
      const { operator, subject, url_template, response_value_path, type } = condition
      return {
        operator,
        subject,
        url_template,
        response_value_path: response_value_path || null,
        type,
      } as HTTPCondition
    }
    if (isSegmentCondition(condition)) {
      const {
        segment_id,
        attribute_id,
        attribute_location,
        attribute_location_key,
        operator,
        subject,
        type,
      } = condition
      return {
        segment_id,
        attribute_id,
        attribute_location: attribute_location ?? "cookie",
        attribute_location_key: attribute_location_key ?? "meiro_user_id",
        operator,
        subject,
        type,
      }
    }
    const { operator, subject } = condition
    return { operator, subject } as EmptyLeafCondition
  }
  if (isSingleValueCondition(condition)) {
    // @ts-ignore
    const { values, min_value, max_value, ...rest } = condition
    // @ts-ignore
    return { ...rest, value: isStringCondition(condition) ? "" : null }
  }
  if (isRangeCondition(condition)) {
    // @ts-ignore
    const { values, value, ...rest } = condition
    return { ...rest, min_value: null, max_value: null }
  }
  if (isArrayCondition(condition)) {
    // @ts-ignore
    const { min_value, max_value, value, ...rest } = condition
    return { ...rest, values: null }
  }
  return condition
}

export function getConditionWithNewSubject(
  condition: LeafCondition,
  newSubject: ConditionSubject,
): LeafCondition {
  if (typedSubjects.includes(newSubject) && isTypedCondition(condition)) {
    if (newSubject === "cookie") {
      if (isLSCondition(condition)) {
        const { key, ...rest } = condition
        return { ...rest, name: key, subject: newSubject }
      }
      if (isGTMCondition(condition)) {
        const { dl_name, dl_key, ...rest } = condition
        return { ...rest, name: null, subject: newSubject }
      }
      if (isHTTPCondition(condition)) {
        if (condition.operator === "is_ok") {
          return { subject: newSubject, operator: null }
        }
        const { response_value_path, url_template, ...rest } = condition
        return { ...rest, name: null, subject: newSubject }
      }
    }

    if (newSubject === "local_storage") {
      if (isCookieCondition(condition)) {
        const { name, ...rest } = condition
        return { ...rest, key: name, subject: newSubject }
      }
      if (isGTMCondition(condition)) {
        const { dl_name, dl_key, ...rest } = condition
        return { ...rest, key: null, subject: newSubject }
      }
      if (isHTTPCondition(condition)) {
        if (condition.operator === "is_ok") {
          return { subject: newSubject, operator: null }
        }
        const { response_value_path, url_template, ...rest } = condition
        return { ...rest, key: null, subject: newSubject }
      }
    }

    if (newSubject === "gtm") {
      if (isCookieCondition(condition)) {
        const { name, ...rest } = condition
        return {
          ...rest,
          dl_key: null,
          dl_name: null,
          subject: newSubject,
        }
      }
      if (isLSCondition(condition)) {
        const { key, ...rest } = condition
        return {
          ...rest,
          dl_key: null,
          dl_name: null,
          subject: newSubject,
        }
      }
      if (isHTTPCondition(condition)) {
        if (condition.operator === "is_ok") {
          return { subject: newSubject, operator: null }
        }
        const { response_value_path, url_template, ...rest } = condition
        return {
          ...rest,
          dl_key: null,
          dl_name: null,
          subject: newSubject,
        }
      }
    }

    if (newSubject === "http_request") {
      if (isCookieCondition(condition)) {
        const { name, ...rest } = condition
        return {
          ...rest,
          response_value_path: null,
          url_template: null,
          subject: newSubject,
        }
      }
      if (isLSCondition(condition)) {
        const { key, ...rest } = condition
        return {
          ...rest,
          response_value_path: null,
          url_template: null,
          subject: newSubject,
        }
      }
      if (isGTMCondition(condition)) {
        const { dl_name, dl_key, ...rest } = condition
        return {
          ...rest,
          response_value_path: null,
          url_template: null,
          subject: newSubject,
        }
      }
    }
  }

  const { operator } = condition
  const newOperator = isOperatorAllowed(operator, newSubject) ? operator : null

  // for other condition combinations, always wipe values when changing subject for now
  return getConditionWithDefaultValues({
    subject: newSubject,
    operator: newOperator,
  } as LeafCondition)
}

export function evaluateRelativeDatetime({ count, units }: RelativeDatetimeObject) {
  if (count === null) return 0

  const msDifference =
    units === "days"
      ? 24 * 60 * 60 * 1000 * count
      : units === "hours"
      ? 60 * 60 * 1000 * count
      : 60 * 1000 * count

  return Date.now() + msDifference
}

export const isStringSingleValueCondition = (
  condition: LeafCondition,
): condition is StringSingleValueCondition =>
  isSingleValueCondition(condition) && typeof condition.value === "string"

export const isStringArrayCondition = (
  condition: LeafCondition,
): condition is StringArrayCondition =>
  // @ts-ignore
  isArrayCondition(condition) && condition.values?.every(v => typeof v === "string")
