import React, { Component } from "react"
import { useDispatch } from "react-redux"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { showToast } from "actions/toast.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { MOMENT, TOAST } from "sharedConstants"
import { getRoutePath } from "routes"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./SourcesList.scss"
import Tippy from "@tippyjs/react"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import { useFetchAllDataSources, useModifyDataSource } from "resources/dataSource/dataSourceQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import {
  useFetchGlobalSettings,
  useModifyGlobalSetting,
} from "resources/globalSettings/globalSettingsQueries"
import { without } from "ramda"

class Sources extends Component {
  goToSourceDetail = sourceId => () => {
    this.props.history.push(getRoutePath("administration.sources.detail", { id: sourceId }))
  }

  toggleHiddenAttribute = source => async () => {
    const changedIsHidden = source.is_hidden === 1 ? 0 : 1
    await this.props.modifyDataSource({ id: source.id, data: { is_hidden: changedIsHidden } })
    refetchAttributes()
    refetchEvents()
  }

  render() {
    const {
      sources,
      areSourcesFulfilled,
      globalSettings,
      areGlobalSettingsFulfilled,
      history,
      toggleSourceHiddenInDashboard,
    } = this.props
    const hiddenDashboardSourceIds =
      globalSettings?.["hidden_data_sources_from_diagnostic_dashboard"]?.value ?? []

    return (
      <Page
        className="setup-sources"
        title="Sources"
        headerContent={
          <Button
            onClick={() => {
              history.push(getRoutePath("administration.sources.create"))
            }}
          >
            + Create source
          </Button>
        }
      >
        {!areSourcesFulfilled && <LoadingIndicator />}

        {areSourcesFulfilled && sources.length > 0 && (
          <Paper hasHeader noPaddingTop>
            <Table className="setup-sources-table">
              <Thead stickyHeader>
                <Th />
                <Th className="name">Name</Th>
                <Th className="description">Description</Th>
                <Th className="diagnostic-dashboard-viz">
                  Show in Diagnostic Dashboard{" "}
                  <Tippy
                    content="The change will be visible after data cache expiration in the Diagnostic
                    Dashboard charts: NO. OF UNIQUE CUSTOMERS IDENTIFIED ACROSS X SOURCES, NO. OF
                    CUSTOMERS IDENTIFIED PER SOURCE and CONNECTED SOURCES. To quicken the update,
                    manually refresh the data cache in the Administration tab / Settings tab."
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={["fas", "info-circle"]}
                        data-tip
                        data-for="show-in-diagnostic-dashboard-tooltip"
                      />
                    </span>
                  </Tippy>
                </Th>
                <Th className="date-added">Date added</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {sources.map(source => {
                  const showInDashboard = !hiddenDashboardSourceIds.includes(source.id)
                  return (
                    <Tr key={source.id} className={source.is_hidden ? "is-hidden" : ""}>
                      <Td className="icon">
                        <div className="icon-container">
                          <SrcDstIcon source={source} />
                        </div>
                      </Td>
                      <Td
                        className="name"
                        withNewBadge={moment().diff(source.created, "days") < 8}
                        textBigger
                        textBold
                        textBlack
                      >
                        {source.name}
                      </Td>
                      <Td className="description">
                        {source.description ? `${source.description}` : "—"}
                      </Td>
                      <Td className="show-in-dd">
                        {areGlobalSettingsFulfilled && (
                          <ToggleButton
                            value={showInDashboard}
                            handleToggle={() => toggleSourceHiddenInDashboard(source.id)}
                            disabled={source.is_hidden === 1}
                          />
                        )}
                      </Td>
                      <Td className="created">
                        {moment.utc(source.created).local().format(MOMENT.DATE_FORMAT)}
                      </Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color="black"
                          size="xs"
                          onClick={this.goToSourceDetail(source.id)}
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />
                        <IconButton
                          color="red"
                          size="xs"
                          onClick={this.toggleHiddenAttribute(source)}
                          className="left-margin"
                          icon={source.is_hidden ? "eye" : "eye-slash"}
                          iconStyle="far"
                          tooltip={source.is_hidden ? "Show" : "Hide"}
                          variant="outlined"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Paper>
        )}
        {areSourcesFulfilled && sources.size === 0 && (
          <p className="info-message">Click on the "Create Source" to get started.</p>
        )}
      </Page>
    )
  }
}

export default props => {
  const { data = [], isSuccess } = useFetchAllDataSources({ showHidden: true })
  const { mutateAsync: modifyDataSource } = useModifyDataSource()
  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const { mutateAsync: modifyGlobalSetting } = useModifyGlobalSetting()
  const dispatch = useDispatch()
  const toggleSourceHiddenInDashboard = sourceId => {
    const hiddenSourcesSetting = globalSettings?.["hidden_data_sources_from_diagnostic_dashboard"]
    if (!hiddenSourcesSetting) {
      return
    }
    const { id, value: hiddenSourceIds } = hiddenSourcesSetting
    const isRemoving = hiddenSourceIds.includes(sourceId)
    return modifyGlobalSetting(
      {
        id,
        data: {
          value: isRemoving
            ? without([sourceId], hiddenSourceIds)
            : hiddenSourceIds.concat(sourceId),
        },
      },
      {
        onSuccess() {
          dispatch(
            showToast(
              `The data source will be ${
                isRemoving ? "shown" : "hidden"
              } from the Diagnostic Dashboard after the next data cache refresh. If you want to see the change now, refresh cache manually.`,
              TOAST.TYPE.INFO,
              getRoutePath("administration.settings"),
              true,
            ),
          )
        },
      },
    )
  }

  return (
    <Sources
      {...props}
      sources={data}
      areSourcesFulfilled={isSuccess}
      modifyDataSource={modifyDataSource}
      globalSettings={globalSettings}
      areGlobalSettingsFulfilled={areGlobalSettingsFulfilled}
      toggleSourceHiddenInDashboard={toggleSourceHiddenInDashboard}
    />
  )
}
