import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import { api } from "api"
import { useDispatch } from "react-redux"
import {
  ChannelSegmentCount,
  EmailsChannel,
  EmailsChannelPayload,
  EmailsChannelResponse,
  PushNotificationChannel,
  PushNotificationChannelReponse,
} from "./channelTypes"
import { useContext } from "react"
import { SocketContext } from "context/socket"
import { equals } from "ramda"

const CHANNEL = "channel" as const
const CHANNEL_PUSH_NOTIFICATIONS_QK: QueryKey = [CHANNEL, "pushNotifications"] as const
const CHANNEL_EMAILS_QK: QueryKey = [CHANNEL, "emails"] as const
const SEGMENT_COUNTS = "segmentCounts" as const
export const CHANNEL_SEGMENT_COUNTS_QK: QueryKey = [CHANNEL, SEGMENT_COUNTS]

export function useFetchChannels() {
  return useQuery([CHANNEL, "all"], () => api.channel.list(), {
    select: ({ channels }) => channels,
  })
}

export function useFetchPushNotificationsChannel(
  config?: UseQueryOptions<
    PushNotificationChannelReponse,
    unknown,
    PushNotificationChannel,
    QueryKey
  >,
) {
  return useQuery(CHANNEL_PUSH_NOTIFICATIONS_QK, api.channel.pushNotifications.retrieve, {
    ...config,
    select: ({ push_notification_channel }) => push_notification_channel,
  })
}

export function useModifyPushNotificationsChannel() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ data }: { data: FormData }) => api.channel.pushNotifications.modify(data), {
    onSuccess: ({ push_notification_channel }) => {
      queryClient.setQueryData(CHANNEL_PUSH_NOTIFICATIONS_QK, { push_notification_channel })
      dispatch(showToast("Channel modified."))
    },
  })
}

export function useFetchEmailChannel(
  config?: UseQueryOptions<EmailsChannelResponse, unknown, EmailsChannel, QueryKey>,
) {
  return useQuery(CHANNEL_EMAILS_QK, api.channel.emails.retrieve, {
    ...config,
    select: ({ email_channel }) => email_channel,
  })
}

export function useModifyEmailsChannel() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: EmailsChannelPayload }) => api.channel.emails.modify(data),
    {
      onSuccess: ({ email_channel }) => {
        queryClient.setQueryData(CHANNEL_EMAILS_QK, { email_channel })
        dispatch(showToast("Channel modified."))
      },
    },
  )
}

export function useFetchChannelSegmentCounts<
  T = ChannelSegmentCount,
  U = { segment_ids: ChannelSegmentCount["segment_ids"]; message: string },
>(
  payload: Pick<ChannelSegmentCount, "channel_type" | "segment_ids">,
  config?: UseQueryOptions<ChannelSegmentCount, U, T, QueryKey>,
) {
  const { channel_type, segment_ids } = payload
  const socket = useContext(SocketContext)
  const queryClient = useQueryClient()

  return useQuery(
    [...CHANNEL_SEGMENT_COUNTS_QK, channel_type, segment_ids] as QueryKey,
    () =>
      new Promise<ChannelSegmentCount>((resolve, reject) => {
        socket.on("channel_segment_counts_response", (msg: ChannelSegmentCount) => {
          if (
            msg.count_type === "channel_results_count" &&
            msg.channel_type === channel_type &&
            equals(Array.from(msg.segment_ids).sort(), Array.from(segment_ids).sort())
          ) {
            if (msg.error) {
              reject({ segment_ids: msg.segment_ids, message: msg.error })
            } else {
              resolve(msg)
            }
          }
        })

        socket.emit("channel_segment_counts", {
          channel_type,
          segment_ids,
        })
      }),
    {
      ...config,
      enabled: segment_ids.length > 0,
      placeholderData: { channel_type, segment_ids },
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: () => {
        // do not off socket if there is any other query being fetched in parallel
        if (!queryClient.isFetching({ queryKey: CHANNEL_SEGMENT_COUNTS_QK })) {
          socket.off("channel_segment_counts_response")
        }
      },
    },
  ) as UseQueryResult<T, U>
}
