import React, { Suspense, lazy, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import IdentityGraphFilter from "../IdentityGraphFilter/IdentityGraphFilter"
import { useFetchIdentityGraph } from "resources/customer/identityGraph/identityGraphQueries"
import { useFetchEventsMap } from "resources/event/eventQueries"
import { useFetchStitchingAttributes } from "resources/attribute/attributeQueries"
import { ActiveAttributes } from "../types"

import styles from "./IdentityView.module.scss"
const IdentityGraph = lazy(() => import("../IdentityGraph/IdentityGraph"))

export default function IdentityView() {
  const { id } = useParams<{ id: string }>()

  const [searchIdentifier, setSearchIdentifier] = useState("")
  const [identityGraphFilter, setIdentityGraphFilter] = useState<ActiveAttributes>([])

  const { data, error, isError, isLoading } = useFetchIdentityGraph(id)
  const { data: stitchingAttributes, isSuccess: areAttributesFulfilled } =
    useFetchStitchingAttributes()
  const { data: eventsMap = {}, isSuccess: areEventsFulfilled } = useFetchEventsMap({
    includeHidden: true,
  })

  useEffect(() => {
    if (data && data.nodes.length > 0) {
      setIdentityGraphFilter(
        data.nodes.map(({ attribute_id }) => ({ id: attribute_id, active: true })),
      )
    }
  }, [data])

  const isFulfilled = areAttributesFulfilled && areEventsFulfilled
  const errorMessage = isError
    ? error?.response?.status === 406
      ? "Graph is too big (more than 500 edges). To investigate, contact administrator."
      : "Something went wrong."
    : undefined

  return (
    <>
      <IdentityGraphFilter
        activeAttributes={identityGraphFilter}
        setActiveAttributes={setIdentityGraphFilter}
        searchIdentifier={searchIdentifier}
        setSearchIdentifier={setSearchIdentifier}
      />
      <Paper className={styles.identityGraphPaper}>
        <Suspense fallback={<LoadingIndicator />}>
          <IdentityGraph
            isLoading={isLoading || !isFulfilled}
            data={data}
            errorMessage={errorMessage}
            eventsMap={eventsMap}
            graphIdentifiersSelection={identityGraphFilter}
            searchIdentifier={searchIdentifier.trim()}
            stitchingAttributes={stitchingAttributes}
          />
        </Suspense>
      </Paper>
    </>
  )
}
