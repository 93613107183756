import React, { Component } from "react"
import {
  reduxForm,
  Form,
  Field,
  FieldArray,
  getFormSyncErrors,
  formValueSelector,
  getFormValues,
} from "redux-form"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import _get from "lodash/get"
import _isNil from "lodash/isNil"
import _set from "lodash/set"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"

import "./IdentityStitchingForm.scss"

class IdentityStitchingForm extends Component {
  renderEvents = ({
    fields,
    eventOptions,
    areEventsFulfilled,
    submitFailed,
    submitErrors,
    formValues,
  }) => {
    return (
      <div className="events-right-content">
        {fields.map((event, index) => {
          return (
            <div
              key={index}
              className={`event-item-wrapper ${index === fields.length - 1 ? "last" : ""}`}
            >
              <div className="box">
                <Field
                  name={`${event}.event_id`}
                  component={SelectField}
                  placeholder="Event"
                  label="Event"
                  validate={required}
                  options={eventOptions}
                  isLoading={!areEventsFulfilled}
                  className="event-id"
                  isSimpleValue
                />
                <Field
                  name={`${event}.value_expression`}
                  component={ExpandableAceEditor}
                  label="Value"
                  expandModalTitle={_get(formValues, `${event}.event_id.label`)}
                  className={`ace-editor-wrapper-with-margin ${
                    submitFailed && !_isNil(_get(submitErrors, `${event}.value_expression`))
                      ? "error"
                      : ""
                  }`}
                  width="320px"
                />
              </div>
              {fields.length > 1 && (
                <IconButton
                  size="xs"
                  color="red"
                  onClick={() => fields.remove(index)}
                  icon="trash-alt"
                  tooltip="Delete"
                  variant="outlined"
                />
              )}
            </div>
          )
        })}
        <Button size="md" onClick={() => fields.push({})} className="add-rule-button">
          + Add rule
        </Button>
      </div>
    )
  }

  selectAttribute = newAttributeId => {
    this.props.change("attribute_id", newAttributeId)
  }

  render() {
    const {
      handleSubmit,
      submitFailed,
      submitErrors,
      attributeId,
      eventOptions,
      areEventsFulfilled,
      initialValues,
      formValues,
      onSubmit,
    } = this.props

    return (
      <section className="identity-stitching-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <div
                className={`attribute-id text-field ${
                  submitFailed && !_isNil(submitErrors.attribute_id) ? "error" : ""
                }`}
              >
                <label>Attribute *</label>
                <ErrorTippy
                  disabled={!submitFailed || _isNil(submitErrors.attribute_id)}
                  content={submitErrors.attribute_id}
                >
                  <div>
                    <AttributePicker
                      attributeId={attributeId}
                      handleAttributeSelect={this.selectAttribute}
                      showSource={false}
                      fixedSize="medium-size"
                      isEditable={true}
                      includeHidden
                      excludeStitching
                    />
                  </div>
                </ErrorTippy>
              </div>
              <FieldArray
                name="configs"
                component={this.renderEvents}
                rerenderOnEveryChange={true}
                props={{
                  eventOptions,
                  areEventsFulfilled,
                  submitFailed,
                  submitErrors,
                  initialValues,
                  formValues,
                }}
              />
            </div>
          </div>
        </Form>
      </section>
    )
  }
}

IdentityStitchingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const selector = formValueSelector("IdentityStitchingForm")
const mapStateToProps = state => ({
  submitErrors: getFormSyncErrors("IdentityStitchingForm")(state),
  attributeId: selector(state, "attribute_id"),
  formValues: getFormValues("IdentityStitchingForm")(state),
})

IdentityStitchingForm = connect(mapStateToProps)(IdentityStitchingForm)

const validate = values => {
  let errors = {}
  if (!_get(values, "attribute_id")) {
    _set(errors, "attribute_id", "Please select the attribute")
  }
  if (values.configs) {
    values.configs.forEach((config, index) => {
      if (!config.value_expression) {
        _set(errors, `configs[${index}].value_expression`, "Please fill in the field")
      }
    })
  }
  return errors
}

IdentityStitchingForm = reduxForm({
  form: "IdentityStitchingForm",
  touchOnBlur: false,
  enableReinitialize: true,
  validate,
})(IdentityStitchingForm)

export default IdentityStitchingForm
