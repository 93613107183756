import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { append, update, whereEq } from "ramda"
import SimpleBar from "simplebar-react"

import Button from "components/UI/elements/Button/Button"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventSourceIds, useFetchEventsMapBySourceId } from "resources/event/eventQueries"
import { Event } from "resources/event/eventTypes"
import { ActiveEvents, ActiveSources } from "../types"

import styles from "./TimelineFilter.module.scss"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

type TimelineFilterProps = {
  activeEvents: ActiveEvents
  activeSources: ActiveSources
  searchTerm: string
  sources: Array<Source>
  setActiveEvents: (newValue: ActiveEvents) => void
  setActiveSources: (newFilter: ActiveSources) => void
  setSearchTerm: (newSearchTerm: string) => void
}

export default function TimelineFilter({
  activeEvents,
  activeSources,
  searchTerm,
  sources,
  setActiveEvents,
  setSearchTerm,
  setActiveSources,
}: TimelineFilterProps) {
  const [expandedSources, setExpandedSources] = useState<
    Array<{ id: Source["id"]; expanded: boolean }>
  >([])

  const { data: timelineAvailableSourceIds = [] } = useFetchEventSourceIds()
  const { data: eventsMapBySourceId = {} } = useFetchEventsMapBySourceId()

  const onReset = () => {
    setActiveSources(
      activeSources.map(s => {
        if (!timelineAvailableSourceIds.includes(s.id)) return s
        return { ...s, active: true }
      }),
    )
    setActiveEvents(
      activeEvents.map(e => {
        if (!timelineAvailableSourceIds.includes(e.source_id)) return e
        return { ...e, active: true }
      }),
    )
  }

  const onSourceExpand = (sourceId: Source["id"]) => {
    const sourceIndex = expandedSources.findIndex(whereEq({ id: sourceId }))

    if (sourceIndex === -1)
      setExpandedSources(append({ id: sourceId, expanded: true }, expandedSources))
    else {
      const source = expandedSources[sourceIndex]
      const newExpandedSources = update(
        sourceIndex,
        { ...source, expanded: !source.expanded },
        expandedSources,
      )

      setExpandedSources(newExpandedSources)
    }
  }

  const onSourceToggle = (sourceId: Source["id"]) => {
    const sourceIndex = activeSources.findIndex(whereEq({ id: sourceId }))
    const source = activeSources[sourceIndex]
    const newFilter = update(sourceIndex, { ...source, active: !source.active }, activeSources)

    setActiveSources(newFilter)

    const sourceEvents = activeEvents.filter(whereEq({ source_id: sourceId }))
    const sourceEventIds = sourceEvents.map(({ id }) => id)

    if (source.active) {
      const newEventsFilter = activeEvents.map(e => {
        if (sourceEventIds.includes(e.id)) return { ...e, active: false }
        else return e
      })

      setActiveEvents(newEventsFilter)
    } else {
      const newEventsFilter = activeEvents.map(e => {
        if (sourceEventIds.includes(e.id)) return { ...e, active: true }
        else return e
      })

      setActiveEvents(newEventsFilter)
    }
  }

  const onEventToggle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const [, sourceId, eventId] = evt.currentTarget.name.split(".")

    const eventIndex = activeEvents.findIndex(whereEq({ id: eventId }))
    const event = activeEvents[eventIndex]
    const newFilter = update(
      eventIndex,
      { ...event, active: evt.currentTarget.checked },
      activeEvents,
    )

    setActiveEvents(newFilter)

    const sourceIndex = activeSources.findIndex(whereEq({ id: sourceId }))
    const source = activeSources[sourceIndex]

    if (evt.currentTarget.checked) {
      const newSourcesFilter = update(sourceIndex, { ...source, active: true }, activeSources)
      setActiveSources(newSourcesFilter)
    } else {
      const sourceEvents = activeEvents.filter(whereEq({ source_id: sourceId }))
      const checkedSourceEvents = sourceEvents.filter(whereEq({ active: true }))

      if (checkedSourceEvents.length === 1) {
        const newSourcesFilter = update(sourceIndex, { ...source, active: false }, activeSources)
        setActiveSources(newSourcesFilter)
      }
    }
  }

  const selectOnly = (eventId: Event["id"], sourceId: Source["id"]) => {
    setActiveEvents(
      activeEvents.map(e => {
        if (!timelineAvailableSourceIds.includes(e.source_id)) return e
        return e.id === eventId ? { ...e, active: true } : { ...e, active: false }
      }),
    )

    setActiveSources(
      activeSources.map(s => {
        if (!timelineAvailableSourceIds.includes(s.id)) return s
        return s.id === sourceId ? { ...s, active: true } : { ...s, active: false }
      }),
    )
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader>
        <SearchField
          fullWidth
          label="Search"
          input={{
            value: searchTerm,
            onChange: (value: string) => setSearchTerm(value),
          }}
          placeholder="Search for payload"
          type="text"
          onClear={() => setSearchTerm("")}
        />
      </Paper>

      <Paper hasHeader={true} className={styles.timelineOptionsPaper}>
        <SimpleBar className={styles.scrollable}>
          <div className={styles.timelineOptionsHeader}>
            <h4 className={styles.title}>Filter by event:</h4>
            <Button color="grey" variant="link" onClick={_ => onReset()}>
              Reset
            </Button>
          </div>
          {activeSources.map(({ id, active }) => {
            if (!timelineAvailableSourceIds.includes(id)) return null

            const source = sources.find(whereEq({ id }))
            if (!source) return null

            const sourceEvents = eventsMapBySourceId[source.id]
            let color = source.frontend_settings?.color
            const isExpanded = expandedSources.find(whereEq({ id }))?.expanded

            return (
              <div key={source.id} className={styles.formRow}>
                <span
                  className={classNames(
                    styles.sourcename,
                    { [styles.turnedOff]: !active },
                    styles.clickable,
                  )}
                  onClick={() => onSourceExpand(source.id)}
                >
                  {source.name}
                  <span className={styles.caretButton}>
                    {isExpanded && <FontAwesomeIcon icon={["fas", "caret-up"]} />}
                    {!isExpanded && <FontAwesomeIcon icon={["fas", "caret-down"]} />}
                  </span>
                </span>

                <div
                  className={classNames(styles.sourceIcon, styles[color], {
                    [styles.disabled]: !active,
                  })}
                  onClick={() => {
                    onSourceToggle(source.id)
                  }}
                >
                  <SrcDstIcon source={source} white />
                </div>

                {Array.isArray(sourceEvents) && (
                  <div
                    className={classNames(styles.eventTypes, {
                      [styles.expanded]: isExpanded,
                      [styles.collapsed]: !isExpanded,
                    })}
                  >
                    {sourceEvents.map(event => {
                      const checked = activeEvents.find(whereEq({ id: event.id }))?.active ?? false

                      return (
                        <div key={event.id} className={styles.eventTypeCheckboxWrapper}>
                          <button
                            className={styles.selectOnlyButton}
                            onClick={e => {
                              e.stopPropagation()
                              selectOnly(event.id, source.id)
                            }}
                            type="button"
                          >
                            Select only
                          </button>
                          <Checkbox
                            key={event.id}
                            checked={checked}
                            id={`eventTypes.${source.id}.${event.id}`}
                            label={
                              <>
                                <FontAwesomeIcon icon={["fas", "check"]} />
                                {event.name}
                              </>
                            }
                            name={`eventTypes.${source.id}.${event.id}`}
                            onChange={onEventToggle}
                            variant="secondary"
                            className={styles.sub}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </SimpleBar>
      </Paper>
    </div>
  )
}
