import React from "react"
import { SearchFormWithSelectDestroyable } from "components/UI/components/SearchFormWithSelect"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"

export default function InsightsFilterForm() {
  const { data = [] } = useFetchDataSourceOptions()

  return (
    <SearchFormWithSelectDestroyable
      form="InsigtsFilter"
      inputPlaceholder="Search for insights"
      selectOptions={data}
      selectPlaceholder="All sources"
    />
  )
}
