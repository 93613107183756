import { QueryKey, useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { api } from "api"
import { Segment } from "../segment/segmentTypes"
import { SegmentExport } from "./segmentExportTypes"

const SEGMENT = "segment" as const
const EXPORT = "export" as const

/*
 * BEWARE there can be problems in cache when we don't wanna include push notification exports
 * on segment detail page, especially when you will fetch segment export detail for push notification,
 * put it into the cache and then you'll go to segment detail ... the export can be then found in
 * table which is not desired behaviour
 */
export function useFetchSegmentExportById(segmentId: Segment["id"], exportId: SegmentExport["id"]) {
  return useQuery(
    ["export", segmentId, exportId],
    () => api.segment.export.retrieve(segmentId, exportId),
    {
      select: ({ segment_export }) => segment_export,
    },
  )
}

export function useFetchSegmentExportLogs(segmentId: Segment["id"], exportId: SegmentExport["id"]) {
  const { data, ...rest } = useInfiniteQuery(
    [SEGMENT, segmentId, EXPORT, exportId, "logs"] as QueryKey,
    ({ pageParam }) =>
      api.segment.export.logs.list(segmentId, exportId, pageParam, 10, 0, "id", "DESC"),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segment_export_logs.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(p => p.segment_export_logs) : [] }
}
