import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"
import _isNil from "lodash/isNil"
import _get from "lodash/get"
import _forEach from "lodash/forEach"
import _isPlainObject from "lodash/isPlainObject"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ParametersForm from "./ParametersForm"

import "./ParametersController.scss"
import classNames from "classnames"

class ParametersController extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open && !this.state.open) {
      // modal closed
      document.removeEventListener("mousedown", this._handleOutsideClick, false)
    } else if (!prevState.open && this.state.open) {
      // modal opened
      setTimeout(() => document.addEventListener("mousedown", this._handleOutsideClick, false), 0)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleOutsideClick, false)
  }

  _handleOutsideClick = evt => {
    if (evt.defaultPrevented) return
    if (!_isNil(this.modal)) {
      if (!this.modal.contains(evt.target)) {
        // prevent toggle when click on select option
        if (evt.target) {
          if (
            _get(evt, "target.className", "").includes("react-select-redux-field__option") ||
            _get(evt, "target.className", "").includes("select-option") ||
            evt.target.getAttribute("role") === "option"
          ) {
            return
          }
        }
        this.toggleParameters()
      }
    }
  }

  toggleParameters = () => {
    this.setState(prevState => ({
      open: !prevState.open,
      loading: false,
    }))
  }

  onSubmit = values => {
    const { exportDestinationId, onSaveParameters, showToast } = this.props

    // select values transformation
    const transformedValues = {
      ...values,
    }
    _forEach(values, (value, key) => {
      if (_isPlainObject(value)) {
        transformedValues[key] = value.value
      }
      if (_isNil(value)) {
        delete transformedValues[key]
      }
    })

    const data = { [exportDestinationId]: transformedValues }
    this.setState({ loading: true })
    const savePromise = onSaveParameters(data)
    if (savePromise && typeof savePromise.then === "function") {
      savePromise
        .then(() => {
          showToast("Destination parameters set.")
          this.toggleParameters()
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { open, loading } = this.state
    const {
      form,
      fieldsConfig,
      countOfRequiredParameters,
      countOfFilledParameters,
      isEditable,
      initialValues,
    } = this.props

    if (fieldsConfig.length === 0) {
      // do not display parameters section if there aren't any fields defined by admin
      return null
    }

    let isSomethingFilled = countOfFilledParameters
    if (!isSomethingFilled) {
      _forEach(initialValues, val => {
        if (val) {
          isSomethingFilled = true
        }
      })
    }

    // try to find assigned value in value list
    const transformedIV = { ...initialValues }
    fieldsConfig.forEach(field => {
      if (Array.isArray(field.input_mode) && field.input_mode.includes("value_from_list")) {
        if (initialValues.hasOwnProperty(field.name)) {
          const transformedValue = {
            label: initialValues[field.name],
            value: initialValues[field.name],
          }
          const valueFromList = field.values[initialValues[field.name]]
          if (valueFromList) {
            transformedValue.label = valueFromList
          }
          transformedIV[field.name] = transformedValue
        }
      }
    })

    return (
      <div className="parameters-wrapper">
        <Button
          color="grey"
          icon="code"
          iconClassName="code-icon"
          iconStyle="far"
          variant="outlined"
          className={classNames("parameters-button", {
            "parameters-exist": open || isSomethingFilled,
          })}
          onClick={this.toggleParameters}
        >
          Parameters
          {countOfRequiredParameters
            ? `(${countOfFilledParameters}/${countOfRequiredParameters})`
            : ""}
        </Button>
        <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
          <div className="parameters-modal" ref={DOMNode => (this.modal = DOMNode)}>
            <div className="header">
              <h3>Set parameters</h3>
              <IconButton
                color="grey"
                icon="times"
                tooltip="Close"
                variant="transparent"
                onClick={this.toggleParameters}
                className="close-button"
              />
            </div>
            <ParametersForm
              form={form}
              loading={loading}
              onSubmit={this.onSubmit}
              fieldsConfig={fieldsConfig}
              isEditable={isEditable}
              initialValues={transformedIV}
              countOfFilledParameters={countOfFilledParameters}
            />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

ParametersController.propTypes = {
  initialValues: PropTypes.object.isRequired,
  exportDestinationId: PropTypes.number.isRequired,
  onSaveParameters: PropTypes.func.isRequired,
  fieldsConfig: PropTypes.array.isRequired,
  countOfRequiredParameters: PropTypes.number.isRequired,
  countOfFilledParameters: PropTypes.number.isRequired,
  showToast: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default ParametersController
