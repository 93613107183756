import React, { useState } from "react"
import "./ToggleButton.scss"
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator"
import classNames from "classnames"

type ToggleButtonProps = {
  value: number | boolean
  handleToggle: () => any
  size?: "xs" | "sm" | "md" | "lg"
  disabled?: boolean
}

const ToggleButton = ({
  value,
  handleToggle,
  size = "md",
  disabled = false,
}: ToggleButtonProps) => {
  const [isToggling, setIsToggling] = useState(false)

  async function toggle() {
    setIsToggling(true)
    try {
      await handleToggle()
    } catch {
      // noop
    } finally {
      setIsToggling(false)
    }
  }

  return (
    <button
      className={classNames("toggle-button", { off: !value, toggling: isToggling }, size)}
      onClick={toggle}
      data-testid="button"
      disabled={disabled || isToggling}
      type="button"
    >
      <div className="labels">
        <span className="onLabel">
          {isToggling ? <LoadingIndicator size="xs" color="white" /> : "on"}
        </span>
        <span className="offLabel">
          {isToggling ? <LoadingIndicator size="xs" color="white" /> : "off"}
        </span>
      </div>
      <span className="switch"></span>
    </button>
  )
}

export default ToggleButton
