import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import RolesList from "./Roles"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"

export default function Roles() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("administration.roles")} exact component={RolesList} />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users}
        path={getRoutePath("administration.roles.trash")}
        exact
        render={() => <Trash itemType="user_roles" />}
      />
    </Switch>
  )
}
