import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { getRoutePath } from "routes"
import moment from "moment"
import { MOMENT } from "sharedConstants"
import { getFormValues } from "redux-form"
import EventsFilterForm from "components/UI/components/EventsFilterForm"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"

import "./EventsList.scss"
import { useSelector } from "store"
import { useFetchAllEvents, useModifyEvent } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

class EventsList extends Component {
  goToEventDetail = eventId => () => {
    this.props.history.push(getRoutePath("administration.events.detail", { id: eventId }))
  }

  render() {
    const { history, areEventsFulfilled, events, toggleEventIsHidden, isFiltering } = this.props

    return (
      <Page
        className="setup-events"
        title="Events"
        headerContent={
          <>
            <EventsFilterForm
              destroyOn
              destroyOnUnmount={false}
              formName="SetupSearchEventsForm"
              showHiddenSources={true}
            />
            <Button
              onClick={() => {
                history.push(getRoutePath("administration.events.create"))
              }}
            >
              + Create event
            </Button>
          </>
        }
      >
        {!areEventsFulfilled && <LoadingIndicator />}

        {areEventsFulfilled && events.length > 0 && (
          <Paper noPaddingTop>
            <Table className="setup-events-table">
              <Thead stickyHeader>
                <Th className="name">Name</Th>
                <Th className="source">Data source</Th>
                <Th className="date-added">Date added</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {events.map(event => {
                  const sourceIsHidden = event.source.is_hidden === 1
                  return (
                    <Tr key={event.id} className={event.is_hidden ? "is-hidden" : ""}>
                      <Td
                        textBold
                        textBigger
                        textBlack
                        withNewBadge={moment().diff(event.created, "days") < 8}
                        className="name"
                      >
                        {event.name}
                      </Td>
                      <Td>
                        <div className="source-cell-content">
                          <div className="source-icon">
                            <SrcDstIcon source={event.source} />
                          </div>
                          <span className="source-name">{event.source.name}</span>
                        </div>
                      </Td>
                      <Td className="created">
                        {moment.utc(event.created).local().format(MOMENT.DATE_FORMAT)}
                      </Td>
                      <Td textAlignRight className="actions">
                        <IconButton
                          color="black"
                          size="xs"
                          onClick={this.goToEventDetail(event.id)}
                          icon="pencil-alt"
                          tooltip="Edit"
                          variant="outlined"
                        />

                        <IconButton
                          color="red"
                          size="xs"
                          onClick={() => toggleEventIsHidden(event)}
                          className="left-margin"
                          disabled={sourceIsHidden}
                          icon={event.is_hidden ? "eye" : "eye-slash"}
                          iconStyle="far"
                          tooltip={
                            sourceIsHidden ? "Hidden source" : event.is_hidden ? "Show" : "Hide"
                          }
                          variant="outlined"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </Paper>
        )}
        {areEventsFulfilled && events.length === 0 && !isFiltering && (
          <Paper>
            <RowMessage>Click on the "Create Event" to get started.</RowMessage>
          </Paper>
        )}
        {areEventsFulfilled && events.length === 0 && isFiltering && (
          <Paper>
            <RowMessage>Nothing found.</RowMessage>
          </Paper>
        )}
      </Page>
    )
  }
}

export default props => {
  const formValues = useSelector(getFormValues("SetupSearchEventsForm"))

  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents(
    {
      includeHidden: true,
      orderBy: "name",
      orderDir: "ASC",
      searchTerm: formValues?.search?.trim(),
      sourceId: formValues?.select?.value,
    },
    {
      refetchOnMount: "always",
    },
  )

  const { mutate } = useModifyEvent()
  const toggleEventIsHidden = event =>
    mutate({ id: event.id, data: { is_hidden: +!event.is_hidden } })

  return (
    <EventsList
      {...props}
      events={events}
      areEventsFulfilled={areEventsFulfilled}
      toggleEventIsHidden={toggleEventIsHidden}
      isFiltering={Boolean(formValues?.search || formValues?.select?.value)}
    />
  )
}
