import React, { ComponentProps, ReactNode } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import Tippy from "@tippyjs/react"

import styles from "./IconButton.module.scss"

export type Props = {
  icon?: IconName
  color?: "primary" | "grey" | "red" | "green" | "black" | "white"
  customIcon?: ReactNode
  iconStyle?: IconPrefix
  size?: "xs" | "sm" | "md"
  tooltip?: string
  variant?: "solid" | "outlined" | "transparent"
} & ComponentProps<"button">

const IconButton = ({
  className,
  customIcon,
  icon,
  tooltip,
  color = "primary",
  disabled = false,
  iconStyle = "fas",
  size = "sm",
  type = "button",
  variant = "solid",
  ...buttonAttrs
}: Props) => {
  const content = (
    <button
      {...buttonAttrs}
      disabled={disabled}
      type={type}
      className={classNames(
        styles.iconButton,
        styles[color],
        styles[size],
        styles[variant],
        className,
      )}
    >
      {customIcon ? customIcon : icon ? <FontAwesomeIcon icon={[iconStyle, icon]} /> : null}
    </button>
  )

  if (tooltip)
    return (
      <Tippy content={tooltip} delay={[700, 0]} placement="bottom">
        {disabled ? <span>{content}</span> : content}
      </Tippy>
    )
  else return content
}

export default IconButton
