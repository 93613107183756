import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import { map, prop, sort, update } from "ramda"
import { useDispatch } from "react-redux"
import { SelectOption } from "types/util"
import { ascend } from "utilities/comparators"
import { api } from "../../api"
import { Label, LabelPayload } from "./attributeLabelTypes"

const LABEL_ALL_QK: QueryKey = ["label", "all"]

export function useFetchAllLabels() {
  return useQuery(LABEL_ALL_QK, api.label.listAll)
}

export function useFetchLabelOptions() {
  return useQuery(LABEL_ALL_QK, api.label.listAll, {
    select: map<Label, SelectOption<Label["id"]>>(({ id, name }) => ({ value: id, label: name })),
  })
}

export function useCreateLabel() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ data }: { data: LabelPayload }) => api.label.create(data), {
    onSuccess: ({ tag }) => {
      queryClient.setQueryData<Label[]>(LABEL_ALL_QK, data => {
        if (!data) {
          return
        }

        return sort(ascend(prop("name")), data.concat(tag))
      })
      dispatch(showToast("Label created."))
    },
  })
}

export function useModifyLabel() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: Label["id"]; data: LabelPayload }) => api.label.modify(id, data),
    {
      onSuccess: ({ tag }) => {
        queryClient.setQueryData<Label[]>(LABEL_ALL_QK, data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === tag.id)

          return index === -1
            ? sort(ascend(prop("name")), data.concat(tag))
            : update(index, tag, data)
        })
        dispatch(showToast("Label modified."))
      },
    },
  )
}

export function useDeleteLabel() {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(({ id }: { id: Label["id"] }) => api.label.delete(id), {
    onSuccess(_, { id }) {
      queryClient.setQueryData<Label[]>(LABEL_ALL_QK, data => {
        return data?.filter(report => report.id !== id)
      })

      dispatch(showToast("Label deleted."))
    },
  })
}
