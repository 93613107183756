import React, { useEffect, useMemo, useRef, useState } from "react"
import classNames from "classnames"
import { values, whereEq } from "ramda"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { ToggleSwitchMultiple } from "components/UI/elements/ToggleSwitch"
import Page from "components/UI/Page/Page"
import DestinationParticipation from "./DestinationParticipation/DestinationParticipation"
import Header from "./Header/Header"
import HeaderActions from "./HeaderActions/HeaderActions"
import IdentityView from "./IdentityView/IdentityView"
import SegmentParticipation from "./SegmentParticipation/SegmentParticipation"
import SourceFilter from "./SourceFilter/SourceFilter"
import SourceBoxes, { ATTRIBUTE_VALUES_MAX_COUNT } from "./SourceBoxes/SourceBoxes"
import TimelineEventGroups from "./TimelineEventGroups/TimelineEventGroups"
import TimelineEvents from "./TimelineEvents/TimelineEvents"
import TimelineFilter from "./TimelineFilter/TimelineFilter"
import { useFetchEventSourceIds, useFetchEventsMap } from "resources/event/eventQueries"
import { useFetchAttributesMapBySourceId } from "resources/attribute/attributeQueries"
import { AttributeFull } from "resources/attribute/attributeTypes"
import { useFetchCustomerAttributes } from "resources/customer/attribute/customerAttributeQueries"
import { useFetchCustomerDestinations } from "resources/customer/destination/customerDestinationQueries"
import { useFetchCustomerSegments } from "resources/customer/segment/customerSegmentQueries"
import { useFetchAllDataSources } from "resources/dataSource/dataSourceQueries"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import { getRoutePath } from "routes"
import { SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD, TOAST } from "sharedConstants"
import { ActiveEvents, ActiveSources, Layout, View } from "./types"
import { Label } from "resources/attributeLabel/attributeLabelTypes"

import styles from "./ProfileDetail.module.scss"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import { showToast } from "actions/toast.action"

const filterAttributesByTagId = ({
  attributesMapBySourceId,
  hiddenChannelEngagementSourceIds,
  tagId,
  hideSourceEngagement = false,
}: {
  attributesMapBySourceId: Record<string, Array<AttributeFull>>
  hiddenChannelEngagementSourceIds?: Array<Source["id"]>
  hideSourceEngagement?: boolean
  tagId?: Label["id"]
}) => {
  let filteredAttributes: Array<AttributeFull> = []

  if (Object.keys(attributesMapBySourceId).length === 0 || !tagId) return filteredAttributes

  Object.values(attributesMapBySourceId).forEach(source => {
    source.forEach(attr => {
      if (attr.source.is_hidden) return

      if (Array.isArray(attr.tags) && attr.tags.some(tag => tag.id === tagId)) {
        if (
          hideSourceEngagement &&
          hiddenChannelEngagementSourceIds &&
          Array.isArray(hiddenChannelEngagementSourceIds)
        ) {
          if (!hiddenChannelEngagementSourceIds.includes(attr.source.id))
            filteredAttributes.push(attr)
        } else {
          filteredAttributes.push(attr)
        }
      }
    })
  })

  return filteredAttributes
}

export default function ProfileDetail() {
  const [showScreen, setShowScreen] = useState<View>("attributes")
  const [activeEvents, setActiveEvents] = useState<ActiveEvents>([])
  const [activeSources, setActiveSources] = useState<ActiveSources>([])
  const [searchTerm, setSearchTerm] = useState("")
  const history = useHistory()

  const scrollToSwitcher = useRef<HTMLDivElement>(null)

  const { id: profileId } = useParams<{ id: string }>()

  const dispatch = useDispatch()

  const { data: destinations = [], isLoading: isLoadingDestinations } = useFetchAllDestinations({
    sortByName: true,
  })
  const { data: sources = [], isLoading: isLoadingSources } = useFetchAllDataSources({
    showHidden: true,
  })
  const { data: attributesMapBySourceId = {}, isLoading: isLoadingAttributes } =
    useFetchAttributesMapBySourceId()
  const { data: eventsMap = {}, isLoading: isLoadingEvents } = useFetchEventsMap({
    includeHidden: true,
  })
  const { data: timelineAvailableSourceIds = [] } = useFetchEventSourceIds()

  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const {
    data: customerSegments,
    fetchNextPage: fetchMoreCustomerSegments,
    hasNextPage: hasMoreCustomerSegments,
    isFetchingNextPage: isFetchingMoreCustomerSegments,
    isLoading: isLoadingCustomerSegments,
  } = useFetchCustomerSegments(profileId)
  const { data: customerDestinations, isLoading: isLoadingCustomerDestinations } =
    useFetchCustomerDestinations(profileId)
  const { data: customerAttributesResponse, isLoading: isLoadingCustomerAttributes } =
    useFetchCustomerAttributes(
      {
        attribute_values_max_count: ATTRIBUTE_VALUES_MAX_COUNT,
        customer_entity_id: profileId,
        load_full_structure: 0,
      },
      {
        onError: err => {
          if (
            err.response?.data?.error_type === "customer_entity_id_redirect" &&
            err.response.data.new_customer_entity_id
          ) {
            dispatch(
              showToast(
                `Profile entity ${profileId} has been merged into ${err.response.data.new_customer_entity_id}`,
                TOAST.TYPE.INFO,
              ),
            )
            history.replace(
              getRoutePath("profiles.detail", { id: err.response.data.new_customer_entity_id }),
            )
          }
        },
      },
    )

  const customerSources = useMemo(
    () =>
      sources.filter(source => {
        const sourceAttributes = attributesMapBySourceId[source.id]
        if (Array.isArray(sourceAttributes) && customerAttributesResponse)
          return sourceAttributes.some(attr =>
            customerAttributesResponse.customer_attributes.find(whereEq({ attribute_id: attr.id })),
          )

        return false
      }),
    [attributesMapBySourceId, customerAttributesResponse, sources],
  )

  useEffect(() => {
    if (customerSources.length > 0)
      setActiveSources(customerSources.map(s => ({ active: true, id: s.id })))
  }, [customerSources])

  useEffect(() => {
    if (values(eventsMap).length > 0 && customerSources.length > 0)
      setActiveEvents(
        values(eventsMap)
          .filter(({ source }) => !!customerSources.find(({ id }) => source.id === id))
          .map(({ id, source }) => ({ id, source_id: source.id, active: true })),
      )
  }, [eventsMap, customerSources])

  const { reset } = useProfileIteratorStore()

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const setLayout = (newLayout: Layout) => {
    const { id, frontend_settings } = currentUser!
    const { customerDetailLayout = "two-cols" } = frontend_settings ?? {}

    if (customerDetailLayout !== newLayout) {
      modifyUser({
        id,
        data: {
          frontend_settings: {
            ...(frontend_settings ?? {}),
            customerDetailLayout: newLayout,
          },
        },
      })
    }
  }

  const isLoading =
    isLoadingAttributes ||
    isLoadingDestinations ||
    isLoadingEvents ||
    isLoadingSources ||
    isLoadingCustomerAttributes ||
    isLoadingCustomerDestinations ||
    isLoadingCustomerSegments

  let isSuspiciouslyLarge = false
  if (
    customerAttributesResponse &&
    customerAttributesResponse.customer_events_count !== null &&
    customerAttributesResponse.customer_identifiers_count
  )
    isSuspiciouslyLarge =
      customerAttributesResponse?.customer_events_count >=
        SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD ||
      customerAttributesResponse?.customer_identifiers_count >=
        SUSPICIOUS_ENTITY_EVENT_COUNT_THRESHOLD

  const currentLayout = currentUser?.frontend_settings?.customerDetailLayout ?? "two-cols"
  const customerAttributes = customerAttributesResponse?.customer_attributes ?? []

  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const channelEngagementTagId: Label["id"] | undefined =
    globalSettings?.["channel_engagement_tag_id"]?.value
  const contactInfoTagId: Label["id"] | undefined = globalSettings?.["contact_info_tag_id"]?.value
  const hiddenChannelEngagementSourceIds: Array<Source["id"]> =
    globalSettings?.["hidden_channel_engagement_source_ids"]?.value ?? []

  let contactInfoAttributes: Array<AttributeFull> = []
  if (areGlobalSettingsFulfilled)
    contactInfoAttributes = filterAttributesByTagId({
      attributesMapBySourceId,
      tagId: contactInfoTagId,
    })
  if (contactInfoAttributes.length > 0)
    contactInfoAttributes = contactInfoAttributes.sort((a, b) => {
      if (a.order_index < b.order_index) return -1
      else if (a.order_index > b.order_index) return 1
      else return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })

  let channelEngagementAttributes: Array<AttributeFull> = []
  if (areGlobalSettingsFulfilled)
    channelEngagementAttributes = filterAttributesByTagId({
      attributesMapBySourceId,
      hiddenChannelEngagementSourceIds,
      hideSourceEngagement: true,
      tagId: channelEngagementTagId,
    })

  const filteredSources = sources.filter(s => !!activeSources.find(f => f.id === s.id && f.active))

  const isSearchTermEmpty = searchTerm ? /^\s+$/g.test(searchTerm) : true
  const timelineSources = activeSources.filter(s => timelineAvailableSourceIds.includes(s.id))
  const timelineEvents = activeEvents.filter(s => timelineAvailableSourceIds.includes(s.source_id))
  const showOnlyTimelineEvents =
    timelineEvents.some(whereEq({ active: false })) ||
    timelineSources.some(whereEq({ active: false })) ||
    !isSearchTermEmpty

  return (
    <Page
      title="Profile detail"
      headerContent={<HeaderActions customerId={profileId} customerDataReady={!isLoading} />}
      backRouteFallback={getRoutePath("profiles")}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <div>
            <Header
              customerId={profileId}
              customerAttributes={customerAttributes}
              contactInfoAttributes={contactInfoAttributes}
              channelEngagementAttributes={channelEngagementAttributes}
              isGlobalSettingsFulfilled={areGlobalSettingsFulfilled}
              showSystemMessage={isSuspiciouslyLarge}
            />
            {customerSegments.length > 0 && (
              <SegmentParticipation
                data={customerSegments}
                hasNextPage={hasMoreCustomerSegments}
                isFetchingNextPage={isFetchingMoreCustomerSegments}
                fetchNextPage={fetchMoreCustomerSegments}
              />
            )}
            {destinations.length > 0 && (
              <DestinationParticipation
                customerDestinations={customerDestinations}
                destinations={destinations}
              />
            )}
          </div>
          <div ref={scrollToSwitcher}>
            <div className={styles.switchRow}>
              <hr />
              <div className={styles.switchBackground}>
                <ToggleSwitchMultiple
                  width="280px"
                  name="view-switch"
                  buttons={[{ value: "attributes" }, { value: "timeline" }, { value: "identity" }]}
                  checked={showScreen}
                  handleToggle={setShowScreen}
                />
              </div>
            </div>
            <div
              className={classNames(styles.customerDetailContent, {
                [styles.timelineContent]: showScreen === "timeline",
              })}
            >
              {["attributes", "timeline"].includes(showScreen) && (
                <SourceFilter
                  layoutPickerVisible={showScreen === "attributes"}
                  activeEvents={activeEvents}
                  activeSources={activeSources}
                  layout={currentLayout}
                  sources={sources}
                  view={showScreen}
                  setActiveEvents={setActiveEvents}
                  setActiveSources={setActiveSources}
                  setLayout={setLayout}
                />
              )}
              {showScreen === "attributes" && (
                <div className={styles.customerAttributesBoxes}>
                  <SourceBoxes sources={filteredSources} layout={currentLayout} />
                </div>
              )}
              {showScreen === "timeline" && (
                <div className={styles.timelineContent}>
                  <div className={styles.leftPanel}>
                    <TimelineFilter
                      activeEvents={activeEvents}
                      activeSources={activeSources}
                      searchTerm={searchTerm}
                      setActiveEvents={setActiveEvents}
                      setActiveSources={setActiveSources}
                      setSearchTerm={setSearchTerm}
                      sources={sources}
                    />
                  </div>
                  <div className={styles.rightPanel}>
                    {showOnlyTimelineEvents ? (
                      <TimelineEvents
                        activeEvents={activeEvents}
                        activeSources={activeSources}
                        sources={sources}
                        searchTerm={searchTerm}
                      />
                    ) : (
                      <TimelineEventGroups sources={sources} />
                    )}
                  </div>
                </div>
              )}
              {showScreen === "identity" && <IdentityView />}
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
