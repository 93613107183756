import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import _noop from "lodash/noop"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./ToggleSwitch.scss"

const ToggleIconSwitchField = ({
  name,
  leftValue,
  rightValue,
  width,
  leftLabel,
  rightLabel,
  leftIcon,
  rightIcon,
  className,
  disabled,
  onChange,
}) => {
  return (
    <div
      className={`toggle-switch-wrapper toggle-switch-icon-container ${className ? className : ""}`}
      data-testid="toggle-switch-wrapper"
    >
      <label htmlFor={`${leftValue}-${name}`} data-testid="left-label">
        {leftLabel ? leftLabel : leftValue}
      </label>
      <div
        className={`toggle-switch toggle-switch-form-field toggle-icon-switch ${
          disabled ? "disabled" : ""
        }`}
        style={{ width: width }}
        data-testid="toggle-switch"
      >
        <p className="fieldset" data-testid="fieldset">
          <Field
            type="radio"
            name={name}
            value={leftValue}
            id={`${leftValue}-${name}`}
            component="input"
            className="left-field"
            disabled={disabled}
            onChange={onChange ? onChange : _noop}
            data-testid="left-field"
          />
          <FontAwesomeIcon icon={leftIcon} className="left-icon toggle-icon" />
          <Field
            type="radio"
            name={name}
            value={rightValue}
            id={`${rightValue}-${name}`}
            component="input"
            className="right-field"
            disabled={disabled}
            onChange={onChange ? onChange : _noop}
            data-testid="right-field"
          />
          <FontAwesomeIcon icon={rightIcon} className="right-icon toggle-icon" />
          <span className="switch" data-testid="switch" />
        </p>
      </div>
      <label htmlFor={`${rightValue}-${name}`} data-testid="right-label">
        {rightLabel ? rightLabel : rightValue}
      </label>
    </div>
  )
}

ToggleIconSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  leftValue: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  rightValue: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  idSufix: PropTypes.string,
  leftIcon: PropTypes.array.isRequired,
  rightIcon: PropTypes.array.isRequired,
}

export default ToggleIconSwitchField
