import React from "react"
import { format } from "date-fns"
import { Link, useHistory } from "react-router-dom"
import Waypoint from "react-waypoint"
import create from "zustand"

import Button from "components/UI/elements/Button/Button"
import { DATEFNS } from "sharedConstants"
import { getRoutePath } from "routes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { MetaAttributesSort } from "resources/metaAttributes/metaAttributesTypes"
import Paper from "components/UI/elements/Paper"
import Table, {
  RowMessage,
  SortButton,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "components/UI/elements/Table"
import { useFetchMetaAttributes } from "resources/metaAttributes/metaAttributesQueries"

import styles from "./MetaAttributesList.module.scss"
import Page from "components/UI/Page/Page"

const useStore = create<MetaAttributesSort>(set => ({
  orderBy: "id",
  orderDir: "ASC",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
}))

export default function MetaAttributesList() {
  const history = useHistory()

  const { orderBy, orderDir, setSort } = useStore()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useFetchMetaAttributes({
      orderBy,
      orderDir,
    })

  return (
    <Page
      title="Meta attributes"
      headerContent={
        <Button onClick={() => history.push(getRoutePath("administration.metaAttributes.create"))}>
          + Create Meta Attribute
        </Button>
      }
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Paper>
          {data.length === 0 && <RowMessage>No meta attributes found</RowMessage>}
          {data.length > 0 && (
            <>
              <Table>
                <Thead stickyHeader>
                  <Th>
                    <SortButton
                      column="id"
                      label="ID"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => setSort("id")}
                    />
                  </Th>
                  <Th textAlignRight>
                    <SortButton
                      column="modified"
                      label="Modified at"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => setSort("modified")}
                    />
                  </Th>
                  <Th>&nbsp;</Th>
                </Thead>
                <Tbody>
                  {data.map(({ id, modified }) => (
                    <Tr key={id}>
                      <Td>{id}</Td>
                      <Td textAlignRight className={styles.modified}>
                        <span>{format(new Date(`${modified}Z`), DATEFNS.DATETIME_FORMAT)}</span>
                      </Td>
                      <Td textAlignRight className={styles.action}>
                        <Link to={getRoutePath("administration.metaAttributes.detail", { id })}>
                          <IconButton
                            color="black"
                            icon="pencil-alt"
                            size="xs"
                            tooltip="Edit"
                            variant="outlined"
                          />
                        </Link>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {isFetchingNextPage && <LoadingIndicator />}
              {!isFetchingNextPage && hasNextPage && <Waypoint onEnter={() => fetchNextPage()} />}
            </>
          )}
        </Paper>
      )}
    </Page>
  )
}
