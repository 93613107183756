import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SmartSegmentDetail from "./SmartSegmentDetail/SmartSegmentDetail"
import SmartSegmentsList from "./SmartSegmentsList/SmartSegmentsList"

export default function SmartSegments() {
  return (
    <Switch>
      <Route path={getRoutePath("segments.smart")} exact component={SmartSegmentsList} />
      <Route
        path={getRoutePath("segments.smart.trash")}
        exact
        render={() => <Trash itemType="smart_segments" />}
      />
      <Route path={getRoutePath("segments.smart.detail")} component={SmartSegmentDetail} />
    </Switch>
  )
}
