import React, { Component } from "react"
import { reduxForm, Form, Field } from "redux-form"
import PropTypes from "prop-types"

// ui components
import TagTextField from "components/UI/elements/TagTextField/TagTextField"

// constants helpers
import { required } from "helpers/validators.helper"

class LabelForm extends Component {
  submitForm = values => {
    this.props.onSubmit({ name: values.name })
  }

  render() {
    const { handleSubmit, type } = this.props
    const isCreate = type === "CREATE"

    return (
      <Form className="label-form" autoComplete="off" onSubmit={handleSubmit(this.submitForm)}>
        <div className="custom-text-field">
          <Field
            placeholder="Label name"
            name="name"
            component={TagTextField}
            label={isCreate ? "Create label" : "Modify label"}
            validate={required}
            autoFocus
            maxLength={30}
          />
        </div>
      </Form>
    )
  }
}

LabelForm.propTypes = {
  onFormClose: PropTypes.func.isRequired,
  onLabelDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  type: PropTypes.string.isRequired,
}

LabelForm = reduxForm({
  form: "LabelForm",
  touchOnBlur: false,
  enableReinitialize: true,
})(LabelForm)

export default LabelForm
