import React, { PureComponent } from "react"

import { smartSegmentGroups } from "resources/segment/segment/utilities/smartSegmentUtils"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import SmartSegmentTile from "components/UI/elements/SmartSegmentTile"
import Paper from "components/UI/elements/Paper"

import { getRoutePath } from "routes"

import "./SmartSegmentsList.scss"
import { withRouter } from "react-router"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { RowMessage } from "components/UI/elements/Table"
import { Link } from "react-router-dom"
import {
  useCreateSegment,
  useFetchAllSmartSegments,
} from "resources/segment/segment/segmentQueries"

const assignSegmentsToGroups = smartSegments => {
  const segmentBuckets = {}

  smartSegments
    .sort((a, b) => {
      if (a.frontend_settings?.smart?.position < b.frontend_settings?.smart?.position) {
        return -1
      }
      if (a.frontend_settings?.smart?.position > b.frontend_settings?.smart?.position) {
        return 1
      }
      return a.name.localeCompare(b.name)
    })
    .forEach(segment => {
      const segmentGroupId = segment.frontend_settings?.smart?.group_id
      if (segmentGroupId) {
        if (segmentBuckets[segmentGroupId]) {
          segmentBuckets[segmentGroupId].push(segment)
        } else {
          segmentBuckets[segmentGroupId] = [segment]
        }
      } else {
        if (segmentBuckets["uncategorized"]) {
          segmentBuckets["uncategorized"].push(segment)
        } else {
          segmentBuckets["uncategorized"] = [segment]
        }
      }
    })

  return segmentBuckets
}

class SmartSegmentsList extends PureComponent {
  createSegment = async () => {
    const { createSegment, history } = this.props

    await createSegment(
      {
        name: "Untitled smart segment",
        prebuilt: 1,
        frontend_settings: { smart: { position: 1 } },
      },
      {
        onSuccess: ({ segment: { id } }) => {
          history.push(getRoutePath("segments.smart.detail", { id }))
        },
      },
    )
  }

  renderSmartSegments = () => {
    const { segmentBuckets } = this.props

    let prevColor = null
    const groupsToRender = smartSegmentGroups
      .map(group => {
        if (segmentBuckets[group.id]) {
          const hasBigerMargin = group.color !== prevColor
          prevColor = group.color
          return (
            <Paper
              className={`segments-group ${group.color} ${hasBigerMargin ? "bigger-margin" : ""}`}
              key={group.id}
            >
              <h3>{group.name}</h3>
              <div className="segments">
                {segmentBuckets[group.id].map(segment => (
                  <SmartSegmentTile
                    key={segment.id}
                    name={segment.name}
                    description={segment.frontend_settings?.smart?.description}
                    className="segment-tile"
                    color={group.color}
                    icon={segment.frontend_settings?.smart?.icon}
                    link={{
                      pathname: getRoutePath("segments.smart.detail", { id: segment.id }),
                      state: { goBack: true },
                    }}
                  />
                ))}
              </div>
            </Paper>
          )
        }
        prevColor = group.color
        return null
      })
      .toArray()

    if (segmentBuckets.uncategorized) {
      groupsToRender.push(
        <Paper className="segments-group bigger-margin" key="uncategorized">
          <h3>Uncategorized</h3>
          <div className="segments">
            {segmentBuckets["uncategorized"].map(segment => (
              <SmartSegmentTile
                key={segment.id}
                name={segment.name}
                description={segment.frontend_settings?.smart?.description}
                className="segment-tile"
                icon={segment.frontend_settings?.smart?.icon}
                link={getRoutePath("segments.smart.detail", { id: segment.id })}
              />
            ))}
          </div>
        </Paper>,
      )
    }

    return (
      <>
        <Paper className="top-bar">
          <Link to={getRoutePath("segments.smart.trash")}>
            <Button icon="trash-alt" iconStyle="far" type="button" color="grey" variant="outlined">
              Trash
            </Button>
          </Link>
        </Paper>
        {groupsToRender}
      </>
    )
  }

  render() {
    const {
      smartSegments,
      areSmartSegmentsFetching,
      areSmartSegmentsFulfilled,
      hasAccess,
      isCreatingSegment,
    } = this.props

    const createAllowed = hasAccess.setup.smartSegments

    return (
      <Page
        className="smart-segments-list"
        title="Smart segments"
        headerContent={
          <Button
            onClick={_ => this.createSegment()}
            loading={isCreatingSegment}
            disabled={!createAllowed}
          >
            + Create smart segment
          </Button>
        }
      >
        {!areSmartSegmentsFulfilled && areSmartSegmentsFetching && <LoadingIndicator />}
        {areSmartSegmentsFulfilled && smartSegments.length === 0 && (
          <>
            <Paper className="top-bar">
              <Link to={getRoutePath("segments.smart.trash")}>
                <Button
                  icon="trash-alt"
                  iconStyle="far"
                  type="button"
                  color="grey"
                  variant="outlined"
                >
                  Trash
                </Button>
              </Link>
            </Paper>
            <Paper>
              <RowMessage>
                {createAllowed ? (
                  <span>Click on the "Create Smart Segment" to get started.</span>
                ) : (
                  <span>
                    Smart segments not set. Contact admin to set smart segments for your customer's
                    database.
                  </span>
                )}
              </RowMessage>
            </Paper>
          </>
        )}
        {areSmartSegmentsFulfilled && smartSegments.length > 0 && this.renderSmartSegments()}
      </Page>
    )
  }
}

SmartSegmentsList = withRouter(SmartSegmentsList)

export default props => {
  const hasAccess = useHasAccess()

  const {
    data: smartSegments,
    isLoading: areSmartSegmentsFetching,
    isSuccess: areSmartSegmentsFulfilled,
  } = useFetchAllSmartSegments()

  const { mutateAsync: createSegment, isLoading: isCreatingSegment } = useCreateSegment("smart")

  const segmentBuckets = smartSegments ? assignSegmentsToGroups(smartSegments) : {}

  return (
    <SmartSegmentsList
      {...props}
      hasAccess={hasAccess}
      smartSegments={smartSegments}
      segmentBuckets={segmentBuckets}
      areSmartSegmentsFetching={areSmartSegmentsFetching}
      areSmartSegmentsFulfilled={areSmartSegmentsFulfilled}
      isCreatingSegment={isCreatingSegment}
      createSegment={createSegment}
    />
  )
}
