import React, { useEffect, useState } from "react"
import styles from "./UserCounts.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { api } from "api"
import { FetchUserCountsResponse } from "types/usersActivity"
import Paper from "components/UI/elements/Paper"
import InfoTooltip from "components/UI/elements/InfoTooltip"

export default function UserCounts() {
  type State = {
    data: FetchUserCountsResponse["users_stats"] | null
    isLoading: boolean
  }

  const [userCounts, setUserCounts] = useState<State>({ data: null, isLoading: true })

  async function fetchUserCounts() {
    try {
      const { users_stats } = await api.userCounts()
      setUserCounts({ data: users_stats, isLoading: false })
    } catch {
      setUserCounts({ data: null, isLoading: false })
    }
  }

  useEffect(() => {
    fetchUserCounts()
  }, [])

  const { data, isLoading } = userCounts

  return (
    <Paper className={styles.container}>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Total number of users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : data === null ? "N/A" : data.total}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Active users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : data === null ? "N/A" : data.activated}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>
          Never activated users:
          <InfoTooltip className={styles.info}>
            Users that have been invited but never accepted an invitation and haven't logged in
          </InfoTooltip>
        </div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : data === null ? "N/A" : data.not_activated}
        </div>
      </div>
      <div className={styles.userCountItem}>
        <div className={styles.label}>Deleted users:</div>
        <div className={styles.value}>
          {isLoading ? <LoadingIndicator size="sm" /> : data === null ? "N/A" : data.deleted}
        </div>
      </div>
    </Paper>
  )
}
