import React, { useMemo, useState } from "react"
import create from "zustand"
import { format } from "date-fns"
import { Link, useHistory } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { DATEFNS, PUSH_NOTIFICATIONS_CHANNEL_NAME } from "sharedConstants"
import { getRoutePath } from "routes"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import MarketingContent from "components/UI/components/MarketingContent/MarketingContent"
import {
  MobilePushNotification,
  MobilePushSelectionState,
} from "resources/mobilePushNotification/mobilePushNotificationTypes"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import Table, {
  RowMessage,
  SortButton,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "components/UI/elements/Table"
import {
  useDeleteMobilePushNotification,
  useFetchAllMobilePushNotifications,
} from "resources/mobilePushNotification/mobilePushNotificationQueries"

import styles from "./MobilePushNotificationsList.module.scss"
import Username from "components/Username/Username"
import { useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import {
  useFetchChannels,
  useFetchPushNotificationsChannel,
  useModifyPushNotificationsChannel,
} from "resources/channel/channelQueries"
import Tippy from "@tippyjs/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PushNotificationChannel } from "resources/channel/channelTypes"
import ChannelFrequencyCap from "components/UI/components/ChannelFrequencyCap/ChannelFrequencyCap"
import { isLocalFrequencyConflicting } from "resources/channel/channelUtils"
import ChannelFrequencyCapModal from "components/UI/components/ChannelFrequencyCapModal/ChannelFrequencyCapModal"

const pageTitle = "Mobile push campaigns"

const useStore = create<MobilePushSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function MobilePushNotificationsList() {
  const hasAccess = useHasAccess()

  const [selectedNotification, setSelectedNotification] = useState<MobilePushNotification | null>(
    null,
  )
  const [affectedCampaigns, setAffectedCampaigns] = useState<Array<MobilePushNotification>>([])
  const [frequencyCapToSave, setFrequencyCapToSave] = useState<
    PushNotificationChannel["frequency_cap"] | undefined
  >()

  const history = useHistory()

  const { data: channels, isLoading: isLoadingChannels } = useFetchChannels()
  const notificationsEnabled = channels
    ? channels.find(({ name }) => name === PUSH_NOTIFICATIONS_CHANNEL_NAME)?.is_active
    : false

  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()
  const {
    data,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
  } = useFetchAllMobilePushNotifications(
    {
      enabled: hasAccess.mobilePushNotifications.view && notificationsEnabled,
    },
    { orderBy, orderDir, searchTerm: searchTerm.trim() },
  )

  const { mutate, isLoading: isDeleting } = useDeleteMobilePushNotification()

  const { data: pushNotificationsChannel, isLoading: isLoadingPushNotificationsChannel } =
    useFetchPushNotificationsChannel({
      enabled: hasAccess.setup.channels && notificationsEnabled,
    })
  const modifyMutation = useModifyPushNotificationsChannel()

  const conflictingCampaigns = useMemo(() => {
    if (!data || !pushNotificationsChannel) return []

    return data.push_notifications.filter(({ frequency_cap }) =>
      isLocalFrequencyConflicting(frequency_cap, pushNotificationsChannel.frequency_cap),
    )
  }, [data, pushNotificationsChannel])

  const closeDeleteModal = () => setSelectedNotification(null)

  const closeFrequencyCapModal = () => {
    setAffectedCampaigns([])
    setFrequencyCapToSave(undefined)
  }

  const modifyFrequencyCap = (frequencyCap: PushNotificationChannel["frequency_cap"]) => {
    if (modifyMutation.isLoading) return

    const formData = new FormData()
    if (frequencyCap) formData.append("frequency_cap", JSON.stringify(frequencyCap))

    modifyMutation.mutate(
      { data: formData },
      {
        onSuccess: () => {
          closeFrequencyCapModal()
        },
      },
    )
  }

  // `isLoading` is true if the query is disabled and stays true. We have to check `isFetching` to
  //  exclude that scenario from this if statement.
  if (isLoadingChannels || (isLoadingNotifications && isFetchingNotifications))
    return (
      <Page title={pageTitle}>
        <LoadingIndicator />
      </Page>
    )

  if (!notificationsEnabled)
    return (
      <Page title={pageTitle}>
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications are ready&nbsp;for&nbsp;use!</h1>
          <strong>
            To set this up, please contact{" "}
            <a href="mailto:support@meiro.io" target="_blank" rel="noreferrer">
              support@meiro.io
            </a>{" "}
            or get in touch with your Meiro account manager!
          </strong>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      </Page>
    )

  if (!hasAccess.mobilePushNotifications.view)
    return (
      <Page title={pageTitle}>
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications for mobile apps</h1>
          <strong>
            It seems like you don't have access to the Mobile Push tab. If you want to know more
            about your access settings, please contact your administrator.
          </strong>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      </Page>
    )

  const isEmpty = data?.push_notifications.length === 0

  return (
    <Page
      title={pageTitle}
      headerContent={
        <>
          <SearchField
            input={{ value: searchTerm, onChange: setSearchTerm }}
            placeholder="Search for name"
            onClear={() => setSearchTerm("")}
            wrapperClassName={styles.search}
          />
          <Button
            disabled={!hasAccess.mobilePushNotifications.edit}
            onClick={() =>
              history.push({
                pathname: getRoutePath("channels.mobile-push.create"),
                state: { goBack: true },
              })
            }
          >
            + Create Mobile Push
          </Button>
        </>
      }
    >
      {pushNotificationsChannel && (
        <ChannelFrequencyCap
          channel="push_notifications"
          description="The rule applies to all mobile push campaigns. It restricts the number of notifications within a specific time interval. The limit is set to the registration token, and if a customer profile has multiple devices, all of them will be considered as a single count towards the global limit."
          disabled={!hasAccess.mobilePushNotifications.edit}
          isLoading={isLoadingPushNotificationsChannel}
          isSaving={modifyMutation.isLoading}
          conflictingCampaigns={conflictingCampaigns.map(({ id, name }) => ({ id, name }))}
          frequencyCap={pushNotificationsChannel.frequency_cap}
          onSubmit={formValues => {
            if (data?.push_notifications) {
              const conflictingCampaignsToBe = data.push_notifications.filter(({ frequency_cap }) =>
                isLocalFrequencyConflicting(frequency_cap, formValues),
              )

              if (conflictingCampaignsToBe.length > 0) {
                setAffectedCampaigns(conflictingCampaignsToBe)
                setFrequencyCapToSave(formValues)
                return
              }

              modifyFrequencyCap(formValues)
            }
          }}
        />
      )}
      <Paper className={styles.paper} dataTestId="mobile-push-notifications-paper">
        <div className={styles.trashButtonWrapper}>
          <Link to={getRoutePath("channels.mobile-push.trash")}>
            <Button type="button" color="grey" variant="outlined" icon="trash-alt" iconStyle="far">
              Trash
            </Button>
          </Link>
        </div>
        {isEmpty ? (
          <RowMessage>No mobile push found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>
                <SortButton
                  column="name"
                  label="Name"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("name")}
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="last_activation"
                  label="Last activation"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("last_activation")}
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="modified"
                  label="Modified at"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("modified")}
                />
              </Th>
              <Th>&nbsp;</Th>
            </Thead>
            <Tbody>
              {data?.push_notifications.map(notification => (
                <React.Fragment key={notification.id}>
                  <Tr>
                    <Td
                      textBigger
                      textBlack
                      textBold
                      className={styles.name}
                      data-testid="mobile-push-column-name"
                    >
                      {notification.frequency_cap.ignore_channel_frequency_cap && (
                        <Tippy content="Ignores global frequency cap." placement="bottom">
                          <FontAwesomeIcon icon={["fas", "bolt"]} className={styles.boltIcon} />
                        </Tippy>
                      )}
                      {conflictingCampaigns.find(({ id }) => notification.id === id) && (
                        <Tippy content="Conflicting with global frequency cap." placement="bottom">
                          <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                            className={styles.warningIcon}
                          />
                        </Tippy>
                      )}
                      {notification.name}
                    </Td>
                    <Td textAlignRight className={styles.date}>
                      {notification["last_activation"]
                        ? format(
                            new Date(`${notification.last_activation}Z`),
                            DATEFNS.DATETIME_FORMAT,
                          )
                        : ""}
                    </Td>
                    <Td textAlignRight className={styles.date}>
                      <div>
                        {" "}
                        {format(new Date(`${notification.modified}Z`), DATEFNS.DATETIME_FORMAT)}
                      </div>
                      <div className={styles.modifiedBy}>
                        by <Username userId={notification.modified_by} />
                      </div>
                    </Td>
                    <Td textAlignRight className={styles.actions}>
                      <Link
                        to={getRoutePath("channels.mobile-push.detail", {
                          id: notification.id,
                        })}
                        className={styles.editLink}
                      >
                        <IconButton
                          color="black"
                          size="xs"
                          variant="outlined"
                          icon="pencil-alt"
                          tooltip="Edit"
                          data-testid="edit-mobile-push-notification"
                        />
                      </Link>
                      <IconButton
                        disabled={!hasAccess.mobilePushNotifications.edit}
                        color="red"
                        icon="trash-alt"
                        size="xs"
                        tooltip="Delete"
                        variant="outlined"
                        onClick={() => setSelectedNotification(notification)}
                        data-testid="remove-mobile-push-notification"
                      />
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      {isEmpty && searchTerm === "" && (
        <MarketingContent
          img={
            <div className={styles.marketingContent}>
              <div className={styles.bottomLine} />
              <div data-testid="mobile-push-notifications-phone" className={styles.phone}>
                <div data-testid="mobile-push-notifications-screen" className={styles.screen} />
              </div>
            </div>
          }
        >
          <h1>Push notifications for mobile apps</h1>
          <p>
            The new channel for activation allows you to combine real-time web personalization{" "}
            <span className={styles.primary}>with mobile app push messages</span>.
          </p>
          <p>Use your audiences to trigger personalized content for your mobile app users.</p>
        </MarketingContent>
      )}
      <ConfirmModal
        isLoading={isDeleting}
        open={!!selectedNotification}
        action="delete"
        item={selectedNotification?.name}
        title="Are you sure?"
        type="delete"
        what="mobile push"
        handleClose={closeDeleteModal}
        handleConfirm={() => mutate(selectedNotification!, { onSuccess: () => closeDeleteModal() })}
      />
      {affectedCampaigns.length > 0 && (
        <ChannelFrequencyCapModal
          affectedCampaigns={affectedCampaigns.map(({ id, name }) => ({ id, name }))}
          channel="push_notifications"
          isLoading={modifyMutation.isLoading}
          open={affectedCampaigns.length > 0}
          onSubmit={() => {
            if (frequencyCapToSave) modifyFrequencyCap(frequencyCapToSave)
          }}
          onClose={closeFrequencyCapModal}
        />
      )}
    </Page>
  )
}
