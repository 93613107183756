import React, { useMemo, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import Select from "react-select"
import SimpleBar from "simplebar-react"

import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { max, min, required } from "helpers/validators.helper"
import {
  FrequencyCapPeriodType,
  ChannelFrequencyCapType,
  ChannelType,
} from "resources/channel/channelTypes"
import { getRoutePath } from "routes"

import styles from "./ChannelFrequencyCap.module.scss"

const periodTypeOptions: Array<{ label: string; value: FrequencyCapPeriodType }> = [
  { label: "Days", value: "DAYS" },
  { label: "Hours", value: "HOURS" },
]

const SIMPLE_BAR_HEIGHT = 400

type FormFields = ChannelFrequencyCapType

type ChannelSettingsProps = {
  channel: ChannelType
  conflictingCampaigns: Array<{ id: string; name: string }>
  description: string
  disabled: boolean
  frequencyCap: ChannelFrequencyCapType
  isLoading: boolean
  isSaving: boolean
  onSubmit: (formValues: FormFields) => void
}

export default function ChannelFrequencyCap({
  channel,
  conflictingCampaigns,
  description,
  disabled,
  frequencyCap,
  isLoading,
  isSaving,
  onSubmit,
}: ChannelSettingsProps) {
  const [expanded, setExpanded] = useState(false)
  const [isBottom, setIsBottom] = useState(false)

  const scrollableRef = useRef<HTMLElement>(null)
  const { current } = scrollableRef

  const isScrollVisible = useMemo(() => {
    if (!current) return false
    return current.clientHeight === SIMPLE_BAR_HEIGHT
  }, [current])

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormFields>()

  const periodType = watch("period.type", frequencyCap?.period.type)

  const textPlaceholder = channel === "emails" ? "email" : "mobile push"
  const textPlaceholderPlural = channel === "emails" ? "emails" : "mobile pushes"

  return (
    <>
      <Paper
        className={classNames(styles.frequencyCapPaper, {
          [styles.hasConflicts]: expanded && conflictingCampaigns.length > 0,
        })}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.rowFlexBox}>
              <div className={styles.header}>
                <h3 className={styles.title}>Global frequency cap</h3>
              </div>
              <div className={styles.buttons}>
                {expanded && (
                  <Button disabled={disabled} loading={isSaving} color="green" type="submit">
                    Save
                  </Button>
                )}
                <IconButton
                  color="grey"
                  icon={expanded ? "caret-up" : "caret-down"}
                  variant="transparent"
                  onClick={() => setExpanded(prev => !prev)}
                  className={styles.toggleButton}
                />
              </div>
            </div>
            {expanded && (
              <div className={styles.fields}>
                <div className={styles.header}>
                  <h3 className={styles.title}>
                    {`Global count for all ${textPlaceholderPlural} per user.`}
                  </h3>
                  <p className={styles.description}>{description}</p>
                </div>
                <div className={styles.maxCount}>
                  <TextInput
                    defaultValue={frequencyCap?.max_count}
                    error={errors?.max_count?.message}
                    max={50}
                    min={1}
                    step={1}
                    type="number"
                    className={styles.textInput}
                    {...register("max_count", {
                      valueAsNumber: true,
                      validate: {
                        required,
                        max: max(50),
                        min: min(1),
                      },
                    })}
                  />
                  <div className={styles.times}>Times</div>
                </div>
                <span className={styles.text}>during period of</span>
                <div className={styles.period}>
                  <TextInput
                    defaultValue={frequencyCap?.period.size}
                    error={errors?.period?.size?.message}
                    max={periodType === "DAYS" ? 7 : 168}
                    min={1}
                    step={1}
                    type="number"
                    className={styles.textInput}
                    {...register("period.size", {
                      valueAsNumber: true,
                      validate: {
                        required,
                        max: periodType === "DAYS" ? max(7) : max(168),
                        min: min(1),
                      },
                    })}
                  />
                  <Controller
                    control={control}
                    name="period.type"
                    defaultValue={frequencyCap?.period.type}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        simpleValue
                        components={{ DropdownIndicator }}
                        options={periodTypeOptions}
                        value={periodTypeOptions.find(o => o.value === value)}
                        onChange={o => onChange(o?.value)}
                        styles={selectStyles("medium", "no-left", "300px", "#FE7F66", "#F7F9FB")}
                        className={classNames("select-input", styles.periodType)}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </Paper>
      {expanded && conflictingCampaigns.length > 0 && (
        <Paper className={styles.conflictingCampaignsPaper}>
          <div className={styles.header}>
            <h3 className={styles.title}>{`Conflicting ${textPlaceholder} campaigns`}</h3>
            <p className={styles.description}>
              {`Frequency cap caused conflict in some ${textPlaceholder} campaigns. Either change global frequency cap or resolve the problem in conflicting ${textPlaceholder} campaigns.`}
            </p>
          </div>
          <SimpleBar
            scrollableNodeProps={{ ref: scrollableRef }}
            onScrollCapture={() => {
              if (current) {
                const { scrollTop, scrollHeight, clientHeight } = current
                if (Math.round(scrollTop + clientHeight) === scrollHeight) setIsBottom(true)
                else setIsBottom(false)
              }
            }}
            className={classNames(styles.bar, { [styles.notBottom]: !isBottom && isScrollVisible })}
            style={{ maxHeight: `${SIMPLE_BAR_HEIGHT}px` }}
          >
            <div className={styles.campaigns}>
              {conflictingCampaigns.map((campaign, index) => (
                <Link
                  key={campaign.id}
                  to={getRoutePath(
                    channel === "emails" ? "channels.emails.detail" : "channels.mobile-push.detail",
                    { id: campaign.id },
                  )}
                  className={classNames(styles.link, {
                    [styles.noBorder]: conflictingCampaigns.length - 1 === index,
                  })}
                >
                  <span className={styles.name}>{campaign.name}</span>
                  <FontAwesomeIcon icon="circle-arrow-right" />
                </Link>
              ))}
            </div>
          </SimpleBar>
        </Paper>
      )}
    </>
  )
}
