import React from "react"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import _isFinite from "lodash/isFinite"
import _get from "lodash/get"
import _toInteger from "lodash/toInteger"
import _isArray from "lodash/isArray"
import _take from "lodash/take"
import _drop from "lodash/drop"
import _orderBy from "lodash/orderBy"
import ChartDataLabels from "chartjs-plugin-datalabels"

// constants, helpers
import { abbreviateNumber } from "helpers/number.helper"
import { lightenDarkenColor } from "helpers/chartjs.helper"
import { useFetchDataSourcesMap } from "resources/dataSource/dataSourceQueries"

const SHOW_EXACT_VALUES = 3

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    itemSort: function (a, b) {
      if (a.datasetIndex > b.datasetIndex) {
        return -1
      }
      if (a.datasetIndex < b.datasetIndex) {
        return 1
      }
      return 0
    },
    callbacks: {
      label: function (tooltipItems, data) {
        if (_isFinite(tooltipItems.yLabel) && tooltipItems.yLabel > 0) {
          const labelsArray = _get(data, `datasets[${tooltipItems.datasetIndex}].label`)
          if (_isArray(labelsArray)) {
            if (labelsArray.length === 1 && labelsArray[0] === undefined) {
              return `Other: ${abbreviateNumber(tooltipItems.yLabel)}`
            } else if (labelsArray.length <= 3) {
              // one liner
              return `${labelsArray.join(", ")}: ${abbreviateNumber(tooltipItems.yLabel)}`
            } else {
              // take first 3 names, rest render in afterLabel callback
              return `${_take(labelsArray, 3).join(", ")},`
            }
          }
          return ""
        }
        return ""
      },
      afterLabel: function (tooltipItems, data) {
        if (_isFinite(tooltipItems.yLabel) && tooltipItems.yLabel > 0) {
          const labelsArray = _get(data, `datasets[${tooltipItems.datasetIndex}].label`)
          if (_isArray(labelsArray)) {
            if (labelsArray.length > 3) {
              // multiple liner, continue with list
              const label = []
              let tmpLabel = ""
              const arrayToTraverse = _drop(labelsArray, 3)
              arrayToTraverse.forEach((text, index) => {
                if (tmpLabel.length === 0) {
                  tmpLabel = text
                } else {
                  tmpLabel += ` ${text}`
                }
                if (index !== arrayToTraverse.length - 1) {
                  tmpLabel += ","
                } else {
                  tmpLabel += `: ${abbreviateNumber(tooltipItems.yLabel)}`
                }
                if (index % 3 === 2) {
                  label.push(tmpLabel)
                  tmpLabel = ""
                }
              })
              if (tmpLabel.length !== 0) {
                label.push(tmpLabel)
              }
              return label
            }
          }
          return ""
        }
        return ""
      },
    },
  },
  hover: {
    mode: null,
  },
  layout: {
    padding: {
      top: 20,
      right: 10,
      bottom: 3,
      left: 3,
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          autoSkip: false,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          suggestedMin: 0,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return abbreviateNumber(value)
          },
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      color: function (ctx) {
        return ctx.dataset.borderColor
      },
      formatter: function (value, ctx) {
        const datasets = ctx.chart.data.datasets
        let lastDatasetIndexWithFiniteValue = 0
        datasets.forEach((dataset, idx) => {
          if (_isFinite(dataset.data[ctx.dataIndex])) {
            lastDatasetIndexWithFiniteValue = idx
          }
        })
        if (ctx.datasetIndex === lastDatasetIndexWithFiniteValue) {
          let sum = 0
          datasets.forEach(dataset => {
            if (_isFinite(dataset.data[ctx.dataIndex])) {
              sum += dataset.data[ctx.dataIndex]
            }
          })
          return abbreviateNumber(sum)
        } else {
          return ""
        }
      },
      font: {
        size: 12,
        weight: "bold",
      },
      anchor: "end",
      align: "end",
      offset: -3,
    },
  },
}

const CustomersAcrossXSourcesChart = ({ rawData, className, sourcesMap }) => {
  const transformedRawData = rawData.map(entry => {
    let other = 0
    let sourceValues = []
    const sorted = _orderBy(entry.source_values, "customers_count", "desc")
    sorted.forEach((sourceValue, index) => {
      if (index < SHOW_EXACT_VALUES) {
        sourceValues.push(sourceValue)
      } else {
        other += sourceValue.customers_count
      }
    })
    if (other) {
      sourceValues.push({
        source_ids: [""],
        customers_count: other,
      })
    }
    return {
      ...entry,
      source_values: sourceValues.reverse(),
    }
  })
  let data = {
    labels: [],
    datasets: [],
  }
  transformedRawData.forEach((sourceCountEntry, sourceCountIndex) => {
    data.labels.push(
      sourceCountEntry.source_count === 1 ? "1 source" : `${sourceCountEntry.source_count} sources`,
    )
    const baseColor = "#19309F"
    const lighteningFactor = _toInteger(60 / (sourceCountEntry.source_values.length - 1))
    sourceCountEntry.source_values.forEach((value, sourceIndex) => {
      const color = lightenDarkenColor(
        baseColor,
        +((sourceCountEntry.source_values.length - 1 - sourceIndex) * lighteningFactor),
      )
      data.datasets.push({
        label:
          value.source_ids.length > 0
            ? value.source_ids.map(sourceId => sourcesMap[sourceId]?.name)
            : "Other",
        backgroundColor: color,
        borderColor: color,
        data: [
          ...Array(sourceCountIndex).fill(null),
          value.customers_count,
          ...Array(transformedRawData.length - sourceCountIndex - 1).fill(null),
        ],
        minBarLength: 1,
      })
    })
  })

  return (
    <div className={`customers-counts-chart ${className}`}>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

CustomersAcrossXSourcesChart.propTypes = {
  rawData: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default props => {
  const { data = {} } = useFetchDataSourcesMap()

  return <CustomersAcrossXSourcesChart {...props} sourcesMap={data} />
}
