import React, { lazy, PureComponent, Suspense } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List } from "immutable"
import _isPlainObject from "lodash/isPlainObject"
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import _has from "lodash/has"
import _toString from "lodash/toString"
import { formValueSelector } from "redux-form"
import moment from "moment"

// actions
import { showToast } from "actions/toast.action"

// selectors

// ui components
import ExpandedInsight from "components/UI/components/ExpandedInsight/ExpandedInsight"

// constants, helpers
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

import "./SegmentInsights.scss"
import { SocketContext } from "context/socket"
import { TOAST } from "sharedConstants"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"

import { reorderStickyInsights } from "helpers/insight.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import { useSegmentCountsStore } from "resources/segment/segment/segmentCounts"
const InsightTile = lazy(() => import("components/UI/elements/InsightTile"))

class SegmentInsights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      attributeAggregations: null,
      segmentAggregationValues: null,
      allAggregationValues: null,
      expandedInsight: {
        data: null,
        open: false,
      },
    }
    this.pendingPromises = new PendingPromise()
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    this.context.off("segment_aggregations_response")
  }

  componentDidMount() {
    const { showToast, id } = this.props

    this.context.on("segment_aggregations_response", msg => {
      if (_has(msg, "error")) {
        showToast(msg.error, TOAST.TYPE.ERROR)
      } else {
        if (_toString(msg.segment_id) === id) {
          // so it's related to current segment
          if (this.state.segmentAggregationValues) {
            this.setState(prevState => ({
              segmentAggregationValues: {
                ...prevState.segmentAggregationValues,
                [msg.segment_aggregation.attribute_aggregation_id.toString()]:
                  msg.segment_aggregation,
              },
            }))
          } else {
            this.setState({
              segmentAggregationValues: {
                [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
              },
            })
          }
        } else {
          // so it's data insight
          if (this.state.allAggregationValues) {
            this.setState(prevState => ({
              allAggregationValues: {
                ...prevState.allAggregationValues,
                [msg.segment_aggregation.attribute_aggregation_id.toString()]:
                  msg.segment_aggregation,
              },
            }))
          } else {
            this.setState({
              allAggregationValues: {
                [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
              },
            })
          }
        }
      }
    })

    const attributesAggregationsRequest = this.pendingPromises.create(
      this.fetchAllAttributesAggregations(),
    )
    attributesAggregationsRequest.promise
      .then(response => {
        this.setState({
          attributeAggregations: response,
        })
        this.pendingPromises.remove(attributesAggregationsRequest)
      })
      .catch(error => {
        this.pendingPromises.remove(attributesAggregationsRequest)
      })

    this.fetchSegmentAggregationsValues()
    this.fetchAllAggregationsValues()
  }

  fetchSegmentAggregationsValues = () => {
    this.context.emit("segment_aggregations", { segment_id: this.props.id })
  }

  fetchAllAggregationsValues = () => {
    this.context.emit("segment_aggregations", { segment_id: null })
  }

  fetchAllAttributesAggregations = async () => {
    const caller = new AllResourceItemsFetcher()
    const data = await caller
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.attributesAggregations.list(offset, limit, "order_index", "ASC", loadFullStructure),
      )
      .setDataPath("attribute_aggregations")
      .setLoadFullStructure(0)
      .run()

    return List(data)
  }

  openExpandedInsight = ({ attribute, insight, globalAudience, segmentedAudience, subAttribute }) =>
    this.setState({
      expandedInsight: {
        open: true,
        data: {
          attribute,
          insight,
          globalAudience,
          segmentedAudience,
          subAttribute,
        },
      },
    })

  closeExpandedInsight = () => {
    const { expandedInsight } = this.state

    if (expandedInsight.open)
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      searchTerm,
      filteredSource,
      stickyInsightIds,
      hasAccess,
      conditionsResultsCount,
      customersTotalCount,
    } = this.props
    const {
      attributeAggregations,
      segmentAggregationValues,
      allAggregationValues,
      expandedInsight,
    } = this.state
    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = attributeAggregations.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
    }

    if (filteredSource) {
      resultAttributeAggregations = resultAttributeAggregations.filter(item => {
        return attributesMapById[item.attribute_id]?.source.id === filteredSource.value
      })
    }

    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightIds,
    )

    const dataSize = List.isList(attributeAggregations) ? attributeAggregations.size : 0

    return (
      <section className="segment-analytics">
        {!areAttributesFulfilled && <LoadingIndicator />}
        {areAttributesFulfilled && dataSize > 0 && (
          <div className={`tiles-section`}>
            {resultAttributeAggregations.map(item => {
              const segmentAggregationValueObj = segmentAggregationValues?.[item.id.toString()]
              const aggregationValueObj = allAggregationValues?.[item.id.toString()]
              let segmentAggregationValue = null
              let percentage = null
              if (segmentAggregationValueObj) {
                if (_isPlainObject(segmentAggregationValueObj.result)) {
                  if (_has(segmentAggregationValueObj, "result.value")) {
                    segmentAggregationValue = segmentAggregationValueObj.result.value
                  } else if (_has(segmentAggregationValueObj, "result.count")) {
                    segmentAggregationValue = segmentAggregationValueObj.result.count
                  }
                  percentage = segmentAggregationValueObj.result.segment_percentage
                } else {
                  segmentAggregationValue = segmentAggregationValueObj.result
                }
              }
              if (_isArray(segmentAggregationValue) && segmentAggregationValue.length) {
                if (_isPlainObject(segmentAggregationValue[0])) {
                  const tmp = [...segmentAggregationValue]
                  percentage = []
                  segmentAggregationValue = []
                  tmp.forEach(obj => {
                    if (_has(obj, "value")) {
                      segmentAggregationValue.push(obj.value)
                    } else if (_has(obj, "count")) {
                      segmentAggregationValue.push(obj.count)
                    }
                    percentage.push(obj.segment_percentage)
                  })
                }
              }

              const attribute = attributesMapById[item.attribute_id]
              const subAttribute =
                attribute && item.sub_attribute_id
                  ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                  : null
              const compareValue =
                item.settings?.value_from && item.settings?.value_to
                  ? [item.settings.value_from, item.settings.value_to]
                  : item.settings?.value
              return (
                <Suspense key={item.id} fallback={<LoadingIndicator />}>
                  <InsightTile
                    id={item.id}
                    name={item.name}
                    value={segmentAggregationValue}
                    compareValue={compareValue}
                    attribute={attributesMapById[item.attribute_id]}
                    subAttribute={subAttribute}
                    percentage={percentage}
                    funcType={item.function}
                    outOf={conditionsResultsCount}
                    color={_get(item, "frontend_settings.color")}
                    attributePercentage={
                      segmentAggregationValueObj?.customers_with_attribute_percentage
                    }
                    showNewBadge={moment().diff(item.created, "days") < 8}
                    displayType={_get(item, "frontend_settings.tile_type", "chart")}
                    onExpandClick={() =>
                      this.openExpandedInsight({
                        subAttribute,
                        attribute: attributesMapById[item.attribute_id],
                        insight: item,
                        globalAudience: {
                          isKnownPercentage:
                            aggregationValueObj?.customers_with_attribute_percentage,
                          result: _get(aggregationValueObj, "result", 0),
                          total: customersTotalCount,
                        },
                        segmentedAudience: {
                          isKnownPercentage:
                            segmentAggregationValueObj?.customers_with_attribute_percentage,
                          result: _get(segmentAggregationValueObj, "result", 0),
                          total: conditionsResultsCount,
                        },
                      })
                    }
                    isLoading={!segmentAggregationValueObj}
                    isSticky={stickyInsightIds.includes(item.id)}
                  />
                </Suspense>
              )
            })}
          </div>
        )}
        {areAttributesFulfilled && List.isList(attributeAggregations) && dataSize === 0 && (
          <div className="segment-analytics-no-content">
            {hasAccess.setup.insights && (
              <p className="info-message">
                Insights not set. Go to Setup / Insights tab to create insights.
              </p>
            )}
            {!hasAccess.setup.insights && (
              <p className="info-message">Insights not set. Contact admin to set insights.</p>
            )}
          </div>
        )}
        {dataSize > 0 && resultAttributeAggregations.size === 0 && (
          <div className="segment-analytics-no-content">
            <p className="info-message">Nothing found.</p>
          </div>
        )}
        {expandedInsight.data && expandedInsight.open && (
          <ExpandedInsight
            {...expandedInsight.data}
            favorite={stickyInsightIds.includes(expandedInsight.data.insight.id)}
            open={expandedInsight.open}
            onClose={this.closeExpandedInsight}
          />
        )}
      </section>
    )
  }
}

SegmentInsights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.object.isRequired,
}

const selector = formValueSelector("InsigtsFilter")
const mapStateToProps = state => ({
  searchTerm: selector(state, "search"),
  filteredSource: selector(state, "select"),
  showToast: PropTypes.func.isRequired,
})

SegmentInsights = connect(mapStateToProps, {
  showToast,
})(SegmentInsights)

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()

  const { conditionsResultsCount, customersTotalCount } = useSegmentCountsStore()

  return (
    <SegmentInsights
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      stickyInsightIds={currentUser.frontend_settings?.stickyInsightsIds ?? []}
      hasAccess={hasAccess}
      conditionsResultsCount={conditionsResultsCount}
      customersTotalCount={customersTotalCount}
    />
  )
}
