import React from "react"
import SimpleBar from "simplebar-react"

import { ScheduleType } from "types/util"

import styles from "./ScheduleBar.module.scss"

type Props = {
  onSelect: (type: ScheduleType) => void
}

const ScheduleBar = React.forwardRef<HTMLDivElement, Props>(({ onSelect }, ref) => (
  <div ref={ref} className={styles.wrapper}>
    <SimpleBar className={styles.bar}>
      <ul className={styles.list}>
        <li onClick={() => onSelect("static")}>One-time schedule</li>
        <li onClick={() => onSelect("repetitive")}>Recurring schedule</li>
      </ul>
    </SimpleBar>
  </div>
))

export default ScheduleBar
