import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Form, Field, reduxForm } from "redux-form"
import ReactMarkdown from "react-markdown"

import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import TextArea from "components/UI/elements/TextArea/ReduxFormTextArea"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import { required } from "helpers/validators.helper"

import "./SingleView.scss"

class SingleView extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      viewMode: true,
    }
  }

  componentDidMount() {
    if (this.props.initialValues.id === 0) {
      //create -> set focus to name field, enable it
      if (this.nameFieldRef) {
        this.nameFieldRef.focus()
      }
    }
  }

  onSubmit = values => {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      })
      this.props.handleNoteSave(values).catch(() => {
        this.setState({ loading: false })
      })
    }
  }

  onCancelClick = () => {
    if (this.state.viewMode) {
      this.props.handleBackAction()
    } else {
      // edit mode, cancel textarea editing
      if (this.props.initialValues.id === 0) {
        this.props.handleBackAction()
      }
      this.setState({
        viewMode: true,
      })
    }
  }

  editContent = () => {
    if (this.props.isEditable) {
      this.setState({
        viewMode: false,
      })
    }
  }

  render() {
    const { viewMode, loading } = this.state
    const { handleSubmit, handleBackAction, initialValues, isEditable } = this.props
    return (
      <Form className="note-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="note-form-header">
          <IconButton
            color="grey"
            className="back-button"
            onClick={handleBackAction}
            icon="chevron-left"
            variant="transparent"
          />
          <div className="note-name">
            <Field
              name="name"
              component={TextField}
              label="Note name"
              disabled={!isEditable}
              setFieldToBeFocused={input => {
                this.nameFieldRef = input
              }}
              validate={required}
            />
          </div>
        </div>
        {viewMode && (
          <section className="markdown-content-wrapper">
            <div
              onClick={this.editContent}
              className={`markdown-content ${isEditable ? "" : "disabled"}`}
            >
              {initialValues.content.length !== 0 && (
                <ReactMarkdown source={initialValues.content} />
              )}
              {initialValues.content.length === 0 && "Click to edit note's content."}
            </div>
          </section>
        )}
        {!viewMode && (
          <Field
            name="content"
            component={TextArea}
            placeholder="Content"
            rows={11}
            className="note-markdown-textarea"
            autoFocus
          />
        )}
        <div className="tips">
          TIPS:
          <span className="headline"># Headline</span>
          <span className="bold">**bold**</span>
          <span className="italics">_italics_</span>
          <span className="strikethrough">~~strike~~</span>
        </div>
        <div className="note-actions">
          <Button color="grey" variant="link" onClick={this.onCancelClick}>
            Cancel
          </Button>
          <Button icon="save" loading={loading} type="submit" disabled={!isEditable}>
            Save
          </Button>
        </div>
      </Form>
    )
  }
}

SingleView.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleBackAction: PropTypes.func.isRequired,
  handleNoteSave: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: "NoteForm",
  enableReinitialize: true,
  touchOnBlur: false,
  destroyOnUnmount: false,
})(SingleView)
