import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  AttributeCreatePayload,
  Attribute,
  AttributeModifyPayload,
  AttributeFull,
} from "./attributeTypes"

const attribute = {
  listAll(): Promise<AttributeFull[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/attributes", {
          offset,
          limit,
          order_by: "name",
          order_dir: "ASC",
          load_full_structure: 1,
          tag_ids: [],
          name_filter: "",
          source_ids: [],
          show_hidden: 1,
        }),
      key: "attributes",
    })
  },
  create(data: AttributeCreatePayload): Promise<{ attribute: AttributeFull }> {
    return requestFactory("post", "/attributes?load_full_structure=1", data)
  },
  modify(id: Attribute["id"], data: AttributeModifyPayload): Promise<{ attribute: AttributeFull }> {
    return requestFactory("patch", `/attributes/${id}?load_full_structure=1`, data)
  },
}

export default attribute
