import { api } from "api"

export default async function getDashboardData() {
  const customerCountsPromise = api.customersCount()

  const totalEventsPromise = api.customerEventsCount().then(r => r.customer_events_count)

  const cacheStatusPromise = await api.cacheStatus()

  return {
    customerCounts: await customerCountsPromise,
    totalEvents: await totalEventsPromise,
    cacheStatus: await cacheStatusPromise,
  }
}
