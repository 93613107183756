import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import IdentityStitchingDetail from "./IdentityStitchingDetail/IdentityStitchingDetail"
import IdentityStitchingList from "./IdentityStitchingList/IdentityStitchingList"

export default function IdentityStitchingSetup() {
  return (
    <Switch>
      <Route
        path={getRoutePath("administration.identity-stitching")}
        exact
        component={IdentityStitchingList}
      />
      <Route
        path={getRoutePath("administration.identity-stitching.create")}
        component={IdentityStitchingDetail}
      />
      <Route
        path={getRoutePath("administration.identity-stitching.detail")}
        component={IdentityStitchingDetail}
      />
    </Switch>
  )
}
