import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import { useCallback, useEffect, useState } from "react"
import TrashDataTable from "./components/TrashDataTable/TrashDataTable"
import { fetchTrashItems as fetchTrashItemsAPI, restoreTrashItem } from "./trashRequests"
import { TrashItem, TrashItemType, FetchTrashItemsResponse } from "./trashTypes"
import { showToast } from "actions/toast.action"
import { useDispatch } from "react-redux"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import getReadableItemType from "./utilities/getReadableItemType"
import Page from "components/UI/Page/Page"
import styles from "./Trash.module.scss"
import useDebounce from "hooks/useDebounce"
import SearchField from "components/UI/elements/SearchField"
import { getRoutePath } from "routes"
import { queryClient } from "app/queryClient"
import { MOBILE_PUSH_NOTIFICATION_ALL_QK } from "resources/mobilePushNotification/mobilePushNotificationQueries"
import { EMAIL_ALL_QK } from "resources/email/emailQueries"

type TrashItemsState = {
  data: TrashItem[]
  itemType: TrashItemType
  selectionSettings: FetchTrashItemsResponse["selection_settings"]
}

type RestorationModalState = {
  isOpen: boolean
  trashItem?: TrashItem
}

type TrashProps = {
  itemType: TrashItemType
}

const backLinks: Record<TrashItemType, Parameters<typeof getRoutePath>[0]> = {
  emails: "channels.emails",
  embedded_web_banners: "channels.native-banners",
  popup_web_banners: "channels.popup-banners",
  push_notifications: "channels.mobile-push",
  segment_export_destinations: "administration.destinations",
  custom_segments: "segments.custom",
  featured_segments: "segments.featured",
  smart_segments: "segments.smart",
  user_roles: "administration.roles",
  users: "administration.users",
}

const detailLinks: Record<TrashItemType, Parameters<typeof getRoutePath>[0]> = {
  emails: "channels.emails.detail",
  embedded_web_banners: "channels.native-banners.detail",
  popup_web_banners: "channels.popup-banners.detail",
  push_notifications: "channels.mobile-push.detail",
  segment_export_destinations: "administration.destinations.detail",
  custom_segments: "segments.custom.detail",
  featured_segments: "segments.featured.detail",
  smart_segments: "segments.smart.detail",
  user_roles: "administration.roles",
  users: "administration.users.detail",
}

export default function Trash({ itemType }: TrashProps) {
  const dispatch = useDispatch()

  const [trashItems, setTrashItems] = useState<TrashItemsState | null>(null)
  const [restorationModal, setRestorationModal] = useState<RestorationModalState>({
    isOpen: false,
  })
  const [isRestoringTrashItem, setIsRestoringTrashItem] = useState(false)

  const fetchTrashItems = useCallback(
    async (offset: number, item_type: TrashItemType, searched_text: string) => {
      try {
        if (offset === 0) {
          setTrashItems(null)
        }
        return await fetchTrashItemsAPI({ item_type, offset, searched_text })
      } catch (err) {
        dispatch(showToast(`Unknown entity type: ${item_type}`))
      }
    },
    [dispatch],
  )

  const filterTrash = useCallback(
    async (searchText: string) => {
      const res = await fetchTrashItems(0, itemType, searchText)
      if (res) {
        setTrashItems({
          selectionSettings: res.selection_settings,
          data: res.data,
          itemType,
        })
      }
    },
    [fetchTrashItems, itemType],
  )

  const loadMoreTrashItems = async () => {
    if (trashItems) {
      const {
        itemType,
        selectionSettings: { offset, limit, searched_text },
      } = trashItems
      const res = await fetchTrashItems(offset + limit, itemType, searched_text)
      if (res && trashItems?.itemType === itemType) {
        setTrashItems(prevState => ({
          data: [...(prevState?.data ?? []), ...res.data],
          selectionSettings: res.selection_settings,
          itemType,
        }))
      }
    }
  }

  const openRestorationModal = (trashItem: TrashItem) => {
    setRestorationModal({
      isOpen: true,
      trashItem,
    })
  }

  const closeRestorationModal = () => {
    setRestorationModal(prevState => ({ ...prevState, isOpen: false }))
  }

  const confirmRestorationModal = async () => {
    if (!restorationModal.trashItem || isRestoringTrashItem) {
      return
    }

    setIsRestoringTrashItem(true)
    const { id, type } = restorationModal.trashItem
    try {
      await restoreTrashItem(id, type)
      closeRestorationModal()
      const readableItemType = getReadableItemType(type)
      // Temporary fix. TODO: refactor restoring item into a react-query mutation, use setQueryData
      if (type === "push_notifications") {
        queryClient.refetchQueries({ queryKey: MOBILE_PUSH_NOTIFICATION_ALL_QK })
      } else if (type === "emails") queryClient.refetchQueries({ queryKey: EMAIL_ALL_QK })
      dispatch(
        showToast(
          `${readableItemType.charAt(0).toUpperCase() + readableItemType.slice(1)} restored.`,
          undefined,
          getRoutePath(detailLinks[type], { id }),
        ),
      )
      setIsRestoringTrashItem(false)
      // Refetch list
      filterTrash(searchText)
    } catch (err) {
      dispatch(showToast(`Unknown entity type: ${type}`))
      setIsRestoringTrashItem(false)
    }
  }

  const [searchText, setSearchText] = useState("")
  const debouncedSearchTerm = useDebounce(searchText)

  useEffect(() => {
    filterTrash(debouncedSearchTerm)
  }, [debouncedSearchTerm, filterTrash])

  return (
    <Page
      title={`Deleted ${getReadableItemType(itemType, { capitalize: false, plural: true })}`}
      headerContent={
        <SearchField
          input={{ value: searchText, onChange: setSearchText }}
          placeholder="Search for name"
          onClear={() => setSearchText("")}
          fullWidth={false}
          autoFocus
          wrapperClassName={styles.searchFieldWrapper}
        />
      }
      backRouteFallback={getRoutePath(backLinks[itemType])}
    >
      {!trashItems ? (
        <LoadingIndicator />
      ) : (
        <Paper>
          <TrashDataTable
            data={trashItems.data}
            hasMoreDataToLoad={
              trashItems.data.length ===
              trashItems.selectionSettings.offset + trashItems.selectionSettings.limit
            }
            itemType={trashItems.itemType}
            onRestoreClick={openRestorationModal}
            onLoadMore={loadMoreTrashItems}
          />
        </Paper>
      )}
      <ConfirmModal
        open={restorationModal.isOpen}
        type={MODAL.TYPE.SUCCESS}
        handleClose={closeRestorationModal}
        handleConfirm={confirmRestorationModal}
        title="Restore item"
        action="restore"
        what={
          restorationModal.trashItem?.type
            ? getReadableItemType(restorationModal.trashItem.type, { capitalize: false })
            : ""
        }
        item={restorationModal.trashItem?.name}
        isLoading={isRestoringTrashItem}
      />
    </Page>
  )
}
