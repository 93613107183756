import _toString from "lodash/toString"
import { api } from "api"

export const SEGMENT = {
  EXPORT: {
    ACTION: {
      LIST: "LIST_SEGMENT_EXPORTS",
      RETRIEVE: "SEGMENT_EXPORT_RETRIEVE",
      RUN: "RUN_SEGMENT_EXPORT",
    },
    ITEMS_PER_PAGE: 10,
  },
}

export const fetchSegmentExportsList =
  (segmentId, offset, limit, promise = true) =>
  async dispatch => {
    if (promise) {
      return dispatch({
        type: SEGMENT.EXPORT.ACTION.LIST,
        meta: {
          segmentId: _toString(segmentId),
        },
        payload: {
          promise: (async () => {
            return await api.segment.export.list(segmentId, offset, limit, "created", "DESC", 0)
          })(),
        },
      })
    } else {
      return dispatch({
        type: SEGMENT.EXPORT.ACTION.LIST,
        meta: {
          segmentId: _toString(segmentId),
        },
        payload: await api.segment.export.list(segmentId, offset, limit, "created", "DESC", 0),
      })
    }
  }

export const runSegmentExport =
  (segmentId, attributes, loadFullStructure = 0) =>
  async dispatch =>
    dispatch({
      type: SEGMENT.EXPORT.ACTION.RUN,
      meta: {
        segmentId: _toString(segmentId),
      },
      payload: {
        promise: (async () => {
          return await api.segment.export.run(segmentId, attributes, loadFullStructure)
        })(),
      },
    })
