import React, { ComponentPropsWithRef, forwardRef, useCallback, useRef } from "react"
import styles from "./FileField.module.scss"
import classnames from "classnames"
import Button from "../Button/Button"

const NO_FILE_CHOSEN = "No file chosen"

type FileFieldProps = {
  label?: string
  error?: string
  disabled?: boolean
  onClearClick?: () => void
} & Omit<ComponentPropsWithRef<"input">, "type">
const FileField = forwardRef(function FileField(
  { label, error, className, disabled, value, onClearClick, ...props }: FileFieldProps,
  ref: any,
) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const clearFileSelection = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ""
    onClearClick!()
  }, [onClearClick])

  return (
    <div className={classnames(styles.inputAndButtonWrapper, className)}>
      <div
        className={classnames(styles.fileField, {
          [styles.hasError]: error,
          [styles.disabled]: disabled,
        })}
      >
        <label className={styles.label}>
          {label && <div className={styles.labelText}>{label}</div>}
          <input
            type="file"
            className={styles.hiddenInput}
            ref={input => {
              inputRef.current = input
              if (typeof ref === "function") {
                ref(input)
              } else if (ref) {
                ref.current = input
              }
            }}
            {...props}
            disabled={disabled}
          />
          <div className={styles.box}>
            <div className={styles.fileName}>{value ?? NO_FILE_CHOSEN}</div>
            <Button color="grey" disabled={disabled} variant="outlined" className={styles.button}>
              Choose file
            </Button>
          </div>
          {error && <div className={styles.errorMessage}>{error}</div>}
        </label>
      </div>
      {onClearClick && (
        <Button
          color="red"
          disabled={!value}
          icon="trash-alt"
          variant="outlined"
          onClick={clearFileSelection}
          className={styles.clearImageButton}
        >
          Clear image
        </Button>
      )}
    </div>
  )
})

export default FileField
