import React, { useCallback, useRef, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SegmentPickerBar from "./SegmentPickerBar/SegmentPickerBar"
import { Segment } from "resources/segment/segment/segmentTypes"
import { SegmentOption } from "./types"
import useClickOutHandler from "hooks/useClickOutHandler"
import useDebounce from "hooks/useDebounce"
import useKeyListener from "hooks/useKeyListener"
import {
  useFetchFeaturedSegments,
  useFetchRegularSegments,
  useFetchSegmentById,
  useFetchSegmentsByIds,
  useFetchSmartSegments,
} from "resources/segment/segment/segmentQueries"

import styles from "./SegmentPicker.module.scss"
import { isNil, prop, sort, whereEq } from "ramda"
import { ascend } from "utilities/comparators"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import IconButton from "components/UI/elements/IconButton/IconButton"
import ErrorTippy from "components/UI/elements/ErrorTippy/ErrorTippy"

type ActivatedSegmentsIds = Array<Segment["id"]>
type SegmentType = SegmentOption["type"]

type Props = {
  value: SegmentOption["value"] | Array<SegmentOption["value"]> | null
  onChange: (newValue: SegmentOption["value"] | Array<SegmentOption["value"]>) => any
  className?: string
  errorMessage?: string
  hideLabel?: boolean
  disableActivated?: boolean
  activatedOptions?: ActivatedSegmentsIds
  multiple?: boolean
}

const isActivated = (segment: Segment, activatedSegmentsIds?: ActivatedSegmentsIds) =>
  activatedSegmentsIds?.includes(segment.id) ?? false

const getSegmentOptions = ({
  segments,
  segmentType,
  activatedSegmentsIds,
}: {
  segments: Array<Segment>
  segmentType: SegmentType
  activatedSegmentsIds?: ActivatedSegmentsIds
}) =>
  segments.map(segment => ({
    label: segment.name,
    value: segment.id,
    type: segmentType,
    isActivated: isActivated(segment, activatedSegmentsIds),
  }))

const SegmentPicker = ({
  className,
  errorMessage,
  onChange,
  value,
  hideLabel,
  disableActivated,
  activatedOptions,
  multiple = false,
}: Props) => {
  const [focused, setFocused] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onClose = useCallback(() => {
    setSearchTerm("")
    setFocused(false)
    inputRef.current?.blur()
  }, [])

  const { isOpen, open, close, ref, toggle } = useClickOutHandler({
    closeCallback: onClose,
    openCallback: () => setFocused(true),
    preventCloseIf: e =>
      [
        "SegmentPicker_input",
        "SegmentPicker_button",
        "SegmentPickerBar",
        "SegmentPicker_segments",
        "SegmentPicker_placeholder",
      ].some(className => (e.target as HTMLElement)?.className.includes?.(className)),
  })

  useKeyListener("Escape", close)

  const debouncedSearchTerm = useDebounce(searchTerm)

  const {
    data: regularSegmentResponse,
    fetchNextPage: fetchRegularNextPage,
    hasNextPage: hasNextPageRegular,
    isLoading: isLoadingRegular,
  } = useFetchRegularSegments({
    limit: 50,
    orderBy: "name",
    orderDir: "ASC",
    searchTerm: debouncedSearchTerm,
    selectedTags: [],
    showMy: true,
    showSharedWithMe: true,
    showForeign: true,
  })
  const {
    data: featuredSegmentResponse,
    fetchNextPage: fetchFeaturedNextPage,
    hasNextPage: hasNextPageFeatured,
    isLoading: isLoadingFeatured,
  } = useFetchFeaturedSegments({
    limit: 50,
    orderBy: "name",
    orderDir: "ASC",
    searchTerm: debouncedSearchTerm,
    selectedTags: [],
  })
  const {
    data: smartSegmentResponse,
    fetchNextPage: fetchSmartNextPage,
    hasNextPage: hasNextPageSmart,
    isLoading: isLoadingSmart,
  } = useFetchSmartSegments(searchTerm)

  const fetchNextPage = (category: SegmentType) => {
    switch (category) {
      case "custom":
        fetchRegularNextPage()
        break
      case "featured":
        fetchFeaturedNextPage()
        break
      case "smart":
        fetchSmartNextPage()
        break
    }
  }

  const hasNextPage = (category: SegmentType) => {
    switch (category) {
      case "custom":
        return hasNextPageRegular ?? false
      case "featured":
        return hasNextPageFeatured ?? false
      case "smart":
        return hasNextPageSmart ?? false
      default:
        return false
    }
  }

  const segmentOptions = [
    ...getSegmentOptions({
      activatedSegmentsIds: activatedOptions,
      segments: regularSegmentResponse,
      segmentType: "custom",
    }),
    ...getSegmentOptions({
      activatedSegmentsIds: activatedOptions,
      segments: featuredSegmentResponse,
      segmentType: "featured",
    }),
    ...getSegmentOptions({
      activatedSegmentsIds: activatedOptions,
      segments: smartSegmentResponse,
      segmentType: "smart",
    }),
  ]

  let selectedSegmentOption = segmentOptions.find(item => item.value === value) ?? null

  const { data: segment } = useFetchSegmentById(value as SegmentOption["value"], {
    enabled: !multiple && !!value,
    staleTime: Infinity,
  })

  if (!multiple && segment && selectedSegmentOption === null) {
    selectedSegmentOption = {
      label: segment.name,
      value: segment.id,
      type: segment.prebuilt ? "smart" : segment.featured ? "featured" : "custom",
      isActivated: isActivated(segment, activatedOptions),
    }
    segmentOptions.push(selectedSegmentOption)
  }

  const segmentResults = useFetchSegmentsByIds(Array.isArray(value) ? value : [], {
    staleTime: Infinity,
  })

  const selectedSegmentOptions: Array<SegmentOption> =
    Array.isArray(value) && segmentResults.length > 0
      ? segmentResults
          .filter(({ data }) => data && value.includes(data.segment.id))
          .map(({ data }) => ({
            label: data!.segment.name,
            value: data!.segment.id,
            type: data!.segment.prebuilt ? "smart" : data!.segment.featured ? "featured" : "custom",
            isActivated: isActivated(data!.segment, activatedOptions),
          }))
      : []

  if (multiple && !searchTerm && segmentResults.length > 0) {
    const missingOptions = segmentResults.filter(({ data }) => {
      if (!data) return false

      return !segmentOptions!.find(whereEq({ value: data.segment.id }))
    })

    missingOptions.forEach(({ data }) => {
      if (!data) return

      segmentOptions.push({
        label: data.segment.name,
        value: data.segment.id,
        type: data.segment.prebuilt ? "smart" : data.segment.featured ? "featured" : "custom",
        isActivated: isActivated(data.segment, activatedOptions),
      })
    })
  }

  const sortedSegmentOptions = sort(ascend(prop("label")), segmentOptions)

  return (
    <ErrorTippy disabled={!errorMessage} content={errorMessage}>
      <div className={className} data-testid="segment-picker">
        {!hideLabel && <label className={styles.label}>Segment</label>}
        <div className={styles.wrapper}>
          {!multiple && isLoading && (
            <LoadingIndicator className={styles.spinner} size="sm" fixedWidth />
          )}
          {!multiple && !isNil(value) && !isLoading && (
            <Link
              className={styles.idChip}
              to={getRoutePath("segments.custom.detail", { id: value })}
            >
              ID: {value}
            </Link>
          )}
          {!multiple && !isLoading && (
            <input
              ref={inputRef}
              autoComplete="off"
              className={classNames(styles.input, {
                [styles.error]: errorMessage,
                [styles.open]: focused || !selectedSegmentOption,
                [styles.hasValue]: !isNil(value),
              })}
              data-testid="segment-picker-input"
              placeholder={
                isLoading ? "" : selectedSegmentOption?.label ?? "Select segment or type to search"
              }
              type="text"
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              onFocus={open}
            />
          )}
          {multiple && (
            <div
              className={classNames(styles.segments, { [styles.focused]: focused })}
              onClick={e => {
                if (
                  ["SegmentPicker_segments", "SegmentPicker_placeholder"].some(className =>
                    (e.target as HTMLElement)?.className.includes(className),
                  )
                )
                  open()
              }}
            >
              {selectedSegmentOptions?.map(item => (
                <div key={item.value} className={styles.segment}>
                  <span>{item.label}</span>
                  <IconButton
                    color="white"
                    icon="times"
                    variant="transparent"
                    className={styles.closeButton}
                    onClick={async () => {
                      setIsLoading(true)
                      try {
                        await onChange(
                          selectedSegmentOptions
                            ?.filter(o => o.value !== item.value)
                            .map(o => o.value) ?? [],
                        )
                      } catch {
                      } finally {
                        setIsLoading(false)
                      }
                    }}
                  />
                </div>
              ))}
              {segmentResults.some(({ isLoading }) => isLoading) ? (
                <LoadingIndicator size="xs" fixedWidth />
              ) : (
                <input
                  ref={inputRef}
                  className={classNames(styles.placeholder, { [styles.hasValue]: searchTerm })}
                  placeholder="Select segment or type to search"
                  type="text"
                  value={searchTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(event.target.value)
                  }
                />
              )}
            </div>
          )}
          <button type="button" className={styles.button} onClick={toggle}>
            <FontAwesomeIcon
              icon={["fas", focused ? "caret-up" : "caret-down"]}
              className={styles.icon}
            />
          </button>
          {isOpen && (
            <SegmentPickerBar
              ref={ref}
              loading={isLoadingRegular || isLoadingFeatured || isLoadingSmart}
              searchMode={!!debouncedSearchTerm}
              options={sortedSegmentOptions}
              selectedOption={multiple ? null : selectedSegmentOption}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              onSelect={async newValue => {
                setIsLoading(true)
                close()
                try {
                  await onChange(
                    multiple
                      ? selectedSegmentOptions
                        ? [...selectedSegmentOptions, newValue].map(o => o.value)
                        : [newValue.value]
                      : newValue.value,
                  )
                } catch {
                } finally {
                  setIsLoading(false)
                }
              }}
              disableActivated={disableActivated}
            />
          )}
        </div>
      </div>
    </ErrorTippy>
  )
}

export default SegmentPicker
