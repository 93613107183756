import React, { ComponentPropsWithRef, forwardRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

import ErrorTippy from "../ErrorTippy/ErrorTippy"

import styles from "./TextInput.module.scss"

export type TextInputProps = {
  error?: string
  label?: string
  labelPosition?: "top" | "left"
} & ComponentPropsWithRef<"input">

const TextInput = forwardRef(function TextInput(
  { className, error, id, label, name, type, labelPosition = "top", ...props }: TextInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const inputId = id ?? name

  return (
    <div
      className={classNames(styles.root, className, { [styles.hasError]: !!error })}
      data-testid={`text-field-${name}`}
    >
      <div className={classNames(styles.wrapper, { [styles.row]: labelPosition === "left" })}>
        {label && <label htmlFor={inputId}>{label}</label>}
        {type === "password" ? (
          <ErrorTippy disabled={!error} content={error}>
            <div className={styles.password}>
              <input
                id={inputId}
                name={name}
                ref={ref}
                type={passwordVisible ? "text" : "password"}
                {...props}
              />
              <span
                data-testid="eye-button"
                onClick={() => setPasswordVisible(prev => !prev)}
                className={styles.icon}
              >
                <FontAwesomeIcon icon={["far", passwordVisible ? "eye-slash" : "eye"]} />
              </span>
            </div>
          </ErrorTippy>
        ) : (
          <ErrorTippy disabled={!error} content={error}>
            <input id={inputId} name={name} ref={ref} type={type ?? "text"} {...props} />
          </ErrorTippy>
        )}
      </div>
    </div>
  )
})

export default TextInput
