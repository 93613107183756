import React, { ComponentPropsWithRef, forwardRef } from "react"
import classNames from "classnames"

import ErrorTippy from "../ErrorTippy/ErrorTippy"

import styles from "./TextArea.module.scss"

export type TextAreaProps = {
  className?: string
  error?: string
  label?: string
  rows?: number
} & Omit<ComponentPropsWithRef<"textarea">, "id" | "type">

const TextArea = forwardRef(function TextArea(
  { className, error, label, name, rows, ...props }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.hasError]: !!error,
      })}
    >
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <ErrorTippy disabled={!error} content={error}>
        <textarea id={name} name={name} ref={ref} rows={rows} {...props} />
      </ErrorTippy>
    </div>
  )
})

export default TextArea
