import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import DestinationDetail from "./DestinationDetail/DestinationDetail"
import DestinationsList from "./DestinationsList/DestinationsList"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import Trash from "pages/Trash/Trash"

export default function DestinationsSetup() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("administration.destinations")}
        exact
        component={DestinationsList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation}
        path={getRoutePath("administartion.destinations.trash")}
        exact
        render={() => <Trash itemType="segment_export_destinations" />}
      />
      <Route
        path={getRoutePath("administration.destinations.detail")}
        component={DestinationDetail}
      />
    </Switch>
  )
}
