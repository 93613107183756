import ChatGPT from "components/ChatGPT/ChatGPT"
import Page from "components/UI/Page/Page"
import React from "react"
import { useHistory } from "react-router-dom"
import { useCreateMobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationQueries"
import { CreateMobilePushNotificationPayload } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { getRoutePath } from "routes"
import MobilePushNotificationForm from "../components/MobilePushNotificationForm/MobilePushNotificationForm"
import styles from "../components/MobilePushNotificationForm/MobilePushNotificationForm.module.scss"

export default function MobilePushNotificationCreate() {
  const history = useHistory()
  const mutation = useCreateMobilePushNotification()

  const create = (data: CreateMobilePushNotificationPayload) =>
    mutation.mutateAsync(
      { data },
      {
        onSuccess: ({ push_notification: { id } }) => {
          history.push(getRoutePath("channels.mobile-push.detail", { id }))
        },
      },
    )

  return (
    <Page
      title={"Create mobile push"}
      contentClassName={styles.container}
      backRouteFallback={getRoutePath("channels.mobile-push")}
      headerContent={<ChatGPT />}
    >
      <MobilePushNotificationForm onSubmit={create} />
    </Page>
  )
}
