import React, { lazy, Suspense } from "react"
import classNames from "classnames"
import styles from "./ActivityDatePicker.module.scss"
import Button from "components/UI/elements/Button/Button"
import useClickOutHandler from "hooks/useClickOutHandler"
import { DateString } from "types/util"
import { format, formatISO, subYears } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useEffect } from "react"
import { getISODateDaysAgo, getISODateToday, isLastDays } from "helpers/dateFormat.helper"
import { DATEFNS } from "sharedConstants"
import { toLocalDate } from "helpers/date.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
const DatePicker = lazy(() => import("react-datepicker"))

type ActivityDatePickerProps = {
  startDate: DateString
  endDate: DateString
  setStartDate: (date: DateString) => void
  setEndDate: (date: DateString) => void
}

export default function ActivityDatePicker({
  startDate: propStartDate,
  endDate: propEndDate,
  setStartDate: propSetStartDate,
  setEndDate: propSetEndDate,
}: ActivityDatePickerProps) {
  const { close, isOpen, ref, toggle } = useClickOutHandler({
    preventCloseIf: e =>
      [
        "react-datepicker__day--outside-month",
        "react-datepicker__year-option",
        "react-datepicker__month-option",
        "react-datepicker__navigation",
      ].some(className => (e.target as HTMLElement)?.className?.includes(className)),
  })

  const [startDate, setStartDate] = useState(propStartDate)
  const [endDate, setEndDate] = useState(propEndDate)

  useEffect(() => {
    setStartDate(propStartDate)
    setEndDate(propEndDate)
  }, [propEndDate, propStartDate, isOpen])

  const setLastDays = (number: number) => {
    propSetStartDate(getISODateDaysAgo(number - 1))
    propSetEndDate(getISODateToday())
    close()
  }

  const apply = () => {
    propSetStartDate(startDate)
    propSetEndDate(endDate)
    close()
  }

  const buttonText = isLastDays(propStartDate, propEndDate, 7)
    ? "Last 7 days"
    : isLastDays(propStartDate, propEndDate, 28)
    ? "Last 28 days"
    : isLastDays(propStartDate, propEndDate, 90)
    ? "Last 90 days"
    : `${format(toLocalDate(propStartDate), DATEFNS.DATE_FORMAT)}–${format(
        toLocalDate(propEndDate),
        DATEFNS.DATE_FORMAT,
      )}`

  return (
    <div className={styles.container}>
      <div className={styles.label}>Date range</div>
      <button
        color="white"
        onClick={toggle}
        className={classNames(styles.button, { [styles.isOpen]: isOpen })}
      >
        {buttonText}
        <FontAwesomeIcon
          icon={["fas", "caret-down"]}
          className={styles.icon}
          flip={isOpen ? "vertical" : undefined}
        />
      </button>
      {isOpen && (
        <div className={styles.dropdown} ref={ref}>
          <div className={styles.dropdownHeader}>
            <Button
              color={isLastDays(startDate, endDate, 7) ? "primary" : "grey"}
              size="xs"
              variant={isLastDays(startDate, endDate, 7) ? "solid" : "outlined"}
              onClick={() => setLastDays(7)}
            >
              Last 7 days
            </Button>
            <Button
              color={isLastDays(startDate, endDate, 28) ? "primary" : "grey"}
              size="xs"
              variant={isLastDays(startDate, endDate, 28) ? "solid" : "outlined"}
              onClick={() => setLastDays(28)}
            >
              Last 28 days
            </Button>
            <Button
              color={isLastDays(startDate, endDate, 90) ? "primary" : "grey"}
              size="xs"
              variant={isLastDays(startDate, endDate, 90) ? "solid" : "outlined"}
              onClick={() => setLastDays(90)}
            >
              Last 90 days
            </Button>
          </div>
          <div className={classNames("users-activity-datepicker", styles.calendarsWrapper)}>
            <Suspense fallback={<LoadingIndicator />}>
              <DatePicker
                selected={toLocalDate(startDate)}
                onChange={date => setStartDate(formatISO(date as Date, { representation: "date" }))}
                calendarClassName="calendar-dropdown"
                disabledKeyboardNavigation
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                minDate={subYears(new Date(), 10)}
                maxDate={toLocalDate(endDate)}
                inline
              />
              <DatePicker
                selected={toLocalDate(endDate)}
                onChange={date => setEndDate(formatISO(date as Date, { representation: "date" }))}
                calendarClassName="calendar-dropdown"
                disabledKeyboardNavigation
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                minDate={toLocalDate(startDate)}
                maxDate={new Date()}
                inline
              />
            </Suspense>
          </div>
          <div className={styles.dropdownFooter}>
            <Button color="grey" variant="outlined" onClick={close}>
              cancel
            </Button>
            <Button onClick={apply}>apply</Button>
          </div>
        </div>
      )}
    </div>
  )
}
