import React from "react"
import PropTypes from "prop-types"
import _round from "lodash/round"
import _isNumber from "lodash/isNumber"
import _isNil from "lodash/isNil"

import { abbreviateNumber } from "helpers/number.helper"

import "./SegmentationNumbers.scss"
import Tippy from "@tippyjs/react"

const SegmentationNumbers = props => {
  let percentage = props.isLoading ? "" : "N/A"

  if (
    !props.isLoading &&
    _isNumber(props.totalNumber) &&
    _isNumber(props.segmentedNumber) &&
    props.totalNumber > 0 &&
    props.segmentedNumber !== -1
  ) {
    percentage = _round(props.segmentedNumber / (props.totalNumber / 100), 0)
    if (percentage === 0 && props.segmentedNumber > 0) {
      percentage = "<1"
    }
    if (percentage === 100 && props.segmentedNumber < props.totalNumber) {
      percentage = ">99"
    }
  }

  const isSegmentedLoading =
    props.isLoading && !(_isNumber(props.segmentedNumber) && props.segmentedNumber !== -1)
  const isTotalLoading = props.isLoading && !_isNumber(props.totalNumber)

  const isPercentageLoading = isSegmentedLoading || isTotalLoading
  const isError = props.error || Boolean(props.errorMessage)

  return (
    <div
      className={`segmentation-numbers-wrapper ${props.className ? props.className : ""} ${
        isError ? "error" : ""
      }`}
    >
      {props.showLabel && <div className="segmentation-label">Segmented:</div>}
      <div className="box">
        {!isError && (
          <React.Fragment>
            <span className={`segmented ${isSegmentedLoading ? "loading" : ""}`}>
              {_isNumber(props.segmentedNumber) && props.segmentedNumber !== -1 ? (
                <span>{Number(props.segmentedNumber).toLocaleString()}</span>
              ) : (
                <React.Fragment>
                  <Tippy
                    content="To see the number, please, save conditions."
                    disabled={_isNil(props.uniqueDataTipId)}
                  >
                    <span>N/A</span>
                  </Tippy>
                </React.Fragment>
              )}
            </span>
            {props.showTotal && (
              <React.Fragment>
                <span className="out-of"> out&nbsp;of </span>
                <span className={`segmentation-total ${isTotalLoading ? "loading" : ""}`}>
                  {!isTotalLoading ? abbreviateNumber(props.totalNumber) : <span>N/A</span>}
                </span>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {isError && (
          <span className="error-message">
            {props.errorMessage ? props.errorMessage : "Error loading numbers"}
          </span>
        )}
      </div>
      {percentage !== "" && !isError && (
        <div className={`percents ${isPercentageLoading ? "loading" : ""}`}>({percentage}%)</div>
      )}
    </div>
  )
}

SegmentationNumbers.defaultProps = {
  showLabel: true,
  showTotal: true,
  error: false,
}

SegmentationNumbers.propTypes = {
  totalNumber: PropTypes.number,
  segmentedNumber: PropTypes.number,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  uniqueDataTipId: PropTypes.string,
  showLabel: PropTypes.bool,
  showTotal: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default SegmentationNumbers
