import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Map } from "immutable"
import moment from "moment"
import CustomDatePicker from "components/UI/components/CustomDatePicker"
import Whisperer from "components/UI/components/Whisperer/Whisperer"
import { update } from "ramda"
import styles from "./ValueContainer.module.scss"
import classNames from "classnames"
import SelectField from "components/UI/elements/SelectField"
import {
  OPERATION_CONTEXT_MAPPER,
  OPERATION_CONTEXT_TYPE,
  OPERATION_HAS_VALUE,
  OPERATION_HAS_WHISPERER,
} from "resources/segment/segment/utilities/segmentOperationsConstants"
import { MOMENT } from "sharedConstants"

const getValue = e => (e.target.type === "number" ? parseFloat(e.target.value) : e.target.value)

const valuesToOptions = values =>
  Array.isArray(values) ? values.map(value => ({ value, label: value })) : []

const optionsToValues = options => options.map(({ value }) => value)

class ValueContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      datePickersOpen: Map(),
      suggestions: [],
      hasFetchedSuggestions: false,
    }
  }

  fetchSuggestions() {
    const {
      condition: { operation },
      stringSelectOptionsFetch,
    } = this.props

    // Only multi-value string inputs; single-value whisperer fetches its suggestions itself
    if (
      OPERATION_HAS_VALUE[operation] === -1 &&
      OPERATION_CONTEXT_MAPPER[operation] === OPERATION_CONTEXT_TYPE.STRING
    ) {
      if (!this.state.hasFetchedSuggestions && stringSelectOptionsFetch) {
        stringSelectOptionsFetch().then(suggestions =>
          this.setState({ suggestions, hasFetchedSuggestions: true }),
        )
      }
    } else {
      this.setState({ hasFetchedSuggestions: false })
    }
  }

  componentDidMount() {
    this.fetchSuggestions()
  }

  componentDidUpdate() {
    this.fetchSuggestions()
  }

  _toggleDatePicker =
    (valueIndex = 0) =>
    () => {
      this.setState(prevState => ({
        datePickersOpen: prevState.datePickersOpen.setIn(
          [valueIndex],
          !prevState.datePickersOpen.getIn([valueIndex]),
        ),
      }))
    }

  formatDate = date =>
    moment.isMoment(date)
      ? date
          .utc()
          .format(
            this.props.dataType === "datetime" ? MOMENT.DB_DATETIME_FORMAT : MOMENT.DB_DATE_FORMAT,
          )
      : date.value ?? date ?? null

  render() {
    const { isEditable, onChange, condition, stringSelectOptionsFetch, errors, dataType } =
      this.props
    const { datePickersOpen, suggestions } = this.state
    const { value, operation } = condition
    const numOfInputs = OPERATION_HAS_VALUE[operation]

    if (!numOfInputs) {
      return null
    }

    let inputs

    const toNumbersIfNeeded = values =>
      OPERATION_CONTEXT_MAPPER[operation] === OPERATION_CONTEXT_TYPE.NUMBER
        ? values.map(value => parseFloat(value)).filter(value => !isNaN(value))
        : values

    if (numOfInputs === -1) {
      // Multi-value input
      inputs = [
        <div className={styles.multiInputWrapper}>
          <SelectField
            meta={{ touched: true, error: errors?.[0] }}
            placeholder="Search…"
            className={classNames(styles.multiInput, { [styles.readOnly]: !isEditable })}
            input={{
              value: valuesToOptions(value),
              onChange: options => onChange(toNumbersIfNeeded(optionsToValues(options))),
            }}
            isCreatable
            isMulti
            allowCopy
            options={suggestions}
            disabled={!isEditable}
            hideErrorMessage
          />
        </div>,
      ]
    }

    if (OPERATION_CONTEXT_MAPPER[operation] === OPERATION_CONTEXT_TYPE.DATE) {
      if (numOfInputs === 1) {
        let fieldValue = value ?? ""
        if (value && moment(value).isValid()) {
          fieldValue = moment.utc(value).local()
        }
        inputs = [
          <div className="text-field">
            <CustomDatePicker
              value={fieldValue}
              open={datePickersOpen.getIn([0])}
              isEditable={isEditable}
              onChange={obj => {
                onChange(this.formatDate(obj))
              }}
              toggle={this._toggleDatePicker()}
              className={classNames(styles.datePicker, { [styles.error]: errors?.[0] })}
              dataType={dataType}
            />
          </div>,
        ]
      } else if (numOfInputs === 2) {
        const values = Array.isArray(value) ? value : ["", ""]
        const momentValues = values.map(val =>
          moment(val).isValid() ? moment.utc(val).local() : val,
        )
        inputs = [
          <div className="text-field">
            <CustomDatePicker
              value={momentValues[0]}
              open={datePickersOpen.getIn([0])}
              isEditable={isEditable}
              onChange={obj => {
                onChange(update(0, this.formatDate(obj), values))
              }}
              toggle={this._toggleDatePicker(0)}
              className={classNames(styles.datePicker, { [styles.error]: errors?.[0] })}
              dataType={dataType}
              placeholder="Pick minimum date"
            />
          </div>,
          <div className="text-field">
            <CustomDatePicker
              value={momentValues[1]}
              open={datePickersOpen.getIn([1])}
              isEditable={isEditable}
              onChange={obj => {
                onChange(update(1, this.formatDate(obj), values))
              }}
              toggle={this._toggleDatePicker(1)}
              className={classNames(styles.datePicker, { [styles.error]: errors?.[1] })}
              dataType={dataType}
              placeholder="Pick maximum date"
            />
          </div>,
        ]
      }
    } else if (OPERATION_HAS_WHISPERER[operation]) {
      inputs = [
        <div className="string-select-wrapper">
          <Whisperer
            placeholder="Search..."
            fetchOptions={stringSelectOptionsFetch}
            value={value ?? ""}
            onChange={(_, { newValue }) => onChange(newValue)}
            disabled={!isEditable}
            className={classNames(styles.whisperer, { [styles.error]: errors?.[0] })}
            size="small"
          />
        </div>,
      ]
    } else {
      const inputType =
        OPERATION_CONTEXT_MAPPER[operation] === OPERATION_CONTEXT_TYPE.NUMBER ? "number" : "text"

      if (numOfInputs === 1) {
        inputs = [
          <div className="text-field">
            <input
              className={classNames("condition-input", { [styles.error]: errors?.[0] })}
              value={value ?? ""}
              onChange={e => onChange(getValue(e))}
              disabled={!isEditable}
              type={inputType}
              autoComplete="off"
              placeholder="Enter value"
            />
          </div>,
        ]
      } else if (numOfInputs === 2) {
        let values = Array.isArray(value) ? value : ["", ""]
        inputs = [
          <div className="text-field">
            <input
              className={classNames("condition-input", "between", { [styles.error]: errors?.[0] })}
              value={values[0]}
              onChange={e => onChange(update(0, getValue(e), values))}
              disabled={!isEditable}
              type={inputType}
              autoComplete="off"
              placeholder="Enter minimum value"
            />
          </div>,
          <div className="text-field">
            <input
              className={classNames("condition-input", "between", { [styles.error]: errors?.[1] })}
              value={values[1]}
              onChange={e => onChange(update(1, getValue(e), values))}
              disabled={!isEditable}
              type={inputType}
              autoComplete="off"
              placeholder="Enter maximum value"
            />
          </div>,
        ]
      }
    }

    return (
      <div className={classNames(styles.container, { [styles.readOnly]: !isEditable })}>
        {inputs.map((input, index) => (
          <div className={styles.inputContainer} key={index}>
            {input}
          </div>
        ))}
      </div>
    )
  }
}

ValueContainer.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  condition: PropTypes.object.isRequired,
  stringSelectOptionsFetch: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
}

export default ValueContainer
