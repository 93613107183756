import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { format } from "date-fns"
import React, { useCallback, useState } from "react"
import { DATEFNS } from "sharedConstants"
import { Notification } from "resources/notification/notificationTypes"
import contentHtml from "./contentHtml"
import styles from "./Notification.module.scss"
import { getCurrentUserId, useAuthTokenStore } from "auth/auth"

type NotificationProps = {
  notification: Notification
  isDropdown?: boolean
  onLinkClick?: () => void
}

const categoryIconMap: { [category in Notification["details"]["category"]]: IconProp } = {
  ATTRIBUTE: ["fas", "tachometer"],
  EVENT: ["fas", "tachometer"],
  INSIGHT: ["fas", "tachometer"],
  SEGMENT: ["fas", "filter"],
}

export default function NotificationCard({
  notification: { importance, timestamp, read, details },
  isDropdown,
  onLinkClick,
}: NotificationProps) {
  const currentUserId = useAuthTokenStore(getCurrentUserId)
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = useCallback(() => setIsExpanded(s => !s), [])

  const { mainHtml, detailsHtml } = contentHtml(details, currentUserId!, isDropdown, onLinkClick)
  const formattedTimestamp = format(
    new Date(timestamp),
    isDropdown ? DATEFNS.TIME_FORMAT : DATEFNS.DATETIME_FORMAT,
  )

  return (
    <div
      className={classNames(styles.container, styles[`${importance.toLowerCase()}Importance`], {
        [styles.isDropdown]: isDropdown,
        [styles.read]: read,
        [styles.hasDetails]: detailsHtml,
      })}
    >
      <div className={styles.main}>
        <div className={styles.unreadNotificationBadge} />
        <div className={styles.categoryIcon}>
          <FontAwesomeIcon icon={categoryIconMap[details.category]} />
        </div>
        <div className={styles.body}>{mainHtml}</div>
        <div className={styles.timestamp}>{formattedTimestamp}</div>
        <div className={styles.expandButtonWrapper}>
          <button className={styles.expandButton} onClick={toggleIsExpanded}>
            <FontAwesomeIcon
              icon={["fas", "chevron-down"]}
              flip={isExpanded ? "vertical" : undefined}
            />
          </button>
        </div>
      </div>
      {detailsHtml && isExpanded && <div className={styles.expansion}>{detailsHtml}</div>}
    </div>
  )
}
