import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import promiseMiddleware from "redux-promise-middleware"

import rootReducer from "reducers"
import { composeWithDevTools } from "redux-devtools-extension"
import { ActionType, PROMISE_TYPE_DELIMITER } from "types/promiseActionTypes"
import { TypedUseSelectorHook, useSelector as originalUseSelector } from "react-redux"

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(
      promiseMiddleware({
        promiseTypeDelimiter: PROMISE_TYPE_DELIMITER,
        promiseTypeSuffixes: [ActionType.Pending, ActionType.Fulfilled, ActionType.Rejected],
      }),
      thunk,
    ),
  ),
)

export default store

export type State = ReturnType<typeof store.getState>
export const useSelector: TypedUseSelectorHook<State> = originalUseSelector
