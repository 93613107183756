import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import SearchFormWithSelect, {
  SearchFormWithSelectDestroyable,
} from "components/UI/components/SearchFormWithSelect"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"

class AttributesFilterForm extends PureComponent {
  render() {
    const { sourceOptions, destroyOnUnmount = true, formName = "SearchAttributeForm" } = this.props
    if (destroyOnUnmount) {
      return (
        <SearchFormWithSelectDestroyable
          form={formName}
          inputPlaceholder="Search for attributes"
          selectOptions={sourceOptions}
          selectPlaceholder="All sources"
        />
      )
    } else {
      return (
        <SearchFormWithSelect
          form={formName}
          inputPlaceholder="Search for attributes"
          selectOptions={sourceOptions}
          selectPlaceholder="All sources"
        />
      )
    }
  }
}

AttributesFilterForm.propTypes = {
  destroyOnUnmount: PropTypes.bool,
  formName: PropTypes.string,
  showHiddenSources: PropTypes.bool,
}

export default props => {
  const { data = [] } = useFetchDataSourceOptions({ showHidden: props.showHiddenSources })

  return <AttributesFilterForm {...props} sourceOptions={data} />
}
