import React from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Paper from "components/UI/elements/Paper"
import DestinationAttributes from "./components/DestinationAttributes/DestinationAttributes"
import Table, { Thead, Th, Tbody, Td, Tr, RowMessage } from "components/UI/elements/Table"
import { MOMENT } from "sharedConstants"
import "./Destinations.scss"
import Tippy from "@tippyjs/react"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

export default function Destinations() {
  const { data: destinations = [], isSuccess: areDestinationsFulfilled } = useFetchAllDestinations()

  return (
    <Page className="sources-destinations" title="Destinations">
      {!areDestinationsFulfilled && <LoadingIndicator />}

      {areDestinationsFulfilled && (
        <Paper noPaddingTop>
          {destinations.length === 0 && <RowMessage>No connected destination found.</RowMessage>}

          {destinations.length > 0 && (
            <Table className="destinations-table">
              <Thead stickyHeader="destinations-table-sticky-header">
                <Th />
                <Th className="service-name">Destination</Th>
                <Th className="description-col">description</Th>
                <Th className="exported-attributes-col">
                  exported attributes{" "}
                  <Tippy
                    content="Attributes that will be exported to your chosen destination. This is a
                        mandatory field."
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={["fas", "info-circle"]}
                        data-tip
                        data-for="exported-attributes-tooltip"
                      />
                    </span>
                  </Tippy>
                </Th>
                <Th className="mandatory-attributes-col">
                  mandatory attributes{" "}
                  <Tippy
                    content="Attributes that must be present in order to export to the destination. This
                        is an optional field."
                  >
                    <span>
                      <FontAwesomeIcon icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>
                </Th>
                <Th textAlignRight className="date-added">
                  Last&nbsp;export
                </Th>
              </Thead>
              <Tbody>
                {destinations.map(destination => {
                  return (
                    <Tr key={destination.id}>
                      <Td className="icon">
                        <SrcDstIcon destination={destination} />
                      </Td>
                      <Td textBigger textBlack textBold className="name">
                        {destination.name}
                      </Td>
                      <Td className="description">
                        {destination.description ? `${destination.description}` : "—"}
                      </Td>
                      <Td className="attribute-container">
                        <DestinationAttributes
                          destination={destination}
                          showAttributes="exported"
                        />
                      </Td>
                      <Td className="attribute-container">
                        <DestinationAttributes
                          destination={destination}
                          showAttributes="mandatory"
                        />
                      </Td>
                      <Td textAlignRight className="created">
                        {destination.last_export
                          ? moment.utc(destination.last_export).local().format(MOMENT.DATE_FORMAT)
                          : "never"}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </Paper>
      )}
    </Page>
  )
}
