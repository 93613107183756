import React, { lazy, PureComponent, Suspense } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List } from "immutable"
import _isPlainObject from "lodash/isPlainObject"
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import _has from "lodash/has"
import { formValueSelector } from "redux-form"
import moment from "moment"
import _isNumber from "lodash/isNumber"

// actions
import { showToast } from "actions/toast.action"

// ui components
import InsightsFilterForm from "components/UI/components/InsightsFilterForm"
import Paper from "components/UI/elements/Paper"
import ExpandedInsight from "components/UI/components/ExpandedInsight/ExpandedInsight"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"

// constants, helpers
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

import "./Insights.scss"
import { SocketContext } from "context/socket"
import { TOAST } from "sharedConstants"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"
import { reorderStickyInsights } from "helpers/insight.helper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { useFetchCurrentUser, useHasAccess } from "resources/user/currentUserQueries"
import Page from "components/UI/Page/Page"
import { RowMessage } from "components/UI/elements/Table"
const InsightTile = lazy(() => import("components/UI/elements/InsightTile"))

class Insights extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      attributeAggregations: null,
      aggregationValues: null,
      customers: {
        loading: true,
        count: null,
      },
      expandedInsight: {
        data: null,
        open: false,
      },
    }
    this.pendingPromises = new PendingPromise()
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    this.context.off("segment_aggregations_response")
  }

  componentDidMount() {
    const { showToast } = this.props

    // wss
    this.context.on("segment_aggregations_response", msg => {
      if (_has(msg, "error")) {
        showToast(msg.error, TOAST.TYPE.ERROR)
      } else {
        if (msg.segment_id === null) {
          // so it's data insight
          if (this.state.aggregationValues) {
            this.setState(prevState => ({
              aggregationValues: {
                ...prevState.aggregationValues,
                [msg.segment_aggregation.attribute_aggregation_id.toString()]:
                  msg.segment_aggregation,
              },
            }))
          } else {
            this.setState({
              aggregationValues: {
                [msg.segment_aggregation.attribute_aggregation_id]: msg.segment_aggregation,
              },
            })
          }
        }
      }
    })

    const attributesAggregationsRequest = this.pendingPromises.create(
      this.fetchAllAttributesAggregations(),
    )
    attributesAggregationsRequest.promise
      .then(response => {
        this.setState({
          attributeAggregations: response,
        })
        this.pendingPromises.remove(attributesAggregationsRequest)
      })
      .catch(error => {
        this.pendingPromises.remove(attributesAggregationsRequest)
      })

    this.fetchAllAggregationsValues()

    const customersCountRequest = this.pendingPromises.create(api.customersCount())

    customersCountRequest.promise
      .then(response => {
        this.setState({
          customers: {
            loading: false,
            count: response.customer_entities_count,
          },
        })
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState(prevState => ({
            customers: {
              ...prevState.customers,
              loading: false,
            },
          }))
        }
        this.pendingPromises.remove(customersCountRequest)
      })
  }

  fetchAllAggregationsValues = () => {
    this.context.emit("segment_aggregations", { segment_id: null })
  }

  fetchAllAttributesAggregations = async () => {
    const caller = new AllResourceItemsFetcher()
    const data = await caller
      .setEndpointCall((offset, limit, loadFullStructure) =>
        api.attributesAggregations.list(offset, limit, "order_index", "ASC", loadFullStructure),
      )
      .setDataPath("attribute_aggregations")
      .setLoadFullStructure(0)
      .run()
    if (data.length === 0) {
      this.setState({
        aggregationValues: [],
      })
    }
    return List(data)
  }

  toggleInsightModal = ({ attribute, globalAudience, insight, subAttribute }) => {
    this.setState({
      expandedInsight: {
        open: true,
        data: {
          attribute,
          globalAudience,
          insight,
          subAttribute,
        },
      },
    })
  }

  closeExpandedInsight = () => {
    const { expandedInsight } = this.state

    if (expandedInsight.open)
      this.setState({
        expandedInsight: {
          ...expandedInsight,
          open: false,
        },
      })
  }

  render() {
    const {
      areAttributesFulfilled,
      attributesMapById,
      searchTerm,
      filteredSource,
      stickyInsightIds,
      hasAccess,
    } = this.props
    const { attributeAggregations, aggregationValues, customers, expandedInsight } = this.state

    let resultAttributeAggregations = attributeAggregations
    if (searchTerm) {
      resultAttributeAggregations = resultAttributeAggregations.filter(item => {
        return item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      })
    }

    if (filteredSource) {
      resultAttributeAggregations = resultAttributeAggregations.filter(item => {
        return attributesMapById[item.attribute_id]?.source.id === filteredSource.value
      })
    }

    resultAttributeAggregations = reorderStickyInsights(
      resultAttributeAggregations,
      stickyInsightIds,
    )

    const dataSize = List.isList(attributeAggregations) ? attributeAggregations.size : 0
    return (
      <Page
        title="Insights"
        className="insights"
        headerContent={
          <>
            {attributeAggregations && areAttributesFulfilled && <InsightsFilterForm />}
            <div className="layout-customers-count-wrapper">
              {_isNumber(customers.count) && (
                <SegmentationNumbers
                  totalNumber={customers.count}
                  segmentedNumber={customers.count}
                />
              )}
            </div>
          </>
        }
      >
        {!areAttributesFulfilled && <LoadingIndicator />}
        {areAttributesFulfilled && dataSize > 0 && (
          <div className="tiles-section">
            {resultAttributeAggregations.map(item => {
              const aggregationValueObj = aggregationValues?.[item.id.toString()]
              let aggregationValue = null
              let percentage = null
              if (aggregationValueObj) {
                if (_isPlainObject(aggregationValueObj.result)) {
                  if (_has(aggregationValueObj, "result.value")) {
                    aggregationValue = aggregationValueObj.result.value
                  } else if (_has(aggregationValueObj, "result.count")) {
                    aggregationValue = aggregationValueObj.result.count
                  }
                  percentage = aggregationValueObj.result.segment_percentage
                } else {
                  aggregationValue = aggregationValueObj.result
                }
              }
              if (_isArray(aggregationValue) && aggregationValue.length) {
                if (_isPlainObject(aggregationValue[0])) {
                  const tmp = [...aggregationValue]
                  percentage = []
                  aggregationValue = []
                  tmp.forEach(obj => {
                    if (_has(obj, "value")) {
                      aggregationValue.push(obj.value)
                    } else if (_has(obj, "count")) {
                      aggregationValue.push(obj.count)
                    }
                    percentage.push(obj.segment_percentage)
                  })
                }
              }
              const attribute = attributesMapById[item.attribute_id]
              const subAttribute =
                attribute && item.sub_attribute_id
                  ? getCompoundAttributeSubAttribute(item.sub_attribute_id, attribute.data_type)
                  : null
              const compareValue =
                item.settings?.value_from && item.settings?.value_to
                  ? [item.settings.value_from, item.settings.value_to]
                  : item.settings?.value
              return (
                <Suspense key={item.id} fallback={<LoadingIndicator />}>
                  <InsightTile
                    id={item.id}
                    name={item.name}
                    value={aggregationValue}
                    compareValue={compareValue}
                    attribute={attribute}
                    subAttribute={subAttribute}
                    percentage={percentage}
                    funcType={item.function}
                    outOf={customers.count}
                    className="insights-tile"
                    color={_get(item, "frontend_settings.color")}
                    attributePercentage={aggregationValueObj?.customers_with_attribute_percentage}
                    showNewBadge={moment().diff(item.created, "days") < 8}
                    displayType={_get(item, "frontend_settings.tile_type", "chart")}
                    onExpandClick={() =>
                      this.toggleInsightModal({
                        subAttribute,
                        attribute: attributesMapById[item.attribute_id],
                        insight: item,
                        globalAudience: {
                          isKnownPercentage:
                            aggregationValueObj?.customers_with_attribute_percentage,
                          result: _get(aggregationValueObj, "result"),
                          total: customers.count,
                        },
                      })
                    }
                    isLoading={!aggregationValueObj}
                    isSticky={stickyInsightIds.includes(item.id)}
                  />
                </Suspense>
              )
            })}
          </div>
        )}
        {areAttributesFulfilled && List.isList(attributeAggregations) && dataSize === 0 && (
          <Paper className="insights-no-content">
            {hasAccess.setup.insights && (
              <RowMessage>
                Insights not set. Go to Administration / Insights tab to create insights.
              </RowMessage>
            )}
            {!hasAccess.setup.insights && (
              <RowMessage>Insights not set. Contact admin to set insights.</RowMessage>
            )}
          </Paper>
        )}
        {dataSize > 0 && resultAttributeAggregations.size === 0 && (
          <Paper className="insights-no-content">
            <RowMessage>Nothing found.</RowMessage>
          </Paper>
        )}

        {expandedInsight.data && expandedInsight.open && (
          <ExpandedInsight
            {...expandedInsight.data}
            favorite={stickyInsightIds.includes(expandedInsight.data.insight.id)}
            open={expandedInsight.open}
            onClose={this.closeExpandedInsight}
          />
        )}
      </Page>
    )
  }
}

Insights.propTypes = {
  areAttributesFulfilled: PropTypes.bool.isRequired,
  attributesMapById: PropTypes.object.isRequired,
  showToast: PropTypes.func.isRequired,
}

const selector = formValueSelector("InsigtsFilter")
const mapStateToProps = state => ({
  searchTerm: selector(state, "search"),
  filteredSource: selector(state, "select"),
})

Insights = connect(mapStateToProps, {
  showToast,
})(Insights)

export default props => {
  const { data: attributesMap = {}, isSuccess } = useFetchAttributesMap()
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()

  return (
    <Insights
      {...props}
      attributesMapById={attributesMap}
      areAttributesFulfilled={isSuccess}
      stickyInsightIds={currentUser.frontend_settings?.stickyInsightsIds ?? []}
      hasAccess={hasAccess}
    />
  )
}
