import {
  fulltextCustomerAttributeSearch,
  fulltextCustomerSearch,
} from "actions/customerSearch.action"
import { showToast } from "actions/toast.action"
import Button from "components/UI/elements/Button/Button"
import { propEq } from "ramda"
import React, { PureComponent } from "react"
import { connect, useSelector } from "react-redux"
import {
  useFetchCurrentUser,
  useHasAccess,
  useToggleFavoriteCustomer,
} from "resources/user/currentUserQueries"
import {
  getCustomerSearchesSelectedAttributeId,
  getCustomerSearchesSelectionSettings,
  hasCustomerSearchesMoreResults,
} from "selectors/customerSearch.selector"
import PropTypes from "prop-types"
import styles from "./HeaderActions.module.scss"
import { SocketContext } from "context/socket"
import { withRouter } from "react-router-dom"
import { List } from "immutable"
import { getRoutePath } from "routes"
import { useFetchSegmentCustomers } from "resources/segment/segment/segmentQueries"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import { getCustomerSearchesData } from "selectors/customerSearch.selector"

class HeaderActions extends PureComponent {
  static contextType = SocketContext

  constructor(props) {
    super(props)
    this.state = {
      usedCustomersNavigation: false,
      navigatingForward: false,
      navigatingBackward: false,
      forwardButtonDisabled: false,
      isTogglingFavorite: false,
    }
  }

  componentDidMount() {
    const {
      customerIds,
      hasCustomerSearchMoreResults,
      hasMoreSegmentCustomers,
      loadMoreSegmentCustomers,
      profileIteratorIndex,
      segmentId,
    } = this.props
    if (profileIteratorIndex !== null) {
      // chech if visited customer is not last in mem, if yes, fetch more when possible
      if (!segmentId) {
        // customer search pagination
        if ([customerIds.length - 1, customerIds.length - 2].includes(profileIteratorIndex)) {
          if (hasCustomerSearchMoreResults) this.loadMoreSearchCustomers()
          else if (profileIteratorIndex === customerIds.length - 1)
            this.setState({ forwardButtonDisabled: true })
        }
      } else {
        // segment customers preview
        if ([customerIds.length - 1, customerIds.length - 2].includes(profileIteratorIndex)) {
          if (hasMoreSegmentCustomers) loadMoreSegmentCustomers()
          else if (profileIteratorIndex === customerIds.length - 1)
            this.setState({ forwardButtonDisabled: true })
        }
      }
    }
  }

  componentWillUnmount() {
    this.context.off("segment_customer_entities_response")
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerDataReady &&
      (this.state.navigatingForward || this.state.navigatingBackward)
    ) {
      this.setState({
        navigatingForward: false,
        navigatingBackward: false,
      })
    }

    const {
      customerIds,
      profileIteratorIndex,
      segmentId,
      loadMoreSegmentCustomers,
      hasCustomerSearchMoreResults,
      hasMoreSegmentCustomers,
    } = this.props
    if (profileIteratorIndex !== null && profileIteratorIndex !== prevProps.profileIteratorIndex) {
      if (segmentId) {
        if (profileIteratorIndex === customerIds.length - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        } else if (profileIteratorIndex === customerIds.length - 2 && hasMoreSegmentCustomers) {
          loadMoreSegmentCustomers()
        }
      } else {
        if (profileIteratorIndex === customerIds.length - 1 && !this.state.forwardButtonDisabled) {
          this.setState({ forwardButtonDisabled: true })
        } else if (
          profileIteratorIndex === customerIds.length - 2 &&
          hasCustomerSearchMoreResults
        ) {
          this.loadMoreSearchCustomers()
        }
      }
    }
  }

  toggleFavoriteCustomer = async () => {
    this.setState({ isTogglingFavorite: true })
    try {
      const { toggleFavoriteCustomer, customerId } = this.props
      await toggleFavoriteCustomer(customerId)
    } catch {
      // noop
    }
    this.setState({ isTogglingFavorite: false })
  }

  loadMoreSearchCustomers = () => {
    const {
      customerSearchSelectionSettings: selectionSettings,
      customerSearchAttributeId: selectedAttributeId,
      fulltextCustomerSearch,
      fulltextCustomerAttributeSearch,
    } = this.props
    if (selectedAttributeId) {
      fulltextCustomerAttributeSearch(
        selectedAttributeId,
        selectionSettings.search_value,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        1,
      )
        .then(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
        .catch(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
    } else {
      fulltextCustomerSearch(
        selectionSettings.search_text,
        selectionSettings.offset + selectionSettings.limit,
        selectionSettings.limit,
        1,
      )
        .then(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
        .catch(() => {
          this.setState({
            forwardButtonDisabled: false,
          })
        })
    }
  }

  previousCustomer = () => {
    const { customerIds, profileIteratorIndex, history, setIndex } = this.props
    if (!this.state.navigatingBackward) {
      this.setState({
        navigatingBackward: true,
        usedCustomersNavigation: true,
        forwardButtonDisabled: false,
      })
      if (customerIds.length > 0) {
        const newIndex = profileIteratorIndex - 1
        if (newIndex >= 0) {
          const customerId = customerIds[newIndex]
          if (customerId) {
            setIndex(newIndex)
            history.replace({
              pathname: getRoutePath("profiles.detail", { id: customerId }),
              state: history.location.state,
            })
          }
        }
      }
    }
  }

  nextCustomer = () => {
    const { customerIds, profileIteratorIndex, history, setIndex } = this.props
    if (!this.state.navigatingForward) {
      this.setState({
        navigatingForward: true,
        usedCustomersNavigation: true,
      })
      if (customerIds.length > 0) {
        const newIndex = profileIteratorIndex + 1
        const customerId = customerIds[newIndex]
        if (customerId) {
          setIndex(newIndex)
          history.replace({
            pathname: getRoutePath("profiles.detail", { id: customerId }),
            state: history.location.state,
          })
        }
      }
    }
  }

  render() {
    const { isCustomerFavorite, profileIteratorIndex } = this.props
    const { navigatingForward, navigatingBackward, forwardButtonDisabled, isTogglingFavorite } =
      this.state

    const showAsFavorite =
      (isCustomerFavorite && !isTogglingFavorite) || (!isCustomerFavorite && isTogglingFavorite)
    return (
      <div className={styles.headerActions}>
        {profileIteratorIndex !== null && (
          <Button
            color="grey"
            icon="chevron-left"
            iconStyle="far"
            loading={navigatingBackward}
            variant="outlined"
            onClick={this.previousCustomer}
            disabled={profileIteratorIndex === 0}
          >
            Previous profile
          </Button>
        )}
        <Button
          color={showAsFavorite ? "primary" : "grey"}
          icon="star"
          iconStyle={showAsFavorite ? "fas" : "far"}
          variant={showAsFavorite ? "solid" : "outlined"}
          onClick={this.toggleFavoriteCustomer}
        >
          Favorite
        </Button>
        {profileIteratorIndex !== null && (
          <Button
            color="grey"
            icon="chevron-right"
            iconPosition="end"
            iconStyle="far"
            loading={navigatingForward}
            variant="outlined"
            onClick={this.nextCustomer}
            disabled={forwardButtonDisabled}
          >
            Next profile
          </Button>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  hasCustomerSearchMoreResults: hasCustomerSearchesMoreResults(state),
  customerSearchSelectionSettings: getCustomerSearchesSelectionSettings(state),
  customerSearchAttributeId: getCustomerSearchesSelectedAttributeId(state),
})
HeaderActions = withRouter(
  connect(mapStateToProps, {
    fulltextCustomerSearch,
    fulltextCustomerAttributeSearch,
    showToast,
  })(HeaderActions),
)

HeaderActions.propTypes = {
  customerId: PropTypes.string.isRequired,
  customerDataReady: PropTypes.bool.isRequired,
}

export default props => {
  const { data: currentUser } = useFetchCurrentUser()
  const hasAccess = useHasAccess()
  const toggleFavoriteCustomer = useToggleFavoriteCustomer()

  const { index, segmentId, setIndex } = useProfileIteratorStore()
  const customerSearches = useSelector(state => getCustomerSearchesData(state))

  const {
    data: segmentCustomers,
    hasNextPage: hasMoreSegmentCustomers,
    fetchNextPage: loadMoreSegmentCustomers,
  } = useFetchSegmentCustomers(segmentId, { enabled: !!segmentId })

  let customerIds = []
  if (segmentId) customerIds = segmentCustomers.map(customer => customer.id)
  else {
    if (List.isList(customerSearches))
      customerIds = customerSearches.map(customer => customer.customer_entity.id).toJS()
  }

  const isCustomerFavorite = currentUser.cdp_settings?.favourite_customers?.some(
    propEq(props.customerId, "customer_entity_id"),
  )

  return (
    <HeaderActions
      {...props}
      hasAccess={hasAccess}
      toggleFavoriteCustomer={toggleFavoriteCustomer}
      isCustomerFavorite={isCustomerFavorite}
      hasMoreSegmentCustomers={hasMoreSegmentCustomers}
      loadMoreSegmentCustomers={loadMoreSegmentCustomers}
      customerIds={customerIds}
      profileIteratorIndex={index}
      segmentId={segmentId}
      setIndex={setIndex}
    />
  )
}
