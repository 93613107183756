import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import _toString from "lodash/toString"
import _mapKeys from "lodash/mapKeys"
import _map from "lodash/map"
import _isNumber from "lodash/isNumber"
import _filter from "lodash/filter"
import _includes from "lodash/includes"
import moment from "moment"

// selectors
import { getSegmentExports } from "resources/segment/segmentExport/segmentExportSelectors"

// models
import ExportModel from "resources/segment/segmentExport/segmentExportModel"

// actions
import {
  fetchSegmentExportsList,
  SEGMENT,
  runSegmentExport,
} from "resources/segment/segmentExport/segmentExportActions"
import { showToast } from "actions/toast.action"

// ui elements
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"

import Scheduler from "./Scheduler/Scheduler"
import ParametersController from "./ParametersController"
import PromotionBar from "./PromotionBar/PromotionBar"

// constants, helpers
import { MOMENT, TOAST } from "sharedConstants"
import { api } from "api"
import Username from "components/Username/Username"
import PendingPromise from "helpers/pendingPromise.helper"

// routes
import { getRoutePath } from "routes"

import "./SegmentExports.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Tippy from "@tippyjs/react"
import { SocketContext } from "context/socket"
import { BASE_API_URL } from "api/request"
import { useAllowedDestinationsIds, useHasAccess } from "resources/user/currentUserQueries"
import classNames from "classnames"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"
import {
  useFetchSegmentNumbers,
  useSegmentCountsStore,
} from "resources/segment/segment/segmentCounts"
import { equals, omit } from "ramda"
import { useModifySegment } from "resources/segment/segment/segmentQueries"

const REFRESH_INTERVAL = 5000

class SegmentExports extends PureComponent {
  static contextType = SocketContext

  refreshInterval = null

  constructor(props) {
    super(props)
    this.state = {
      buttonLoading: Map(),
      running: Map(),
      loadMoreButtonLoading: false,
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    if (this.props.hasAccess.segments.export) {
      this.fetchExportsInitialRows()
      this.refreshInterval = setInterval(
        () => this.fetchExportsInitialRows(false),
        REFRESH_INTERVAL,
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = null
    this.pendingPromises.cancelAll()
  }

  fetchExportsInitialRows = (promise = true) => {
    const { fetchSegmentExportsList, segment, isSegmentDeleting } = this.props

    if (isSegmentDeleting) {
      return
    }

    const segmentExportsListRequest = this.pendingPromises.create(
      fetchSegmentExportsList(segment.id, 0, SEGMENT.EXPORT.ITEMS_PER_PAGE, promise),
    )
    segmentExportsListRequest.promise
      .then(response => {
        const segmentExports = response.value
          ? response.value.segment_exports
          : response.payload.segment_exports
        const running = Map(
          _mapKeys(
            _map(
              _filter(segmentExports, segmentExport =>
                _includes(["waiting", "running"], segmentExport.status),
              ),
              segmentExport => new ExportModel(segmentExport),
            ),
            "segment_export_destination_id",
          ),
        )

        this.setState({
          running,
        })
        this.pendingPromises.remove(segmentExportsListRequest)
      })
      .catch(() => {
        this.pendingPromises.remove(segmentExportsListRequest)
      })
  }

  _cancelExportApiCall = async exportId => {
    const { segment } = this.props
    return await api.segment.export.cancel(segment.id, exportId, 0)
  }

  cancelExportByDestinationId = destinationId => () => {
    const exportJob = this.state.running.get(_toString(destinationId))
    this._cancelExportApiCall(exportJob.id)
      .then(() => {
        this.props.showToast("Export will be canceled.", TOAST.TYPE.SUCCESS)
      })
      .catch(() => {
        this.fetchExportsInitialRows()
      })
  }

  runSegmentExport = destinationId => async () => {
    const { segment, runSegmentExport, destinations, refetchSegmentNumbers } = this.props

    this.setState(prevState => ({
      buttonLoading: prevState.buttonLoading.set(destinationId, true),
    }))
    await runSegmentExport(segment.id, {
      name: destinations.find(({ id }) => id === destinationId)?.name + " export",
      segment_export_destination_id: destinationId,
    })
      .then(response => {
        const { segment_export } = response.value
        this.setState(prevState => ({
          buttonLoading: prevState.buttonLoading.set(destinationId, false),
          running: prevState.running.set(
            _toString(segment_export.segment_export_destination_id),
            new ExportModel(segment_export),
          ),
        }))

        // Refresh numbers
        refetchSegmentNumbers({ refreshCache: true })

        this.props.showToast("Export initiated.", TOAST.TYPE.SUCCESS)
      })
      .catch(() => {
        this.fetchExportsInitialRows()
        this.setState(prevState => ({
          buttonLoading: prevState.buttonLoading.set(destinationId, false),
        }))
      })
  }

  loadMoreSegmentExports = () => {
    this.setState({
      loadMoreButtonLoading: true,
    })
    const { segment, segmentExports, fetchSegmentExportsList } = this.props
    fetchSegmentExportsList(
      segment.id,
      segmentExports.getIn(["selectionSettings", "offset"]) + SEGMENT.EXPORT.ITEMS_PER_PAGE,
      SEGMENT.EXPORT.ITEMS_PER_PAGE,
    )
      .then(() => {
        this.setState({
          loadMoreButtonLoading: false,
        })
      })
      .catch(() => {
        this.setState({
          loadMoreButtonLoading: false,
        })
      })
  }

  modifySegmentScheduling = (schedules, exportDestinationId) => {
    const { segment, modifySegment } = this.props

    let scheduling = segment?.settings?.scheduling
    if (scheduling) {
      if (schedules.length === 0) {
        // erase scheduling
        let resultScheduling = scheduling.map(setSchedule => {
          const destinations = setSchedule.destination_ids
          if (destinations.includes(exportDestinationId)) {
            if (destinations.length === 1) {
              // erase schedule
              return null
            } else {
              // erase destination only
              return {
                ...setSchedule,
                destination_ids: setSchedule.destination_ids.filter(
                  id => id !== exportDestinationId,
                ),
              }
            }
          }
          return setSchedule
        })
        resultScheduling = resultScheduling.filter(val => val !== null)
        const settings =
          resultScheduling.length === 0
            ? omit(["scheduling"], segment.settings)
            : { ...segment.settings, scheduling: resultScheduling }

        return modifySegment({
          id: segment.id,
          data: { settings },
        })
      } else {
        // 3 options, schedule settings is shared / schedule settings is single / schedule settings is new
        let resultScheduling = scheduling.map(setSchedule => {
          if (setSchedule?.destination_ids?.includes(exportDestinationId)) {
            // omit and create new entry
            if (setSchedule?.destination_ids?.length === 1) {
              return null
            } else {
              return {
                ...setSchedule,
                destination_ids: setSchedule.destination_ids.filter(
                  id => id !== exportDestinationId,
                ),
              }
            }
          }
          return setSchedule
        })
        resultScheduling = resultScheduling.filter(val => val !== null)
        let added = false
        resultScheduling = resultScheduling.map(setSchedule => {
          if (equals(setSchedule.schedules, schedules)) {
            // schedules equals, push to array of dest ids
            added = true
            return {
              ...setSchedule,
              destination_ids: [...setSchedule.destination_ids, exportDestinationId],
            }
          }
          return setSchedule
        })
        if (!added) {
          resultScheduling = resultScheduling.concat([
            {
              schedules,
              destination_ids: List([exportDestinationId]),
            },
          ])
        }
        const settings = { ...segment.settings, scheduling: resultScheduling }

        return modifySegment({
          id: segment.id,
          data: { settings },
        })
      }
    } else {
      // initial scheduling set
      if (schedules.length > 0) {
        let settings = null
        if (segment?.settings) {
          settings = {
            ...segment.settings,
            scheduling: [{ destination_ids: [exportDestinationId], schedules }],
          }
        } else {
          settings = {
            scheduling: [{ destination_ids: [exportDestinationId], schedules }],
          }
        }

        return modifySegment({ id: segment.id, data: { settings } })
      }
    }
  }

  modifySegmentDestinationParameters = dstObj => {
    const { segment, modifySegment } = this.props
    let dataToSend = {
      settings: {
        destination_parameters: dstObj,
      },
    }
    if (segment?.settings) {
      const settings = segment.settings
      if (settings.destination_parameters) {
        // merge with destination_parameters
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: {
              ...settings.destination_parameters,
              ...dstObj,
            },
          },
        }
      } else {
        // merge destination_parameters with existing settings
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: dstObj,
          },
        }
      }
    }

    return modifySegment({ id: segment.id, data: dataToSend })
  }

  render() {
    const {
      destinations,
      isEditable,
      segment,
      exportDestinationResultsCount,
      segmentNumbersError,
      segmentExports,
      isSmartSegment,
      isFeaturedSegment,
      allowedDestinationIds,
    } = this.props
    const { running, buttonLoading, loadMoreButtonLoading } = this.state

    const hasExportLogs =
      List.isList(segmentExports.get("data")) && segmentExports.get("data").size > 0

    return (
      <div className="segment-export">
        {destinations && destinations.length > 0 && (
          <div>
            <PaperHeader size="small" titleText="export destinations" />
            <Paper className="segment-export-settings" hasHeader={true}>
              <table className="table segment-export-table">
                <thead>
                  <tr>
                    <th>Destination</th>
                    <th>
                      Exportable{" "}
                      <InfoTooltip placement="right" interactive>
                        Segmented customers available for each export destinations.{" "}
                        <a
                          href="https://docs.meiro.io/books/meiro-business-explorer/page/export-out-of-available-for-the-destination--what-does-it-mean"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Learn more
                        </a>
                        .
                      </InfoTooltip>
                    </th>
                    <th>Schedule</th>
                    <th>Parameters</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {destinations.map(destination => {
                    const destinationStatus = running.getIn([_toString(destination.id), "status"])
                    let segmentNumberDestinationIndex = -1
                    segmentNumberDestinationIndex = exportDestinationResultsCount.findIndex(
                      ({ exportDestinationId }) => exportDestinationId === destination.id,
                    )

                    let schedules = null
                    const scheduling = segment?.settings?.scheduling
                    if (scheduling) {
                      scheduling.forEach(setSchedule => {
                        if (setSchedule.destination_ids.includes(+destination.id)) {
                          schedules = setSchedule.schedules
                        }
                      })
                    }

                    let countOfRequiredParameters = 0
                    let countOfFilledParameters = 0
                    const destinationParameters =
                      segment?.settings?.destination_parameters?.[_toString(destination.id)] ?? {}
                    const miWorkspaceVariables = destination.settings?.mi_workspace_variables ?? []

                    miWorkspaceVariables.forEach(param => {
                      if (param.required) {
                        countOfRequiredParameters += 1
                        if (destinationParameters[param.name]) {
                          countOfFilledParameters += 1
                        }
                      }
                    })

                    const schedulesExist = Array.isArray(schedules) && schedules.length > 0
                    const isExportAllowed =
                      countOfFilledParameters >= countOfRequiredParameters &&
                      allowedDestinationIds.includes(destination.id)
                    const canSheduleBeOpened = (isExportAllowed && isEditable) || schedulesExist
                    const schedulerPermission = !canSheduleBeOpened
                      ? "disabled"
                      : allowedDestinationIds.includes(destination.id) && isEditable
                      ? "edit"
                      : "view"

                    return (
                      <tr key={destination.id} className="segment-export-settings-row">
                        <td className="destination">
                          <div className="destination-chip-wrapper">
                            <div
                              className={`destination-chip ${destination.frontend_settings?.color}`}
                            >
                              <SrcDstIcon
                                destination={destination}
                                white
                                className="destination-icon"
                              />
                            </div>
                            <div className="destination-info">{destination.name}</div>
                          </div>
                        </td>
                        <td className="segmentation-numbers-col">
                          <SegmentationNumbers
                            totalNumber={
                              segmentNumberDestinationIndex === -1
                                ? undefined
                                : exportDestinationResultsCount[segmentNumberDestinationIndex]
                                    .totalCount
                            }
                            segmentedNumber={
                              segmentNumberDestinationIndex === -1
                                ? undefined
                                : exportDestinationResultsCount[segmentNumberDestinationIndex].count
                            }
                            isLoading={
                              !_isNumber(
                                segmentNumberDestinationIndex === -1
                                  ? undefined
                                  : exportDestinationResultsCount[segmentNumberDestinationIndex]
                                      .totalCount,
                              ) ||
                              !_isNumber(
                                segmentNumberDestinationIndex === -1
                                  ? undefined
                                  : exportDestinationResultsCount[segmentNumberDestinationIndex]
                                      .count,
                              )
                            }
                            uniqueDataTipId={`tooltip-destination-${destination.id}`}
                            showLabel={false}
                            error={segmentNumbersError}
                          />
                        </td>
                        <td>
                          {destination.id === 2 ? (
                            "—"
                          ) : (
                            <Scheduler
                              displayToast
                              id={destination.id}
                              exportDestinationId={destination.id}
                              repetitiveSchedules={schedules ?? []}
                              onSaveRepetitiveSchedules={this.modifySegmentScheduling}
                              permission={schedulerPermission}
                              tooltip={
                                !isEditable
                                  ? "You need edit permission for this segment to add export schedules."
                                  : !allowedDestinationIds.includes(destination.id)
                                  ? "You don't have permission to schedule exports for this destination."
                                  : "You need to fill all the required parameters to be able to schedule exports."
                              }
                              tooltipDisabled={canSheduleBeOpened}
                              type="repetitive"
                            />
                          )}
                        </td>
                        <td>
                          {
                            <ParametersController
                              exportDestinationId={destination.id}
                              initialValues={destinationParameters}
                              onSaveParameters={this.modifySegmentDestinationParameters}
                              fieldsConfig={miWorkspaceVariables}
                              countOfRequiredParameters={countOfRequiredParameters}
                              countOfFilledParameters={countOfFilledParameters}
                              showToast={this.props.showToast}
                              form={`DestinationParametersForm[${destination.id}]`}
                              isEditable={isEditable}
                            />
                          }
                        </td>
                        <td>
                          <div className="export-actions">
                            <Tippy
                              content={
                                !allowedDestinationIds.includes(destination.id)
                                  ? "You don't have permission to run export for this destination."
                                  : "You need to fill all the required parameters to be able to run export."
                              }
                              disabled={isExportAllowed}
                            >
                              <div>
                                <Button
                                  disabled={!isExportAllowed}
                                  icon={destinationStatus ? "hourglass" : undefined}
                                  iconStyle="far"
                                  loading={buttonLoading.get(destination.id)}
                                  spinIcon={destinationStatus === "waiting"}
                                  onClick={
                                    destinationStatus
                                      ? this.cancelExportByDestinationId(destination.id)
                                      : this.runSegmentExport(destination.id)
                                  }
                                  className={classNames({
                                    running: destinationStatus === "running",
                                    waiting: destinationStatus === "waiting",
                                  })}
                                >
                                  {destinationStatus ? "Cancel" : "Export"}
                                </Button>
                              </div>
                            </Tippy>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Paper>
            <PromotionBar />

            <PaperHeader
              className="segment-export-history-header"
              titleText="exports history"
              size="small"
            />
            <Paper className="segment-export-history" hasHeader={true}>
              {!hasExportLogs && (
                <div className="no-exports-message">There are no exports yet.</div>
              )}
              {hasExportLogs && (
                <>
                  <Table className="segment-export-history-table">
                    <Thead>
                      <Th className="history-th">Export history</Th>
                      <Th className="history-th">User</Th>
                      <Th className="history-th">Destination</Th>
                      <Th className="history-th">Exported</Th>
                      <Th textAlignRight className="history-th">
                        Status
                      </Th>
                      <Th textAlignCenter className="one-icon history-th">
                        Log
                      </Th>
                      <Th textAlignCenter className="one-icon history-th">
                        CSV
                      </Th>
                    </Thead>
                    <Tbody>
                      {segmentExports.get("data").map(segmentExport => (
                        <Tr key={segmentExport.id} className="history-row">
                          <Td className="history-cell" textBlack textBigger textBold>
                            {segmentExport.name}
                          </Td>
                          <Td className="history-cell">
                            <Username userId={segmentExport.user_id} />
                          </Td>
                          <Td className="history-cell">
                            {
                              destinations.find(
                                ({ id }) => id === segmentExport.segment_export_destination_id,
                              )?.name
                            }
                          </Td>
                          <Td className="history-cell">
                            {moment
                              .utc(segmentExport.created)
                              .local()
                              .format(MOMENT.DATETIME_FORMAT)}
                          </Td>
                          <Td
                            textAlignRight
                            className={`status history-cell ${segmentExport.status}`}
                          >
                            {segmentExport.status}
                          </Td>
                          <Td textAlignCenter className="history-cell">
                            <Link
                              to={{
                                pathname: getRoutePath(
                                  isSmartSegment
                                    ? "segments.smart.export.detail"
                                    : isFeaturedSegment
                                    ? "segments.featured.export.detail"
                                    : "segments.custom.export.detail",
                                  {
                                    id: segment.id,
                                    eid: segmentExport.id,
                                  },
                                ),
                                state: { goBack: true },
                              }}
                            >
                              <IconButton
                                icon="file-alt"
                                iconStyle="far"
                                size="xs"
                                tooltip="View log"
                                variant="outlined"
                                className="log-icon"
                              />
                            </Link>
                          </Td>
                          <Td textAlignCenter className="history-cell">
                            {segmentExport.segment_export_destination_id === 2 &&
                              segmentExport.status === "finished" && (
                                // download csv file, id = 2 is guaranteed by instalator
                                <a
                                  href={`${BASE_API_URL}/segments/${segment.id}/exports/${segmentExport.id}/download?file_download_token=${segmentExport.file_download_token}`}
                                  download
                                >
                                  <IconButton
                                    icon="download"
                                    iconStyle="far"
                                    size="xs"
                                    tooltip="Download export"
                                    variant="outlined"
                                    className="download-icon"
                                  />
                                </a>
                              )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  {segmentExports.get("hasMoreItems") && (
                    <div className="load-more-button-wrapper">
                      <Button
                        color="grey"
                        loading={loadMoreButtonLoading}
                        variant="outlined"
                        onClick={this.loadMoreSegmentExports}
                      >
                        Show more
                      </Button>
                    </div>
                  )}
                </>
              )}
              {this.state.isLoading && <LoadingIndicator />}
            </Paper>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  segmentExports: getSegmentExports(state, ownProps.segment.id),
})

SegmentExports.propTypes = {
  segment: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  segmentExports: PropTypes.instanceOf(Map).isRequired,
  runSegmentExport: PropTypes.func.isRequired,
  isSegmentDeleting: PropTypes.bool.isRequired,
  isSmartSegment: PropTypes.bool,
  isFeaturedSegment: PropTypes.bool,
}

SegmentExports = connect(mapStateToProps, {
  showToast,
  fetchSegmentExportsList,
  runSegmentExport,
})(SegmentExports)

export default props => {
  const { id } = useParams()

  const hasAccess = useHasAccess()
  const allowedDestinationIds = useAllowedDestinationsIds()
  const { data: destinations = [], isSuccess: areDestinationsFulfilled } = useFetchAllDestinations()

  const { mutateAsync: modifySegment } = useModifySegment()
  const { refetch: refetchSegmentNumbers } = useFetchSegmentNumbers(+id)
  const { exportDestinationResultsCount, error: segmentNumbersError } = useSegmentCountsStore()

  return (
    <SegmentExports
      {...props}
      hasAccess={hasAccess}
      allowedDestinationIds={allowedDestinationIds}
      destinations={destinations}
      areDestinationsFulfilled={areDestinationsFulfilled}
      exportDestinationResultsCount={exportDestinationResultsCount}
      segmentNumbersError={segmentNumbersError}
      refetchSegmentNumbers={refetchSegmentNumbers}
      modifySegment={modifySegment}
    />
  )
}
