import Trash from "pages/Trash/Trash"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import UserDetail from "./UserDetail/UserDetail"
import UsersList from "./UsersList/UsersList"
import { useHasAccess } from "resources/user/currentUserQueries"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"

export default function Users() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route path={getRoutePath("administration.users")} exact component={UsersList} />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users}
        path={getRoutePath("administration.users.trash")}
        exact
        render={() => <Trash itemType="users" />}
      />
      <Route path={getRoutePath("administration.users.detail")} component={UserDetail} />
    </Switch>
  )
}
