import React from "react"
import classNames from "classnames"
import { format } from "date-fns"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import SegmentChip from "components/SegmentChip/SegmentChip"
import Username from "components/Username/Username"
import { DATEFNS, SEGMENT } from "sharedConstants"
import { dbDateTimeToISO } from "helpers/date.helper"
import { MobilePushNotification } from "resources/mobilePushNotification/mobilePushNotificationTypes"
import { useFetchMobilePushExports } from "resources/mobilePushNotification/export/mobilePushNotificationExportQueries"
import { useHasSegmentPermission } from "resources/user/currentUserQueries"

import styles from "./ActivationHistory.module.scss"

export default function ActivationHistory({
  notificationId,
}: {
  notificationId: MobilePushNotification["id"]
}) {
  const hasSegmentPermission = useHasSegmentPermission()

  const {
    data: activations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingActivations,
    isSuccess: isSuccessActivations,
  } = useFetchMobilePushExports(notificationId, {
    refetchInterval: SEGMENT.EXPORT.REFRESH_INTERVAL,
  })

  return (
    <div>
      <PaperHeader size="small" titleText="Activation history" />
      <Paper hasHeader className={styles.body}>
        {isLoadingActivations && <LoadingIndicator />}
        {isSuccessActivations &&
          (activations.length === 0 ? (
            <div className={styles.emptyMessage}>There are no activations yet.</div>
          ) : (
            <div className={styles.historyTable}>
              <div className={styles.header}>
                <div>Segment name</div>
                <div>User</div>
                <div>Activated</div>
                <div>Status</div>
              </div>
              {activations.map(({ user_id, created, status, id, segments }) => (
                <React.Fragment key={id}>
                  <div className={styles.row}>
                    <div className={styles.segments}>
                      {segments.map(({ deleted, id, name, type }) => {
                        const permission = hasSegmentPermission(id, {
                          segmentType: type,
                        })

                        const hasAccess =
                          typeof permission === "boolean"
                            ? permission
                            : ["read", "write"].includes(permission)

                        return (
                          <SegmentChip
                            key={id}
                            deleted={deleted}
                            hasAccess={hasAccess}
                            segmentId={id}
                            segmentName={name}
                            segmentType={type}
                            variant="filled"
                          />
                        )
                      })}
                    </div>
                    <div>
                      <Username userId={user_id} />
                    </div>
                    <div className={styles.timestamp}>
                      {format(new Date(dbDateTimeToISO(created)), DATEFNS.DATETIME_FORMAT)}
                    </div>
                    <div className={classNames(styles.status, styles[`status__${status}`])}>
                      {status}
                    </div>
                  </div>
                  <hr className={styles.line} />
                </React.Fragment>
              ))}
            </div>
          ))}
        {hasNextPage && (
          <div className={styles.loadMore}>
            <Button
              color="grey"
              variant="outlined"
              className={classNames({ loading: isFetchingNextPage })}
              onClick={() => fetchNextPage()}
            >
              Show more
            </Button>
          </div>
        )}
      </Paper>
    </div>
  )
}
