import React from "react"
import { useParams } from "react-router-dom"
import { whereEq } from "ramda"
import Waypoint from "react-waypoint"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import CustomerEvent from "../CustomerEvent/CustomerEvent"
import { ActiveEvents, ActiveSources } from "../types"
import useDebounce from "hooks/useDebounce"
import { useFetchCustomerEvents } from "resources/customer/event/customerEventQueries"
import { Source } from "resources/dataSource/dataSourceTypes"
import { useFetchEventSourceIds, useFetchEventsMap } from "resources/event/eventQueries"
import { ITEMS_PER_PAGE } from "sharedConstants"

import styles from "./TimelineEvents.module.scss"

type TimelineEventsProps = {
  activeEvents: ActiveEvents
  activeSources: ActiveSources
  sources: Array<Source>
  searchTerm?: string
}

export default function TimelineEvents({
  activeEvents,
  activeSources,
  searchTerm,
  sources,
}: TimelineEventsProps) {
  const { id: profileId } = useParams<{ id: string }>()

  const { data: timelineAvailableSourceIds = [] } = useFetchEventSourceIds()
  const { data: eventsMap = {} } = useFetchEventsMap({
    includeHidden: true,
  })

  const debouncedSearchTerm = useDebounce(searchTerm)

  const timelineSources = activeSources.filter(({ id }) => timelineAvailableSourceIds.includes(id))
  const filteredSources = timelineSources.filter(whereEq({ active: true }))
  const areAllSourcesActive = timelineSources.every(whereEq({ active: true }))
  const areAllSourcesDeactivated = timelineSources.every(whereEq({ active: false }))
  const sourceIds = areAllSourcesActive ? [] : filteredSources.map(({ id }) => id)

  const timelineEvents = activeEvents.filter(({ source_id }) =>
    timelineAvailableSourceIds.includes(source_id),
  )
  const filteredEvents = timelineEvents.filter(whereEq({ active: true }))
  const areAllEventsActive = timelineEvents.every(whereEq({ active: true }))
  const areAllEventsDeactivated = timelineEvents.every(whereEq({ active: false }))
  const eventIds = areAllEventsActive ? [] : filteredEvents.map(({ id }) => id)

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isSuccess } =
    useFetchCustomerEvents<0>({
      customer_entity_id: profileId,
      limit: ITEMS_PER_PAGE,
      load_full_structure: 0,
      order_by: "event_time",
      order_dir: "DESC",
      search_text: debouncedSearchTerm?.trim(),
      event_ids: eventIds,
      source_ids: sourceIds,
    })

  const renderWaypoint = () => {
    if (!isFetchingNextPage && hasNextPage) {
      return <Waypoint onEnter={() => fetchNextPage()} bottomOffset={-300} />
    } else if (isFetchingNextPage && hasNextPage) {
      return <LoadingIndicator />
    }
  }

  const noResultsFound = (
    <Paper className={styles.noResults}>
      <p>No results found, consider to modify filter settings.</p>
    </Paper>
  )

  if (areAllEventsDeactivated && areAllSourcesDeactivated) return noResultsFound

  if (isLoading) return <LoadingIndicator />

  if (isSuccess && data.length === 0) return noResultsFound

  return (
    <>
      {data.map(customerEvent => {
        const source = sources.find(({ id }) => id === customerEvent.source_id)
        if (!source) return null

        return (
          <CustomerEvent
            key={customerEvent.id}
            hasTimeline={false}
            dataSource={source}
            customerEvent={customerEvent}
            event={eventsMap[customerEvent.event_id]}
          />
        )
      })}
      {renderWaypoint()}
    </>
  )
}
