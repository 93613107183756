import React from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import EventForm from "../components/EventForm/EventForm"
import styles from "./EventDetail.module.scss"
import Tippy from "@tippyjs/react"
import { useHistory, useParams } from "react-router-dom"
import { useFetchEventById, useModifyEvent } from "resources/event/eventQueries"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Flag } from "types/util"
import { EventPayload } from "resources/event/eventTypes"

export default function EventDetail() {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: event, isSuccess: isEventFulfilled } = useFetchEventById(id, {
    refetchOnMount: "always",
  })
  const modifyMutation = useModifyEvent()
  const toggleMutation = useModifyEvent()
  const modifyEvent = (data: EventPayload) =>
    modifyMutation.mutate(
      { id, data },
      {
        onSuccess() {
          history.push(getRoutePath("administration.events"))
        },
      },
    )
  const toggleEventIsHidden = () =>
    event && toggleMutation.mutate({ id: event.id, data: { is_hidden: +!event.is_hidden as Flag } })
  const cloneEvent = () =>
    history.push({
      pathname: getRoutePath("administration.events.create"),
      state: { event },
    })

  return (
    <Page
      title="Edit event"
      headerContent={
        <div className={styles.headerButtons}>
          {event && (
            <>
              <Tippy content="Source is hidden." disabled={event.source.is_hidden === 0}>
                <span>
                  <Button
                    color="red"
                    disabled={event.source.is_hidden === 1}
                    icon={event.is_hidden === 1 ? "eye" : "eye-slash"}
                    iconStyle="far"
                    variant="outlined"
                    onClick={toggleEventIsHidden}
                    loading={toggleMutation.isLoading}
                  >
                    {event.is_hidden === 1 ? "Show" : "Hide"}
                  </Button>
                </span>
              </Tippy>

              <Button color="grey" icon="clone" variant="outlined" onClick={cloneEvent}>
                Copy
              </Button>
            </>
          )}
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.events"))
            }}
          >
            Cancel
          </Button>
          <Button loading={modifyMutation.isLoading} type="submit" form="event-form">
            Save
          </Button>
        </div>
      }
    >
      {!isEventFulfilled && <LoadingIndicator />}
      {isEventFulfilled && <EventForm onSubmit={modifyEvent} event={event!} />}
    </Page>
  )
}
