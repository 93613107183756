import { MouseEvent, useCallback, useEffect, useRef, useState } from "react"

type ClickOutHandlerProps = {
  openCallback?: () => void
  closeCallback?: () => void
  preventCloseIf?: (e: MouseEvent) => boolean
  initState?: boolean
}

export default function useClickOutHandler({
  openCallback,
  closeCallback,
  preventCloseIf,
  initState = false,
}: ClickOutHandlerProps = {}) {
  const [isOpen, setIsOpen] = useState(initState)
  const ref = useRef<HTMLDivElement>(null)
  // `buttonRef` added to fix a bug where the navbar dropdowns wouldn't open after EasyEmailEditor
  // was mounted. The `handleClickOut` listener would fire even when clicking on a closed dropdown
  // toggle to open it. I spent too much time trying to figure out why, so this is a temporary fix:
  // don't run `handleClickOut` if the click target is the button that toggles the dropdown. Later
  // we can figure out what is happening with EasyEmailEditor here and remove this ref. Other
  // dropdowns are not affected by this bug, only the navbar ones.
  const buttonRef = useRef<HTMLButtonElement>(null)

  const close = useCallback(() => {
    closeCallback?.()
    setIsOpen(false)
  }, [closeCallback])

  const handleClickOut = useCallback(
    e => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        !buttonRef.current?.contains(e.target) &&
        !preventCloseIf?.(e)
      ) {
        close()
      }
    },
    [close, preventCloseIf],
  )

  const open = useCallback(() => {
    openCallback?.()
    setIsOpen(true)
  }, [openCallback])

  const toggle = useCallback(() => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }, [close, isOpen, open])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOut, false)
    }
    return () => document.removeEventListener("click", handleClickOut, false)
  }, [handleClickOut, isOpen])

  return { isOpen, open, close, toggle, ref, buttonRef }
}
