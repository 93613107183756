import React, { PureComponent, useState } from "react"
import { getFormValues } from "redux-form"
import _get from "lodash/get"
import _set from "lodash/set"
import _forEach from "lodash/forEach"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"

// ui components
import Paper from "components/UI/elements/Paper"
import EventsFilterForm from "components/UI/components/EventsFilterForm"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"

// helpers
import { getCustomerEventTitle } from "helpers/event.helper"

import "./Events.scss"
import { useFetchAllEvents } from "resources/event/eventQueries"
import { useSelector } from "store"
import { useFetchCustomerEventsCounts } from "resources/customer/event/customerEventQueries"
import Page from "components/UI/Page/Page"
import Tippy from "@tippyjs/react"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import StructuredEventPayload from "components/UI/elements/StructuredEventPayload/StructuredEventPayload"
import SrcDstIcon from "components/UI/elements/SrcDstIcon/SrcDstIcon"

class Events extends PureComponent {
  eventExamples = {}

  constructor(props) {
    super(props)
    this.state = {
      carouselHeights: {},
      activeExamples: {},
    }
  }

  componentDidMount() {
    if (this.props.areEventsFulfilled) {
      this.computeCarouselHeights()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.areEventsFulfilled !== prevProps.areEventsFulfilled) {
      this.computeCarouselHeights()
    }
  }

  computeCarouselHeights = () => {
    const carouselHeights = {}
    const activeExamples = {}
    _forEach(this.eventExamples, (examples, key) => {
      let maxHeight = 0
      _forEach(examples, example => {
        if (example.clientHeight > maxHeight) {
          maxHeight = example.clientHeight
        }
      })
      _set(carouselHeights, `${key}`, maxHeight)
      _set(activeExamples, `${key}`, 0)
    })
    this.setState({
      carouselHeights,
      activeExamples,
    })
  }

  getExampleStyle = (eventId, exampleIndex) => {
    const { activeExamples } = this.state
    const activeIndex = _get(activeExamples, eventId)
    if (activeIndex !== undefined) {
      let left = Math.abs(exampleIndex - activeIndex) * 690
      if (exampleIndex < activeIndex) {
        left = -left
      }
      return {
        left: `${left}px`,
        position: "absolute",
      }
    }
    return {}
  }

  moveCarousel = (direction, eventId) => () => {
    if (direction === "left") {
      this.setState(prevState => ({
        activeExamples: {
          ...prevState.activeExamples,
          [eventId]: (prevState.activeExamples[eventId] -= 1),
        },
      }))
    } else if (direction === "right") {
      this.setState(prevState => ({
        activeExamples: {
          ...prevState.activeExamples,
          [eventId]: (prevState.activeExamples[eventId] += 1),
        },
      }))
    }
  }

  render() {
    const {
      areEventsFulfilled,
      events,
      customerEventsCounts,
      filters: { orderBy, orderDir, setSort },
    } = this.props
    const { carouselHeights, activeExamples } = this.state

    return (
      <Page
        className="events-container"
        title="Events"
        headerContent={
          <div className={"events-header-container"}>
            <Tippy
              placement="right"
              interactive
              content={
                <>
                  <p>
                    Events are <strong>behaviours of a customer</strong>. They show information
                    about what actions customers have performed and when.{" "}
                    <strong>When a customer’s action happens</strong> (e.g. customer visits a web
                    page, makes a payment transaction, etc.){" "}
                    <strong>it is recorded as an event</strong>. Meiro is able to extract data from
                    the events and create attributes like "Date of the last visit to the web" or
                    "Total revenue from all transactions".
                  </p>
                  <p>
                    To learn more about Events tab, please refer to{" "}
                    <a
                      href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data-library#bkmrk-events-tab"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      this article
                    </a>
                    .
                  </p>
                </>
              }
            >
              <span className="info-tooltip-icon">
                <FontAwesomeIcon icon={["fas", "info-circle"]} />
              </span>
            </Tippy>
            <EventsFilterForm />
          </div>
        }
      >
        {!areEventsFulfilled && <LoadingIndicator />}
        {areEventsFulfilled && (
          <Paper noPaddingTop>
            {events.length > 0 && (
              <Table className="events-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => setSort("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="source-col">
                    <SortButton
                      column="dataSource"
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onClick={() => setSort("source")}
                      label="Data source"
                    />
                  </Th>
                  <Th className="examples-col">Examples</Th>
                </Thead>
                <Tbody>
                  {events.map(event => {
                    const eventCount = customerEventsCounts.find(ce => ce.event_id === event.id)
                    const examples = []
                    if (Array.isArray(event.examples)) {
                      event.examples.forEach(example => {
                        examples.push({
                          type: event.type,
                          payload: example,
                        })
                      })
                    }
                    return (
                      <Tr key={event.id}>
                        <Td
                          textBigger
                          textBlack
                          textBold
                          withNewBadge={moment().diff(event.created, "days") < 8}
                          className="event-name"
                        >
                          {event.name}
                        </Td>
                        <Td className="event-source">
                          <div className="event-source-flex-wrapper">
                            <div>
                              <SrcDstIcon source={event.source} />
                            </div>
                            <div className="source-n-count">
                              <div>{event.source.name}</div>
                              {Number.isInteger(eventCount?.count) && (
                                <div className="count">{eventCount.count} filled</div>
                              )}
                            </div>
                          </div>
                        </Td>
                        <Td className="event-examples">
                          <div
                            className="carousel"
                            style={{
                              height: Number.isFinite(carouselHeights[event.id])
                                ? `${carouselHeights[event.id]}px`
                                : "auto",
                            }}
                          >
                            <div className="carousel-content">
                              {examples.map((example, key) => {
                                const title = getCustomerEventTitle(
                                  example,
                                  event.schema,
                                  event.name,
                                )

                                return (
                                  <div
                                    key={key}
                                    className="event-example"
                                    ref={divElement => {
                                      _set(this.eventExamples, `${event.id}.[${key}]`, divElement)
                                    }}
                                    style={this.getExampleStyle(event.id, key)}
                                  >
                                    <h4>{title}</h4>
                                    <div className="payload">
                                      <StructuredEventPayload
                                        customerEvent={example}
                                        schema={event.schema}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            {activeExamples[event.id] > 0 && (
                              <button
                                className="carousel-button carousel-left"
                                onClick={this.moveCarousel("left", event.id)}
                              >
                                <FontAwesomeIcon icon={["far", "chevron-left"]} />
                              </button>
                            )}
                            {activeExamples[event.id] < examples.length - 1 && (
                              <button
                                className="carousel-button carousel-right"
                                onClick={this.moveCarousel("right", event.id)}
                              >
                                <FontAwesomeIcon icon={["far", "chevron-right"]} />
                              </button>
                            )}
                          </div>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
            {events.length === 0 && <RowMessage className="mt-10">Nothing found.</RowMessage>}
          </Paper>
        )}
      </Page>
    )
  }
}

export default props => {
  const searchFormValues = useSelector(getFormValues("SearchEventForm"))
  const [{ orderBy, orderDir }, setSortState] = useState({ orderBy: "name", orderDir: "ASC" })

  const setSort = orderBy =>
    setSortState(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy,
    }))

  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents(
    {
      orderBy,
      orderDir,
      searchTerm: searchFormValues?.search?.trim(),
      sourceId: searchFormValues?.select?.value,
    },
    { refetchOnMount: "always" },
  )

  const { data: customerEventsCounts = [] } = useFetchCustomerEventsCounts()

  return (
    <Events
      {...props}
      events={events}
      areEventsFulfilled={areEventsFulfilled}
      customerEventsCounts={customerEventsCounts}
      filters={{ orderBy, orderDir, setSort }}
    />
  )
}
