import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import { api } from "api"
import { groupBy, mapObjIndexed, prop } from "ramda"
import { useDispatch } from "react-redux"
import { Attribute } from "resources/attribute/attributeTypes"
import { StitchingRule } from "./stitchingAttributeTypes"

const STITCHING_RULE_ALL_QK: QueryKey = ["stitchingRule", "all"]

export function useFetchStitchingAttributesMap() {
  return useQuery(STITCHING_RULE_ALL_QK, api.stitchingAttribute.listAll, {
    select: rules => {
      const rulesMapByAttributeId = groupBy(prop("attribute_id"), rules)
      return mapObjIndexed(
        (rules: StitchingRule[], attribute_id) => ({
          attribute_id,
          configs: rules.map(({ event_id, value_expression }) => ({ event_id, value_expression })),
        }),
        rulesMapByAttributeId,
      )
    },
  })
}

export function useModifyStitchingAttribute() {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async ({
      attributeId,
      data,
      oldAttributeId,
    }: {
      attributeId: Attribute["id"]
      data: Omit<StitchingRule, "attribute_id">[]
      oldAttributeId?: Attribute["id"]
    }) => {
      if (oldAttributeId) {
        await api.stitchingAttribute.set(oldAttributeId, [])
      }
      return api.stitchingAttribute.set(attributeId, data)
    },
    {
      onSuccess({ stitching_attribute_configs }, { attributeId, data, oldAttributeId }) {
        queryClient.setQueryData<StitchingRule[]>(STITCHING_RULE_ALL_QK, data => {
          return data
            ?.filter(
              rule => rule.attribute_id !== attributeId && rule.attribute_id !== oldAttributeId,
            )
            .concat(stitching_attribute_configs)
        })
        dispatch(showToast("Stitching rule set."))
      },
    },
  )
}

export function useDeleteStitchingAttribute() {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    ({ attributeId }: { attributeId: Attribute["id"] }) =>
      api.stitchingAttribute.set(attributeId, []),
    {
      onSuccess(_, { attributeId }) {
        queryClient.setQueryData<StitchingRule[]>(STITCHING_RULE_ALL_QK, data => {
          return data?.filter(rule => rule.attribute_id !== attributeId)
        })
        dispatch(showToast("Stitching deleted."))
      },
    },
  )
}
