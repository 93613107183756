import React, { ComponentPropsWithoutRef, ReactNode, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import styles from "./Table.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ISO8601DateTime, OrderDir } from "types/util"
import { Link } from "react-router-dom"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Waypoint from "react-waypoint"
import { User } from "resources/user/userTypes"
import Username from "components/Username/Username"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"

export type Column<T> = {
  id: string
  label?: string
  renderCell?: (t: T) => ReactNode
  gridTemplate?: string
  onSort?: () => void
}

type TableProps<T> = {
  data: T[]
  columns: Column<T>[]
  renderRow?: (t: T) => ReactNode
  sortBy?: string
  sortDir?: OrderDir
  emptyMessage?: string
  getRowClassName?: (t: T) => string | undefined
  getRowLink?: (t: T) => string | undefined
  fetchNextPage?: () => void
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
}

export default function Table<T extends { id: string | number }>({
  data,
  columns,
  renderRow,
  sortBy,
  sortDir,
  emptyMessage = "Nothing found.",
  getRowClassName,
  getRowLink,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: TableProps<T>) {
  const gridTemplateColumns = columns.map(({ gridTemplate }) => gridTemplate ?? "auto").join(" ")

  const headerRef = useRef<HTMLDivElement>(null)
  const [headerIsSticking, setHeaderIsSticking] = useState(false)

  useEffect(() => {
    function listener() {
      if (headerRef.current) {
        setHeaderIsSticking(headerRef.current.getBoundingClientRect().top < 0)
      }
    }

    window.addEventListener("scroll", listener)
    return () => window.removeEventListener("scroll", listener)
  }, [])

  if (data.length === 0) {
    return <div className={styles.emptyMessage}>{emptyMessage}</div>
  }

  return (
    <>
      <div ref={headerRef} className={styles.table} style={{ gridTemplateColumns }}>
        <div className={classNames(styles.header, { [styles.isSticking]: headerIsSticking })}>
          {columns.map(({ id, label, onSort }) => (
            <div key={id} className={styles.headerCell}>
              {onSort ? (
                <button
                  className={classNames(styles.sortButton, { [styles.active]: sortBy === id })}
                  onClick={onSort}
                >
                  {label}
                  <FontAwesomeIcon
                    icon={["fas", "caret-up"]}
                    className={styles.sortIcon}
                    flip={sortBy === id && sortDir === "DESC" ? "vertical" : undefined}
                  />
                </button>
              ) : (
                <div className={styles.label}>{label}</div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.body}>
          {data.map(row => {
            const rowClassName = classNames(styles.row, getRowClassName?.(row))
            const rowLink = getRowLink?.(row)
            const rowContent = renderRow
              ? renderRow(row)
              : columns.map(({ id, renderCell }) => <Cell key={id}>{renderCell?.(row)}</Cell>)

            return rowLink ? (
              <Link className={rowClassName} to={rowLink} key={row.id}>
                {rowContent}
              </Link>
            ) : (
              <div className={rowClassName} key={row.id}>
                {rowContent}
              </div>
            )
          })}
        </div>
      </div>
      {fetchNextPage &&
        hasNextPage &&
        (isFetchingNextPage ? (
          <LoadingIndicator />
        ) : (
          <Waypoint onEnter={_ => fetchNextPage()} bottomOffset={-300} />
        ))}
    </>
  )
}

export function Cell({ className, ...props }: ComponentPropsWithoutRef<"div">) {
  return <div className={classNames(styles.cell, className)} {...props} />
}

export function Name({ name }: { name: string }) {
  return <div className={styles.name}>{name}</div>
}

export function Modified({
  modifiedAt,
  modifiedBy,
}: {
  modifiedAt: ISO8601DateTime | null
  modifiedBy: User["id"] | null
}) {
  if (!modifiedAt) {
    return null
  }

  return (
    <div>
      <div>{format(new Date(modifiedAt), DATEFNS.DATETIME_FORMAT)}</div>
      {modifiedBy && (
        <div className={styles.modifiedBy}>
          by <Username userId={modifiedBy} />
        </div>
      )}
    </div>
  )
}
