import IconButton from "components/UI/elements/IconButton/IconButton"
import Table, { RowMessage, Tbody, Th, Thead, Tr, Td } from "components/UI/elements/Table"
import { format } from "date-fns"
import { DATEFNS, PERMISSION } from "sharedConstants"
import { TrashItem, TrashItemType } from "pages/Trash/trashTypes"
import styles from "./TrashDataTable.module.scss"
import getReadableItemType from "pages/Trash/utilities/getReadableItemType"
import InfiniteLoader from "./components/InfiniteLoader/InfiniteLoader"
import Username from "components/Username/Username"
import { useHasAccess, useHasSegmentPermission } from "resources/user/currentUserQueries"

type TrashDataTableProps = {
  data: TrashItem[]
  hasMoreDataToLoad: boolean
  itemType: TrashItemType
  onRestoreClick: (trashItem: TrashItem) => void
  onLoadMore: () => Promise<void>
}

export default function TrashDataTable({
  data,
  hasMoreDataToLoad,
  itemType,
  onRestoreClick,
  onLoadMore,
}: TrashDataTableProps) {
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()

  function isAllowedToRestore(item: TrashItem) {
    switch (item.type) {
      case "custom_segments": {
        return (
          hasAccess.segments.editForeign ||
          hasSegmentPermission(item.id as number, { segmentType: "custom" }) === PERMISSION.WRITE
        )
      }
      case "featured_segments": {
        return (
          hasAccess.segments.featured.edit ||
          hasSegmentPermission(item.id as number, { segmentType: "featured" }) === PERMISSION.WRITE
        )
      }
      case "smart_segments": {
        return (
          hasAccess.setup.smartSegments ||
          hasSegmentPermission(item.id as number, { segmentType: "prebuilt" }) === PERMISSION.WRITE
        )
      }
      case "popup_web_banners": {
        return hasAccess.webBanners.edit
      }
      case "embedded_web_banners": {
        return hasAccess.webBanners.edit
      }
      case "push_notifications": {
        return hasAccess.mobilePushNotifications.edit
      }
      case "emails": {
        return hasAccess.emails.edit
      }
      case "segment_export_destinations": {
        return hasAccess.setup.implementation
      }
      case "users": {
        return hasAccess.administration.users
      }
      case "user_roles": {
        return hasAccess.administration.users
      }
    }
  }

  return data.length === 0 ? (
    <RowMessage>
      No {getReadableItemType(itemType, { capitalize: false, plural: true })} found.
    </RowMessage>
  ) : (
    <>
      <Table>
        <Thead>
          <Th>Name</Th>
          <Th textAlignRight className={styles.deletedByColumn}>
            Deleted at
          </Th>
          <Th className={styles.actionsColumn}>&nbsp;</Th>
        </Thead>
        <Tbody>
          {data.map(item => {
            return (
              <Tr key={item.id}>
                <Td textBlack textBigger textBold data-testid="td-name">
                  {item.name}
                </Td>
                <Td textAlignRight>
                  <span className={styles.deletedAt}>
                    {format(new Date(item.deleted_at), DATEFNS.DATETIME_FORMAT)}
                  </span>
                  {item.deleted_by && (
                    <span className={styles.deletedBy}>
                      by <Username userId={item.deleted_by} />
                    </span>
                  )}
                </Td>
                <Td textAlignRight>
                  <IconButton
                    color="green"
                    size="xs"
                    icon="redo"
                    iconStyle="far"
                    onClick={() => onRestoreClick(item)}
                    data-testid="restore-trash-item"
                    tooltip="Restore"
                    variant="outlined"
                    disabled={!isAllowedToRestore(item)}
                  />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <InfiniteLoader hasMoreDataToLoad={hasMoreDataToLoad} onLoadMoreData={onLoadMore} />
    </>
  )
}
