import React from "react"
import _template from "lodash/template"
import _map from "lodash/map"
import _has from "lodash/has"
import JSPath from "jspath"
import { getUserFriendlyValueFormat } from "./attributeValue.helper"
import moment from "moment"

export const errorMessage = value => (
  <span className="error">
    Error: The value has a different type than defined in the event schema. Received value:{" "}
    {JSON.stringify(value)}
  </span>
)

export const isCorrectType = (value, type) => {
  switch (type) {
    case "number": {
      return typeof value === "number"
    }
    case "datetime": {
      return moment(value).isValid()
    }
    case "string": {
      return typeof value === "string"
    }
    default: {
      return true
    }
  }
}

export const getValue = (customerEvent, type, path, isArray = false) => {
  let values = []
  try {
    values = JSPath.apply(path, customerEvent.payload)
  } catch {
    if (isArray) {
      return []
    } else {
      return "—"
    }
  }

  if (isArray) {
    switch (type) {
      case "number": {
        return _map(values, value => getUserFriendlyValueFormat(value, "int"))
      }
      case "datetime": {
        return _map(values, value => getUserFriendlyValueFormat(value, "datetime"))
      }
      case "string": {
        return values
      }
      default: {
        return values
      }
    }
  } else {
    if (values.length === 0) {
      return "—"
    } else {
      switch (type) {
        case "number": {
          return getUserFriendlyValueFormat(values[0], "int")
        }
        case "datetime": {
          return getUserFriendlyValueFormat(values[0], "datetime")
        }
        case "string": {
          return values[0]
        }
        default: {
          return values[0]
        }
      }
    }
  }
}

export const getCustomerEventTitle = (customerEvent, schema, defaultName) => {
  let title = defaultName
  if (_has(schema, "title") && schema.title) {
    try {
      const compiled = _template(schema.title)
      title = compiled(customerEvent.payload)
    } catch {
      return title
    }
  }
  return title
}
