import { prop, sort, update } from "ramda"
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { useDispatch } from "react-redux"

import { ascend, descend } from "utilities/comparators"
import { metaAttributes } from "./metaAttributesEndpoints"
import {
  MetaAttribute,
  MetaAttributesListResponse,
  MetaAttributeModifyPayload,
  MetaAttributeCreatePayload,
  MetaAttributesSort,
} from "./metaAttributesTypes"
import { showToast } from "actions/toast.action"

const META_ATTRIBUTE = "metaAttribute" as const
const META_ATTRIBUTE_LIST_QK: QueryKey = [META_ATTRIBUTE, "list"]

export const useFetchMetaAttributes = (
  options: Pick<MetaAttributesSort, "orderBy" | "orderDir">,
) => {
  const { data, ...rest } = useInfiniteQuery<MetaAttributesListResponse>(
    META_ATTRIBUTE_LIST_QK,
    ({ pageParam }) => metaAttributes.list({ offset: pageParam }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.meta_attributes.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  const flatData = data ? data.pages.flatMap(p => p.meta_attributes) : []
  const comparator = options.orderDir === "ASC" ? ascend : descend

  return { ...rest, data: sort(comparator(prop(options.orderBy)), flatData) }
}

export const useFetchMetaAttribute = (id: MetaAttribute["id"]) =>
  useQuery([META_ATTRIBUTE, id], () => metaAttributes.retrieve(id), {
    select: ({ meta_attribute }) => meta_attribute,
  })

export const useCreateMetaAttribute = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: MetaAttributeCreatePayload }) => metaAttributes.create(data),
    {
      onSuccess: ({ meta_attribute }) => {
        queryClient.setQueryData<InfiniteData<MetaAttributesListResponse>>(
          META_ATTRIBUTE_LIST_QK,
          data => {
            if (!data) return

            const lastPageNum = data.pages.length - 1
            const lastPage = data.pages[lastPageNum]
            if (
              lastPage.selection_settings.limit !== null &&
              lastPage.meta_attributes.length < lastPage.selection_settings.limit
            ) {
              lastPage.meta_attributes.push(meta_attribute)
              data.pages[lastPageNum] = lastPage
            } else
              data.pages.push({
                meta_attributes: [meta_attribute],
                selection_settings: {
                  ...lastPage.selection_settings,
                  offset:
                    lastPage.selection_settings.offset !== null &&
                    lastPage.selection_settings.limit !== null
                      ? lastPage.selection_settings.offset + lastPage.selection_settings.limit
                      : null,
                },
              })

            return data
          },
        )
        queryClient.setQueryData([META_ATTRIBUTE, meta_attribute.id], { meta_attribute })
        dispatch(showToast("Meta attribute created."))
      },
    },
  )
}

export const useModifyMetaAttribute = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: MetaAttribute["id"]; data: MetaAttributeModifyPayload }) =>
      metaAttributes.modify(id, data),
    {
      onSuccess: ({ meta_attribute }) => {
        queryClient.setQueryData<InfiniteData<MetaAttributesListResponse>>(
          META_ATTRIBUTE_LIST_QK,
          data => {
            if (!data) return

            const metaAttributesPages = data.pages.map(p => {
              const index = p.meta_attributes.findIndex(({ id }) => id === meta_attribute.id)
              if (index !== -1)
                return { ...p, meta_attributes: update(index, meta_attribute, p.meta_attributes) }
              else return p
            })

            return { ...data, pages: metaAttributesPages }
          },
        )
        queryClient.setQueryData([META_ATTRIBUTE, meta_attribute.id], { meta_attribute })
        dispatch(showToast("Meta attribute modified."))
      },
    },
  )
}
