import React, { Component } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import _get from "lodash/get"

import styles from "./TagTextField.module.scss"

class TagTextField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // min width
      inputWidth: 60,
    }
    this.helperRef = React.createRef()
  }

  componentDidMount() {
    this.setState({
      inputWidth: _get(this.helperRef, "current.clientWidth", 57) + 3,
    })
  }

  /* istanbul ignore next */
  componentDidUpdate() {
    const calculatedWidth = _get(this.helperRef, "current.clientWidth", 57) + 3
    if (this.state.inputWidth !== calculatedWidth) {
      this.setState({
        inputWidth: calculatedWidth,
      })
    }
  }

  render() {
    const {
      input,
      label,
      meta: { touched, error },
      className,
      placeholder = "",
      autoFocus = false,
      setFieldToBeFocused = () => {},
      maxLength = 30,
      color = "primary",
    } = this.props
    const errorMessage = touched ? error : ""

    return (
      <div
        className={classNames(
          styles.tagField,
          { [styles.error]: !!errorMessage },
          styles[color],
          className,
        )}
        data-testid="field-wrapper"
      >
        {label && <label data-testid="label">{label}</label>}
        <input
          {...input}
          placeholder={placeholder ? placeholder : ""}
          autoFocus={autoFocus}
          ref={input => setFieldToBeFocused(input)}
          style={{
            width: `${this.state.inputWidth}px`,
          }}
          className={classNames({ [styles.hasValue]: !!input.value })}
          data-testid="input-field"
          maxLength={maxLength}
          autoComplete="off"
        />
        <span className={styles.helper} ref={this.helperRef}>
          {input.value ? input.value : placeholder}
        </span>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    )
  }
}

TagTextField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  setFieldToBeFocused: PropTypes.func,
  color: PropTypes.string,
}

export default TagTextField
