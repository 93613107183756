import React, { useCallback } from "react"
import styles from "./ReportForm.module.scss"
import { Report } from "resources/report/reportTypes"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Button from "components/UI/elements/Button/Button"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required } from "helpers/validators.helper"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import _toInteger from "lodash/toInteger"
import Page from "components/UI/Page/Page"

export type ReportFormValues = {
  name: Report["name"]
  dashboard_id: Report["dashboard_id"]
  disabled: Report["disabled"]
}
type ReportFormProps = {
  initialValues?: ReportFormValues
  onSubmit: (data: ReportFormValues) => void
  onDelete?: () => void
}
type ReportFormType = "create" | "edit"
export default function ReportForm({ initialValues, onSubmit, onDelete }: ReportFormProps) {
  const history = useHistory()
  const formType: ReportFormType = initialValues ? "edit" : "create"
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ReportFormValues>({
    defaultValues: initialValues ?? { disabled: false },
  })

  const submitForm: SubmitHandler<ReportFormValues> = useCallback(
    async values => {
      const submitValues: ReportFormValues = {
        ...values,
        dashboard_id: _toInteger(values.dashboard_id),
      }
      try {
        await onSubmit(submitValues)
      } catch (err) {}
    },
    [onSubmit],
  )

  const goBackToReportsList = useCallback(() => {
    history.push(getRoutePath("administration.reports"))
  }, [history])

  return (
    <form onSubmit={handleSubmit(submitForm)} autoComplete="off">
      <Page
        title={formType === "create" ? "Create report" : "Edit report"}
        headerClassName={styles.header}
        headerContent={
          <div>
            {formType === "edit" && (
              <Button
                color="red"
                icon="trash-alt"
                variant="outlined"
                onClick={onDelete}
                className={styles.buttonMargin}
              >
                Delete
              </Button>
            )}
            <Button
              color="grey"
              variant="outlined"
              onClick={goBackToReportsList}
              className={styles.buttonMargin}
            >
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        }
      >
        <Paper className={styles.formContent}>
          <TextInput
            label="Report name"
            error={errors.name?.message}
            placeholder="Report name"
            className={styles.nameField}
            minLength={1}
            maxLength={100}
            {...register("name", { validate: required })}
          />
          <TextInput
            label="Metabase dashboard ID"
            error={errors.dashboard_id?.message}
            className={styles.linkField}
            placeholder="Metabase dashboard ID"
            autoComplete="off"
            type="number"
            min="0"
            step="1"
            {...register("dashboard_id", { validate: required })}
          />
          <Controller
            name="disabled"
            control={control}
            render={({ field }) => {
              return (
                <div className={styles.showToggleButtonWrapper}>
                  <label>Show</label>
                  <ToggleButton
                    value={!field.value}
                    handleToggle={() => {
                      field.onChange(!field.value)
                    }}
                  />
                </div>
              )
            }}
          />
        </Paper>
      </Page>
    </form>
  )
}
