import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { abbreviateNumber } from "helpers/number.helper"
import { useFetchChannelSegmentCounts } from "resources/channel/channelQueries"
import { ChannelType } from "resources/channel/channelTypes"
import { Segment } from "resources/segment/segment/segmentTypes"

import styles from "./SegmentChannelNumbers.module.scss"

type SegmentChannelNumbersProps = {
  channelType: ChannelType
  segmentId: Segment["id"]
}

export default function SegmentChannelNumbers({
  channelType,
  segmentId,
}: SegmentChannelNumbersProps) {
  const { data, isFetching, isLoading } = useFetchChannelSegmentCounts({
    channel_type: channelType,
    segment_ids: [segmentId],
  })

  return (
    <div className={styles.flexBox}>
      <div className={styles.titleWrapper}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={channelType === "emails" ? "envelope" : "bell"} />
        </div>
        <span className={styles.title}>{channelType === "emails" ? "Emails" : "Mobile push"}</span>
      </div>
      <span className={styles.outOf}>
        {isLoading || isFetching ? (
          <LoadingIndicator size="sm" />
        ) : (
          <>
            {data?.count !== undefined && data?.total_count !== undefined ? (
              <>
                <b>{abbreviateNumber(data?.count)}</b> out of {abbreviateNumber(data?.total_count)}
              </>
            ) : (
              <>Not active</>
            )}
          </>
        )}
      </span>
    </div>
  )
}
