import React, { Component } from "react"
import { connect } from "react-redux"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm from "../components/AttributeForm/AttributeForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { api } from "api"
import { showToast } from "actions/toast.action"
import _get from "lodash/get"
import { getInitialAttributeFormValues } from "../components/AttributeForm/attributeInitialValues"

import "./AttributeCreate.scss"
import Page from "components/UI/Page/Page"
import { useFetchDataSourceOptions } from "resources/dataSource/dataSourceQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"

class AttributeCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating: false,
    }
  }

  createAttribute = async data => {
    if (!this.state.creating) {
      this.setState({ creating: true })
      const { history, showToast } = this.props
      try {
        await api.attribute.create(data)
        showToast("Attribute created.")
        history.push(getRoutePath("administration.attributes"))
      } catch {
        this.setState({ creating: false })
      }
    }
  }

  render() {
    const { history, location, sourceOptions, areSourcesFulfilled } = this.props
    const { creating } = this.state

    // prefilled values from copy
    const attribute = _get(location, "state.attribute")
    const formInitValues = getInitialAttributeFormValues(attribute, sourceOptions)

    return (
      <Page
        className="create-attribute"
        title="Create attribute"
        headerContent={
          <div className="header-buttons">
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.attributes"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={creating} formName="AttributeForm" />
          </div>
        }
      >
        <Paper hasHeader className="create-attribute-content">
          {areSourcesFulfilled ? (
            <AttributeForm onSubmit={this.createAttribute} initialValues={formInitValues} />
          ) : (
            <LoadingIndicator />
          )}
        </Paper>
      </Page>
    )
  }
}

AttributeCreate = connect(null, { showToast })(AttributeCreate)

export default props => {
  const { data: sourceOptions, isSuccess: areSourcesFulfilled } = useFetchDataSourceOptions({
    showHidden: true,
  })

  return (
    <AttributeCreate
      {...props}
      sourceOptions={sourceOptions}
      areSourcesFulfilled={areSourcesFulfilled}
    />
  )
}
