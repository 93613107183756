import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query"

import { api } from "api"
import {
  CustomerAttributeListError,
  CustomerAttributeListResponse,
  CustomerAttributeRetrievePaginatedPayload,
  CustomerAttributeRetrievePayload,
  CustomerAttributeRetrieveResponse,
  CustomerAttributesListPayload,
} from "./customerAttributeTypes"

export function useFetchCustomerAttributes(
  data: CustomerAttributesListPayload,
  config?: UseQueryOptions<
    CustomerAttributeListResponse<0>,
    CustomerAttributeListError,
    CustomerAttributeListResponse<0>,
    QueryKey
  >,
) {
  return useQuery(
    ["customer", data.customer_entity_id, "attribute"] as QueryKey,
    () => api.customerAttribute.list(data),
    {
      ...config,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  )
}

export const useFetchCustomerAttributePaginated = (
  data: CustomerAttributeRetrievePaginatedPayload,
  config?: UseQueryOptions<
    CustomerAttributeRetrieveResponse,
    unknown,
    CustomerAttributeRetrieveResponse,
    QueryKey
  >,
) =>
  useQuery(
    [
      "customer",
      data.customer_entity_id,
      "attribute",
      data.attribute_id,
      "offset",
      data.offset,
    ] as QueryKey,
    () => api.customerAttribute.retrieve(data),
    { ...config, keepPreviousData: true },
  )

export const useFetchCustomerAttribute = (
  { attribute_id, customer_entity_id }: CustomerAttributeRetrievePayload,
  config: UseInfiniteQueryOptions<CustomerAttributeRetrieveResponse, unknown> | undefined = {},
) => {
  const { data, ...rest } = useInfiniteQuery(
    ["customer", customer_entity_id, "attribute", attribute_id] as QueryKey,
    ({ pageParam }) =>
      api.customerAttribute.retrieve({
        attribute_id,
        customer_entity_id,
        limit: 10,
        offset: pageParam ?? 0,
      }),
    {
      ...config,
      staleTime: 1000 * 60,
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.customer_attribute_values.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.customer_attribute_values) : [] }
}
