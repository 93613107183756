import { TOAST } from "sharedConstants"

export const showToast = (
  message: string,
  type: typeof TOAST.TYPE[keyof typeof TOAST.TYPE] = TOAST.TYPE.SUCCESS,
  route = "",
  hidePrevious = false,
  link?: { title: string; url: string },
  routes?: Array<{ title: string; path: string }>,
) => ({
  type: TOAST.ACTION.SHOW,
  payload: { message, type, route, hidePrevious, link, routes },
})
