import React, { useCallback, useEffect, useState } from "react"
import classnames from "classnames"
import { get, noop, toInteger } from "lodash"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { Link, Redirect, useLocation } from "react-router-dom"

import { api } from "api"
import { login, useAuthTokenStore } from "auth/auth"
import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import Button from "components/UI/elements/Button/Button"
import Checkbox from "components/UI/elements/Checkbox/Checkbox"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { required, email } from "helpers/validators.helper"
import useLocalStorage from "hooks/useLocalStorage"
import okta from "images/white-okta-logo.png"
import { getRoutePath } from "routes"
import { REDIRECT_AFTER_OKTA_LOGIN_LS_KEY } from "sharedConstants"

import styles from "./Login.module.scss"

type FormValues = {
  email: string
  password: string
}

let credentialsLoginEnabled: number | string | undefined = 0,
  oktaLoginEnabled: number | string | undefined = 0,
  recaptchaSiteKey: string | null | undefined = null
if (process.env.NODE_ENV === "production") {
  credentialsLoginEnabled = "[[CREDENTIALS_LOGIN_ENABLED]]"
  oktaLoginEnabled = "[[OKTA_LOGIN_ENABLED]]"
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  credentialsLoginEnabled = process.env.REACT_APP_CREDENTIALS_LOGIN_ENABLED
  oktaLoginEnabled = process.env.REACT_APP_OKTA_LOGIN_ENABLED
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

export default function Login() {
  const [generalTermsCheckboxValue, setGeneralTermsCheckboxValue] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [auth, setAuth] = useState<{
    loading: boolean
    options: {
      is_credentials_login_enabled: boolean
      is_okta_login_enabled: boolean
      okta_auth_link: string
    } | null
  }>({ loading: true, options: null })
  const [loading, setLoading] = useState(false)
  const [oktaLoading, setOktaLoading] = useState(false)

  const location = useLocation()
  const [, setRedirectAfterOktaLogin] = useLocalStorage<string | null>(
    REDIRECT_AFTER_OKTA_LOGIN_LS_KEY,
    null,
  )
  const [cdpGeneralTermsAccepted, setCdpGeneralTermsAccepted] = useLocalStorage(
    "cdpGeneralTermsAccepted",
    false,
  )

  const redirectToOktaAuthLink = useCallback(() => {
    const oktaLink = get(auth, "options.okta_auth_link")
    window.open(oktaLink, "_self")
  }, [auth])

  useEffect(() => {
    api
      .authOptions()
      .then(response =>
        setAuth({
          options: response,
          loading: false,
        }),
      )
      .catch(error => {
        if (!get(error, "isCanceled")) setAuth(prevState => ({ ...prevState, loading: false }))
      })

    document.body.classList.add("toastify-on-top")

    return () => {
      document.body.classList.remove("toastify-on-top")
    }
  }, [])

  useEffect(() => {
    if (!auth.loading && oktaLoading) redirectToOktaAuthLink()
  }, [auth.loading, oktaLoading, redirectToOktaAuthLink])

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: { email: "", password: "" },
  })

  useEffect(() => {
    if (!loading && isSubmitSuccessful) reset()
  }, [loading, isSubmitSuccessful, reset])

  const { authToken } = useAuthTokenStore()

  const onSubmit = (values: FormValues) => {
    if (!loading) {
      const variables = recaptchaToken ? { ...values, recaptcha_token: recaptchaToken } : values

      setLoading(true)
      login(variables)
        .then(() => {
          if (!cdpGeneralTermsAccepted) setCdpGeneralTermsAccepted(true)
        })
        .catch((error: { response?: { data?: { show_recaptcha: boolean } } }) => {
          if (error.response?.data?.show_recaptcha) {
            setShowRecaptcha(true)
            setLoading(true)
          } else setLoading(false)
        })
    }
  }

  const loginWithOkta = () => {
    try {
      const urlParams = new URLSearchParams(location.search)
      const redirect = urlParams.get("redirect")

      setRedirectAfterOktaLogin(redirect)
    } catch (_) {
      noop()
    }

    if (auth.loading) setOktaLoading(true)
    else redirectToOktaAuthLink()
  }

  const renderGeneralTermsCheckbox = () => (
    <div className={styles.generalTerms}>
      <Checkbox
        centered={false}
        checked={generalTermsCheckboxValue}
        label={
          <>
            I consent with{" "}
            <a href="https://meiro.io/general-terms/" target="_blank" rel="noopener noreferrer">
              Meiro General Terms
            </a>{" "}
            and{" "}
            <a
              href="https://meiro.io/user-security-guidelines/"
              target="_blank"
              rel="noopener noreferrer"
            >
              User Security Guidelines
            </a>
          </>
        }
        data-testid="agreement-checkbox"
        onChange={_ => setGeneralTermsCheckboxValue(prev => !prev)}
      />
    </div>
  )

  const renderOktaButton = () => (
    <Button
      fullWidth
      size="md"
      loading={oktaLoading}
      onClick={loginWithOkta}
      className={classnames(styles.oktaLogin, {
        [styles.hideLogo]: oktaLoading,
      })}
      disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
    >
      Login with <img src={okta} alt="" />
    </Button>
  )

  const showLoginForm = toInteger(credentialsLoginEnabled) === 1
  const showOktaButton = toInteger(oktaLoginEnabled) === 1
  const showGeneralTermsCheckbox = cdpGeneralTermsAccepted === false

  if (authToken) {
    return (
      <Redirect
        to={new URLSearchParams(window.location.search).get("redirect") ?? getRoutePath("home")}
      />
    )
  }

  return (
    <AuthFormLayout>
      <div className={styles.wrapper}>
        {showLoginForm && (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <h2>Login</h2>
            <div className="form-row">
              <TextInput
                autoFocus
                error={errors.email?.message}
                label="Email"
                placeholder="Email"
                {...register("email", { validate: { required, email } })}
              />
            </div>
            <div className="form-row">
              <TextInput
                error={errors.password?.message}
                label="Password"
                placeholder="Password"
                type="password"
                {...register("password", { validate: { required } })}
              />
            </div>
            {showRecaptcha && recaptchaSiteKey && (
              <div className={classnames("form-row", styles.recaptcha)}>
                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={setRecaptchaToken} />
              </div>
            )}

            <div
              className={classnames(styles.actions, {
                [styles.withCheckbox]: showGeneralTermsCheckbox,
              })}
            >
              <Button
                data-testid="login-button"
                size="md"
                type="submit"
                fullWidth
                loading={loading}
                disabled={
                  (showGeneralTermsCheckbox && !generalTermsCheckboxValue) ||
                  (showRecaptcha && !recaptchaToken)
                }
                className={classnames({ [styles.login]: showOktaButton })}
              >
                Login
              </Button>
              {showOktaButton && renderOktaButton()}
              {showGeneralTermsCheckbox && renderGeneralTermsCheckbox()}
              <Link
                className={styles.underformLink}
                data-testid="forgot-password-link"
                to={getRoutePath("password.reset")}
              >
                Forgot password?
              </Link>
            </div>
          </form>
        )}
        {!showLoginForm && showOktaButton && (
          <>
            {renderOktaButton()}
            {showGeneralTermsCheckbox && renderGeneralTermsCheckbox()}
          </>
        )}
      </div>
    </AuthFormLayout>
  )
}
