import React from "react"

import Checkbox, { CheckboxProps } from "./Checkbox"

type Props = {
  input: {
    value: boolean
    onChange: (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => React.ChangeEventHandler<HTMLInputElement>
  }
  meta: { touched: boolean; error?: string }
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => React.ChangeEventHandler<HTMLInputElement>
} & Omit<CheckboxProps, "errorMessage">

const CheckboxReduxFormField = ({
  input: { value, onChange: onInputChange, ...restInput },
  meta: { error, touched },
  onChange,
  ...restProps
}: Props) => {
  const errorMessage = touched && error ? error : undefined

  return (
    <Checkbox
      {...restProps}
      {...restInput}
      checked={value}
      errorMessage={errorMessage}
      onChange={event => {
        onInputChange(event)
        onChange?.(event)
      }}
    />
  )
}

export default CheckboxReduxFormField
