import React, { forwardRef, ReactElement, ReactNode, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _toLower from "lodash/toLower"
import _isString from "lodash/isString"
import classnames from "classnames"
import styles from "./Table.module.scss"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
} from "@dnd-kit/core"
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import DragHandleImgSrc from "./DragHandle.svg"
import { OrderDir } from "types/util"

type SortButtonProps = {
  column: string
  label: string | ReactNode
  orderBy?: any
  orderDir?: "asc" | "desc" | OrderDir | null
  onClick: () => void
}
export const SortButton = ({ column, label, orderBy, orderDir, onClick }: SortButtonProps) => (
  <button
    type="button"
    className={classnames(styles.sortButton, { [styles.active]: orderBy === column })}
    onClick={onClick}
  >
    {label}
    {orderDir && (_toLower(orderDir) === "desc" || orderBy !== column) && (
      <FontAwesomeIcon icon={["fas", "caret-down"]} />
    )}
    {orderDir && _toLower(orderDir) === "asc" && orderBy === column && (
      <FontAwesomeIcon icon={["fas", "caret-up"]} />
    )}
  </button>
)

SortButton.propTypes = {
  column: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  orderDir: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

type TheadProps = {
  stickyHeader?: string | boolean
  className?: string
  children: ReactNode
}
export const Thead = ({ stickyHeader, className, children }: TheadProps) => {
  const handleStickyHeader = useCallback(() => {
    const stickyTableHeaders = document.getElementsByClassName(
      _isString(stickyHeader) ? stickyHeader : styles.stickyTableHeader,
    )
    if (stickyTableHeaders.length > 0) {
      const header = stickyTableHeaders[0]
      const bounding = header.getBoundingClientRect()
      if (bounding && bounding.top <= 0) {
        header.classList.add(styles.stickyOn)
      } else {
        header.classList.remove(styles.stickyOn)
      }
    }
  }, [stickyHeader])

  useEffect(() => {
    window.addEventListener("scroll", handleStickyHeader)

    return () => {
      window.removeEventListener("scroll", handleStickyHeader)
    }
  }, [handleStickyHeader])

  return (
    <div className={classnames(styles.customThead, className)}>
      <div
        className={classnames(
          styles.customTr,
          { [styles.stickyTableHeader]: stickyHeader },
          stickyHeader,
        )}
      >
        <Th className={styles.overlap}>&nbsp;</Th>
        {children}
        <Th className={styles.overlap}>&nbsp;</Th>
      </div>
    </div>
  )
}

Thead.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  stickyHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

type ThProps = {
  className?: string
  children?: ReactNode
  textAlignRight?: boolean
  textAlignCenter?: boolean
}
export const Th = ({ className, children, textAlignRight, textAlignCenter }: ThProps) => {
  return (
    <div
      className={classnames(styles.customTh, className, {
        [styles.textAlignRight]: textAlignRight,
        [styles.textAlignCenter]: textAlignCenter,
      })}
    >
      {children}
    </div>
  )
}

Th.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

type SortableActiveItemProps = {
  id: string
  children: ReactNode
}
const SortableActiveItem = forwardRef<HTMLDivElement, SortableActiveItemProps>(
  ({ id, children, ...props }, ref) => {
    return (
      <div className={styles.sortableActiveItem} {...props} ref={ref}>
        <div>
          <Td className={styles.overlap}>&nbsp;</Td>
          <Td className={styles.dragHandleColumn}>
            <DragHandle />
          </Td>
          {children}
          <Td className={styles.overlap}>&nbsp;</Td>
        </div>
      </div>
    )
  },
)

type TbodyProps = {
  className?: string
  children: ReactNode
}
export const Tbody = ({ className, children }: TbodyProps) => {
  return <div className={classnames(styles.customTbody, className)}>{children}</div>
}

Tbody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

type TbodySortableChildProps = {
  props: {
    id: string
    children: ReactNode[]
  }
} & ReactElement
type TbodySortableProps = {
  items: any[]
  onDragEnd: (event: DragEndEvent) => void
  className?: string
  children: TbodySortableChildProps[]
} & TbodyProps
export const TbodySortable = ({ className, items, onDragEnd, children }: TbodySortableProps) => {
  const [activeId, setActiveId] = useState<null | string>(null)
  const sensors = useSensors(useSensor(PointerSensor))

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id)
  }, [])

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (active.id !== over?.id) {
        onDragEnd(event)
      }
      setActiveId(null)
    },
    [onDragEnd],
  )

  return (
    <div className={classnames(styles.customTbody, className)}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children.map(child => {
            if (activeId && activeId === child.props.id) {
              return React.cloneElement(child, {
                className: classnames(child.props.className, styles.draggedItem),
              })
            }
            return child
          })}
        </SortableContext>
        <DragOverlay dropAnimation={null}>
          {activeId ? (
            <SortableActiveItem id={activeId}>
              {children!.find(item => item.props.id === activeId)?.props.children}
            </SortableActiveItem>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

type TdProps = {
  className?: string
  children: ReactNode
  textBigger?: boolean
  textBlack?: boolean
  textBold?: boolean
  textAlignCenter?: boolean
  textAlignRight?: boolean
  withNewBadge?: boolean
}
export const Td = ({
  className,
  children,
  textBigger,
  textBlack,
  textBold,
  textAlignCenter,
  textAlignRight,
  withNewBadge,
  ...props
}: TdProps) => {
  return (
    <div
      className={classnames(styles.customTd, className, {
        [styles.textBigger]: textBigger,
        [styles.textBlack]: textBlack,
        [styles.textBold]: textBold,
        [styles.textAlignCenter]: textAlignCenter,
        [styles.textAlignRight]: textAlignRight,
        [styles.withNew]: withNewBadge,
      })}
      {...props}
    >
      {withNewBadge ? (
        <>
          <span className={styles.title}>{children}</span>
          <span className={styles.newBadge}>New</span>
        </>
      ) : (
        children
      )}
    </div>
  )
}

Td.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  textBigger: PropTypes.bool,
  textBlack: PropTypes.bool,
  textBold: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  textAlignRight: PropTypes.bool,
}

type TrProps = {
  className?: string
  children: ReactNode
  onClick?: (evt?: MouseEvent) => void
  disabled?: boolean
  href?: string
}
export const Tr = ({ className, children, onClick, disabled, href, ...otherProps }: TrProps) => {
  const props: Record<string, any> = {
    ...otherProps,
    className: classnames(styles.customTr, className, {
      [styles.linkRow]: href,
      [styles.disabled]: disabled,
    }),
  }
  if (onClick) {
    props.onClick = onClick
  }

  const content = (
    <React.Fragment>
      <Td className={styles.overlap}>&nbsp;</Td>
      {children}
      <Td className={styles.overlap}>&nbsp;</Td>
    </React.Fragment>
  )

  if (href) {
    return (
      <Link to={href} {...props}>
        {content}
      </Link>
    )
  } else {
    return <div {...props}>{content}</div>
  }
}

export const DragHandle = ({ ...props }) => (
  <button tabIndex={0} className={styles.dragHandleBtn} {...props}>
    <img src={DragHandleImgSrc} alt="" />
  </button>
)

type TrSortableProps = {
  id: string
} & TrProps
export const TrSortable = ({
  id,
  className,
  children,
  onClick,
  disabled,
  href,
  ...otherProps
}: TrSortableProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform) ?? undefined,
    transition: transition ?? undefined,
  }
  const props: Record<string, any> = {
    ...otherProps,
    className: classnames(styles.customTr, className, {
      [styles.linkRow]: href,
      [styles.disabled]: disabled,
    }),
  }
  if (onClick) {
    props.onClick = onClick
  }

  const content = (
    <React.Fragment>
      <Td className={styles.overlap}>&nbsp;</Td>
      <Td className={styles.dragHandleColumn}>
        <DragHandle {...listeners} />
      </Td>
      {children}
      <Td className={styles.overlap}>&nbsp;</Td>
    </React.Fragment>
  )

  if (href) {
    return (
      <Link ref={setNodeRef} to={href} style={style} {...attributes} {...props}>
        {content}
      </Link>
    )
  } else {
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...props}>
        {content}
      </div>
    )
  }
}

Tr.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

type RowMessageProps = {
  children: ReactNode
  className?: string
}
export const RowMessage = ({ children, className = "" }: RowMessageProps) => (
  <div className={classnames(styles.fullWidthRowMessage, className)}>{children}</div>
)

RowMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

type TableProps = {
  className?: string
  children: ReactNode
}
const Table = ({ className, children }: TableProps) => (
  <div className={classnames(styles.customTable, className)}>{children}</div>
)

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Table
