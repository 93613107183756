import React, { ComponentPropsWithRef, forwardRef } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import Tippy from "@tippyjs/react"

import styles from "./Button.module.scss"

export type ButtonProps = {
  color?: "primary" | "grey" | "green" | "red" | "black"
  fullWidth?: boolean
  icon?: IconName
  iconClassName?: string
  iconPosition?: "start" | "end"
  iconStyle?: IconPrefix
  loading?: boolean
  size?: "xs" | "sm" | "md" | "lg"
  spinIcon?: boolean
  tooltip?: string
  variant?: "solid" | "outlined" | "link" | "text"
} & ComponentPropsWithRef<"button">

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      icon,
      iconClassName,
      tooltip,
      color = "primary",
      disabled = false,
      fullWidth = false,
      iconPosition = "start",
      iconStyle = "fas",
      loading = false,
      size = "sm",
      spinIcon = false,
      type = "button",
      variant = "solid",
      ...buttonAttrs
    },
    ref,
  ) => {
    const content = (
      <button
        {...buttonAttrs}
        disabled={disabled}
        ref={ref}
        type={type}
        className={classNames(
          styles.button,
          styles[color],
          styles[size],
          styles[variant],
          className,
          {
            [styles.fullWidth]: fullWidth,
            [styles.loading]: loading,
          },
        )}
      >
        {iconPosition === "end" && <span>{children}</span>}
        {icon && !loading && (
          <FontAwesomeIcon
            icon={[iconStyle, icon]}
            spin={spinIcon}
            className={classNames(
              {
                [styles.iconStart]: iconPosition === "start",
                [styles.iconEnd]: iconPosition === "end",
              },
              iconClassName,
            )}
          />
        )}
        {iconPosition === "start" && <span>{children}</span>}
      </button>
    )

    if (tooltip)
      return (
        <Tippy content={tooltip} delay={[700, 0]} placement="bottom">
          {disabled ? <span>{content}</span> : content}
        </Tippy>
      )
    else return content
  },
)

export default Button
