import React, { PureComponent } from "react"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import Paper from "components/UI/elements/Paper"
import DestinationForm from "../components/DestinationForm/DestinationForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import "./DestinationDetail.scss"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import {
  useDeleteDestination,
  useFetchDestinationById,
  useModifyDestination,
} from "resources/exportDestination/exportDestinationQueries"
import { useParams } from "react-router-dom"
import { useFetchWorkspaceOptions } from "resources/workspace/workspaceQueries"

class DestinationDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modifying: false,
      deleteModal: {
        open: false,
        isLoading: false,
      },
    }
  }

  modifyDestination = async data => {
    if (!this.state.modifying) {
      this.setState({ modifying: true })
      const { history, modifyDestination, destination } = this.props
      try {
        await modifyDestination({ id: destination.id, data })
        history.push(getRoutePath("administration.destinations"))
      } catch {
        this.setState({ modifying: false })
      }
    }
  }

  toggleDeleteModal = () => {
    if (!this.state.deleteModal.isLoading) {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: !prevState.deleteModal.open,
        },
      }))
    }
  }

  deleteDestination = async () => {
    const { deleteDestination, destination, history } = this.props
    this.setState(prevState => ({
      deleteModal: {
        ...prevState.deleteModal,
        isLoading: true,
      },
    }))
    try {
      await deleteDestination({ id: destination.id })
      history.push(getRoutePath("administration.destinations"))
    } catch {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { history, areDestinationsFulfilled, destination, workspaces } = this.props
    const { modifying, deleteModal } = this.state
    const formInitValues = {}
    if (destination) {
      formInitValues.id = destination.id
      formInitValues.name = destination.name
      formInitValues.description = destination.description
      formInitValues.attribute_ids = destination.attribute_ids ? destination.attribute_ids : null
      formInitValues.mandatory_attribute_ids = destination.mandatory_attribute_ids
        ? destination.mandatory_attribute_ids
        : null
      formInitValues.icon = destination.icon ? destination.icon.replace(/\.[^/.]+$/, "") : null
      formInitValues.settings = {
        mandatory_attributes_operator: destination.settings?.mandatory_attributes_operator ?? "or",
      }
      formInitValues.registration_token_attribute_id =
        destination.settings?.registration_token_attribute_id ?? null
      formInitValues.color = destination.frontend_settings?.color ?? null

      if (destination.mi_workspace_id) {
        formInitValues.mi_workspace = workspaces.find(
          item => item.value === destination.mi_workspace_id,
        )
        if (!formInitValues.mi_workspace) {
          formInitValues.mi_workspace = {
            value: destination.mi_workspace_id,
            label: `${destination.mi_workspace_id}`,
          }
        }
      }

      const parameters = destination.settings?.mi_workspace_variables
      if (parameters) {
        formInitValues.settings.mi_workspace_variables = parameters.map(param => ({
          ...param,
          input_mode: {
            user_input: param.input_mode.includes("user_input"),
            value_from_list: param.input_mode.includes("value_from_list"),
          },
        }))
      }
    }

    return (
      <Page
        className="detail-destination"
        title="Edit destination"
        headerContent={
          <div className="header-buttons">
            <Button
              color="red"
              icon="trash-alt"
              variant="outlined"
              onClick={this.toggleDeleteModal}
            >
              Delete
            </Button>
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.destinations"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={modifying} formName="DestinationForm" />
          </div>
        }
      >
        {!areDestinationsFulfilled && <LoadingIndicator />}
        {areDestinationsFulfilled && (
          <Paper className="detail-destination-content">
            <DestinationForm
              onSubmit={this.modifyDestination}
              initialValues={formInitValues}
              disabledFields={["id"]}
            />
          </Paper>
        )}
        <ConfirmModal
          open={deleteModal.open}
          type={MODAL.TYPE.DELETE}
          handleClose={this.toggleDeleteModal}
          handleConfirm={this.deleteDestination}
          title="Are you sure?"
          action="delete"
          what="destination"
          item={destination ? destination.name : ""}
          isLoading={deleteModal.isLoading}
        />
      </Page>
    )
  }
}

export default props => {
  const { id } = useParams()
  const { data: destination, isSuccess } = useFetchDestinationById(+id)
  const { mutateAsync: modifyDestination } = useModifyDestination()
  const { mutateAsync: deleteDestination } = useDeleteDestination()
  const { data: workspaces = [] } = useFetchWorkspaceOptions()

  return (
    <DestinationDetail
      {...props}
      destination={destination}
      areDestinationsFulfilled={isSuccess}
      modifyDestination={modifyDestination}
      deleteDestination={deleteDestination}
      workspaces={workspaces}
    />
  )
}
