import React, { useState } from "react"
import classNames from "classnames"
import styles from "./NotificationPreview.module.scss"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import androidImg from "./android.png"
import iPhoneImg from "./iPhone.png"
import meiroLogo from "images/meiro-labyrinth.svg"

type NotificationPreviewProps = {
  androidTitlePreview: string
  androidBodyPreview: string
  iOSTitlePreview: string
  iOSBodyPreview: string
}

export default function NotificationPreview({
  androidTitlePreview,
  androidBodyPreview,
  iOSTitlePreview,
  iOSBodyPreview,
}: NotificationPreviewProps) {
  const [phoneType, setPhoneType] = useState<"iOS" | "Android">("iOS")

  return (
    <div className={styles.container}>
      {!(androidTitlePreview || iOSTitlePreview) || !(androidBodyPreview || iOSBodyPreview) ? (
        <div className={styles.emptyMessage}>
          Fill in the title and body to see the notification preview
        </div>
      ) : (
        <>
          <ToggleSwitch
            leftValue="iOS"
            rightValue="Android"
            checked={phoneType}
            handleToggle={() => setPhoneType(os => (os === "iOS" ? "Android" : "iOS"))}
            width="150px"
            className={styles.toggleSwitch}
          />
          <div className={styles.imgWrapper}>
            <img
              src={phoneType === "iOS" ? iPhoneImg : androidImg}
              alt=""
              className={styles.phoneImg}
            />
            <div
              className={classNames(styles.notification, {
                [styles.android]: phoneType === "Android",
                [styles.iOS]: phoneType === "iOS",
              })}
            >
              {phoneType === "iOS" && <img className={styles.appLogo} alt="" src={meiroLogo} />}
              <div className={styles.main} data-testid="mobile-push-notification-preview">
                <div className={styles.header}>
                  {phoneType === "Android" && (
                    <img className={styles.appLogo} alt="" src={meiroLogo} />
                  )}
                  {phoneType === "Android" && <div className={styles.appName}>Meiro</div>}
                  {phoneType === "iOS" && <div className={styles.titleText}>{iOSTitlePreview}</div>}
                  <div className={styles.time}>3 min ago</div>
                </div>
                {phoneType === "Android" && (
                  <div className={styles.titleText}>{androidTitlePreview}</div>
                )}
                <div className={styles.bodyText}>
                  {phoneType === "Android" ? androidBodyPreview : iOSBodyPreview}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.infoText}>
            Attribute IDs in the preview are replaced with example attribute values, if examples are
            available.
          </div>
        </>
      )}
    </div>
  )
}
