import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import { required } from "helpers/validators.helper"
import Tippy from "@tippyjs/react"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import { useFetchChannels } from "resources/channel/channelQueries"
import { EMAILS_CHANNEL_NAME, PUSH_NOTIFICATIONS_CHANNEL_NAME } from "sharedConstants"
import { prop, whereEq } from "ramda"
import { featuresSections } from "resources/userRole/features"
import { useFetchAllDestinations } from "resources/exportDestination/exportDestinationQueries"
import { useFetchAllLabels } from "resources/attributeLabel/attributeLabelQueries"

const dependencies = [
  // For the first element to be on, the second element must be on

  // WATCH OUT: if you want to put in "segments/export", that might not work because there is a
  // special case relationship between "segments/export" and its subfeatures

  // segments
  ["foreign_segments/view", "foreign_segments/list"],
  ["foreign_segments/edit", "foreign_segments/view"],

  ["featured_segments/view", "featured_segments/list"],
  ["featured_segments/edit", "featured_segments/view"],

  // tags
  ["settings/tags", "data/tags"],

  // reports
  ["reports/edit", "reports/view"],

  // web banners
  ["web_banners/view", "featured_segments/list"],
  ["web_banners/view", "featured_segments/view"],
  ["web_banners/view", "foreign_segments/list"],
  ["web_banners/view", "foreign_segments/view"],

  ["web_banners/edit", "web_banners/view"],

  // push notifications
  ["push_notifications/view", "featured_segments/list"],
  ["push_notifications/view", "featured_segments/view"],
  ["push_notifications/view", "foreign_segments/list"],
  ["push_notifications/view", "foreign_segments/view"],

  ["push_notifications/edit", "push_notifications/view"],

  // emails
  ["emails/view", "featured_segments/list"],
  ["emails/view", "featured_segments/view"],
  ["emails/view", "foreign_segments/list"],
  ["emails/view", "foreign_segments/view"],

  ["emails/edit", "emails/view"],
]

class RoleForm extends Component {
  toggleFeature = (name, value) => {
    const { change, destinations, formValues } = this.props

    if (formValues[name] !== value) {
      change(name, value)
    }

    if (value === "on") {
      dependencies
        .filter(([dependent]) => dependent === name)
        .forEach(([_, dependency]) => {
          this.toggleFeature(dependency, "on")
        })
    }

    if (value === "off") {
      dependencies
        .filter(([_, dependency]) => dependency === name)
        .forEach(([dependent]) => {
          this.toggleFeature(dependent, "off")
        })
    }

    if (name === "segments/export") {
      destinations.forEach(destination => {
        this.toggleFeature(`segments/export/${destination.id}`, value)
      })
    }

    if (name.startsWith("segments/export/")) {
      if (value === "off") {
        change("segments/export", "off")
      }

      if (value === "on") {
        const areAllOtherExportsOn = Object.entries(formValues)
          .filter(([feature]) => feature.startsWith("segments/export/") && feature !== name)
          .every(([_, value]) => value === "on")

        if (areAllOtherExportsOn) {
          change("segments/export", "on")
        }
      }
    }
  }

  toggleAllFeatures = (value, featuresMap) => () => {
    featuresMap.forEach(name => {
      this.toggleFeature(name, value)
    })
  }

  renderFormRow = ({ label, feature, tooltip }) => {
    const { destinations, channels } = this.props

    const areEmailsActive = channels?.find(whereEq({ name: EMAILS_CHANNEL_NAME }))?.is_active
    const areMobilePushesActive = channels?.find(
      whereEq({ name: PUSH_NOTIFICATIONS_CHANNEL_NAME }),
    )?.is_active

    if (
      (feature.startsWith("emails/") && !areEmailsActive) ||
      feature.startsWith("push_notifications/" && !areMobilePushesActive)
    ) {
      return null
    }

    return (
      <div className="row" key={feature}>
        <div className="main">
          <label>
            {label}{" "}
            <Tippy placement="right" content={tooltip}>
              <span>
                <FontAwesomeIcon icon={["fas", "info-circle"]} className="info-icon" />
              </span>
            </Tippy>
          </label>
          <ToggleSwitchField
            name={feature}
            leftValue="off"
            rightValue="on"
            onChange={({ target }) => this.toggleFeature(target.name, target.value)}
            onOffType
            size="sm"
          />
        </div>

        {feature === "segments/export" && destinations.length > 0 && (
          <div className="secondary">
            {destinations.map(destination => {
              return (
                <div className="secondary-row" key={destination.id}>
                  <label>– {destination.name}</label>
                  <ToggleSwitchField
                    name={`${feature}/${destination.id}`}
                    leftValue="off"
                    rightValue="on"
                    onChange={({ target }) => this.toggleFeature(target.name, target.value)}
                    onOffType
                    size="sm"
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }

  renderFeaturesSection = ({ title, items }) => {
    return (
      <Paper className="features-section">
        <div className="header-part">
          <h4>{title}</h4>
          <div className="all-features-actions">
            <Button
              variant="link"
              onClick={this.toggleAllFeatures("off", items.map(prop("feature")))}
              className="button"
            >
              Disable all
            </Button>
            <div className="buttons-delimiter" />
            <Button
              variant="link"
              onClick={this.toggleAllFeatures("on", items.map(prop("feature")))}
              className="button"
            >
              Enable all
            </Button>
          </div>
        </div>
        <div className="features-toggles">{items.map(this.renderFormRow)}</div>
      </Paper>
    )
  }

  selectAttributeVisibilityLabel = labelId => {
    const { formValues, change } = this.props
    const currentLlabelIds = formValues?.attribute_visibility_label_ids ?? []
    change("attribute_visibility_label_ids", [...currentLlabelIds, labelId])
  }

  deselectAttributeVisibilityLabel = labelId => {
    const { formValues, change } = this.props
    change(
      "attribute_visibility_label_ids",
      formValues.attribute_visibility_label_ids.filter(lId => lId !== labelId),
    )
  }

  render() {
    const { closeForm, handleSubmit, onSubmit, isSaving, areLabelsFulfilled, formValues, labels } =
      this.props

    return (
      <Form className="role-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <PaperHeader className="admin-roles-header" size="small">
          <Field
            component={TextField}
            placeholder="Name"
            name="name"
            label="Name role"
            labelPosition="left"
            validate={required}
            maxLength={50}
            className="admin-roles-header-name"
          />
          <div className="right-part">
            <Button color="grey" variant="outlined" onClick={closeForm}>
              Cancel
            </Button>
            <Button loading={isSaving} type="submit">
              Save
            </Button>
          </div>
        </PaperHeader>
        <div className="admin-roles-content form-content">
          <div className="left-column">
            {featuresSections.slice(0, 3).map(this.renderFeaturesSection)}
          </div>
          <div className="right-column">
            {featuresSections.slice(3).map(this.renderFeaturesSection)}
          </div>
        </div>
        <div className="attribute-visibility-settings">
          <Paper className="attribute-visibility-box">
            <h4>
              Attribute visibility protection{" "}
              <InfoTooltip placement="top" className="attribute-visibility-tooltip">
                Specify attributes' labels for which this user role won't see values.
              </InfoTooltip>
            </h4>
            <div className="attribute-visibility-labels">
              {areLabelsFulfilled && (
                <div className="attribute-visibility-labels-list">
                  {Array.isArray(formValues?.attribute_visibility_label_ids) &&
                    formValues.attribute_visibility_label_ids.map(labelId => {
                      const label = labels.find(l => l.id === labelId)
                      const labelName = label?.name ?? labelId
                      return (
                        <Tag
                          key={labelId}
                          color="primary"
                          clickable
                          onClick={() => this.deselectAttributeVisibilityLabel(labelId)}
                        >
                          {labelName}
                        </Tag>
                      )
                    })}
                  <TagPicker
                    className="attribute-visibility-tag-picker"
                    selectedTagIds={formValues?.attribute_visibility_label_ids ?? []}
                    allTags={labels}
                    onTagSelect={this.selectAttributeVisibilityLabel}
                    dropdownAlign="left"
                    type="label"
                  />
                </div>
              )}
            </div>
          </Paper>
        </div>
      </Form>
    )
  }
}

RoleForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  formValues: PropTypes.object,
  areLabelsFulfilled: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  formValues: getFormValues("RoleForm")(state),
})

RoleForm = reduxForm({
  form: "RoleForm",
  touchOnBlur: false,
  enableReinitialize: true,
})(RoleForm)

RoleForm = connect(mapStateToProps)(RoleForm)

export default props => {
  const { data: channels } = useFetchChannels()
  const { data: destinations = [] } = useFetchAllDestinations()
  const { data: labels = [], isSuccess: areLabelsFulfilled } = useFetchAllLabels()

  return (
    <RoleForm
      {...props}
      channels={channels}
      destinations={destinations}
      labels={labels}
      areLabelsFulfilled={areLabelsFulfilled}
    />
  )
}
