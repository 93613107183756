import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field, FieldArray, getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import _trim from "lodash/trim"
import _toLower from "lodash/toLower"
import _isNumber from "lodash/isNumber"
import _forEach from "lodash/forEach"
import _toString from "lodash/toString"
import SimpleBar from "simplebar-react"

import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import IconButton from "components/UI/elements/IconButton/IconButton"
import TextField from "components/UI/elements/TextInput/ReduxFormTextField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import CheckboxReduxFormField from "components/UI/elements/Checkbox/CheckboxReduxFormField"
import Avatar from "components/UI/elements/Avatar"
import SelectField from "components/UI/elements/SelectField"

// actions
import {
  setDefaultPermissions,
  markAllSegments,
  setPermisionsAndMarkSegments,
  setActiveUser,
  toggleAllSwitch,
  toggleAllUserPermissions,
} from "actions/inviteForm.action"
import { showToast } from "actions/toast.action"

// models
import SegmentModel from "resources/segment/segment/segmentModel"

// constants, helpers
import { TOAST, PERMISSION } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { required, email, minLength } from "helpers/validators.helper"

import "./CreateUserModal.scss"
import { useCreateUser, useFetchAllUsersMap, useInviteUser } from "resources/user/userQueries"
import { useHasAccess, useHasSegmentPermission } from "resources/user/currentUserQueries"
import { getFeatureTagLabel } from "resources/userRole/features"
import { useFetchDestinationsMap } from "resources/exportDestination/exportDestinationQueries"
import { useCreateSegmentPermissionsByUserId } from "resources/segmentPermission/segmentPermissionQueries"

const SHOW_FEATURES_DEFAULT_COUNT = 5

class CreateUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      segments: null,
      expandedFeatures: Map(),
      loadingAllSegments: false,
      allSegmentsLoaded: false,
    }
  }

  async componentDidUpdate(prevProps) {
    const { hasAccess, segmentPermission } = this.props

    if (this.props.open && !prevProps.open) {
      if (this.state.segments === null && this.state.loadingAllSegments === false) {
        const caller = new AllResourceItemsFetcher()
        this.setState({ loadingAllSegments: true })

        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.segment.list(
              offset,
              limit,
              "name",
              "ASC",
              "",
              [],
              loadFullStructure,
              1,
              1,
              hasAccess.segments.listForeign ? 1 : 0,
            ),
          )
          .setDataPath("segments")
          .setLoadFullStructure(0)
          .run()

        this.setState({
          loadingAllSegments: false,
          allSegmentsLoaded: true,
          segments: List(
            data
              .filter(segment => segmentPermission(segment.id) === PERMISSION.WRITE)
              .map(segment => new SegmentModel(segment)),
          ),
          expandedFeatures: Map(),
        })
      } else {
        this.setState({
          expandedFeatures: Map(),
        })
      }
    }
  }

  onSubmit = values => {
    const { showToast } = this.props
    const { page, loading } = this.state

    if (page === 1) {
      this.changeNextPage(true)
    } else {
      if (!loading) {
        this.setState({ loading: true })

        this.usersCreated = 0
        this.createUsers(
          List(values["invite-users"]),
          values?.send_invitation_emails ? "invite" : "create",
          isSuccess => {
            this.setState({ loading: false })
            if (isSuccess) {
              if (this.usersCreated > 0) {
                let toastMessage = ""
                if (values.send_invitation_emails) {
                  toastMessage = `${this.usersCreated === 1 ? "Invite" : "Invites"}  sent.`
                } else {
                  toastMessage = `${this.usersCreated === 1 ? "User" : "Users"} created.`
                }

                showToast(toastMessage, TOAST.TYPE.SUCCESS)
              }
              this.cancel(true)()
            }
          },
        )
      }
    }
  }

  createUsers = (users, apiCallType, cbFn) => {
    if (users.size === 0) {
      cbFn(true)
    } else {
      const { inviteUser, createUser } = this.props
      const user = users.last()
      const data = {
        name: _trim(user.name),
        email: _trim(user.email),
        role_id: user.role.value,
      }

      if (apiCallType === "create") {
        data.password = user.password
      }

      const segmentsAcl = {}
      if (!user.role.features.includes("foreign_segments/edit")) {
        if (user.role.features.includes("foreign_segments/view") && user["segment-permission"]) {
          _forEach(user["segment-selected"], (value, key) => {
            if (value && user["segment-permission"][key] === PERMISSION.WRITE) {
              segmentsAcl[Number(key.split("-")[1])] = PERMISSION.WRITE
            }
          })
        } else {
          if (user["segment-selected"]) {
            _forEach(user["segment-selected"], (value, key) => {
              if (value) {
                segmentsAcl[Number(key.split("-")[1])] =
                  user["segment-permission"] && user["segment-permission"][key]
                    ? user["segment-permission"][key]
                    : PERMISSION.READ
              }
            })
          }
        }
      }
      const callerFunc = apiCallType === "invite" ? inviteUser : createUser
      callerFunc(data, segmentsAcl)
        .then(() => {
          this.usersCreated += 1
          this.createUsers(users.pop(), apiCallType, cbFn)
        })
        .catch(() => {
          this.createUsers(users.pop(), apiCallType, cbFn)
        })
    }
  }

  cancel =
    (resetForm = false) =>
    () => {
      const { handleClose, reset } = this.props
      handleClose()

      // reset after fade out
      if (resetForm) {
        setTimeout(() => {
          this.changeNextPage(false)
          reset()
        }, 500)
      }
    }

  setPermissionsDefault = (segments = null) => {
    const { setDefaultPermissions } = this.props
    if (segments) {
      setDefaultPermissions(
        "CreateUserForm",
        segments.map(ws => ws.id),
      )
    } else if (List.isList(this.state.segments)) {
      setDefaultPermissions("CreateUserForm", this.state.segments.map(ws => ws.id).toArray())
    }
  }

  setSegmentDefaultPermissionsAndMarkThem = (segments, userIndex, markValue) => {
    this.props.setPermisionsAndMarkSegments(
      "CreateUserForm",
      segments.map(sg => sg.id),
      userIndex,
      markValue,
    )
  }

  emailUnique = value => {
    const { formValues, users } = this.props
    const otherFormUsers = _get(formValues, "invite-users")
    if (_isArray(otherFormUsers)) {
      let occurences = 0
      otherFormUsers.forEach(user => {
        if (_toLower(_trim(user.email)) === _toLower(_trim(value))) {
          occurences++
        }
      })
      if (occurences > 1) {
        return "Email has been used more than once"
      }
    }

    const existingUser = Object.values(users).find(
      user => _toLower(_trim(user.email)) === _toLower(_trim(value)),
    )
    if (existingUser && existingUser.deleted) {
      return "User is already in Trash"
    } else if (existingUser && !existingUser.deleted) {
      return "User already exists"
    }
    return undefined
  }

  onMarkAllChange = member => async evt => {
    const { allSegmentsLoaded } = this.state
    const { markAllSegments } = this.props
    const { checked } = evt.currentTarget
    const index = Number(member.match(/\[(\d+)\]/)[1])
    if (_isNumber(index)) {
      if (checked) {
        markAllSegments("CreateUserForm", index, true)
      } else {
        if (allSegmentsLoaded) {
          markAllSegments("CreateUserForm", index, false)
        }
      }
    }
  }

  onToggleAllChange = member => async evt => {
    const { toggleAllSwitch } = this.props
    const { checked } = evt.currentTarget
    const index = Number(member.match(/\[(\d+)\]/)[1])
    if (_isNumber(index)) {
      toggleAllSwitch("CreateUserForm", index, checked === true)
    }
  }

  toggleAllSwitchesIfMarked = member => evt => {
    const { formValues, toggleAllUserPermissions } = this.props
    const index = Number(member.match(/\[(\d+)\]/)[1])
    if (_isNumber(index)) {
      const toggleAll = _get(formValues, `invite-users[${index}].toggle-all`)
      if (toggleAll) {
        toggleAllUserPermissions("CreateUserForm", index, evt.currentTarget.value)
      }
    }
  }

  renderSegments = userIndex => {
    const { formValues } = this.props
    const { segments, loadingAllSegments } = this.state

    if (segments.size === 0) {
      return null
    }

    const member = `invite-users[${userIndex}]`
    let markAllDisabled = false
    let segmentsToggleDisabled = false
    const memberFeatures = _get(formValues, `${member}.role.features`, [])
    let warningMessage = null

    if (memberFeatures.includes("foreign_segments/edit")) {
      markAllDisabled = true
      segmentsToggleDisabled = true
      warningMessage =
        "User will hold Segments/Edit All permission that allows editing all segments."
    } else if (memberFeatures.includes("foreign_segments/view")) {
      markAllDisabled = true
      warningMessage =
        "User will hold Segment/ View All permission that allows viewing all segments, please grant edit permission if needed."
    }

    return (
      <React.Fragment>
        <SimpleBar className="segments">
          <div className="select-all">
            <div className="checkbox-wrapper">
              <Field
                name={`${member}.select-all`}
                id={`${member}.select-all`}
                component={CheckboxReduxFormField}
                onChange={this.onMarkAllChange(member)}
                disabled={markAllDisabled}
                label={
                  <>
                    <div className="horizontal-line" />
                    <span className="bold-text">Mark all</span>
                  </>
                }
                size="sm"
              />
            </div>
            <div className="checkbox-wrapper permission">
              <Field
                name={`${member}.toggle-all`}
                id={`${member}.toggle-all`}
                component={CheckboxReduxFormField}
                onChange={this.onToggleAllChange(member)}
                disabled={segmentsToggleDisabled}
                label={
                  <>
                    <div className="horizontal-line" />
                    <span className="bold-text">Toggle all</span>
                  </>
                }
                size="sm"
              />
            </div>
          </div>
          <div>
            {List.isList(segments) &&
              segments.map(segment => {
                return (
                  <div key={segment.id} className="segment">
                    <div className="checkbox-wrapper">
                      <Field
                        name={`${member}.segment-selected.id-${segment.id}`}
                        id={`${member}.segment-selected.id-${segment.id}`}
                        component={CheckboxReduxFormField}
                        disabled={markAllDisabled}
                        label={
                          <>
                            <div className="horizontal-line" />
                            {segment.name}
                          </>
                        }
                        size="sm"
                      />
                    </div>
                    <div>
                      <ToggleSwitchField
                        name={`${member}.segment-permission.id-${segment.id}`}
                        leftLabel="View"
                        leftValue={PERMISSION.READ}
                        rightLabel="Edit"
                        rightValue={PERMISSION.WRITE}
                        width="7rem"
                        size="small"
                        label=""
                        disabled={
                          _get(formValues, `${member}.segment-selected.id-${segment.id}`) !==
                            true || segmentsToggleDisabled
                        }
                        onChange={this.toggleAllSwitchesIfMarked(member)}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          {loadingAllSegments && <div className="loading-overlay" />}
        </SimpleBar>
        {warningMessage && <p className="warning-message">{warningMessage}</p>}
      </React.Fragment>
    )
  }

  toggleExpandedFeatures = index => () => {
    const { expandedFeatures } = this.state
    const isExpanded = expandedFeatures.get(_toString(index)) ? true : false
    this.setState({
      expandedFeatures: expandedFeatures.set(_toString(index), !isExpanded),
    })
  }

  // redux-form needs stable reference for validation fn, otherwise it ends up in infinite loop
  minLength12 = minLength(12)

  renderInviteUsers = ({ fields, showPasswordFields }) => {
    const { roles, formValues, destinations } = this.props
    const { expandedFeatures } = this.state
    const defaultInviteUser = {
      name: "",
      email: "",
      role: "",
    }

    if (fields.length === 0) {
      fields.push(defaultInviteUser)
    }

    return (
      <React.Fragment>
        {fields.map((member, index) => {
          const features = _get(formValues, `${member}.role.features`, null)

          const isExpanded = expandedFeatures.get(_toString(index)) ? true : false
          const isExpandable = _isArray(features) && features.length > SHOW_FEATURES_DEFAULT_COUNT

          return (
            <div className="form-row" key={index}>
              <div className="first-inner-row">
                <Avatar
                  email={fields.get(index)["email"] ? fields.get(index)["email"] : ""}
                  name={fields.get(index)["name"] ? fields.get(index)["name"] : ""}
                  gravatarSize={200}
                  className="first-step-avatar"
                />
                <Field
                  name={`${member}.email`}
                  component={TextField}
                  validate={[required, email, this.emailUnique]}
                  placeholder="Email"
                  className="email-field"
                  autoFocus
                />
                <div className="name-pwd-wrapper">
                  <Field
                    name={`${member}.name`}
                    component={TextField}
                    placeholder="Name"
                    className="name-field"
                    validate={required}
                  />
                  {showPasswordFields && (
                    <Field
                      name={`${member}.password`}
                      component={TextField}
                      type="password"
                      placeholder="Password"
                      className="password-field"
                      validate={[required, this.minLength12]}
                    />
                  )}
                </div>
                <IconButton
                  color="red"
                  onClick={() => {
                    fields.remove(index)
                    fields.forEach(field => {
                      // hack to reset validation
                      setTimeout(() => this.props.blur("CreateUserForm", `${field}.email`), 100)
                    })
                  }}
                  className="trash-icon"
                  disabled={fields.length === 1}
                  size="xs"
                  tooltip="Delete"
                  icon="trash-alt"
                  variant="outlined"
                />
              </div>
              <div className="second-inner-row">
                <Field
                  component={SelectField}
                  name={`${member}.role`}
                  label="Role *"
                  placeholder="Select role"
                  validate={required}
                  isSearchable={true}
                  isLoading={roles ? false : true}
                  options={roles ? roles : []}
                  maxDropdownHeight="100px"
                />
                <div className="permissions-list">
                  <p className="label">Permissions:</p>
                  {features === null && (
                    <p className="no-role-selected">Select the role to see permissions.</p>
                  )}
                  {_isArray(features) && features.length === 0 && (
                    <p className="no-role-selected">Selected role has no permissions.</p>
                  )}
                  {_isArray(features) && features.length > 0 && (
                    <React.Fragment>
                      {features.map((feature, index) => {
                        if (!isExpanded && index >= SHOW_FEATURES_DEFAULT_COUNT) {
                          return null
                        }
                        return (
                          <span key={feature} className="feature">
                            {getFeatureTagLabel(feature, destinations)}
                          </span>
                        )
                      })}
                      {isExpandable && (
                        <Button
                          size="xs"
                          variant="link"
                          onClick={this.toggleExpandedFeatures(index)}
                        >
                          {isExpanded
                            ? "Show less"
                            : `Show more (+${features.length - SHOW_FEATURES_DEFAULT_COUNT})`}
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        <Button
          icon="plus-circle"
          iconStyle="far"
          variant="link"
          onClick={() => fields.push(defaultInviteUser)}
          className={`invite-more-btn ${fields.length > 4 ? "hidden" : ""}`}
        >
          add more people
        </Button>
      </React.Fragment>
    )
  }

  renderPermissions = ({ fields, activeUser }) => {
    return (
      <React.Fragment>
        <div className="form-row permissions">
          {fields.map((member, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`permissions-user ${index === activeUser ? "active" : ""}`}
                  onClick={() => {
                    this.props.setActiveUser("CreateUserForm", index)
                  }}
                >
                  <Avatar
                    email={fields.get(index)["email"]}
                    name={fields.get(index)["name"]}
                    gravatarSize={200}
                    className="img"
                    disabledLook={index !== activeUser}
                  />
                  <p className="permissions-name">{fields.get(index)["name"]}</p>
                </div>
                {index + 1 !== fields.length && <div className="vr-line" />}
              </React.Fragment>
            )
          })}
        </div>
        {this.renderSegments(activeUser)}
      </React.Fragment>
    )
  }

  changeNextPage = isNext => {
    const { setActiveUser } = this.props

    if (isNext) {
      this.setPermissionsDefault()
      setActiveUser("CreateUserForm")
      this.setState({
        page: 2,
      })
    } else {
      this.setState({
        page: 1,
      })
    }
  }

  renderActionBtns = () => {
    const { page, loadingAllSegments } = this.state

    return (
      <div className="action-buttons">
        {page === 1 && (
          <Button color="grey" size="md" variant="text" onClick={this.cancel(false)}>
            Cancel
          </Button>
        )}
        {page === 2 && (
          <Button size="md" onClick={() => this.changeNextPage(false)}>
            Back
          </Button>
        )}

        <span className="pagination-lbl">{page}/2</span>

        {page === 1 && (
          <Button size="md" type="submit" className={page === 1 ? "" : "hide"}>
            Next
          </Button>
        )}

        {page === 2 && (
          <Button
            disabled={loadingAllSegments}
            loading={this.state.loading}
            size="md"
            type="submit"
          >
            Create User
          </Button>
        )}
      </div>
    )
  }

  renderFieldArray = () => {
    const { formValues } = this.props
    const { page, segments, loadingAllSegments } = this.state

    if (page === 1) {
      return (
        <FieldArray
          name="invite-users"
          component={this.renderInviteUsers}
          rerenderOnEveryChange={true}
          props={{ showPasswordFields: !formValues?.send_invitation_emails }}
        />
      )
    } else if (page === 2) {
      if (loadingAllSegments) {
        return <div className="loading-segments">Loading all segments...</div>
      } else {
        return (
          <FieldArray
            name="invite-users"
            component={this.renderPermissions}
            rerenderOnEveryChange={true}
            props={{ segments, activeUser: formValues["active-user"] }} // This is to force this FieldArray to rerender
          />
        )
      }
    }
  }

  render() {
    const { open, handleSubmit } = this.props
    const { page } = this.state
    return (
      <Modal
        open={open}
        handleClose={this.cancel(false)}
        title="Create user"
        className="modal-list"
      >
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} className="modal-list-form">
          {page === 1 && (
            <div className="send-emails">
              <Field
                name="send_invitation_emails"
                label="Send an invitation"
                component={CheckboxReduxFormField}
              />
            </div>
          )}
          <SimpleBar className="scrollable">
            <div className="create-user-list">{this.renderFieldArray()}</div>
          </SimpleBar>
          {this.renderActionBtns()}
        </form>
        <div className="form-row regulations-note-wrapper">
          <p className="regulations-note">
            Please ensure that you are compliant with Meiro's Data Privacy regulations
          </p>
        </div>
      </Modal>
    )
  }
}

CreateUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  roles: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    formValues: getFormValues("CreateUserForm")(state),
  }
}

CreateUserModal = reduxForm({
  form: "CreateUserForm",
  touchOnBlur: false,
})(
  connect(mapStateToProps, {
    showToast,
    setDefaultPermissions,
    markAllSegments,
    setPermisionsAndMarkSegments,
    setActiveUser,
    toggleAllSwitch,
    toggleAllUserPermissions,
  })(CreateUserModal),
)

export default props => {
  const { data: users = {} } = useFetchAllUsersMap()
  const hasAccess = useHasAccess()
  const hasSegmentPermission = useHasSegmentPermission()
  const { data: destinationsMap = {} } = useFetchDestinationsMap()
  const createSegmentPermissionsMutation = useCreateSegmentPermissionsByUserId()

  const createMutation = useCreateUser()
  const createUser = async (data, segmentPermissions) => {
    const user = await createMutation.mutateAsync({ data })

    if (Object.keys(segmentPermissions).length) {
      await createSegmentPermissionsMutation.mutateAsync({ userId: user.id, segmentPermissions })
    }

    return user
  }

  const inviteMutation = useInviteUser()
  const inviteUser = async (data, segmentPermissions) => {
    const user = await inviteMutation.mutateAsync({ data })

    if (Object.keys(segmentPermissions).length) {
      await createSegmentPermissionsMutation.mutateAsync({ userId: user.id, segmentPermissions })
    }

    return user
  }

  return (
    <CreateUserModal
      {...props}
      users={users}
      createUser={createUser}
      inviteUser={inviteUser}
      hasAccess={hasAccess}
      segmentPermission={hasSegmentPermission}
      destinationsMap={destinationsMap}
    />
  )
}
