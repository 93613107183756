import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { Link } from "react-router-dom"

import { Segment } from "resources/segment/segment/segmentTypes"
import { getRoutePath } from "routes"
import { SegmentType } from "types/util"

import styles from "./SegmentChip.module.scss"

export type SegmentChipProps = {
  hasAccess: boolean
  segmentId: Segment["id"]
  segmentName: Segment["name"]
  segmentType?: SegmentType
  deleted?: boolean
  variant?: "regular" | "filled"
}

export default function SegmentChip({
  hasAccess,
  segmentId,
  segmentName,
  segmentType,
  deleted = false,
  variant = "regular",
}: SegmentChipProps) {
  const disabled = deleted || !hasAccess

  const chip = (
    <div
      className={classNames(styles.chip, {
        [styles.disabled]: disabled,
        [styles.filled]: variant === "filled",
      })}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon icon={["fas", "filter"]} />
      </div>
      <span className={styles.name}>
        <b>{segmentName}</b>
      </span>
    </div>
  )

  return (
    <Tippy
      disabled={!disabled}
      content={
        deleted
          ? "Segment has been removed."
          : !hasAccess
          ? "You don't have permission to view this segment"
          : ""
      }
      placement="bottom"
    >
      {segmentType ? (
        <Link
          to={{
            pathname: getRoutePath(
              segmentType === "custom"
                ? "segments.custom.detail"
                : segmentType === "featured"
                ? "segments.featured.detail"
                : "segments.smart.detail",
              { id: segmentId },
            ),
            state: { previous: true },
          }}
          className={classNames(styles.link, { [styles.disabled]: disabled })}
        >
          {chip}
        </Link>
      ) : (
        chip
      )}
    </Tippy>
  )
}
