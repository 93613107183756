import React, { useRef, useState } from "react"
import classNames from "classnames"
import styles from "./ActivityChart.module.scss"
import IconButton from "components/UI/elements/IconButton/IconButton"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import { Bar } from "react-chartjs-2"
import { ascend, assocPath, pipe, prop, sort, sum } from "ramda"
import { CategoryData, FilterType, SubcategoryData } from "types/usersActivity"
import { categoryColor, categoryLabel, subcategoryLabel } from "./categories"
import SelectField from "components/UI/elements/SelectField"
import { SelectOption } from "types/util"
import { lightenDarkenColor } from "helpers/chartjs.helper"
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels"
import { optionsBase } from "./chartOptionsBase"
import useKeyListener from "hooks/useKeyListener"
import { User } from "resources/user/userTypes"
import { addCategoryTotal } from "./addCategoryTotal"
import Tippy from "@tippyjs/react"
import { abbreviateNumber } from "helpers/number.helper"
import { ChartOptions, ChartData } from "chart.js"
import makeCustomTooltip from "./makeCustomTooltip"
import { descend } from "utilities/comparators"
import { UserRole } from "resources/userRole/userRoleTypes"

type ActivityChartProps = {
  categoryData: CategoryData
  xAxisLabels: string[]
  userOptions: SelectOption<User["id"]>[]
  roleOptions: SelectOption<UserRole["id"]>[]
  filterType: FilterType
}

type ExpandState = "collapsed" | "expandStart" | "expanding" | "expanded" | "collapseStart"
type PositionStyle = { top: number; bottom: number; left: number; right: number }

export default function ActivityChart({
  categoryData: { id: categoryId, subcategory_data, total_interactions },
  xAxisLabels,
  userOptions,
  roleOptions,
  filterType,
}: ActivityChartProps) {
  const subcategoryDataWithTotal = addCategoryTotal(
    sort(
      ascend(({ id }) => subcategoryLabel(id)),
      subcategory_data,
    ),
  )

  const subcategoryOptions = subcategoryDataWithTotal.map(({ id }) => ({
    value: id,
    label: subcategoryLabel(id),
  }))
  const [selectedSubcategory, setSelectedSubcategory] = useState<SelectOption<string>>(
    subcategoryOptions[0],
  )

  const getLabel = (id: SubcategoryData["datasets"][number]["id"]) => {
    switch (filterType) {
      case "all_users":
      case "user_ids":
        return userOptions.find(({ value }) => value.toString() === id)?.label ?? id
      case "user_role_ids":
        return roleOptions.find(({ value }) => value.toString() === id)?.label ?? id
      case "domains":
        return id
      default:
        return id
    }
  }

  const baseColor = categoryColor(categoryId)

  const { datasets } = subcategoryDataWithTotal.find(({ id }) => id === selectedSubcategory.value)!

  const data: ChartData = {
    labels: xAxisLabels,
    // Sort by total number of interactions over the whole date range, descending
    datasets: sort(descend(pipe(prop("data"), sum)), datasets).map(({ id, data }, index) => {
      const color = lightenDarkenColor(baseColor, 25 * (index % 5))

      return {
        label: getLabel(id),
        data: data.slice(),
        borderColor: color,
        backgroundColor: color,
      }
    }),
  }

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [expandState, setExpandState] = useState<ExpandState>("collapsed")
  const [originalRect, setOriginalRect] = useState<PositionStyle>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  const toggleChart = () => {
    if (expandState === "collapsed") {
      const wrapperRect = wrapperRef.current!.getBoundingClientRect()
      setOriginalRect({
        top: wrapperRect.top,
        bottom: window.innerHeight - wrapperRect.bottom,
        left: wrapperRect.left,
        right: window.innerWidth - wrapperRect.right,
      })
      setExpandState("expandStart")
      setTimeout(() => setExpandState("expanding"), 20)
      setTimeout(() => setExpandState("expanded"), 200)
    } else if (expandState === "expanded") {
      setExpandState("collapseStart")
      setTimeout(() => setExpandState("collapsed"), 200)
    }
  }

  const expandedWidth = Math.min(1160, window.innerWidth - 200)
  const expandedPosition = {
    top: 100,
    bottom: 100,
    left: (window.innerWidth - expandedWidth) / 2,
    right: (window.innerWidth - expandedWidth) / 2,
  }

  const containerStyle: Record<ExpandState, PositionStyle | undefined> = {
    collapsed: undefined,
    expandStart: originalRect,
    expanding: expandedPosition,
    expanded: expandedPosition,
    collapseStart: originalRect,
  }

  useKeyListener("Escape", () => {
    if (expandState === "expanded") {
      toggleChart()
    }
  })

  const chartRef = useRef<Bar>(null)

  const dataLabelFormatter = (_: any, ctx: Context) =>
    // Only show label above the last dataset (the top one in the column)
    expandState === "expanded" && ctx.datasetIndex === ctx.chart.data.datasets!.length - 1
      ? // Show sum across all datasets for that particular data point (column)
        abbreviateNumber(sum(ctx.chart.data.datasets!.map(({ data }: any) => data[ctx.dataIndex])))
      : ""

  const chartOptions: ChartOptions = pipe<[ChartOptions], ChartOptions, ChartOptions>(
    assocPath(["tooltips", "custom"], makeCustomTooltip(chartRef)),
    assocPath(["plugins", "datalabels", "formatter"], dataLabelFormatter),
  )(optionsBase)

  return (
    <div
      className={classNames(styles.expandWrapper, {
        [styles.collapsed]: expandState === "collapsed",
        [styles.expandStart]: expandState === "expandStart",
        [styles.expanding]: expandState === "expanding",
        [styles.expanded]: expandState === "expanded",
        [styles.collapseStart]: expandState === "collapseStart",
      })}
      ref={wrapperRef}
    >
      <div onClick={toggleChart} className={styles.overlay}></div>
      <div className={styles.container} style={containerStyle[expandState]}>
        <PaperHeader className={styles.header}>
          <div className={styles.title}>{categoryLabel(categoryId)}</div>
          <div className={styles.interactions}>
            Total interactions:{" "}
            <Tippy content={total_interactions}>
              <span className={styles.interactionsValue}>
                {abbreviateNumber(total_interactions)}
              </span>
            </Tippy>
          </div>
          {subcategoryDataWithTotal.length > 1 && (
            <SelectField
              input={{
                value: selectedSubcategory,
                onChange: (option: SelectOption<string>) => setSelectedSubcategory(option),
              }}
              options={subcategoryOptions}
              size="small"
              className={styles.subcategoryPicker}
            />
          )}
          <IconButton
            className={styles.expandButton}
            icon={expandState === "collapsed" ? "expand-alt" : "times"}
            iconStyle="far"
            tooltip={expandState === "collapsed" ? "Expand" : "Close"}
            onClick={toggleChart}
          />
        </PaperHeader>
        <Paper hasHeader className={styles.chartWrapper}>
          <Bar data={data} options={chartOptions} plugins={[ChartDataLabels]} ref={chartRef} />
        </Paper>
      </div>
    </div>
  )
}
