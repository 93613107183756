import React from "react"
import classNames from "classnames"
import { update, whereEq } from "ramda"

import InfoTooltip from "components/UI/elements/InfoTooltip"
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import { hexToRgba } from "helpers/chartjs.helper"
import { useFetchStitchingAttributes } from "resources/attribute/attributeQueries"
import { Attribute } from "resources/attribute/attributeTypes"
import { ActiveAttributes } from "../types"

import styles from "./IdentityGraphFilter.module.scss"

type IdentityGraphFilterProps = {
  activeAttributes: ActiveAttributes
  searchIdentifier: string
  setActiveAttributes: (newValue: ActiveAttributes) => void
  setSearchIdentifier: (searchIdentifier: string) => void
}

export default function IdentityGraphFilter({
  activeAttributes,
  searchIdentifier,
  setActiveAttributes,
  setSearchIdentifier,
}: IdentityGraphFilterProps) {
  const { data: stitchingAttributes = [] } = useFetchStitchingAttributes()

  const toggleIdentifier = (attributeId: Attribute["id"]) => {
    const identifierIndex = activeAttributes.findIndex(whereEq({ id: attributeId }))
    const identifier = activeAttributes[identifierIndex]

    const newValue = update(
      identifierIndex,
      { ...identifier, active: !identifier.active },
      activeAttributes,
    )
    setActiveAttributes(newValue)
  }

  return (
    <div className={styles.container}>
      <Paper hasHeader={true} className={styles.searchFieldPaper}>
        <h3>
          <span>
            Identity graph{" "}
            <InfoTooltip placement="right" className={styles.infoTooltip} interactive>
              Identity Graph shows how your customer entity has been stitched from various
              identifiers across data sources.{" "}
              <a
                href="https://docs.meiro.io/books/meiro-knowledgebase/page/identity-stitching-and-how-customer-identity-appears"
                target="_blank"
                rel="noreferrer noopener"
              >
                Learn more here
              </a>
              .
            </InfoTooltip>
          </span>
        </h3>

        <SearchField
          placeholder="Search for identifier value"
          type="text"
          input={{
            value: searchIdentifier,
            onChange: (value: string) => setSearchIdentifier(value),
          }}
          onClear={() => setSearchIdentifier("")}
          size="large"
          wrapperClassName={styles.searchFieldWrapper}
        />
      </Paper>

      {activeAttributes.length > 0 && (
        <Paper className={styles.identifiersPaper}>
          <div className={styles.label}>Filter identifier:</div>
          <div className={styles.identifiers}>
            {stitchingAttributes.map(attribute => {
              const graphIdentifier = activeAttributes.find(whereEq({ id: attribute.id }))
              if (!graphIdentifier) return null

              return (
                <div key={attribute.id} className={styles.identifier}>
                  <div
                    className={classNames(styles.dot, {
                      [styles.active]: graphIdentifier.active,
                    })}
                    style={{
                      backgroundColor: hexToRgba(attribute.identity_graph_color, 0.5),
                      borderColor: hexToRgba(attribute.identity_graph_color, 1),
                    }}
                  />

                  <button
                    className={classNames(styles.button, {
                      [styles.active]: graphIdentifier.active,
                    })}
                    style={{ color: hexToRgba(attribute.identity_graph_color, 1) }}
                    onClick={() => toggleIdentifier(attribute.id)}
                    type="button"
                  >
                    {attribute.name}
                  </button>
                </div>
              )
            })}
          </div>
        </Paper>
      )}
    </div>
  )
}
