import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext } from "react"
import classNames from "classnames"
import styles from "./NavBar.module.scss"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink } from "react-router-dom"
import Tippy from "@tippyjs/react"

export const NavBarContext = createContext({
  expandedItem: null as string | null,
  isActiveItemExpanded: false,
  isNavExpanded: false,
  setExpandedItem: (_ => {}) as Dispatch<SetStateAction<string | null>>,
  setIsActiveItemExpanded: (_ => {}) as Dispatch<SetStateAction<boolean>>,
  expandNav: () => {},
  resetNavState: () => {},
})

type NavItemProps = {
  id: string
  path: string
  name: string
  hasAccess?: boolean
  icon: IconName
  children?: ReactNode
}

export function NavItem({ id, path, name, hasAccess, icon, children }: NavItemProps) {
  const {
    expandedItem,
    isActiveItemExpanded,
    isNavExpanded,
    setExpandedItem,
    setIsActiveItemExpanded,
    expandNav,
    resetNavState,
  } = useContext(NavBarContext)

  const isHome = path === "/"
  const isActive =
    (!isHome && window.location.pathname.startsWith(path)) ||
    (isHome && window.location.pathname === "/")
  const isExpanded = (isActive && isActiveItemExpanded) || expandedItem === id
  const isExpandable = Boolean(children)

  return (
    <li className={classNames(styles.navItem, { [styles.active]: isActive })}>
      <Tippy placement="right" content={name} disabled={isNavExpanded}>
        <Tippy
          placement="bottom"
          content="Your user role has limited access to this tab. To edit permissions contact the administrator."
          className="disabledMenuTooltip"
          disabled={hasAccess !== false}
        >
          {
            <NavLink
              exact={isHome}
              to={path}
              className={classNames(styles.navItemLink, {
                [styles.disabled]: hasAccess === false,
              })}
              activeClassName={styles.active}
              onClick={e => {
                if (hasAccess === false) {
                  e.preventDefault()
                  return
                }
                if (isExpandable) {
                  // No navigation; only toggle sub-items list
                  e.preventDefault()
                  if (isNavExpanded) {
                    // Nav is already expanded; toggle sub-items
                    if (isActive) {
                      setIsActiveItemExpanded(s => !s)
                    } else {
                      setExpandedItem(s => (s === id ? null : id))
                    }
                  } else {
                    expandNav()
                    // Expanding nav; open sub-items
                    if (isActive) {
                      setIsActiveItemExpanded(true)
                    } else {
                      setExpandedItem(id)
                    }
                  }
                } else {
                  // Navigate & reset
                  resetNavState()
                }
              }}
            >
              <FontAwesomeIcon icon={[isActive ? "fas" : "far", icon]} className={styles.navIcon} />
              <span className={styles.navName}>{name}</span>
              {isExpandable && (
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  className={styles.expandIcon}
                  flip={isExpanded ? "vertical" : undefined}
                />
              )}
            </NavLink>
          }
        </Tippy>
      </Tippy>
      {isExpanded && children && <ul className={styles.subItemsList}>{children}</ul>}
    </li>
  )
}

type NavSubItemProps = {
  path: string
  name: string
  hasAccess?: boolean
  isNew?: boolean
  isBeta?: boolean
}

export function NavSubItem({ name, path, hasAccess, isNew, isBeta }: NavSubItemProps) {
  const { resetNavState } = useContext(NavBarContext)

  return (
    <li className={styles.navSubItem}>
      <NavLink
        to={path}
        className={classNames(styles.navSubItemLink, {
          [styles.disabled]: hasAccess === false,
          [styles.new]: isNew,
          [styles.beta]: isBeta,
        })}
        activeClassName={styles.active}
        onClick={e => {
          if (hasAccess === false) {
            e.preventDefault()
          }
          // Navigate & reset
          resetNavState()
        }}
      >
        <Tippy
          placement="bottom"
          content="Your user role has limited access to this tab. To edit permissions contact the administrator."
          className="disabledMenuTooltip"
          disabled={hasAccess !== false}
        >
          <div className={styles.navSubItemName}>{name}</div>
        </Tippy>
      </NavLink>
    </li>
  )
}

type NavGroupProps = {
  name: string
  children: ReactNode
}

export function NavGroup({ name, children }: NavGroupProps) {
  return (
    <li className={styles.navGroup}>
      <div className={styles.navGroupName}>{name}</div>
      <ul className={styles.navGroupList}>{children}</ul>
    </li>
  )
}
