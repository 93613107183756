import React from "react"
import { useHistory } from "react-router-dom"

import { getRoutePath } from "routes"
import MetaAttributesForm from "./components/MetaAttributesForm/MetaAttributesForm"
import { useCreateMetaAttribute } from "resources/metaAttributes/metaAttributesQueries"

export default function MetaAttributesCreate() {
  const history = useHistory()

  const { mutate } = useCreateMetaAttribute()

  return (
    <MetaAttributesForm
      onCancel={() => history.push(getRoutePath("administration.metaAttributes"))}
      onSubmit={data =>
        mutate(
          { data },
          {
            onSuccess: ({ meta_attribute: { id } }) =>
              history.push(getRoutePath("administration.metaAttributes.detail", { id })),
          },
        )
      }
      isCreate
    />
  )
}
