import React, { useState } from "react"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import IdentityStitchingForm from "../components/IdentityStitchingForm/IdentityStitchingForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import "./IdentityStitchingDetail.scss"
import { useFetchAllEvents } from "resources/event/eventQueries"
import { prop, sort } from "ramda"
import { ascend } from "utilities/comparators"
import Page from "components/UI/Page/Page"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { Redirect, useHistory, useParams } from "react-router-dom"
import {
  useDeleteStitchingAttribute,
  useFetchStitchingAttributesMap,
  useModifyStitchingAttribute,
} from "resources/stitchingAttribute/stitchingAttributeQueries"

export default function IdentityStitchingDetail() {
  const { id } = useParams()
  const isCreate = id === undefined
  const history = useHistory()
  const { data: events = [], isSuccess: areEventsFulfilled } = useFetchAllEvents({
    includeHidden: true,
  })
  const eventOptions = sort(
    ascend(prop("label")),
    events.map(event => ({
      label: `${event.source.name}: ${event.name}`,
      value: event.id,
      hidden: event.is_hidden === 1,
      source_id: event.source.id,
    })),
  )
  const { data: stitchingAttributesMap = {}, isSuccess: areStitchingAttributesFulfilled } =
    useFetchStitchingAttributesMap()
  const modifyMutation = useModifyStitchingAttribute()
  const deleteMutation = useDeleteStitchingAttribute()
  const stitchingAttribute = isCreate ? undefined : stitchingAttributesMap[id]
  const modifyStitchingAttribute = ({ attribute_id, configs }) =>
    modifyMutation.mutate(
      {
        attributeId: attribute_id,
        data: configs,
        oldAttributeId: id && id !== attribute_id ? id : undefined,
      },
      {
        onSuccess() {
          history.push(getRoutePath("administration.identity-stitching"))
        },
      },
    )
  const deleteStitchingAttribute = attributeId =>
    deleteMutation.mutate(
      { attributeId },
      {
        onSuccess() {
          history.push(getRoutePath("administration.identity-stitching"))
        },
      },
    )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  if (!isCreate && areStitchingAttributesFulfilled && !stitchingAttribute) {
    return <Redirect to={getRoutePath("not-found")} />
  }
  const initialValues = isCreate ? { configs: [{}] } : stitchingAttribute

  return (
    <Page
      className="identity-stitching-detail"
      title={`${isCreate ? "Create" : "Edit"} stitching rule`}
      headerContent={
        areStitchingAttributesFulfilled && (
          <div className="header-buttons">
            {!isCreate && (
              <Button
                color="red"
                icon="trash-alt"
                variant="outlined"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>
            )}
            <Button
              color="grey"
              variant="outlined"
              onClick={() => {
                history.push(getRoutePath("administration.identity-stitching"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton
              isLoading={modifyMutation.isLoading}
              formName="IdentityStitchingForm"
            />
          </div>
        )
      }
    >
      {!areStitchingAttributesFulfilled && <LoadingIndicator />}
      {areStitchingAttributesFulfilled && (
        <Paper className="identity-stitching-detail-content">
          <IdentityStitchingForm
            onSubmit={modifyStitchingAttribute}
            initialValues={initialValues}
            eventOptions={eventOptions}
            areEventsFulfilled={areEventsFulfilled}
          />
          <ConfirmModal
            open={isDeleteModalOpen}
            type={MODAL.TYPE.DELETE}
            handleClose={() => setIsDeleteModalOpen(false)}
            handleConfirm={() => deleteStitchingAttribute(id)}
            title="Are you sure?"
            action="delete"
            text="Do you really want to delete these identinty stitching rules?"
            isLoading={deleteMutation.isLoading}
          />
        </Paper>
      )}
    </Page>
  )
}
